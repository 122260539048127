import { CalculationProgressEvent } from '@fintastic/web/data-access/calc'
import {
  status,
  user,
  versionId,
  taskId,
  error,
  errorCode,
  errorMessage,
  errorDetails,
  userModifiedEntities,
  dependenciesGraphAffectedFlag,
  processedCalculationActions,
  createdListRows,
  deletedListRows,
  userModifiedListIds,
  userModifiedMetricIds,
} from './selectors'
import {
  successful,
  failed,
  triggeredByUser,
  triggeredByFormulaEditAction,
  triggeredByUpdateModelAction,
  triggeredByInputEditAction,
  triggeredByDuplicateListRowsAction,
  triggeredByDeleteListRowsAction,
  triggeredByReplaceListAction,
  triggeredByCreateListAction,
  triggeredByCreateMetricAction,
  triggeredByReplaceMetricAction,
  triggeredByAddListRowsAction,
  someEntityIsModifiedByUser,
  dependenciesGraphAffected,
  taskIdMatches,
  errorCausedByEntity,
  errorCausedByListColumn,
  triggeredByDeleteEntityAction,
} from './predicates'
import { DropFistArgument } from '@fintastic/shared/util/types'

export class ImmutableCalculationProgressEvent {
  readonly #data: CalculationProgressEvent

  constructor(event: CalculationProgressEvent) {
    this.#data = event
  }

  unwrap() {
    return this.#data
  }

  private get data() {
    return this.unwrap()
  }

  public get status() {
    return status(this.data)
  }

  public get user() {
    return user(this.data)
  }

  public get versionId() {
    return versionId(this.data)
  }

  public get taskId() {
    return taskId(this.data)
  }

  public get error() {
    return error(this.data)
  }

  public get errorCode() {
    return errorCode(this.data)
  }

  public get errorMessage() {
    return errorMessage(this.data)
  }

  public get errorDetails() {
    return errorDetails(this.data)
  }

  public get userModifiedEntities() {
    return userModifiedEntities(this.data)
  }

  public get dependenciesGraphAffectedFlag() {
    return dependenciesGraphAffectedFlag(this.data)
  }

  public get processedCalculationActions() {
    return processedCalculationActions(this.data)
  }

  public get createdListRows() {
    return createdListRows(this.data)
  }

  public get deletedListRows() {
    return deletedListRows(this.data)
  }

  public get userModifiedListIds() {
    return userModifiedListIds(this.data)
  }

  public get userModifiedMetricIds() {
    return userModifiedMetricIds(this.data)
  }

  public successful = (...args: DropFistArgument<typeof successful>) =>
    successful(this.data, ...args)

  public failed = (...args: DropFistArgument<typeof failed>) =>
    failed(this.data, ...args)

  public triggeredByUser = (
    ...args: DropFistArgument<typeof triggeredByUser>
  ) => triggeredByUser(this.data, ...args)

  public triggeredByUpdateModelAction = (
    ...args: DropFistArgument<typeof triggeredByUpdateModelAction>
  ) => triggeredByUpdateModelAction(this.data, ...args)

  public triggeredByFormulaEditAction = (
    ...args: DropFistArgument<typeof triggeredByFormulaEditAction>
  ) => triggeredByFormulaEditAction(this.data, ...args)

  public triggeredByInputEditAction = (
    ...args: DropFistArgument<typeof triggeredByInputEditAction>
  ) => triggeredByInputEditAction(this.data, ...args)

  public triggeredByDuplicateListRowsAction = (
    ...args: DropFistArgument<typeof triggeredByDuplicateListRowsAction>
  ) => triggeredByDuplicateListRowsAction(this.data, ...args)

  public triggeredByAddListRowsAction = (
    ...args: DropFistArgument<typeof triggeredByAddListRowsAction>
  ) => triggeredByAddListRowsAction(this.data, ...args)

  public triggeredByDeleteListRowsAction = (
    ...args: DropFistArgument<typeof triggeredByDeleteListRowsAction>
  ) => triggeredByDeleteListRowsAction(this.data, ...args)

  public triggeredByReplaceListAction = (
    ...args: DropFistArgument<typeof triggeredByReplaceListAction>
  ) => triggeredByReplaceListAction(this.data, ...args)

  public triggeredByCreateListAction = (
    ...args: DropFistArgument<typeof triggeredByCreateListAction>
  ) => triggeredByCreateListAction(this.data, ...args)

  public triggeredByReplaceMetricAction = (
    ...args: DropFistArgument<typeof triggeredByReplaceMetricAction>
  ) => triggeredByReplaceMetricAction(this.data, ...args)

  public triggeredByCreateMetricAction = (
    ...args: DropFistArgument<typeof triggeredByCreateMetricAction>
  ) => triggeredByCreateMetricAction(this.data, ...args)

  public triggeredByDeleteEntityAction = (
    ...args: DropFistArgument<typeof triggeredByDeleteEntityAction>
  ) => triggeredByDeleteEntityAction(this.data, ...args)

  public someEntityIsModifiedByUser = (
    ...args: DropFistArgument<typeof someEntityIsModifiedByUser>
  ) => someEntityIsModifiedByUser(this.data, ...args)

  public dependenciesGraphAffected = (
    ...args: DropFistArgument<typeof dependenciesGraphAffected>
  ) => dependenciesGraphAffected(this.data, ...args)

  public taskIdMatches = (...args: DropFistArgument<typeof taskIdMatches>) =>
    taskIdMatches(this.data, ...args)

  public errorCausedByEntity = (
    ...args: DropFistArgument<typeof errorCausedByEntity>
  ) => errorCausedByEntity(this.data, ...args)

  public errorCausedByListColumn = (
    ...args: DropFistArgument<typeof errorCausedByListColumn>
  ) => errorCausedByListColumn(this.data, ...args)
}

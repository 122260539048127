import { styled } from '@mui/material'

export const StyledType = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 16px;
  margin-top: 4px;
`

export const StyledTypeText = styled('div')`
  margin-right: 6px;
`

export const StyledCalcTypeIcon = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: auto;
  }
`

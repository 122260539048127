import { EChartsOption } from 'echarts'
import {
  ChartDisplaySettings,
  defaultChartLegendFormat,
} from '@fintastic/web/feature/charts'
import { defaultChartTextStyle } from './chart-default-style'
import { Maybe } from '@fintastic/shared/util/types'
import { LegendComponentOption } from 'echarts/types/dist/echarts'

const standardFormatter = (text: string) => text

const compactFormatter = (text: string) => (text || '').replaceAll(',\n', ', ')

export const processLegendSettings = (
  option: Maybe<EChartsOption>,
  settings: ChartDisplaySettings,
  series: EChartsOption['series'],
  showZoomPan?: boolean,
) => {
  if (!option) {
    return
  }
  const titles: string[] = Array.isArray(series)
    ? (series || []).map((v) => (v.name as string) || '')
    : []

  const maxLines =
    settings.legendFormat === 'compact'
      ? titles.reduce(
          (prev, cur) => Math.max((cur || '').split('\n').length, prev),
          1,
        )
      : 1

  const legendContainerHeight = maxLines * 14 + (maxLines === 1 ? 12 : 0) // 14 - default line height, 12 - extra top space
  const legendContainerWidth = settings.legendFormat === 'compact' ? 80 : 120

  const legend: LegendComponentOption = {
    textStyle: {
      ...defaultChartTextStyle,
      overflow: 'truncate',
    },
    orient: 'horizontal',
    icon: 'pin',
    type: 'scroll',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    width: 'auto',
    height: 'auto',
    formatter:
      settings.legendFormat === defaultChartLegendFormat ||
      !settings.legendFormat
        ? compactFormatter
        : standardFormatter,
    tooltip: {
      show: true,
    },
  }

  if (settings?.legendPosition === 'left') {
    legend.orient = 'vertical'
    legend.top = 'center'
    legend.left = 10
    legend.align = 'auto'
    legend.width = legendContainerWidth
    legend.height = '80%'
    legend.textStyle = {
      ...legend.textStyle,
      width: legendContainerWidth - 20,
    }

    option.grid = {
      ...option.grid,
      left: legendContainerWidth + 20, // 20 - gap space
    }
  }

  if (settings?.legendPosition === 'right') {
    legend.orient = 'vertical'
    legend.top = 'center'
    legend.height = '80%'
    legend.right = 0
    legend.width = 120
    // legend.align = 'right'
    legend.textStyle = {
      ...legend.textStyle,
      width: legendContainerWidth - 20,
    }

    option.grid = {
      ...option.grid,
      right: legendContainerWidth + 20,
    }
  }

  if (settings?.legendPosition === 'top') {
    legend.left = 'center'
    legend.height = legendContainerHeight
    legend.width = '90%'
    legend.top = 12
    legend.textStyle = {
      ...legend.textStyle,
      height: legend.height,
    }

    option.grid = {
      ...option.grid,
      top: legendContainerHeight + 30,
    }
  }

  // default
  if (settings?.legendPosition === 'bottom' || !settings?.legendPosition) {
    const currentBottom = showZoomPan ? 50 : 5

    legend.bottom =
      (settings.legendFormat === 'compact' ? 10 : 5) + currentBottom
    legend.left = 'center'
    legend.width = '90%'
    legend.height = legendContainerHeight
    legend.textStyle = {
      ...legend.textStyle,
      height: legend.height,
    }

    // https://github.com/apache/echarts/issues/14724
    option.grid = {
      ...option.grid,
      bottom: legendContainerHeight + 20,
      top: 40,
    }
  }

  option.legend = legend
}

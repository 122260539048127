import { useQuery } from 'react-query'
import { Maybe } from '@fintastic/shared/util/types'
import { metricsAndListsCacheKeys } from '../cache'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import {
  ChartAggRequestDimension,
  isChartAggRequestDimensionVersion,
  loadChartDataMultiversion,
} from '../api/metrics-chart-api'
import { MetricChartDimensions } from '@fintastic/web/feature/charts'
import { AxiosError } from 'axios'
import { LoadChartDataRequestError } from '../types'

export const mapChartDimensionsToRequestDimensions = (
  dimensions?: Maybe<MetricChartDimensions>,
): ChartAggRequestDimension[] =>
  dimensions && dimensions.length > 0
    ? dimensions.map((d) => {
        if (d.type === 'Version') {
          return {
            version: true,
          }
        }
        return {
          dimension_uid: d.id,
          aggregate: Boolean(d.aggregate),
          time_dimension: d.type === 'Time',
        }
      })
    : []

export const isChartAggRequestDimensionsEquals = (
  dims1: ChartAggRequestDimension[],
  dims2: ChartAggRequestDimension[],
) => {
  if (!dims1 || !dims2) {
    return false
  }

  if (dims1.length !== dims2.length) {
    return false
  }

  const hasDiffers = dims1.some((d1, i) => {
    const d2 = dims2[i]

    if (
      isChartAggRequestDimensionVersion(d1) !==
      isChartAggRequestDimensionVersion(d2)
    ) {
      return true
    }

    if (
      isChartAggRequestDimensionVersion(d1) &&
      isChartAggRequestDimensionVersion(d2)
    ) {
      return d1.version !== d2.version
    }

    if (
      !isChartAggRequestDimensionVersion(d1) &&
      !isChartAggRequestDimensionVersion(d2)
    ) {
      if (d1.time_dimension) {
        return d1.time_dimension !== d2.time_dimension
      }

      return (
        d1.dimension_uid !== d2.dimension_uid || d1.aggregate !== d2.aggregate
      )
    }
    return false
  })
  return !hasDiffers
}

export function useLoadMetricChartDataMultiversion(
  versionIds: string[],
  metricId: string,
  periodSelection?: PeriodSelection,
  chartRequestDimensions?: ChartAggRequestDimension[],
  enabled = true,
) {
  const isQueryEnabled =
    !!versionIds &&
    versionIds.length > 0 &&
    !!metricId &&
    !!chartRequestDimensions &&
    chartRequestDimensions.length > 0 &&
    enabled

  return useQuery(
    metricsAndListsCacheKeys.metricChartMultiversion(
      versionIds,
      metricId,
      periodSelection,
      chartRequestDimensions || [],
    ),
    async () => {
      if (!versionIds || versionIds.length === 0 || !metricId) {
        throw new Error('versionIds or metricId is not defined')
      }
      try {
        return await loadChartDataMultiversion(
          versionIds,
          metricId,
          periodSelection,
          chartRequestDimensions || [],
        )
      } catch (err) {
        let errorCode = 520 // unknown server error
        let errorMessage = ''

        if (typeof (err as AxiosError).response?.status !== 'undefined') {
          errorCode = Number((err as AxiosError).response?.status)
        }

        if (typeof (err as AxiosError).message !== 'undefined') {
          errorMessage = (err as AxiosError).message
        }

        throw new LoadChartDataRequestError(
          errorCode,
          errorMessage || 'Error on requesting data',
          versionIds.join(','),
          metricId,
        )
      }
    },
    {
      enabled: isQueryEnabled,
      refetchInterval: false as const,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
    },
  )
}

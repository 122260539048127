import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { ConfirmationPopup } from '@fintastic/shared/ui/modal-framework'
import { useAuthUserInfo } from '@fintastic/web/feature/auth'
import { useQueryClient } from 'react-query'
import { useConnectionStatus } from '../connection-status'
import { Maybe } from '@fintastic/shared/util/types'

export const LostConnectionNotification: React.FC = () => {
  const {
    tabIsVisible,
    networkOnline,
    prevNetworkOnline,
    pusherConnection,
    prevPusherConnection,
    doublePrevPusherConnection,
  } = useConnectionStatus()

  const authenticated = useAuthUserInfo() !== undefined
  const queryClient = useQueryClient()

  const [showPopup, setShowPopup] = useState<Maybe<'network' | 'pusher'>>(null)

  const invalidateCache = useCallback(
    (log: () => void) => {
      log()
      queryClient.invalidateQueries()
    },
    [queryClient],
  )

  const showPopupTimeoutId = useRef<Maybe<number>>(null)
  const cleanTimouts = useCallback(() => {
    if (showPopupTimeoutId.current) {
      clearTimeout(showPopupTimeoutId.current)
    }
  }, [])

  const invalidationHappendWithKeyRef = useRef<Maybe<string>>(null)
  useEffect(() => {
    if (!authenticated) {
      cleanTimouts()
      return
    }

    if (!tabIsVisible) {
      cleanTimouts()
      return
    }

    if (showPopup) {
      if (pusherConnection === 'connected' && networkOnline) {
        setShowPopup(null)
      }
    }

    const networkJustReconnected = networkOnline && prevNetworkOnline === false
    const pusherJustReconnected =
      pusherConnection === 'connected' &&
      (prevPusherConnection === 'unavailable' ||
        prevPusherConnection === 'failed' ||
        (prevPusherConnection === 'connecting' &&
          (doublePrevPusherConnection === 'unavailable' ||
            doublePrevPusherConnection === 'failed')))

    const invalidationHappendWithKey = JSON.stringify({
      networkOnline,
      prevNetworkOnline,
      pusherConnection,
      prevPusherConnection,
      doublePrevPusherConnection,
    })

    if (
      (networkJustReconnected || pusherJustReconnected) &&
      invalidationHappendWithKeyRef.current !== invalidationHappendWithKey
    ) {
      invalidateCache(() => {
        Sentry.captureMessage(
          `All cache is invalidated after successfull reconnection. Details: ${JSON.stringify(
            {
              networkJustReconnected,
              pusherJustReconnected,
            },
          )}`,
          'log',
        )
      })
      cleanTimouts()
      invalidationHappendWithKeyRef.current = invalidationHappendWithKey
      return
    }

    if (!networkOnline) {
      cleanTimouts()
      showPopupTimeoutId.current = setTimeout(() => {
        setShowPopup('network')
      }, 0) as unknown as number
      return
    }

    if (pusherConnection !== 'connected') {
      cleanTimouts()
      showPopupTimeoutId.current = setTimeout(() => {
        setShowPopup('pusher')
      }, 40_000) as unknown as number
      return
    }
  }, [
    authenticated,
    cleanTimouts,
    doublePrevPusherConnection,
    invalidateCache,
    networkOnline,
    prevNetworkOnline,
    prevPusherConnection,
    pusherConnection,
    showPopup,
    tabIsVisible,
  ])

  useEffect(() => {
    if (!authenticated) {
      return
    }
    if (showPopup === 'pusher') {
      Sentry.captureMessage('An "Unstable connection" popup is shown', 'log')
    }
    if (showPopup === 'network') {
      Sentry.captureMessage('A "Trying to connect" popup is shown', 'log')
    }
  }, [showPopup, authenticated])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => cleanTimouts, [])

  if (!showPopup || !authenticated) {
    return null
  }

  if (showPopup === 'network') {
    return (
      <ConfirmationPopup
        open
        title="Trying to connect…"
        description={
          <span>You seem to be offline. Please check your connection.</span>
        }
        onConfirm={refreshPage}
        showCloseButton={false}
      />
    )
  }

  return (
    <ConfirmationPopup
      open
      title="Unstable connection"
      description={
        <span>Your internet connection is unstable. Click to refresh.</span>
      }
      onConfirm={refreshPage}
      showCloseButton={false}
    >
      <ConfirmationPopup.ConfirmButton variant="contained" color="primary">
        Refresh
      </ConfirmationPopup.ConfirmButton>
    </ConfirmationPopup>
  )
}

const refreshPage = () => {
  document.location.reload()
}

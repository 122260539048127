import {
  useDirectNavigationExecutor,
  DirectNavigationErrorWithMeta,
} from '@fintastic/web/feature/direct-navigation'
import { VersionTable } from '@fintastic/web/feature/versions'
import { sleep } from 'effection'
import { useMemo } from 'react'
import { RowNode } from 'ag-grid-community'
import { GridRef } from '@fintastic/shared/util/ag-grid'
import { Maybe } from '@fintastic/shared/util/types'

type Dependencies = {
  gridRef: Maybe<GridRef<VersionTable>>
}

export const useVersionPageDirectNavigationExecutor = (
  versionId: string,
  dependencies: Dependencies,
) => {
  useDirectNavigationExecutor<Dependencies>(
    useMemo(
      () => ({
        subtaskId: 'versionPage/goToMasterDetail',
        shouldStartExecution: ({ task }) =>
          (task.params.type === 'metricCell' ||
            task.params.type === 'listRows' ||
            task.params.type === 'listColumnCell') &&
          task.params.coordinates.versionId === versionId,
        dependencies,
        executor: function* ({ taskParams, getDependency }) {
          if (
            !(
              taskParams.type === 'metricCell' ||
              taskParams.type === 'listRows' ||
              taskParams.type === 'listColumnCell'
            )
          ) {
            throw new Error('unsupported target type')
          }

          const gridApi = yield* getDependency(
            (deps) => deps.gridRef?.current?.api,
            {
              retries: 4,
              retryInterval: 1000,
              debugLabel: 'gridApi',
            },
          )

          let rowNode: RowNode<VersionTable>

          if (taskParams.type === 'metricCell') {
            const targetRowNode = gridApi.getRowNode(
              `${taskParams.coordinates.metricId}:existing`,
            )
            if (!targetRowNode) {
              throw new MetricDoesNotExistError()
            }
            if (!targetRowNode.displayed) {
              throw new MetricIsNotCurrentlyVisibleError()
            }
            rowNode = targetRowNode
          }

          if (
            taskParams.type === 'listColumnCell' ||
            taskParams.type === 'listRows'
          ) {
            const targetRowNode = gridApi.getRowNode(
              `${taskParams.coordinates.listId}:existing`,
            )
            if (!targetRowNode) {
              throw new ListDoesNotExistError()
            }
            if (!targetRowNode.displayed) {
              throw new ListIsNotCurrentlyVisibleError()
            }
            rowNode = targetRowNode
          }

          gridApi.setRowNodeExpanded(rowNode!, true)

          yield* sleep(200)

          gridApi.ensureNodeVisible(rowNode!, 'top')
        },
      }),
      [dependencies, versionId],
    ),
  )
}

class MetricDoesNotExistError
  extends Error
  implements DirectNavigationErrorWithMeta
{
  constructor() {
    super()
    this.name = 'MetricDoesNotExistError'
    Object.setPrototypeOf(this, MetricDoesNotExistError.prototype)
  }

  getUiMessage(): string {
    return 'The row or cell cannot be highlighted because the Metric has been deleted and is no longer available.'
  }

  getSeverity() {
    return 'error' as const
  }
}

class MetricIsNotCurrentlyVisibleError
  extends Error
  implements DirectNavigationErrorWithMeta
{
  constructor() {
    super()
    this.name = 'MetricIsNotCurrentlyVisibleError'
    Object.setPrototypeOf(this, MetricIsNotCurrentlyVisibleError.prototype)
  }

  getUiMessage(): string {
    return "To highlight cells and rows, ensure that filters and search on the version table aren't applied."
  }

  getSeverity() {
    return 'warning' as const
  }
}

class ListDoesNotExistError
  extends Error
  implements DirectNavigationErrorWithMeta
{
  constructor() {
    super()
    this.name = 'ListDoesNotExistError'
    Object.setPrototypeOf(this, ListDoesNotExistError.prototype)
  }

  getUiMessage(): string {
    return 'The row or cell cannot be highlighted because the List has been deleted and is no longer available.'
  }

  getSeverity() {
    return 'error' as const
  }
}

class ListIsNotCurrentlyVisibleError
  extends Error
  implements DirectNavigationErrorWithMeta
{
  constructor() {
    super()
    this.name = 'ListIsNotCurrentlyVisibleError'
    Object.setPrototypeOf(this, ListIsNotCurrentlyVisibleError.prototype)
  }

  getUiMessage(): string {
    return "To highlight cells and rows, ensure that filters and search on the version table aren't applied."
  }

  getSeverity() {
    return 'warning' as const
  }
}

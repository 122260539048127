import React, { useMemo } from 'react'
import { NavButton } from '@fintastic/shared/ui/view-layout'
import { Icon } from './Icon'
import {
  mapDataTypeToItsLabel,
  MetricConfigurableDataValueType,
  MetricDataValueType,
} from '@fintastic/web/util/metrics-and-lists'

export type ValueDataFormatNavButtonProps = {
  onClick: () => void
  dataType: MetricDataValueType
}

export const ValueDataFormatNavButton: React.FC<
  ValueDataFormatNavButtonProps
> = ({ onClick, dataType }) => (
  <NavButton
    onClick={onClick}
    primaryLabel="Value data format"
    secondaryLabel={mapDataTypeToItsLabel(
      dataType as MetricConfigurableDataValueType,
    )}
    icon={<Icon />}
  />
)

import { styled } from '@mui/material'

export const StyledLoader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px 24px;

  width: 100%;
`

import React, { useEffect, useRef, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

export type TabDefinition = {
  key: string
  content: React.ReactNode
  isOpen: boolean
  close: () => void
}

export const useLateralPanelTabs = (tabs: TabDefinition[]): React.ReactNode => {
  const [currentTab, setCurrentTab] = useState<Maybe<string>>(null)
  const prevTabs = useRef<TabDefinition[]>([])

  useEffect(() => {
    const newlyOpenedTabs = tabs.filter(
      (tab) =>
        tab.isOpen && !prevTabs.current.find((t) => t.key === tab.key)?.isOpen,
    )
    const newlyClosedTabs = tabs.filter(
      (tab) =>
        !tab.isOpen &&
        !!prevTabs.current.find((t) => t.key === tab.key)?.isOpen,
    )

    if (newlyOpenedTabs.length) {
      const newCurrentTab = newlyOpenedTabs[newlyOpenedTabs.length - 1].key
      tabs.forEach((tab) => {
        if (tab.key === newCurrentTab) {
          return
        }
        tab.close()
      })
      setCurrentTab(newCurrentTab)
      prevTabs.current = tabs
      return
    }

    if (newlyClosedTabs.find((tab) => tab.key === currentTab)) {
      setCurrentTab(null)
      prevTabs.current = tabs
      return
    }

    prevTabs.current = tabs
  }, [currentTab, tabs])

  return tabs.find((t) => t.key === currentTab && t.isOpen)?.content || null
}

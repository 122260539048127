import { MonthOverMonthToggleIcon } from '@fintastic/shared/ui/icons'
import { PanelToolbarIconButton } from '@fintastic/shared/ui/panel-framework'
import React, { useCallback } from 'react'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'

export const MonthOverMonthToggleButton: React.FC<{
  active: boolean
  enabled: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean | undefined>>
}> = ({ active, enabled, setActive }) => {
  const handleToggle = useCallback(
    () => setActive((prev) => !prev),
    [setActive],
  )

  const multiplePeriodOverPeriodDiffEnabled = useIsFeatureEnabled(
    'multiple_period_over_period_diff',
  )

  return (
    <PanelToolbarIconButton
      data-testid="toggle-diff-between-time-periods-button"
      data-active={active}
      title={
        active
          ? 'Hide variance between time periods'
          : 'Show variance between time periods'
      }
      disabledTitle={
        multiplePeriodOverPeriodDiffEnabled
          ? 'Select a single version to enable period over period variance'
          : 'Select a single version and 2 periods to enable period over period variance'
      }
      onClick={handleToggle}
      color={active ? 'primary' : 'secondary'}
      tooltipProps={{
        arrow: true,
        placement: 'left',
      }}
      selected={active}
      disabled={!enabled}
    >
      <MonthOverMonthToggleIcon />
    </PanelToolbarIconButton>
  )
}

import React, { useCallback, useMemo } from 'react'
import {
  Filter,
  FilterDateRange,
  FilterListAPIOperatorBlankNotBlank,
  FilterListAPIOperatorRange,
  FilterListAPIOperatorSingleValue,
  FilterRange,
  filterUtils,
  FilterValue,
} from '@fintastic/web/util/filters'
import { Box, Button, MenuItem, Select, SelectChangeEvent } from '@mui/material'

export const RangedFilterInput = <
  TValue extends number | string,
  TRange extends FilterRange | FilterDateRange,
  Props extends object = object,
>({
  filterValue,
  onChange,
  disableClear,
  RangeFieldComponent,
  NormalFieldComponent,
  filter,
  ...restProps
}: RangedFilterInputProps<TValue, TRange, Props>): React.ReactNode => {
  const handleChangeOperator = useCallback(
    (e: SelectChangeEvent<SingleValueFilterOperator>) => {
      if (e.target.value === 'range') {
        onChange({
          operator: e.target.value as SingleValueFilterOperator,
          value: ['', ''] as TRange,
        })
      } else {
        const nextValue = (
          typeof filterValue.value === 'string' ||
          typeof filterValue.value === 'number'
            ? filterValue.value
            : ''
        ) as TValue

        onChange({
          operator: e.target.value as SingleValueFilterOperator,
          value: nextValue,
        })
      }
    },
    [filterValue, onChange],
  )

  const handleClearFilter = useCallback(() => {
    onChange({
      operator: 'equal',
      value: '',
    })
  }, [onChange])

  const isEmpty = useMemo<boolean>(
    () => filterUtils.isFilterValueEmpty(filter, filterValue),
    [filter, filterValue],
  )

  const operatorSelect = (
    <Select<SingleValueFilterOperator>
      onChange={handleChangeOperator}
      value={(filterValue.operator as SingleValueFilterOperator) ?? 'equal'}
      size="small"
      fullWidth
    >
      {singleValueOperatorsEntries.map(([k, label]) => (
        <MenuItem key={k} value={k} dense>
          {label}
        </MenuItem>
      ))}
    </Select>
  )

  const clear = disableClear ? null : (
    <Box display="flex" justifyContent="flex-end">
      <Button variant="outlined" disabled={isEmpty} onClick={handleClearFilter}>
        Clear
      </Button>
    </Box>
  )

  if (
    filterValue.operator === 'not_blank' ||
    filterValue.operator === 'blank'
  ) {
    return (
      <Box>
        <Box py={1}>
          <Box mb={1}>{operatorSelect}</Box>
        </Box>
        {clear}
      </Box>
    )
  }

  return (
    <Box>
      <Box py={1}>
        <Box mb={1}>{operatorSelect}</Box>
        {filterValue.operator === 'range' ? (
          <RangeFieldComponent
            filterValue={filterValue as FilterValue<TRange>}
            onChange={onChange}
            {...(restProps as Props)}
          />
        ) : (
          <NormalFieldComponent
            filterValue={filterValue as FilterValue<TValue>}
            onChange={onChange}
            {...(restProps as Props)}
          />
        )}
      </Box>
      {clear}
    </Box>
  )
}

export type RangedFilterInputProps<
  TValue extends number | string,
  TRange extends FilterRange | FilterDateRange,
  Props extends object = object,
> = {
  filterValue: Partial<FilterValue<TValue | TRange>>
  filter: Filter
  onChange: (nextValue: FilterValue<TValue | TRange | ''>) => void
  disableClear: boolean
  RangeFieldComponent: React.FC<
    {
      filterValue: Partial<FilterValue<TRange>>
      onChange: (nextValue: FilterValue<TRange>) => void
    } & Props
  >
  NormalFieldComponent: React.FC<
    {
      filterValue: Partial<FilterValue<TValue | ''>>
      onChange: (nextValue: FilterValue<TValue | ''>) => void
    } & Props
  >
} & Props

const singleValueOperators: Record<
  | FilterListAPIOperatorSingleValue['operator']
  | FilterListAPIOperatorBlankNotBlank['operator']
  | FilterListAPIOperatorRange['operator'],
  string
> = {
  equal: 'Equals',
  not_equal: 'Not equal',
  less: 'Less than',
  less_equal: 'Less than or equals',
  greater: 'Greater than',
  greater_equal: 'Greater than or equals',
  range: 'In range',
  blank: 'Blank',
  not_blank: 'Not blank',
}

type SingleValueFilterOperator = keyof typeof singleValueOperators

const singleValueOperatorsEntries = Object.entries(singleValueOperators)

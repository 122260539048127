import {
  BoardsIcon,
  LayoutSidebarConfig,
  LayoutSidebarNavSectionConfig,
  LiveActualsIcon,
  SettingsIcon,
  VersionsIcon,
} from '@fintastic/shared/ui/app-layout-framework'
import {
  BOARDS_SIDEBAR_BAR_ITEM_KEY,
  BoardsSidebarMenu,
} from '@fintastic/web/feature/boards'
import { useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { compact } from 'lodash'
import { useAvailableSettingsList } from './useAvailableSettingsList'
import { SettingsSidebarMenu } from './SettingsSidebarMenu'
import { useLiveActualsVersion } from '@fintastic/web/data-access/versions'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'

export const useSidebarConfig = (): LayoutSidebarConfig => {
  const [settingsEnabled, availableSettings] = useAvailableSettingsList()
  const isStandardUser = Boolean(useRoleLevelAccess(['standard']))

  const settingsPage = useMemo<Maybe<LayoutSidebarNavSectionConfig>>(() => {
    if (!settingsEnabled) {
      return null
    }

    if (compact(Object.values(availableSettings)).length < 2) {
      // Remove this if extra links apper to dimensions or permissions
      const to = availableSettings.dimensions
        ? '/settings/dimensions'
        : '/settings/permissions#users'
      return {
        key: 'settings-link',
        icon: SettingsIcon,
        type: 'link',
        title: 'Settings',
        isActive: (location) => location.pathname.includes('settings'),
        to,
      }
    }

    return {
      key: 'settings-link',
      icon: SettingsIcon,
      type: 'menu',
      title: 'Settings',
      isActive: (location) => location.pathname.includes('settings'),
      menu: <SettingsSidebarMenu availableSettings={availableSettings} />,
    }
  }, [availableSettings, settingsEnabled])

  const { liveActualsVersion } = useLiveActualsVersion()

  return useMemo<LayoutSidebarConfig>(() => {
    const result: LayoutSidebarConfig = {
      top: [
        {
          key: BOARDS_SIDEBAR_BAR_ITEM_KEY,
          icon: BoardsIcon,
          type: 'menu',
          title: 'Boards',
          menu: <BoardsSidebarMenu />,
          isActive: (location) => location.pathname.includes('board'),
        },
      ],
      footer: compact([settingsPage]),
    }

    if (!isStandardUser) {
      result.top.push({
        key: 'versions',
        icon: VersionsIcon,
        type: 'link',
        title: 'Versions',
        to: '/versions',
        isActive: (location) => location.pathname.includes('versions'),
      })

      result.top.push({
        key: 'live-actuals',
        icon: LiveActualsIcon,
        type: 'link',
        title: 'Live Actuals',
        to: '/live-actuals',
        isActive: (location) => location.pathname.includes('live-actuals'),
        disabled: !liveActualsVersion,
      })
    }
    return result
  }, [settingsPage, isStandardUser, liveActualsVersion])
}

import { GridApi, RowNode } from 'ag-grid-community'
import {
  MetricDataValue,
  reduceSparse,
  SparseMetricData,
} from '@fintastic/web/util/metrics-and-lists'
import {
  DimensionId,
  DimensionValueId,
  RangeDimensionId,
  TimeDimensionId,
} from '@fintastic/web/util/dimensions'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import { MetricGridRow } from '../components/metric-grid/types'
import {
  createFieldKey,
  createRowKey,
} from '@fintastic/web/util/metrics-and-lists'

export type MetricCellCoordinates = {
  versionId: string
  metricId: string
  timeDimension?: TimeDimensionId
  dimensions: Record<DimensionId, DimensionValueId>
}

export type SetMetricCellDataValuePayload = {
  coordinates: MetricCellCoordinates
  value: MetricDataValue
}

export type ListColumnCellCoordinates = MetricCellCoordinates & {
  rowDimension: RangeDimensionId
}

export type SetListColumnCellDataValuePayload = {
  coordinates: ListColumnCellCoordinates
  value: MetricDataValue
}

export const CUSTOM_CELL_VALUE_CHANGED_EVENT_SOURCE = 'custom:setCellDataValue'

export const setMetricCellDataValue = (
  api: GridApi<MetricGridRow>,
  { value, coordinates }: SetMetricCellDataValuePayload,
) => {
  const cell = findCellInMetricOrListGrid(api, coordinates, getMetricGridRowId)
  if (!cell) {
    throw new Error(
      `Cell level update for metrics. Coud not find a cell with coordinates: ${JSON.stringify(
        coordinates,
      )} and put the value '${String(value)}' of type '${typeof value}'`,
    )
  }
  setCellValue(cell.rowNode, cell.columnKey, value)
}

export const setListColumnCellDataValue = (
  api: GridApi<MetricGridRow>,
  { value, coordinates }: SetListColumnCellDataValuePayload,
) => {
  const cell = findCellInMetricOrListGrid(
    api,
    coordinates,
    (c) => c.dimensions[c.rowDimension],
  )
  if (!cell) {
    throw new Error(
      `Cell level update for lists. Coud not find a cell with coordinates: ${JSON.stringify(
        coordinates,
      )} and put the value '${String(value)}' of type '${typeof value}'`,
    )
  }
  setCellValue(cell.rowNode, cell.columnKey, value)
}

const setCellValue = (
  rowNode: RowNode<MetricGridRow>,
  columnKey: string,
  value: MetricDataValue,
) => {
  rowNode.setDataValue(columnKey, value, CUSTOM_CELL_VALUE_CHANGED_EVENT_SOURCE)
}

export const findCellInMetricOrListGrid = <
  TCoords extends MetricCellCoordinates | ListColumnCellCoordinates,
>(
  api: GridApi<MetricGridRow>,
  coordinates: TCoords,
  rowIdGetter: (coordinates: TCoords) => string,
): Maybe<{ rowNode: RowNode<MetricGridRow>; columnKey: string }> => {
  const rowNode = findRowInMetricOrListGrid(api, rowIdGetter(coordinates))
  if (!rowNode) {
    return null
  }

  const columnKey = getColumnKey(coordinates)
  if (!columnExist(api, columnKey)) {
    return null
  }

  return {
    rowNode,
    columnKey,
  }
}

export const findRowInMetricOrListGrid = (
  api: GridApi<MetricGridRow>,
  rowId: string,
) => toMaybe(api.getRowNode(rowId))

const columnExist = (api: GridApi<MetricGridRow>, columnKey: string) =>
  api.getColumnDef(columnKey) !== null

export const getMetricGridRowId = (
  coordinates: MetricCellCoordinates,
): string => {
  if (!coordinates.timeDimension) {
    return createRowKey(coordinates.dimensions)
  }

  const dimsCopy = { ...coordinates.dimensions }
  delete dimsCopy[coordinates.timeDimension]
  return createRowKey(dimsCopy)
}

const getColumnKey = (
  coordinates: MetricCellCoordinates | ListColumnCellCoordinates,
): string =>
  createFieldKey(
    coordinates.versionId,
    coordinates.metricId,
    coordinates.timeDimension
      ? toMaybe(coordinates.dimensions[coordinates.timeDimension])
      : null,
  )

export const metricDataToSetCellValuePayloads = (
  partialCoordinates: Omit<MetricCellCoordinates, 'dimensions'>,
  data: SparseMetricData,
) =>
  reduceSparse<SetMetricCellDataValuePayload[]>(
    data,
    (acc, dims, value) => {
      acc.push({
        coordinates: {
          ...partialCoordinates,
          dimensions: Object.fromEntries(dims),
        },
        value,
      })
      return acc
    },
    [],
  )

export const listColumnsDataToSetCellValuePayloads = (
  versionId: string,
  columns: Array<{
    partialCoordinates: Omit<
      ListColumnCellCoordinates,
      'dimensions' | 'versionId'
    >
    data: SparseMetricData
  }>,
): SetListColumnCellDataValuePayload[] =>
  columns.reduce<SetListColumnCellDataValuePayload[]>((payloads, column) => {
    payloads.push(
      ...reduceSparse<SetListColumnCellDataValuePayload[]>(
        column.data,
        (acc, dims, value) => {
          acc.push({
            coordinates: {
              ...column.partialCoordinates,
              versionId,
              dimensions: Object.fromEntries(dims),
            },
            value,
          })
          return acc
        },
        [],
      ),
    )
    return payloads
  }, [])

import React, { CSSProperties } from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import { SmartSelectOption } from '../types'

export type OptionsListItemProps<T> = {
  option: SmartSelectOption<T> | 'all'
  selected: boolean
  disabled: boolean
  onClick: (withShift: boolean) => void
  style: CSSProperties
}

export const OptionsListItem = <T,>({
  option,
  disabled,
  onClick,
  selected,
  style,
}: OptionsListItemProps<T>) => (
  <ListItem disablePadding={true} sx={style} data-testid={'options-list-item'}>
    <ListItemButton
      disabled={disabled}
      role={undefined}
      onClick={(e) => onClick(e.shiftKey)}
      dense
      sx={{
        height: '100%',
      }}
      data-testid={'options-list-item-button'}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
        }}
      >
        <Checkbox
          edge="start"
          checked={selected}
          tabIndex={-1}
          disableRipple
          data-testid={'options-list-item-checkbox-container'}
        />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          variant: 'body1',
          sx: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        data-testid={'options-list-item-label'}
        primary={option === 'all' ? 'All' : option.label}
      />
    </ListItemButton>
  </ListItem>
)

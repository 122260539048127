import {
  GenericReportAggregates,
  GenericReportDefinition,
  GenericReportMetadata,
} from '@fintastic/web/util/generic-report'
import { Maybe } from '@fintastic/shared/util/types'
import { AxiosError } from 'axios'
import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from './endpoints'
import { CommonError, NetworkError } from './errors'
import {
  fromAxiosErrorToFirstMatchedError,
  makeFromAxiosErrorFunction,
} from '@fintastic/shared/util/errors'
import {
  PeriodSelection,
  UnsupportedGranularityAggregationError,
  isEmptyPeriodSelection,
  isRangeBasedSelection,
} from '@fintastic/web/util/period-selector'
import Qs from 'qs'

export type ReportAggregatesAPIResponse = {
  result: GenericReportAggregates
  definition: GenericReportDefinition
  formula?: Maybe<string>
  object_id?: Maybe<string>
  revision: number
  metadata?: GenericReportMetadata
  ordered_time_labels: string[]
}

export function getReportAggregatesAPI(
  versionId: string,
  periodSelection: PeriodSelection,
  category: string,
  includeTotalsColumn: boolean,
) {
  if (isEmptyPeriodSelection(periodSelection)) {
    throw new Error('Cannot load report for an empty period selection')
  }

  const periodSelectionParams = isRangeBasedSelection(periodSelection)
    ? {
        from_dim_val: periodSelection.range[0],
        to_dim_val: periodSelection.range[1],
        aggregation: periodSelection.aggregationDimensionId,
        time_dim_id: periodSelection.dimensionId,
      }
    : {
        periods: periodSelection.periods,
        aggregation: periodSelection.aggregationDimensionId,
        time_dim_id: periodSelection.dimensionId,
      }

  return axios.post<ReportAggregatesAPIResponse>(
    endpoints.aggregates(versionId, category),
    {},
    {
      params: {
        ...periodSelectionParams,
        with_period_totals: includeTotalsColumn,
      },
      paramsSerializer: (p) => Qs.stringify(p, { arrayFormat: 'repeat' }),
    },
  )
}

export const castError = (error: AxiosError): GenericReportAggregatesError =>
  fromAxiosErrorToFirstMatchedError(error, [
    RestrictedByPermissionsError,
    UnsupportedGranularityAggregationError,
    MissingLookupError,
  ]) || new NetworkError()

export const successfulResponseToError = (
  response: Maybe<Partial<Pick<ReportAggregatesAPIResponse, 'result'>>>,
): Maybe<RestrictedByPermissionsError> =>
  response
    ? RestrictedByPermissionsError.fromSuccessfulResponse(response)
    : null

export type GenericReportAggregatesError = CommonError | KnownError

type KnownError =
  | MissingLookupError
  | RestrictedByPermissionsError
  | UnsupportedGranularityAggregationError

export class RestrictedByPermissionsError extends Error {
  public ERROR_TEXT =
    'Your permissions settings do not allow you to view the data in this Report or the Report is empty'

  constructor() {
    super()
    this.name = 'RestrictedByPermissionsError'
    Object.setPrototypeOf(this, RestrictedByPermissionsError.prototype)
  }

  public static fromAxiosError = makeFromAxiosErrorFunction(
    RestrictedByPermissionsError,
    (e) => e.response?.status === 403,
  )

  public static fromSuccessfulResponse = (
    r: Partial<Pick<ReportAggregatesAPIResponse, 'result'>>,
  ): Maybe<RestrictedByPermissionsError> => {
    if (r.result?.length === 0) {
      return new RestrictedByPermissionsError()
    }
    return null
  }
}

export class MissingLookupError extends Error {
  public ERROR_TEXT =
    'Dimensions dependencies used by the Report do not exist in the version'

  constructor() {
    super()
    this.name = 'MissingLookupError'
    Object.setPrototypeOf(this, MissingLookupError.prototype)
  }

  public static fromAxiosError = makeFromAxiosErrorFunction(
    MissingLookupError,
    (e) => e.response?.status === 406,
  )
}

import React, { useCallback, useMemo } from 'react'
import { ViewLayout } from '@fintastic/shared/ui/view-layout'
import { AggregationFunctionSettings } from '../../../shared/settings/aggregation-function'
import { useTimeAggregation } from './useTimeAggregation'
import { useCategoryAggregation } from './useCategoryAggregation'
import { useDataContext } from '../../connection/data/data-context'
import { useActionsContext } from '../../connection/actions/actions-context'
import {
  canMetricBeAppliedAsWeightsFor,
  CompactMetric,
  MetricConfigurableDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { metricAdtCommonSelectors } from '@fintastic/web/data-access/metrics-and-lists'

const defaultMetricsList: CompactMetric[] = []

export const MetricAggregationFunction: React.FC = () => {
  const { metricsList, metric } = useDataContext()
  const { readonly } = useActionsContext()
  const timeAggregation = useTimeAggregation()
  const categoryAggregation = useCategoryAggregation()

  const metricId = metric?.id()
  const dimensionsIds = useMemo(
    () => metric?.data()?.dimensions() || [],
    [metric],
  )
  const timeDimension = metric?.timeDimension() || null

  const metricAllowedForWeights = useCallback(
    (weghtingMetric: CompactMetric) =>
      canMetricBeAppliedAsWeightsFor(
        {
          id: weghtingMetric.id,
          dataType: metricAdtCommonSelectors.dataType(weghtingMetric),
          timeDimension: metricAdtCommonSelectors.timeDimension(weghtingMetric),
          dimensionsIds: metricAdtCommonSelectors
            .dimensionsMetadata(weghtingMetric)
            .map(({ id }) => id),
        },
        {
          id: metricId || '',
          dimensionsIds,
          timeDimension,
        },
      ),
    [timeDimension, dimensionsIds, metricId],
  )

  if (!metric) {
    return null
  }

  return (
    <ViewLayout title={'Aggregation function'}>
      <AggregationFunctionSettings
        timeAggregation={timeAggregation}
        categoryAggregation={categoryAggregation}
        metricsList={metricsList || defaultMetricsList}
        readonly={readonly}
        metricHasTimeDimension={metric.hasTimeDimension()}
        dataType={metric.dataType() as MetricConfigurableDataValueType}
        metricAllowedForWeights={metricAllowedForWeights}
      />
    </ViewLayout>
  )
}

import { axios } from '@fintastic/web/data-access/service-axios'
import { Board, BoardShortened } from '../types'

export type GetBoardsResponse = {
  result: BoardShortened[]
}

export function getBoards() {
  return axios.get<GetBoardsResponse>('/planning/api/v1/boards/')
}

export function getBoard(boardId: Board['id']) {
  return axios.get<Board>(`/planning/api/v1/boards/${boardId}/`)
}

export type EditableBoardParams = Partial<{
  name: Board['name']
  config: Board['config']
  inherit_access: Board['inherit_access']
  folder_id: Board['folder_id']
}>

export function copyBoard(boardId: Board['id'], params: EditableBoardParams) {
  return axios.post<Board>(`/planning/api/v1/boards/${boardId}/copy`, params)
}

export function createBoard(params: EditableBoardParams) {
  return axios.post<Board>('/planning/api/v1/boards/', params)
}

export function updateBoard(boardId: Board['id'], params: EditableBoardParams) {
  return axios.patch<Board>(`/planning/api/v1/boards/${boardId}/`, params)
}

export function deleteBoard(boardId: Board['id'], permanently?: boolean) {
  return axios.delete(`/planning/api/v1/boards/${boardId}/`, {
    params: { permanently },
  })
}

export function unDeleteBoard(boardId: Board['id']) {
  return axios.put(`/planning/api/v1/boards/${boardId}/`, {})
}

import { useTypedDrawerContext } from '@fintastic/shared/ui/drawer-framework'
import {
  AddLinesDrawerContext,
  addLinesPayloadType,
} from '@fintastic/web/feature/list-add-lines'
import {
  PeriodSelection,
  usePeriodSelectorContextCalendarConfig,
} from '@fintastic/web/util/period-selector'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ColumnColor,
  ListWithoutData,
} from '@fintastic/web/util/metrics-and-lists'
import {
  BaseGridRow,
  ToolbarRowsActionIconButton,
} from '@fintastic/shared/ui/grid-framework'
import { useModalState } from '@fintastic/shared/util/modal'
import { Modal } from '@fintastic/shared/ui/modal-framework'
import { useLoadListOfLists } from '@fintastic/web/data-access/metrics-and-lists'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'
import { SmartSelect, SmartSelectOption } from '@fintastic/shared/ui/dropdowns'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { Maybe } from '@fintastic/shared/util/types'
import { Box, Button } from '@mui/material'
import { sxMixins } from '@fintastic/shared/ui/mui-style-mixins'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'

type Params = {
  versionId: string
  listId: string
}

type Dependencies = {
  selectedRows: BaseGridRow[]
  periodSelection: PeriodSelection
  columnColors: ColumnColor[]
  listMetadata: Maybe<ListWithoutData>
}

export const useListAddLineIntegration = (
  { versionId, listId }: Params,
  { periodSelection, columnColors, selectedRows, listMetadata }: Dependencies,
) => {
  const { target, setTarget, updateTargetParams } = useTypedDrawerContext(
    addLinesPayloadType,
  ) as AddLinesDrawerContext

  const importRowsModalState = useModalState()

  const isThisListAddLineDrawerActive =
    target.versionId === versionId && target.listId === listId

  const calendarConfig = usePeriodSelectorContextCalendarConfig({
    limitToSelectedEntitiesOnly: false,
  })

  const addLines = useCallback(
    (numberOfRows: number) => {
      setTarget(
        {
          versionId,
          listId,
        },
        { numberOfRows, periodSelection, calendarConfig, columnColors },
      )
    },
    [
      columnColors,
      periodSelection,
      setTarget,
      versionId,
      listId,
      calendarConfig,
    ],
  )

  const importRows = useCallback(
    (targetListId: string) => {
      if (selectedRows.length === 0 || !listMetadata) {
        return
      }

      setTarget(
        {
          listId: targetListId,
          versionId,
        },
        {
          periodSelection,
          calendarConfig,
          columnColors,
          rowsToImport: {
            sourceListMetadata: listMetadata,
            rows: selectedRows,
          },
        },
      )
      importRowsModalState.close()
    },
    [
      calendarConfig,
      columnColors,
      importRowsModalState,
      listMetadata,
      periodSelection,
      selectedRows,
      setTarget,
      versionId,
    ],
  )

  useEffect(() => {
    if (!isThisListAddLineDrawerActive) {
      // foreign addLines Drawer | no addLines Drawer
      return
    }

    updateTargetParams({
      listId,
      versionId,
      periodSelection,
      calendarConfig,
      columnColors,
    })
  }, [
    calendarConfig,
    isThisListAddLineDrawerActive,
    listId,
    periodSelection,
    updateTargetParams,
    versionId,
    columnColors,
  ])

  const isImportEnabled = useIsFeatureEnabled(
    'export_rows_from_one_list_to_another',
  )

  const importRowsButton = useMemo(
    () =>
      isImportEnabled &&
      selectedRows.length > 0 &&
      selectedRows.length <= 100 ? (
        <ImportRowsButton onClick={importRowsModalState.open} />
      ) : null,
    [importRowsModalState.open, isImportEnabled, selectedRows.length],
  )

  const importRowsModal = useMemo(
    () =>
      isImportEnabled ? (
        <InportRowsModal
          isOpen={importRowsModalState.isOpen}
          close={importRowsModalState.close}
          onConfirm={importRows}
          versionId={versionId}
          exportingListId={listId}
        />
      ) : null,
    [
      importRows,
      importRowsModalState.close,
      importRowsModalState.isOpen,
      listId,
      versionId,
      isImportEnabled,
    ],
  )

  return useMemo(
    () => ({
      addLines,
      isThisListAddLineDrawerActive,
      importRowsButton,
      importRowsModal,
    }),
    [
      addLines,
      importRowsButton,
      importRowsModal,
      isThisListAddLineDrawerActive,
    ],
  )
}

const InportRowsModal: React.FC<{
  isOpen: boolean
  close: () => void
  onConfirm: (tartgetListId: string) => void
  versionId: string
  exportingListId: string
}> = ({ isOpen, close, onConfirm, versionId, exportingListId }) => {
  const [targetListId, setTargetListId] = useState<Maybe<string>>(null)

  const listOfLists = useLoadListOfLists(isOpen ? versionId : null)
  const options: SmartSelectOption[] = useMemo(
    () =>
      listOfLists.data
        ?.filter(
          (list) => list.source === 'input' && list.id !== exportingListId,
        )
        .map((list) => ({
          value: list.id,
          label: titleFormatter(list.label),
        })) || [],
    [exportingListId, listOfLists.data],
  )

  return (
    <Modal title="Push lines to List" open={isOpen} onRequestClose={close}>
      {listOfLists.isLoading && (
        <CenteredCircularProgress
          size={24}
          fullScreen={false}
          style={{ height: '100px' }}
        />
      )}
      {!listOfLists.isLoading && (
        <>
          <SmartSelect
            options={options}
            value={targetListId}
            onChange={setTargetListId}
            placeholder="Select target List"
            label="Target List"
          />
          <Box sx={{ ...sxMixins.alignContentCenter(), mt: 3 }}>
            <Button onClick={close} variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={targetListId === null}
              onClick={() => {
                targetListId && onConfirm(targetListId)
              }}
              variant="contained"
              color="primary"
              sx={{ ml: 1 }}
            >
              Apply
            </Button>
          </Box>
        </>
      )}
    </Modal>
  )
}

const ImportRowsButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <ToolbarRowsActionIconButton
    onClick={onClick}
    title="Push lines to another List"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.5 -0.5 16 16"
      height="16"
      width="16"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        d="m8.34375 13.3438125 6.1875 -5.9375 -6.1875 -5.9375V4.84375h-1.625c-3.4375 0 -6.25 2.8125 -6.25 6.25v2.0625c0 0.1875 0.1875 0.375 0.375 0.375h0.0625c0.125 0 0.25 -0.125 0.3125 -0.25 0.125 -1.8125 1.625 -3.1875 3.4375 -3.1875h3.6875v3.2500625Z"
        stroke-width="1"
      />
    </svg>
  </ToolbarRowsActionIconButton>
)

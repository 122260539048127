import { DirectNavigationTarget } from './types'

export const mapTargetToTaskId = (target: DirectNavigationTarget): string => {
  if (target.type === 'metricCell') {
    return `${target.type}/${target.coordinates.versionId}/${
      target.coordinates.metricId
    }/${JSON.stringify(target.coordinates.dimensions)}`
  }

  if (target.type === 'listColumnCell') {
    return `${target.type}/${target.coordinates.versionId}/${
      target.coordinates.listId
    }/${JSON.stringify(target.coordinates.dimensions)}`
  }

  if (target.type === 'listRows') {
    return `${target.type}/${target.coordinates.versionId}/${
      target.coordinates.listId
    }/${JSON.stringify(target.coordinates.rowDimensionValueId)}`
  }

  return ''
}

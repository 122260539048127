import { Maybe } from '@fintastic/shared/util/types'
import {
  ChartColumnData,
  ChartDisplaySettings,
} from '@fintastic/web/feature/charts'
import { EChartsOption } from 'echarts'
import {
  extractChartData,
  getMaxFromArray,
  getMinFromArray,
  prepareOptions,
} from './process'
import { MetricMetadata } from '@fintastic/web/util/metrics-and-lists'

export const encodeForBars = (
  data?: ChartColumnData,
  metadata?: Maybe<MetricMetadata>,
  displaySettings?: ChartDisplaySettings,
  showZoomPanPanel?: boolean,
): Maybe<EChartsOption> => {
  if (!data || data.length <= 1) {
    return null
  }
  const { headers, meaningRows } = extractChartData(data)

  let min: number | undefined = undefined
  let max: number | undefined = undefined

  const series: EChartsOption['series'] = meaningRows.map((row) => {
    const data = row.toSpliced(0, 1)
    const rowMin = getMinFromArray(data as number[])
    const rowMax = getMaxFromArray(data as number[])

    if (typeof min === 'undefined' || rowMin < min) {
      min = rowMin
    }

    if (typeof max === 'undefined' || rowMax > max) {
      max = rowMax
    }

    return {
      name: row[0],
      type: 'bar',
      data,
      label: {
        show: false,
        position: 'top',
      },
    }
  })

  return prepareOptions({
    headers,
    series,
    metadata,
    displaySettings,
    min,
    max,
    showZoomPan: showZoomPanPanel,
  })
}

import { useCallback, useMemo, useRef, useState } from 'react'
import { AddLinesState, AddLinesStateValuesRecord } from '../types'
import { MAX_NEW_RECORDS_TO_EDIT, TooManyRecordsToEdit } from '../consts'
import {
  CalendarDatePickerConfig,
  PeriodSelection,
} from '@fintastic/web/util/period-selector'
import { Maybe } from '@fintastic/shared/util/types'
import { ColumnIdParts, ColumnsIdStructure } from '../columns'
import { flatten, isEqual } from 'lodash'
import { v4 as uuid } from 'uuid'
import {
  getAllChangedFieldsFromValues,
  getAllVisibleFields,
  isAllSet1IncludedInSet2,
  validateRecord,
} from './data-utils'
import { ColumnColor } from '@fintastic/web/util/metrics-and-lists'
import { RowToImportDefinition } from '../features/import-rows'
import { BaseGridRow } from '@fintastic/shared/ui/grid-framework'

export const useAddLinesData = ({
  setRowsToImport,
}: {
  setRowsToImport: (rowsToImport: Maybe<RowToImportDefinition>) => void
}): AddLinesState => {
  const [periodSelection, setPeriodSelection] =
    useState<Maybe<PeriodSelection>>(null)

  const [calendarConfig, setCalendarConfig] =
    useState<Maybe<CalendarDatePickerConfig>>(null)

  const [columnColors, setColumnColors] = useState<ColumnColor[]>([])

  const [stateData, setStateData] = useState<AddLinesStateValuesRecord[]>([])
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])

  const [changed, setChanged] = useState<boolean>(false)

  const [busy, setBusy] = useState<boolean>(false)

  const removeSelectedRows = useCallback(() => {
    const newData = stateData.filter((r) => !selectedRowIds.includes(r._rowId))
    setStateData(newData)
    setSelectedRowIds([])
  }, [selectedRowIds, stateData])

  // add or update record
  const setData = useCallback(
    (newRecord: AddLinesStateValuesRecord) => {
      const recordIndex = stateData.findIndex((r) => r.id === newRecord.id)
      if (recordIndex === -1) {
        setStateData(() => [...stateData, newRecord])
      } else {
        setStateData(() => stateData.splice(recordIndex, 1, newRecord))
      }
      setChanged(() => true)
    },
    [stateData],
  )

  const generateRecords = useCallback(
    (numberOfRecords: number, replace = false) => {
      setStateData((records) => {
        const newRecords: AddLinesStateValuesRecord[] = replace
          ? []
          : [...records]

        for (let i = 0; i < numberOfRecords; i++) {
          if (newRecords.length < MAX_NEW_RECORDS_TO_EDIT) {
            const newRecord: AddLinesStateValuesRecord = {
              _rowId: uuid(),
              _valid: false,
            } as AddLinesStateValuesRecord

            newRecords.push({
              ...newRecord,
              _valid: validateRecord(newRecord, mandatoryFields.current),
            } as AddLinesStateValuesRecord)
          }
        }
        return newRecords
      })

      const curLength = replace ? 0 : stateData.length
      if (numberOfRecords + curLength > MAX_NEW_RECORDS_TO_EDIT) {
        throw new TooManyRecordsToEdit('Too many records')
      }
    },
    [stateData.length],
  )

  const mandatoryFields = useRef<ColumnIdParts[]>([])

  const updateMandatoryFields = useCallback((columns: ColumnsIdStructure) => {
    const newMandatoryFields = flatten(columns).filter((col) => col.isDimension)

    if (!isEqual(newMandatoryFields, mandatoryFields.current)) {
      mandatoryFields.current = newMandatoryFields

      setStateData((data) =>
        data.map((record) => ({
          ...record,
          _valid: validateRecord(record, newMandatoryFields),
        })),
      )
    }
  }, [])

  const setCellValue = useCallback(
    (rowId?: string, fieldName?: string, value?: unknown) => {
      if (!rowId || !fieldName) {
        return
      }

      setStateData((data) =>
        data.map((record) => {
          if (record._rowId !== rowId) {
            return { ...record }
          }

          const newRecord: AddLinesStateValuesRecord = {
            ...record,
            [fieldName]: value,
          } as AddLinesStateValuesRecord

          return {
            ...newRecord,
            _valid: validateRecord(newRecord, mandatoryFields.current),
          } as AddLinesStateValuesRecord
        }),
      )
      setChanged(() => true)
    },
    [],
  )

  // @todo: store orphaned column names? (setCellValue + cache)
  const hasOrphanedColumns = useCallback(
    (structure: ColumnsIdStructure): boolean => {
      // 1. get all the unique edited field names
      const allEditedFieldNames = getAllChangedFieldsFromValues(stateData)

      if (allEditedFieldNames.size === 0) {
        // @todo: it can mean "isChanged" (partially, need to check against the visible fields)
        return false
      }

      // 2. try to resolve names in structure...
      // @todo: it works for ANY column, not only for time break downed. Not sure is it OK
      const flattenActualFields = getAllVisibleFields(structure)
      if (flattenActualFields.size === 0) {
        // changed fields > 0, flattenActualFields === 0 - oops, data has orphaned columns for sure
        return true
      }
      // 3. check all "edited" are in "actual"
      return !isAllSet1IncludedInSet2(allEditedFieldNames, flattenActualFields)
    },
    [stateData],
  )

  const importRecords = useCallback(
    (rows: BaseGridRow[]) => {
      setStateData((records) => {
        const newRecords: AddLinesStateValuesRecord[] = [...records]

        if (rows.length + stateData.length > MAX_NEW_RECORDS_TO_EDIT) {
          throw new TooManyRecordsToEdit('Too many records')
        }

        for (let i = 0; i < rows.length; i++) {
          if (newRecords.length < MAX_NEW_RECORDS_TO_EDIT) {
            const newRecord: AddLinesStateValuesRecord = {
              ...rows[i],
              _rowId: uuid(),
              _valid: false,
            } as AddLinesStateValuesRecord

            newRecords.push({
              ...newRecord,
              _valid: validateRecord(newRecord, mandatoryFields.current),
            } as AddLinesStateValuesRecord)
          }
        }
        return newRecords
      })

      setRowsToImport(null)
    },
    [setRowsToImport, stateData.length],
  )

  const reset = useCallback(() => {
    setStateData([])
    setSelectedRowIds([])
    mandatoryFields.current = []
    setChanged(false)
    setPeriodSelection(null)
    setCalendarConfig(null)
    setBusy(false)
    setColumnColors([])
  }, [])

  return useMemo<AddLinesState>(() => {
    // explicit declaration instead of "satisfies"
    const state: AddLinesState = {
      periodSelection: {
        selection: periodSelection,
        setSelection: setPeriodSelection,
      },

      calendarConfig: {
        calendarConfig,
        setCalendarConfig,
      },

      columnColors: {
        columnColors,
        setColumnColors,
      },

      data: {
        values: stateData,
        totalRecordsCount: stateData.length,
        invalidRecordsCount: stateData.filter((r) => !r._valid).length,
        validRecordsCount: stateData.filter((r) => r._valid).length,
        generateRecords,
        importRecords,
        changed,
        setChanged,
        busy,
        setBusy,
        setValues: setData,
        reset,
        updateMandatoryFields,
        setCellValue,
        hasOrphanedColumns,
      },

      selection: {
        selectedRowIds,
        selectedRecordsCount: selectedRowIds.length,
        setSelectedRowIds,
        removeSelectedRows,
      },
    } as const

    return state
  }, [
    periodSelection,
    calendarConfig,
    columnColors,
    stateData,
    generateRecords,
    importRecords,
    changed,
    busy,
    setData,
    reset,
    updateMandatoryFields,
    setCellValue,
    hasOrphanedColumns,
    selectedRowIds,
    removeSelectedRows,
  ])
}

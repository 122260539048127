import { QueryClient } from 'react-query'
import { resetAllVersionsEntitiesCache } from '@fintastic/web/data-access/versions'
import { globalDimensionsKey } from '../api/keys'
import { inActiveTab } from '@fintastic/shared/util/web-api'

export async function invalidateDimensionsRelated(qc: QueryClient) {
  inActiveTab(() => {
    Promise.all([
      qc.invalidateQueries({
        queryKey: globalDimensionsKey,
      }),
      resetAllVersionsEntitiesCache(qc),
    ])
  })
}

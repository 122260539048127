import { useAuthAccessToken } from '@fintastic/web/feature/auth'
import React, { useMemo } from 'react'
import { useLocalStorage } from '@fintastic/shared/util/hooks'
import { Board } from '../types'
import { Widget } from '@fintastic/shared/ui/widgets-framework'
import { isWidgetWithChartSettings } from '@fintastic/web/feature/charts'

export const useLocalBoardWidgetsSettings = (
  board: Board,
): readonly [Widget[], React.Dispatch<React.SetStateAction<Widget[]>>] => {
  const { user } = useAuthAccessToken()

  const [cachedWidgets, setCachedWidgets] = useLocalStorage<Widget[]>(
    `boardWidgets_${board.id}_${user?.email}`,
    defaultLocalWidgetsOverride,
  )

  const validatedCachedValue = useMemo(
    () =>
      (board.config.widgets || []).map((boardWidget) => {
        const localWidget = cachedWidgets.find(
          (cachedWidget) => cachedWidget.id === boardWidget.id,
        )

        if (!localWidget || !isWidgetWithChartSettings(localWidget.settings)) {
          // no local settings at all or not Chart Widget
          return boardWidget
        }

        const localEntityId =
          localWidget.settings.metricId ||
          localWidget.settings.listId ||
          localWidget.settings.reportCategoryId

        const boardEntityId =
          boardWidget.settings.metricId ||
          boardWidget.settings.listId ||
          boardWidget.settings.reportCategoryId

        if (boardEntityId && localEntityId && localEntityId !== boardEntityId) {
          // other metric/list/report etc. id
          return boardWidget
        }
        // @todo: check dims list
        if (
          localWidget.settings.displayLabel !==
          boardWidget.settings.displayLabel
        ) {
          localWidget.settings.displayLabel = boardWidget.settings.displayLabel
        }

        return localWidget
      }),
    [board.config.widgets, cachedWidgets],
  )

  return [validatedCachedValue, setCachedWidgets] as const
}

const defaultLocalWidgetsOverride: Widget[] = []

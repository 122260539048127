import React from 'react'
import { StyledScrollContainer } from './ScrollContainer.styled'

export const ScrollContainer: React.FC<{
  maxHeight?: number
  children: React.ReactNode
}> = ({ maxHeight = 480, children }) => (
  <StyledScrollContainer
    style={{
      maxHeight: `${maxHeight}px`,
    }}
  >
    {children}
  </StyledScrollContainer>
)

import React from 'react'
import {
  StyledCont,
  StyledFrom,
  StyledRoot,
  StyledTo,
} from './RangePicker.styled'
import { SmartSelect, SmartSelectOption } from '@fintastic/shared/ui/dropdowns'
import { Period, PeriodsRange } from '@fintastic/web/util/period-selector'

export type RangePickerProps = {
  value: PeriodsRange
  options: SmartSelectOption<Period>[]
  onChangeFrom: (v: Period) => void
  onChangeTo: (v: Period) => void
  disabled: boolean
}

export const RangePicker: React.FC<RangePickerProps> = ({
  value,
  options,
  onChangeFrom,
  onChangeTo,
  disabled,
}) => (
  <StyledRoot data-testid={'period-selector-range-picker-root'}>
    <StyledCont>
      <StyledFrom data-testid={'range-picker-from-container'}>
        <SmartSelect
          value={value[0]}
          onChange={onChangeFrom}
          options={options}
          placeholder="From"
          disabled={disabled}
          data-testid={'range-picker-from'}
        />
      </StyledFrom>
      <StyledTo data-testid={'range-picker-to-container'}>
        <SmartSelect
          value={value[1]}
          onChange={onChangeTo}
          options={options}
          placeholder="To"
          disabled={disabled}
          data-testid={'range-picker-to'}
        />
      </StyledTo>
    </StyledCont>
  </StyledRoot>
)

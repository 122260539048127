import { titleFormatter } from '@fintastic/shared/util/formatters'
import { Maybe } from '@fintastic/shared/util/types'
import {
  MetricWrapper,
  useLoadMetric,
} from '@fintastic/web/data-access/metrics-and-lists'
import {
  useLoadVersion,
  useLoadVersionEntities,
} from '@fintastic/web/data-access/versions'
import { useMemo } from 'react'

export const useMetricMetadataViewCache = (
  params: Maybe<{ versionId: string; metricId: string }>,
) => {
  const metricQuery = useLoadMetric(
    params?.versionId,
    params?.metricId,
    undefined,
    true,
    true,
  )
  const entitiesQuery = useLoadVersionEntities(params?.versionId)
  const versionQuery = useLoadVersion(params?.versionId)
  const queries = [metricQuery, entitiesQuery, versionQuery]
  const isLoading = queries.some((q) => q.isLoading)
  const isFetching = queries.some((q) => q.isFetching)
  const isError = queries.some((q) => q.isError)
  const isSuccess = queries.some((q) => q.isSuccess)

  const dimensions = useMemo(() => {
    if (!metricQuery.data || !entitiesQuery.data) {
      return []
    }
    const metricWrapper = new MetricWrapper(metricQuery.data)

    const result: { id: string; label: string }[] = []
    const allDims = metricWrapper.data().dimensions()

    for (let i = 0; i < allDims.length; i++) {
      const dim = entitiesQuery.data.dimensions.find((d) => d.id === allDims[i])
      if (!dim) {
        return null
      }
      result.push({
        id: dim.id,
        label: titleFormatter(dim.label),
      })
    }

    return result
  }, [entitiesQuery.data, metricQuery.data])

  return useMemo(
    () =>
      ({
        isLoading: isLoading || isFetching,
        isError,
        isSuccess,
        isLiveActuals: !!versionQuery.data?.is_live_actuals,
        metric: metricQuery.data || null,
        dimensions,
      } as const),
    [
      dimensions,
      isError,
      isFetching,
      isLoading,
      isSuccess,
      metricQuery.data,
      versionQuery.data?.is_live_actuals,
    ],
  )
}

import { TenantConfig } from './tenant-config'
import { Maybe } from '@fintastic/shared/util/types'

const requiredVariables = [
  'REACT_APP_AUTH0_DOMAIN',
  'REACT_APP_AUTH0_CLIENT_ID',
  'REACT_APP_API_URL',
  'REACT_APP_PUSHER_APP_KEY',
] as const

const optionalVariables = [
  'REACT_APP_AUTH0_AUDIENCE',
  'REACT_APP_AUTH0_ORG_ID',
  'REACT_APP_AGGRID_LICENSE',
  'REACT_APP_GOJS_LICENSE',
  'REACT_APP_LOGROCKET_APP_ID',
  'REACT_APP_TENANT_AUTH_SERVICE',
  'REACT_APP_PUSHER_APP_SECRET',
  'REACT_APP_PUSHER_APP_ID',
  'REACT_APP_NEW_APP_LAYOUT_DOMAINS',
  'REACT_APP_SENTRY_DSN',
  'REACT_APP_SENTRY_ENVIRONMENT',
  'REACT_APP_TENANT_LOGIN',
] as const

export type EnvironmentVariables = Record<
  typeof requiredVariables[number],
  string
> &
  Partial<Record<typeof optionalVariables[number], string>>

const envRef: {
  variables: Maybe<EnvironmentVariables>
} = {
  variables: null,
}

export const loadEnvVariables = (tenantConfig: TenantConfig) => {
  const env = JSON.parse(
    JSON.stringify(process.env),
  ) as Partial<EnvironmentVariables>
  if (tenantConfig.auth0OrgId !== undefined) {
    env.REACT_APP_AUTH0_ORG_ID = tenantConfig.auth0OrgId
  }
  if (tenantConfig.auth0ClientId !== undefined) {
    env.REACT_APP_AUTH0_CLIENT_ID = tenantConfig.auth0ClientId
  }
  if (tenantConfig.overridingApiUrl !== undefined) {
    env.REACT_APP_API_URL = tenantConfig.overridingApiUrl
  }

  const keys = [...optionalVariables, ...requiredVariables]
  const result = {} as EnvironmentVariables

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (
      requiredVariables.includes(key as typeof requiredVariables[number]) &&
      !env[`${key}`]
    ) {
      throw new Error(`${key} is required but not defined`)
    }
    result[key] = env[`${key}`] as string
  }

  envRef.variables = result
}

export const environment = (): Readonly<EnvironmentVariables> => {
  if (envRef.variables === null) {
    throw new Error('env variables are not initialized')
  }
  return envRef.variables
}

export const isProduction = process.env['NODE_ENV'] === 'production'

import '../utils/style.css'

import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { AgGridThemeFintasticWrapper } from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import {
  BasicColumnDefinition,
  GenericReportCategoryOptions,
  GenericReportDiff,
  GenericReportId,
  GenericReportNormalised,
  GenericReportTreeRow,
  GenericReportTreeRowCellValue,
  getUOM,
  MonthOverMonthContext,
  SortableDimension,
} from '@fintastic/web/util/generic-report'
import type {
  ColDef,
  ColumnApi,
  ColumnState,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GroupCellRendererParams,
  MenuItemDef,
  ModelUpdatedEvent,
  PostSortRowsParams,
  RangeSelectionChangedEvent,
  RowDataUpdatedEvent,
  ToolPanelVisibleChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import type { Maybe } from '@fintastic/shared/util/types'
import {
  DimensionsSorterPanel,
  DimensionsSorterPanelProps,
} from './DimensionsSorterPanel'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { valueFormatters } from '../utils/value-formatters'
import { compact, flatten, uniq } from 'lodash'
import {
  makeReportTableDiffAggFunc,
  makeReportTableDiffValueGetter,
  makeReportTableValueGetter,
  makeTotalsReportAggFunc,
  makeTotalsReportDiffAggFunc,
  reportAggFunc,
} from './logic'
import type { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import CustomHeader from './CustomHeader'
import { generateDiffFieldId, generateFieldId } from '../utils/field-id'
import { getDiffLabel } from '../utils/get-diff-label'
import { useFeatureTotalsColumnEnabled } from '../hooks/useFeatureTotalsColumnEnabled'
import { useRenderCalculatedRows } from '../hooks/useRenderCalculatedRows'
import clsx from 'clsx'
import {
  BLANK_VALUE_UI_REPRESENTATION,
  containsBlankValue,
  containsMaskedValue,
  isRawBlankValue,
  MASKED_VALUE_UI_REPRESENTATION,
} from '@fintastic/web/util/blanks-and-masked'
import { useDeeplinkOpenedGroups } from '../deeplink/useDeeplinkOpenedGroups'
import { useDeeplinkRangeSelection } from '../deeplink/useDeeplinkRangeSelection'
import { useAddNewComment } from '../hooks/useAddNewComment'
import { ReportGridContext } from '../types'
import { RowGroupOpenedEvent } from 'ag-grid-community/dist/lib/events'
import { Box } from '@mui/material'
import { addButtonsIdentifiers } from '@fintastic/shared/ui/ag-grid'
import {
  isTotalPeriodName,
  parseTotalColumnYear,
} from '../utils/is-total-period-name'
import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'
import { GetContextMenuItemsParams } from 'ag-grid-community/dist/lib/entities/iCallbackParams'
import {
  csvIcon,
  DataExportParams,
  ExportFormat,
  ExportMode,
  ExportXLS,
  xlsIcon,
} from '@fintastic/shared/util/ag-grid-export'
import {
  handleSelectedCellsForAggregation,
  HandleSelectedCellsForAggregationEvent,
  useSelectedCellAggregationAgGridContext,
} from '@fintastic/web/feature/selected-cell-aggregation'
import { extractSelectedCellFromEvent } from '../features/selected-cell-aggregation/extract-selected-cell-value-from-event'
import { sortReportRows } from './calculated-row-sort'
import {
  actionIcons,
  addCommentIcon,
  commentsIcon,
} from './icons/generic-report-icons'
import { DiffPart } from '@fintastic/web/util/versions'
import { useReportOrderedPeriodList } from '../hooks/useReportOrderedPeriodList'
import { useBlanksExternalFilter } from './show-blanks-feature/useBlanksExternalFilter'
import { BlanksFilterPanelContext } from './show-blanks-feature/blanks-filter-panel'
import { blanksFilterToolPanelDefinition } from './show-blanks-feature/blanks-filter-panel/aggrid-tool-panel'
import { formatWithDisplayConfig } from './formatting/apply-display-config'
import { useReportRowData } from '../hooks/useReportRowData'
import { cellRendererSelector } from './cell-renderer/cell-renderer-selector'
import { usePeriodOverPeriodDiffColumns } from './period-over-period-diff/usePeriodOverPeriodDiffColumns'
import { usePeriodOverPeriodAvailable } from './period-over-period-diff/usePeriodOverPeriodAvailable'

const themeOptions = {
  inscribedShape: true,
} as const

export const valueFormatterMaybeCalculatedRowFn =
  (percentageDiff = false) =>
  (
    params: ValueFormatterParams<
      GenericReportTreeRow,
      GenericReportTreeRowCellValue
    >,
  ): string => {
    const finalValue = params.value?.finalValue

    if (containsBlankValue(finalValue)) {
      return BLANK_VALUE_UI_REPRESENTATION
    }

    if (containsMaskedValue(finalValue)) {
      return MASKED_VALUE_UI_REPRESENTATION
    }

    if (typeof finalValue === 'string') {
      return finalValue
    }

    if (finalValue === Infinity) {
      return BLANK_VALUE_UI_REPRESENTATION
    }

    const mappedParams = { ...params, value: finalValue }

    if (percentageDiff) {
      if (
        params.node?.data?.calculatedRow &&
        getUOM(params.node.data.calculatedRow.uom) === 'percentage'
      ) {
        return BLANK_VALUE_UI_REPRESENTATION
      }
      return valueFormatters['percentage'](mappedParams, true)
    }

    if (params.node?.data?.calculatedRow) {
      return valueFormatters[getUOM(params.node.data.calculatedRow.uom)](
        mappedParams,
        true,
      )
    }

    const referenceValue = params?.value?.values?.[0]

    const uoms = uniq(compact(params?.value?.values?.map((i) => i.uom) ?? []))

    if (uoms.length > 1) {
      return BLANK_VALUE_UI_REPRESENTATION
    }

    if (!referenceValue) {
      return BLANK_VALUE_UI_REPRESENTATION
    }

    if (finalValue === null) {
      return BLANK_VALUE_UI_REPRESENTATION
    }

    if (referenceValue.display_settings) {
      return formatWithDisplayConfig(
        referenceValue.uom,
        referenceValue.display_settings,
        finalValue,
      )
    }

    return valueFormatters[referenceValue.uom](mappedParams, true)
  }

export type GenericReportTableProps = {
  gridRef: React.MutableRefObject<
    Maybe<AgGridReactType<GenericReportTreeRowCellValue>>
  >
  reportsOrVersionsIds: GenericReportId[]
  diffs: GenericReportDiff[]
  category: string
  options: GenericReportCategoryOptions
  reportsData: GenericReportNormalised[]
  reportNames?: Record<GenericReportId, string>
  deeplinkWidgetId: string
  onRangeSelectionApplied: ({
    api,
    columnApi,
  }: {
    api: GridApi<GenericReportTreeRowCellValue>
    columnApi: ColumnApi
  }) => void
  showBorderBottom?: boolean
  dimensions: BasicColumnDefinition[]
} & Omit<DimensionsSorterPanelProps, 'resetDimensions'> &
  Required<
    Pick<
      GridOptions,
      'onFilterChanged' | 'onCellClicked' | 'onGridReady' | 'onModelUpdated'
    >
  >

export const sortableDimension = (
  dimensions: BasicColumnDefinition[],
  originalSortedDimensions: SortableDimension[],
): SortableDimension[] => {
  const newDimensions = dimensions.filter(
    (dim) => !originalSortedDimensions.find(({ name }) => name === dim.name),
  )
  const remainingDimensions = originalSortedDimensions.filter((dim) =>
    dimensions.find(({ name }) => dim.name === name),
  )

  return [
    ...remainingDimensions,
    ...newDimensions.map((row) => ({ ...row, id: row.name })),
  ]
}

export const GenericReportTable: React.FC<GenericReportTableProps> = ({
  category,
  diffs,
  reportsOrVersionsIds,
  reportsData,
  dimensions,
  setLocalDimensions,
  localDimensions,
  options,
  onGridReady,
  onCellClicked,
  onFilterChanged,
  onRangeSelectionApplied,
  reportNames,
  deeplinkWidgetId,
  showBorderBottom = false,
  gridRef,
  onModelUpdated,
}) => {
  const periodSelection = usePeriodSelectorContext()

  const autoGroupColumnDef = useMemo<ColDef<GenericReportTreeRow>>(
    () => ({
      minWidth: 385,
      pinned: 'left',
      sort: null,
      sortable: true,
      cellRendererParams: {
        footerValueGetter: (params: GroupCellRendererParams) => {
          const isRootLevel = params?.node?.level === -1
          if (isRootLevel) {
            return options.net_line_title || 'Net'
          }
          return `Sub Total (${params?.value})`
        },
        suppressCount: true,
        innerRendererSelector: cellRendererSelector,
      },
    }),
    [options.net_line_title],
  )

  const allPeriods = useReportOrderedPeriodList({ reportsData })
  const rowData = useReportRowData({
    reportsData,
    dimensions,
    localDimensions,
    options,
    allPeriods,
  })

  const resetDimensions = useCallback(() => {
    if (dimensions.length) {
      setLocalDimensions(sortableDimension(dimensions, []))
    }
  }, [setLocalDimensions, dimensions])

  const [columnFilterActive, setColumnFilterActive] = useState(false)

  const handleUpdateCalculatedRows = useRenderCalculatedRows()
  const handleFilterChanged = useCallback(
    (e: FilterChangedEvent<GenericReportTreeRow>) => {
      setColumnFilterActive(e.api.isColumnFilterPresent())
      handleUpdateCalculatedRows(e.api)
      onFilterChanged?.(e)
    },
    [onFilterChanged, handleUpdateCalculatedRows],
  )

  const openToolPanel = useRef<Maybe<string>>('')

  const keepToolPanelOpen = useCallback(() => {
    if (openToolPanel.current) {
      gridRef.current?.api?.openToolPanel(openToolPanel.current)
    }
  }, [gridRef])

  useEffect(() => {
    keepToolPanelOpen()
    // reset sorting on localDimensions change (reorder groups)
    setTimeout(() => {
      resetAllSorting()
    }, 100)
  }, [localDimensions])

  const totalsColumnEnabled = useFeatureTotalsColumnEnabled(options)

  const addNewComment = useAddNewComment({
    gridRef,
    dimensions: localDimensions,
    deeplinkWidgetId,
  })

  const [openExport, setOpenExport] = useState(false)
  const exportGridParams = useRef<Maybe<DataExportParams>>(null)

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const exportFn = (format: ExportFormat, mode?: ExportMode) => {
        exportGridParams.current = {
          ...params,
          sortedGroupNames: localDimensions.map((d) => d.label),
          exportName: options.displayLabel + '.Report',
          format,
          mode: mode || 'current',
        }

        setOpenExport(() => true)
      }
      return [
        'copy',
        'separator',
        {
          name: 'Download as MS Excel',
          icon: xlsIcon,
          subMenu: [
            {
              name: 'Current view',
              action: () => exportFn('xls'),
            },
            {
              name: 'Current view with comments',
              action: () => exportFn('xls', 'current-with-comments'),
            },
            'separator',
            {
              name: 'Flat',
              action: () => exportFn('xls', 'flat'),
            },
          ],
        },
        {
          name: 'Download as CSV',
          icon: csvIcon,
          subMenu: [
            {
              name: 'Current view',
              action: () => exportFn('csv'),
            },
            {
              name: 'Flat',
              action: () => exportFn('csv', 'flat'),
            },
          ],
        },
        'separator',
        {
          name: 'View comments',
          icon: commentsIcon,
          action: () => addNewComment(false),
        },
        {
          name: 'Add comment',
          icon: addCommentIcon,
          action: () => addNewComment(true),
        },
      ]
    },
    [addNewComment, localDimensions, options.displayLabel, exportGridParams],
  )

  const diffViewEnabled = !!diffs?.length

  const periodOverPeriodAvailable = usePeriodOverPeriodAvailable({
    totalsColumnEnabled,
    allPeriods,
    reportsOrVersionsIds,
  })
  const monthOverMonthContext = useContext(MonthOverMonthContext)

  useEffect(() => {
    monthOverMonthContext.setAvailable(periodOverPeriodAvailable)
  }, [periodOverPeriodAvailable, monthOverMonthContext.setAvailable])

  useEffect(() => {
    resetAllSorting()
  }, [monthOverMonthContext.active])

  const deeplinkRangeSelection = useDeeplinkRangeSelection(
    deeplinkWidgetId,
    gridRef,
    onRangeSelectionApplied,
    localDimensions,
  )

  const {
    isGroupOpenByDefault,
    handleRowGroupOpened: handleDeeplinkRowGroupOpened,
    makeGroupsOpen,
  } = useDeeplinkOpenedGroups(
    category,
    deeplinkWidgetId,
    gridRef,
    deeplinkRangeSelection.handleFirstDataRendered,
    deeplinkRangeSelection.handleSyncWithUrlStarted,
    localDimensions,
  )

  const handleRowGroupOpened = useCallback(
    (event: RowGroupOpenedEvent) => {
      handleDeeplinkRowGroupOpened(event)
    },
    [handleDeeplinkRowGroupOpened],
  )

  const handleFirstDataRendered = useCallback(
    (event: FirstDataRenderedEvent) => {
      deeplinkRangeSelection.handleFirstDataRendered()
    },
    [deeplinkRangeSelection],
  )

  const blanksFilter = useBlanksExternalFilter(
    gridRef,
    useCallback((row) => {
      for (let i = 0; i < row.values.length; i += 1) {
        if (row.calculatedRow) {
          return false
        }

        const value = row.values[i].amount
        if (
          !isRawBlankValue(value) &&
          !containsBlankValue(value) &&
          parseFloat(
            (value as unknown as number).toFixed(
              row.values[i].uom === 'percentage' ? 2 : 0,
            ),
          ) !== 0
        ) {
          return false
        }
      }
      return true
    }, []),
    useMemo(
      () => ({
        widgetId: deeplinkWidgetId,
      }),
      [deeplinkWidgetId],
    ),
  )

  const valueColumns = useMemo(() => {
    // Finds corresponding column for last/first aggregation for NET
    // To be removed when report migrates to period dim ids
    const getLookupPeriodForTotalColumn = (year: string) => {
      if (options.net_totals_aggregation_func === 'sum') {
        return
      }

      if (!options.net_totals_aggregation_func) {
        return
      }

      if (!year) {
        return
      }

      return allPeriods
        .filter((p) => !isTotalPeriodName(p))
        [options.net_totals_aggregation_func === 'last' ? 'findLast' : 'find'](
          (p) => p.endsWith(year),
        )
    }

    return allPeriods.map((period) => {
      const totalsEnabled = totalsColumnEnabled && !monthOverMonthContext.active
      const currentTotalPeriodName = isTotalPeriodName(period)

      if (currentTotalPeriodName && !totalsEnabled) {
        return null
      }

      const lookupPeriodForTotalAggregation =
        getLookupPeriodForTotalColumn(`${parseTotalColumnYear(period)}`) ?? ''

      const columns = reportsOrVersionsIds.map((reportOrVersionId) => {
        const reportName = reportNames?.[reportOrVersionId]
        const valueGetter = makeReportTableValueGetter(
          reportOrVersionId,
          period,
        )

        return (
          <AgGridColumn
            key={generateFieldId(reportOrVersionId, period)}
            field={generateFieldId(reportOrVersionId, period)}
            hide={false}
            sortable={true}
            filter={false}
            type="numericColumn"
            suppressMovable
            suppressColumnsToolPanel
            aggFunc={
              currentTotalPeriodName && lookupPeriodForTotalAggregation
                ? makeTotalsReportAggFunc({
                    lookupForColumnId: generateFieldId(
                      reportOrVersionId,
                      lookupPeriodForTotalAggregation,
                    ),
                  })
                : reportAggFunc
            }
            valueGetter={valueGetter}
            valueFormatter={valueFormatterMaybeCalculatedRowFn()}
            headerName={reportName}
            headerTooltip={reportName}
            cellRendererSelector={cellRendererSelector}
            cellClassRules={{
              // @todo (mykola): check if this correctly works for total column
              'gr-value': (params) => !params.data?.calculatedRow,
            }}
          />
        )
      })

      if (diffViewEnabled && !monthOverMonthContext.active) {
        diffs.forEach((diff) => {
          const isPercentage = diff[DiffPart.mode] === 'percent'
          const isMixed = diff[DiffPart.mode] === 'mixed'
          const infoLabel = getDiffLabel(reportNames, diff)
          const title = isPercentage ? 'Variance %' : 'Variance'

          const diffAggFunc = makeReportTableDiffAggFunc(
            diff[DiffPart.firstVersion],
            diff[DiffPart.secondVersion],
            'reportOrVersionId',
            isPercentage ? 'percentage' : 'subtract',
          )

          const valueGetter = makeReportTableDiffValueGetter(
            diff[DiffPart.firstVersion],
            diff[DiffPart.secondVersion],
            period,
            isPercentage ? 'percentage' : 'subtract',
          )

          columns.push(
            <AgGridColumn
              field={generateDiffFieldId('normal', diff)}
              key={`period_diff_${diff[DiffPart.firstVersion]}-${
                diff[DiffPart.secondVersion]
              }`}
              hide={false}
              sortable={true}
              filter={false}
              type="numericColumn"
              suppressMovable
              suppressColumnsToolPanel
              valueGetter={valueGetter}
              aggFunc={
                currentTotalPeriodName && lookupPeriodForTotalAggregation
                  ? makeTotalsReportDiffAggFunc({
                      defaultDiffAggFunc: diffAggFunc,
                      lookupForColumnIdA: generateFieldId(
                        diff[DiffPart.firstVersion],
                        lookupPeriodForTotalAggregation,
                      ),
                      lookupForColumnIdB: generateFieldId(
                        diff[DiffPart.secondVersion],
                        lookupPeriodForTotalAggregation,
                      ),
                    })
                  : diffAggFunc
              }
              headerName={title}
              headerTooltip={infoLabel}
              valueFormatter={valueFormatterMaybeCalculatedRowFn(isPercentage)}
              cellRendererSelector={cellRendererSelector}
            />,
          )

          if (isMixed) {
            // for mixed mode we need two columns
            // 1 - #, always numeric
            // 2 - %, percentage
            const diffAggFunc = makeReportTableDiffAggFunc(
              diff[DiffPart.firstVersion],
              diff[DiffPart.secondVersion],
              'reportOrVersionId',
              'percentage',
            )

            const valueGetter = makeReportTableDiffValueGetter(
              diff[DiffPart.firstVersion],
              diff[DiffPart.secondVersion],
              period,
              'percentage',
            )

            columns.push(
              <AgGridColumn
                field={generateDiffFieldId('normal', diff, true)}
                key={`period_diff_${diff[DiffPart.firstVersion]}-${
                  diff[DiffPart.secondVersion]
                }_percent`}
                hide={false}
                sortable={true}
                filter={false}
                type="numericColumn"
                suppressMovable
                suppressColumnsToolPanel
                valueGetter={valueGetter}
                aggFunc={
                  currentTotalPeriodName && lookupPeriodForTotalAggregation
                    ? makeTotalsReportDiffAggFunc({
                        defaultDiffAggFunc: diffAggFunc,
                        lookupForColumnIdA: generateFieldId(
                          diff[DiffPart.firstVersion],
                          lookupPeriodForTotalAggregation,
                        ),
                        lookupForColumnIdB: generateFieldId(
                          diff[DiffPart.secondVersion],
                          lookupPeriodForTotalAggregation,
                        ),
                      })
                    : diffAggFunc
                }
                headerName={'Variance %'}
                headerTooltip={infoLabel}
                valueFormatter={valueFormatterMaybeCalculatedRowFn(true)}
                cellRendererSelector={cellRendererSelector}
              />,
            )
          }
        })
      }

      return (
        <AgGridColumn
          key={period}
          headerName={period}
          marryChildren
          suppressColumnsToolPanel
        >
          {columns}
        </AgGridColumn>
      )
    })
  }, [
    allPeriods,
    diffViewEnabled,
    diffs,
    monthOverMonthContext.active,
    options.net_totals_aggregation_func,
    reportNames,
    reportsOrVersionsIds,
    totalsColumnEnabled,
  ])

  const dimensionColumns = useMemo(
    () =>
      dimensions.map((dimension) => (
        <AgGridColumn
          field={dimension.name}
          key={dimension.name}
          headerName={dimension.label}
          hide
          filter="agSetColumnFilter"
          valueGetter={({ data }: ValueGetterParams<GenericReportTreeRow>) =>
            data?.dimensions[dimension.name]
          }
        />
      )),
    [dimensions],
  )

  const periodDiffColumn = usePeriodOverPeriodDiffColumns({
    allPeriods,
    reportsOrVersionsIds,
    options,
  })

  const selectedCellAggregationAgGridContext =
    useSelectedCellAggregationAgGridContext()

  const gridContext = useMemo<ReportGridContext>(
    () => ({
      dimensions: localDimensions,
      deeplinkWidgetId,
      periodSelection,
      ...selectedCellAggregationAgGridContext,
    }),
    [
      localDimensions,
      deeplinkWidgetId,
      periodSelection,
      selectedCellAggregationAgGridContext,
    ],
  )

  const rootPanelRef = useRef<Maybe<HTMLDivElement>>(null)

  const resetAllSorting = useCallback(() => {
    gridRef.current?.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [gridRef])

  const handleGridReady = useCallback(
    (event: GridReadyEvent) => {
      if (rootPanelRef.current) {
        addButtonsIdentifiers(rootPanelRef.current)
      }
      onGridReady(event)
    },
    [onGridReady],
  )

  const handleRowDataUpdated = useCallback(
    (e: RowDataUpdatedEvent<GenericReportTreeRow>) => {
      makeGroupsOpen()
      if (rootPanelRef.current) {
        addButtonsIdentifiers(rootPanelRef.current)
      }
      keepToolPanelOpen()
      handleUpdateCalculatedRows(e.api)
    },
    [handleUpdateCalculatedRows, keepToolPanelOpen, makeGroupsOpen],
  )

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: '',
          labelKey: 'columns',
          iconKey: 'grouping',
          toolPanel: DimensionsSorterPanel,
          toolPanelParams: {
            setLocalDimensions,
            localDimensions,
            resetDimensions,
          },
        },
        {
          id: 'filters',
          labelDefault: '',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        blanksFilterToolPanelDefinition,
      ],
    }),
    [localDimensions, resetDimensions, setLocalDimensions],
  )

  const getDataPath = useCallback(({ path }: GenericReportTreeRow) => path, [])

  const handleToolPanelVisibleChanged = useCallback(
    ({ api }: ToolPanelVisibleChangedEvent) =>
      (openToolPanel.current = api.getOpenedToolPanel()),
    [],
  )

  const updateSelectedCellsAggregations = useCallback(
    (e: HandleSelectedCellsForAggregationEvent) => {
      handleSelectedCellsForAggregation<GenericReportTreeRowCellValue>(
        e,
        extractSelectedCellFromEvent,
      )
    },
    [],
  )

  const handleRangeSelectionChanged = useCallback(
    (e: RangeSelectionChangedEvent<GenericReportTreeRowCellValue>) => {
      updateSelectedCellsAggregations(e)
      deeplinkRangeSelection.handleRangeSelectionChange(e)
    },
    [deeplinkRangeSelection, updateSelectedCellsAggregations],
  )

  const handleModelUpdated = useCallback(
    (e: ModelUpdatedEvent<GenericReportTreeRowCellValue>) => {
      updateSelectedCellsAggregations(e)
      onModelUpdated(e)
    },
    [onModelUpdated, updateSelectedCellsAggregations],
  )

  const handleSortCalculatedRows = useCallback(
    ({ nodes, api }: PostSortRowsParams<GenericReportTreeRow>) => {
      const sortColumn: ColumnState | undefined = gridRef.current?.columnApi
        ?.getColumnState()
        .find((c) => !!c.sort)

      sortReportRows(
        {
          nodes,
          api,
        } as PostSortRowsParams<GenericReportTreeRow>,
        sortColumn,
      )
    },
    [gridRef],
  )

  const reportRenderingKey = useMemo(
    () =>
      category +
      reportsOrVersionsIds.join('-') +
      flatten(diffs).join('-') +
      JSON.stringify(periodSelection),
    [category, diffs, periodSelection, reportsOrVersionsIds],
  )

  return (
    <Box
      ref={rootPanelRef}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <BlanksFilterPanelContext.Provider value={blanksFilter}>
        <AgGridThemeFintasticWrapper
          className={clsx({
            'ag-theme-alpine': true,
            'main-grid': true,
            'generic-report-grid': true,
            'column-filter-is-applied': columnFilterActive,
            'blanks-filter-is-applied': blanksFilter.blanksVisible,
          })}
          sx={{
            'height': '100%',
            'width': '100%',
            'marginBottom': '10px',
            'borderBottom': showBorderBottom
              ? (theme) => `1px solid ${theme.palette.divider}`
              : undefined,
            '& .ag-root-wrapper': {
              boxShadow: 'none !important',
            },
            '& .gr-value': {
              cursor: 'pointer',
            },
            '&.blanks-filter-is-applied [data-icon="custom-rows"]': {
              background: 'var(--fintastic-applied-sidebar-button-bg)',
            },
            '&.column-filter-is-applied': {
              '[data-icon="native-filter"]': {
                background: 'var(--fintastic-applied-sidebar-button-bg)',
              },
            },
          }}
          data-testid="reportTable"
          themeOptions={themeOptions}
        >
          {({ defaultGridProps }) => (
            <AgGridReact
              key={reportRenderingKey}
              ref={gridRef}
              {...defaultGridProps}
              enableRangeSelection={true}
              components={{
                agColumnHeader: CustomHeader,
              }}
              defaultColDef={{
                flex: 1,
                minWidth: 140,
                width: 160,
                filter: true,
                sortable: true,
                resizable: true,
              }}
              autoGroupColumnDef={autoGroupColumnDef}
              icons={actionIcons}
              isExternalFilterPresent={
                blanksFilter.externalFilter.isExternalFilterPresent
              }
              doesExternalFilterPass={
                blanksFilter.externalFilter.doesExternalFilterPass
              }
              sideBar={sideBar}
              rowData={rowData}
              suppressAggFuncInHeader
              groupIncludeTotalFooter={options?.show_net}
              tooltipShowDelay={500}
              animateRows
              onRowGroupOpened={handleRowGroupOpened}
              isGroupOpenByDefault={isGroupOpenByDefault}
              groupRemoveLowestSingleChildren
              suppressDragLeaveHidesColumns
              suppressCsvExport
              defaultExcelExportParams={defaultExcelExportParams}
              treeData
              getDataPath={getDataPath}
              excludeChildrenWhenTreeDataFiltering
              groupMaintainOrder
              onFilterChanged={handleFilterChanged}
              onGridReady={handleGridReady}
              onRowDataUpdated={handleRowDataUpdated}
              onFirstDataRendered={handleFirstDataRendered}
              onCellClicked={onCellClicked}
              onRangeSelectionChanged={handleRangeSelectionChanged}
              onModelUpdated={handleModelUpdated}
              postSortRows={handleSortCalculatedRows}
              onToolPanelVisibleChanged={handleToolPanelVisibleChanged}
              getContextMenuItems={getContextMenuItems}
              context={gridContext}
            >
              {dimensionColumns}
              {valueColumns}
              {periodDiffColumn}
            </AgGridReact>
          )}
        </AgGridThemeFintasticWrapper>
      </BlanksFilterPanelContext.Provider>

      <ExportXLS
        open={openExport}
        onClose={() => setOpenExport(false)}
        exportParams={exportGridParams.current}
        deeplinkWidgetId={deeplinkWidgetId}
      />
    </Box>
  )
}

const defaultExcelExportParams = {
  author: 'Fintastic',
}

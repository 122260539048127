import { axios } from '@fintastic/web/data-access/service-axios'
import { BoardFolder } from '../types'

export type GetFoldersResponse = {
  result: BoardFolder[]
}

export function getFolders() {
  return axios.get<GetFoldersResponse>('/planning/api/v1/board_folders/')
}

export type EditableFolderParams = {
  name: string
}

export function createFolder(params: EditableFolderParams) {
  return axios.post<BoardFolder>('/planning/api/v1/board_folders/', params)
}

export function updateFolder(
  folderId: BoardFolder['id'],
  params: EditableFolderParams,
) {
  return axios.patch<BoardFolder>(
    `/planning/api/v1/board_folders/${folderId}/`,
    params,
  )
}

export function deleteFolder(folderId: BoardFolder['id']) {
  return axios.delete(`/planning/api/v1/board_folders/${folderId}/`)
}

import React, { useCallback, useMemo } from 'react'
import { SupportedEntry } from '../supported-entry-types'
import { DimensionId, VersionDimension } from '@fintastic/web/util/dimensions'
import {
  StyledRoot,
  StyledList,
  StyledListElementContainer,
} from './EntriesList.styled'
import { Entry } from './Entry'
import { historyLogEntryAdt } from '@fintastic/web/util/history'
import { Maybe } from '@fintastic/shared/util/types'
import { LinearProgress, Typography } from '@mui/material'
import { useDirectNavigationTriggerApi } from '@fintastic/web/feature/direct-navigation'
import {
  entrySupportsDirectNavigation,
  mapEntryToDirectNavigationTarget,
} from '../direct-navigation'

type ListElement =
  | {
      type: 'date'
      dateString: string
    }
  | {
      type: 'entry'
      entry: SupportedEntry
    }

export const EntriesList: React.FC<{
  entries: SupportedEntry[]
  isBusy: boolean
  dimensions: Record<DimensionId, VersionDimension>
}> = ({ entries, isBusy, dimensions }) => {
  const directNavigationApi = useDirectNavigationTriggerApi()
  const { navigateTo } = directNavigationApi

  const requestDirectNavigation = useCallback(
    (entry: SupportedEntry) => {
      const target = mapEntryToDirectNavigationTarget(entry)
      if (!target) {
        return
      }
      navigateTo(target)
    },
    [navigateTo],
  )

  const listElements = useMemo<ListElement[]>(() => {
    const usedDates: Record<string, boolean> = {}
    return entries.flatMap((entry) => {
      const dateString = historyLogEntryAdt.getTimestampShortDate(entry)
      const dateSction: Maybe<ListElement> = !usedDates[dateString]
        ? { type: 'date', dateString }
        : null
      if (dateSction) {
        usedDates[dateString] = true
      }
      const entrySection: ListElement = { type: 'entry', entry }
      return dateSction ? [dateSction, entrySection] : [entrySection]
    })
  }, [entries])

  return (
    <StyledRoot>
      {isBusy && (
        <LinearProgress
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            height: '3px',
          }}
          data-testid="linear-progress"
        />
      )}

      <StyledList>
        {listElements.map((element) => (
          <StyledListElementContainer
            key={
              element.type === 'date' ? element.dateString : element.entry.id
            }
          >
            {element.type === 'date' ? (
              <Typography
                variant="overline"
                component="div"
                fontWeight={700}
                sx={(theme) => ({
                  padding: '16px 12px 0',
                  color: theme.palette.text.primary,
                })}
              >
                {element.dateString}
              </Typography>
            ) : (
              <Entry
                entry={element.entry}
                dimensions={dimensions}
                onRequestDirectNavtigation={
                  entrySupportsDirectNavigation(element.entry)
                    ? requestDirectNavigation
                    : undefined
                }
              />
            )}
          </StyledListElementContainer>
        ))}
      </StyledList>
    </StyledRoot>
  )
}

import { CompactMetric } from '@fintastic/web/util/metrics-and-lists'
import { createContext, useContext } from 'react'

export type DataContextValue = {
  metrics?: CompactMetric[]
}

export const DataContext = createContext<DataContextValue>({})

export function useDataContext() {
  return useContext(DataContext)
}

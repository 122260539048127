import React, {
  ChangeEventHandler,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Box, FormControl } from '@mui/material'
import { AgGridCellEditorProps } from '../types'
import {
  AG_CELL_EDITOR_CLASS_NAME,
  AG_TEXT_CELL_EDITOR_CLASS_NAME,
} from '../classNames'
import { Maybe } from '@fintastic/shared/util/types'
import { CheckboxColored } from '@fintastic/shared/ui/components'
import { useFeatureConfig } from '@fintastic/web/feature/config'

// ATTENTION: do not change my styles here,
// change them in frontend/main-client/libs/shared/ui/ag-grid-theme-fintastic/src/lib/theme-variants/default.ts
export const AgGridCheckboxCellEditor = memo(
  forwardRef<unknown, AgGridCellEditorProps>(
    ({ afterInput, stopEditing, ...props }, ref) => {
      const [value, setValue] = useState<Maybe<number>>(props.value ?? null)
      const valueRef = useRef(value)
      const inputRef = useRef<HTMLInputElement>(null)

      const customColorForEditableCells = useFeatureConfig(
        'custom_color_for_editable_cells',
      )

      useImperativeHandle(ref, () => ({
        setValue(value: Maybe<number>) {
          valueRef.current = value
          setValue(value)
        },
        getValue() {
          return valueRef.current
        },
        isCancelBeforeStart() {
          return false
        },
        isCancelAfterEnd() {
          return false
        },
      }))

      const handleChangeInput = useCallback<
        ChangeEventHandler<HTMLInputElement>
      >((event) => {
        if (valueRef.current === 0) {
          setValue(null)
          valueRef.current = null
          return
        }
        setValue(event.target.checked ? 1 : 0)
        valueRef.current = event.target.checked ? 1 : 0
      }, [])

      return (
        <Box
          position="relative"
          width="100%"
          className={
            AG_CELL_EDITOR_CLASS_NAME + ' ' + AG_TEXT_CELL_EDITOR_CLASS_NAME
          }
        >
          <FormControl fullWidth>
            <CheckboxColored
              inputRef={inputRef}
              value={value}
              checked={value === 1}
              indeterminate={value === null || value === undefined}
              onChange={handleChangeInput}
              colorOverride={
                customColorForEditableCells.enabled
                  ? customColorForEditableCells.value
                  : undefined
              }
            />
          </FormControl>
          {afterInput}
        </Box>
      )
    },
  ),
)

export default AgGridCheckboxCellEditor

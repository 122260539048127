/**
 Reducers functions, that used by @reduxjs/toolkit in createSlice function
 @see https://redux-toolkit.js.org/api/createSlice
  Notice! Each reducer used by @reduxjs/toolkit as a recipe of immer.produce function
 @see https://immerjs.github.io/immer/produce
 */

import type { PayloadAction } from '@reduxjs/toolkit'
import type { State } from './state'
import type { WritableDraft } from '@fintastic/shared/data-access/redux'
import { Maybe } from '@fintastic/shared/util/types'

export type SetIsSidebarOpenedPayload = boolean
export type SetIsSidebarOpened = PayloadAction<SetIsSidebarOpenedPayload>

function setIsSidebarOpened(
  state: WritableDraft<State>,
  action: SetIsSidebarOpened,
): void {
  state.isSidebarOpened = action.payload
}

export type SetIsDiscussionsOpenedPayload = boolean
export type SetIsDiscussionsOpened =
  PayloadAction<SetIsDiscussionsOpenedPayload>

function setIsDiscussionsOpened(
  state: WritableDraft<State>,
  action: SetIsDiscussionsOpened,
): void {
  state.isDiscussionsOpened = action.payload
}

export type SetSidebarSubmenuPayload = Maybe<string>
export type SetSidebarSubmenu = PayloadAction<SetSidebarSubmenuPayload>

function setSidebarSubmenu(
  state: WritableDraft<State>,
  action: SetSidebarSubmenu,
): void {
  state.sidebarSubmenu = action.payload
}

export type SetCurrentDiscussionObjectIdPayload = Maybe<string>
export type SetCurrentDiscussionObjectId =
  PayloadAction<SetCurrentDiscussionObjectIdPayload>

function setCurrentDiscussionObjectId(
  state: WritableDraft<State>,
  action: SetCurrentDiscussionObjectId,
): void {
  state.currentDiscussionObjectId = action.payload
}

export type SetIsBottomDrawerOpenedPayload = boolean
export type SetIsBottomDrawerOpened =
  PayloadAction<SetIsBottomDrawerOpenedPayload>

function setIsBottomDrawerOpened(
  state: WritableDraft<State>,
  action: SetIsBottomDrawerOpened,
): void {
  state.isBottomDrawerOpened = action.payload
}

export default {
  setIsSidebarOpened,
  setIsDiscussionsOpened,
  setSidebarSubmenu,
  setCurrentDiscussionObjectId,
  setIsBottomDrawerOpened,
}

import { useEffect, useRef, useCallback } from 'react'
import { TaskState, UpdateTaskFunction } from '../task-state'
import { ShouldStartExecutionPredicate, SubtaskExecutor } from './types'
import { Task as EffectionTask, run, sleep, spawn, all } from 'effection'
import { Maybe } from '@fintastic/shared/util/types'
import { DependenciesChangedEventTarget } from './event-targets'
import { makeDepencenciesOperations } from './dependencies'

type TaskId = string
type SubtaskId = string
type Milliseconds = number

export const useDistributedMonoTaskExecutor = <
  TParams = void,
  TError extends Error = Error,
  TDependencies = unknown,
>(params: {
  subtaskId: string
  shouldStartExecution: ShouldStartExecutionPredicate<TParams>
  executor: SubtaskExecutor<TParams, TDependencies>
  dependencies: TDependencies
  taskState: Maybe<TaskState<TParams, TError>>
  update: UpdateTaskFunction<TError>
  keepAliveInterval?: Milliseconds
  mapSubtaskRuntimeError?: (error: Error) => TError
}) => {
  const {
    update,
    subtaskId,
    taskState,
    shouldStartExecution,
    executor,
    dependencies,
    keepAliveInterval = 1000,
    mapSubtaskRuntimeError,
  } = params

  const processingTaskIdRef = useRef<Maybe<string>>(null)

  const runningEffectionTasks = useRef<
    Record<TaskId, Record<SubtaskId, EffectionTask<void>>>
  >({})
  const stopAllRunningTasks = useCallback(() => {
    run(function* () {
      yield* all(
        Object.values(runningEffectionTasks.current).flatMap((subtasks) =>
          Object.values(subtasks).map((runningTask) => runningTask.halt()),
        ),
      )
    })
    runningEffectionTasks.current = {}
  }, [])
  const stopAllRunningTasksRef = useRef(stopAllRunningTasks)
  stopAllRunningTasksRef.current = stopAllRunningTasks

  const dependenciesRef = useRef(dependencies)
  dependenciesRef.current = dependencies
  const dependenciesChangedEventTarget = useRef<DependenciesChangedEventTarget>(
    new DependenciesChangedEventTarget(),
  )
  useEffect(() => {
    dependenciesChangedEventTarget.current.dispatchEvent(
      new Event(DependenciesChangedEventTarget.DEPENDENCIES_CHANGED_EVENT_NAME),
    )
  }, [dependencies])

  useEffect(() => {
    if (taskState === null) {
      // Reset to initial state for some reason - stop all running executors.
      stopAllRunningTasksRef.current()
      return
    }

    if (
      processingTaskIdRef.current !== null &&
      processingTaskIdRef.current !== taskState.task.id
    ) {
      // Stop all executors for because the task id has been changed.
      stopAllRunningTasksRef.current()
    }

    if (
      (processingTaskIdRef.current === null ||
        processingTaskIdRef.current === taskState.task.id) &&
      (taskState.status === 'completed' || taskState.status === 'failed')
    ) {
      // Current task already completed or failed.
      runningEffectionTasks.current[taskState.task.id]?.[subtaskId]?.halt()
      delete runningEffectionTasks.current[taskState.task.id]
      return
    }

    if (
      (processingTaskIdRef.current === null ||
        processingTaskIdRef.current === taskState.task.id) &&
      taskState.task.subtasks.some((s) => s.id === subtaskId)
    ) {
      // For current task the subtask executor is already started.
      return
    }

    if (!shouldStartExecution(taskState)) {
      // In case if it's waiting for something (parameters matching, other subtasks, etc).
      return
    }

    processingTaskIdRef.current = taskState.task.id

    const effectionTask = run(function* () {
      yield* spawn(function* () {
        while (true) {
          update({
            id: subtaskId,
            status: 'running',
          })
          yield* sleep(keepAliveInterval)
        }
      })

      const { getDependency, checkDependency } = makeDepencenciesOperations({
        getDependencies: () => dependenciesRef.current,
        dependenciesChangedChecker: dependenciesChangedEventTarget.current,
      })

      const result = yield* executor({
        taskParams: taskState.task.params,
        getDependency,
        checkDependency,
      })

      update({
        id: subtaskId,
        status: 'completed',
        final: result?.final,
      })
    })

    effectionTask.catch((error: TError) => {
      if (error.message === 'halted') {
        // Skip errors about haled tasks because thy're not relevant.
        return
      }

      update({
        id: subtaskId,
        status: 'failed',
        error: mapSubtaskRuntimeError?.(error) || error,
      })
    })

    if (!runningEffectionTasks.current[processingTaskIdRef.current]) {
      runningEffectionTasks.current[processingTaskIdRef.current] = {}
    }
    runningEffectionTasks.current[processingTaskIdRef.current][subtaskId] =
      effectionTask
  }, [
    executor,
    keepAliveInterval,
    shouldStartExecution,
    subtaskId,
    taskState,
    update,
    mapSubtaskRuntimeError,
  ])

  useEffect(() => stopAllRunningTasksRef.current, [])
}

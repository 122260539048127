import {
  BasicColumnDefinition,
  GenericReportCategoryOptions,
  GenericReportNormalised,
  GenericReportTreeRow,
  SortableDimension,
} from '@fintastic/web/util/generic-report'
import { buildGenericReportAgRows } from '../logic/buildGenericReportAgRows'
import { useReportCalculatedRows } from './useReportCalculatedRows'
import { useMemo, useRef } from 'react'
import { useTreeViewData } from './useTreeViewData'

export type UseReportRowDataParams = {
  reportsData: GenericReportNormalised[]
  options: GenericReportCategoryOptions
  localDimensions: SortableDimension[]
  dimensions: BasicColumnDefinition[]
  allPeriods: string[]
}

export const useReportRowData = ({
  reportsData,
  dimensions,
  localDimensions,
  options,
  allPeriods,
}: UseReportRowDataParams): GenericReportTreeRow[] => {
  const localDimensionsRef = useRef(localDimensions)

  const localDimensionsKey = useMemo(
    () => getDimensionsKey(localDimensions),
    [localDimensions],
  )

  const rowData = useMemo(
    () =>
      buildGenericReportAgRows(
        reportsData,
        dimensions,
        // Do not update row data if local dimensions change
        localDimensionsRef.current,
      ),
    [dimensions, reportsData],
  )

  const treeData = useTreeViewData(
    rowData,
    localDimensions,
    // Update tree data only if local dimensions change
    getDimensionsKey(localDimensionsRef.current) === localDimensionsKey,
  )

  const treeDataWithCalculatedRows = useReportCalculatedRows(
    options.calculatedRows || [],
    treeData,
    localDimensions,
    allPeriods,
  )

  return treeDataWithCalculatedRows
}

const getDimensionsKey = (dimensions: BasicColumnDefinition[]): string =>
  dimensions.map(({ name }) => name).join('-')

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Version,
  versionIsLockedMessage,
  VersionUserLockParsed,
} from '@fintastic/web/util/versions'
import { Maybe } from '@fintastic/shared/util/types'
import { BaseGridWithContext } from '@fintastic/web/ui'
import { CellClickedEvent } from 'ag-grid-community'
import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import {
  StyledPageContainer,
  StyledVersionTablesGrid,
  StyledVersionTitle,
} from '../Version.styled'
import {
  BaseGridEventHandlers,
  BaseGridProps,
  DeleteRowsButton,
  MasterDetailProps,
  TopBar,
} from '@fintastic/shared/ui/grid-framework'
import { StyledVersionsListQuickSearch } from '../VersionsList.styled'
import { QuickSearch, useQuickSearch } from '@fintastic/shared/ui/components'
import { HistoryIcon, ShareIcon } from '@fintastic/shared/ui/icons'
import { Modal } from '@fintastic/shared/ui/legacy-modal-framework'
import { useModalState } from '@fintastic/shared/util/modal'
import {
  TableDataDetailRenderer,
  TableDataDetailRendererParams,
} from '../features/master-details/TableDataDetailRenderer'
import { useMasterDetailsState } from '../features/master-details/useMasterDetailsState'
import {
  DETAIL_GRID_RENDER_HEIGHT,
  useMasterDetailsRowsSizes,
} from '../features/master-details/useMasterDetailsRowsSizes'
import {
  MasterDetailContextProvider,
  MasterDetailContextValue,
} from '../features/master-details/master-detail-context'
import { VersionsShareForm } from '../Forms/VersionsShareForm'
import { UserData, useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { StyledHistoryButton, StyledShareButton } from './LiveActuals.styled'
import { VersionTable, VersionTableAgGridContext } from '../types'
import { generateVersionPlaceholder } from '../utils'
import { useCurrentEditingFlow } from '@fintastic/web/data-access/metrics-and-lists'
import { useExitSettingsEditingMode } from '../features/metris-and-lists-management/exit-editing-mode/useExitSettingsEditingMode'
import { useTabClosingPrompt } from '../features/metris-and-lists-management/tab-closing-prompt/useTabClosingPrompt'
import { useLabelsListQuery } from '@fintastic/web/data-access/labels'
import { useRowSelection } from '../features/row-selection/useRowSelection'
import { useDeleteEntity } from '../features/metris-and-lists-management/deletion/useDeleteEntity'
import { checkboxSelectionCallback } from '../features/row-selection/checkboxSelectionCallback'
import { useListEditorApi } from '@fintastic/web/feature/list-editor'
import { useModelExplorerApi } from '@fintastic/web/feature/model-explorer'
import { useLinkToEntity } from '../../../features/link-to-entity'
import { useVersionLockFlags } from '../../../hooks/useVersionLockFlags'
import { useCreateEntityModal } from '../features/metris-and-lists-management/enter-creation-mode/useCreateEntityModal'
import { useBaseGridColumns } from '../features/column-definitions'
import { useReportEditorApi } from '@fintastic/web/feature/report-editor'
import {
  useIsHistoryAllowed,
  VersionLevelHistory,
} from '@fintastic/web/feature/history'
import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { useLoadVersionEntities } from '@fintastic/web/data-access/versions'
import { useVersionPageDirectNavigationExecutor } from '../../../features/direct-navigation'
import { AgGridReact } from 'ag-grid-react'

export type LiveActualsProps = {
  version: Version
  versionTables: VersionTable[]
  editable: boolean
  users: UserData[]
  versionUserLock: VersionUserLockParsed
}

export const LiveActuals: React.FC<LiveActualsProps> = ({
  version,
  versionTables,
  editable,
  users,
  versionUserLock,
}) => {
  const { isLockedForCurrentUser } = useVersionLockFlags(versionUserLock)

  const gridRef = useRef<AgGridReact<VersionTable>>()
  const apiRef = useRef<Maybe<GridApi<VersionTable>>>(null)
  const [gridApiAvailable, setGridApiAvailable] = useState(false)
  const rowSelection = useRowSelection()
  const resetRowSelection = rowSelection.reset
  const { uuid: versionId } = version
  const hasEditingAccess = Boolean(
    useRoleLevelAccess(['power_user', 'modeler']),
  )
  const historyAllowed = useIsHistoryAllowed()
  const isPowerUser = Boolean(useRoleLevelAccess(['power_user']))

  const maybeOpenHistory = useHistoryLogGlobalApi()?.openOnVersionLevel
  const openHistory = useCallback(() => {
    maybeOpenHistory?.(versionId)
  }, [maybeOpenHistory, versionId])

  const {
    toggleGridMasterDetail,
    toggleFormulaMasterDetail,
    getMasterDetailTab,
    checkIsFormulaOpened,
    checkIsGridOpened,
  } = useMasterDetailsState()

  const { rowsSizes, setGridSizeCallback, getRowHeight } =
    useMasterDetailsRowsSizes(getMasterDetailTab)

  useEffect(() => {
    if (apiRef.current) {
      // force recalculate row heights
      apiRef.current.resetRowHeights()
    }
  }, [rowsSizes])

  const [waitingForTable, setWaitingForTable] = useState<Maybe<string>>(null)

  const listEditorApi = useListEditorApi()
  const subscribeToCreatedListEvent =
    listEditorApi && listEditorApi.active
      ? listEditorApi.subscribeToCreatedEvent
      : null
  useEffect(() => {
    if (!subscribeToCreatedListEvent) {
      return
    }
    return subscribeToCreatedListEvent(setWaitingForTable)
  }, [subscribeToCreatedListEvent])

  const labelsQuery = useLabelsListQuery('versions')
  const labelListDataRef = useRef(labelsQuery.data)
  labelListDataRef.current = labelsQuery.data

  const modelExplorerApi = useModelExplorerApi()

  const entitiesQuery = useLoadVersionEntities(versionId)
  const dimensionsRef = useRef(entitiesQuery.data?.dimensions)
  dimensionsRef.current = entitiesQuery.data?.dimensions ?? []

  const columns = useBaseGridColumns(
    {
      versionId,
      isLiveActuals: true,
      editable,
      hasEditingAccess,
    },
    {
      checkIsFormulaOpened,
      checkIsGridOpened,
      toggleFormulaMasterDetail,
      modelExplorerApi,
      labelListDataRef,
      dimensionsRef,
    },
  )

  const masterDetailProps = useMemo<MasterDetailProps<VersionTable>>(() => {
    const detailCellRendererParams: TableDataDetailRendererParams = {
      version,
    }

    return {
      masterDetail: true,
      detailCellRenderer: TableDataDetailRenderer,
      detailRowHeight: DETAIL_GRID_RENDER_HEIGHT,
      detailCellRendererParams,
    }
  }, [version])

  const handleMetricOrListCreatedEvent = useCallback(
    (metricOrListId: string) => {
      setWaitingForTable(metricOrListId)
    },
    [setWaitingForTable],
  )

  // applied only for metrics
  const { popup: exitSettingsEditingPopup, open: exisSettingsEditing } =
    useExitSettingsEditingMode(versionId)

  useTabClosingPrompt()

  const deleteEntityApi = useDeleteEntity(
    versionId,
    useMemo(
      () => ({
        onSuccess: resetRowSelection,
      }),
      [resetRowSelection],
    ),
  )

  const masterDetailContextValue: MasterDetailContextValue = useMemo(
    () => ({
      setGridSizeCallback,
      getTab: getMasterDetailTab,
      toggleFormulaMasterDetail,
      versionId,
      /** @deprecated Use `versionUserLock` directly */
      versionLocked: !editable && !isLockedForCurrentUser,
      onMetricOrListCreated: handleMetricOrListCreatedEvent,
      onCancelMetricOrListEditing: exisSettingsEditing,
      settingsEditingAllowed: hasEditingAccess && editable,
      requestEntityDeletion: deleteEntityApi.requestEntityDeletion,
    }),
    [
      setGridSizeCallback,
      getMasterDetailTab,
      toggleFormulaMasterDetail,
      versionId,
      editable,
      isLockedForCurrentUser,
      handleMetricOrListCreatedEvent,
      exisSettingsEditing,
      hasEditingAccess,
      deleteEntityApi.requestEntityDeletion,
    ],
  )

  const currentEditingFlow = useCurrentEditingFlow()
  const reportEditorApi = useReportEditorApi()
  const maybeActiveReportsEditorApi = reportEditorApi?.active
    ? reportEditorApi
    : null

  const handleCellClicked = useCallback(
    (event: CellClickedEvent<VersionTable>) => {
      if (
        event.column.getColId() === 'info.label_ids' ||
        event.column.getColId() === 'info.description' ||
        event.column.getColId() === 'dimensions'
      ) {
        return
      }
      if (typeof event.data?.id === 'undefined') {
        return
      }

      let shouldPreventCollapse =
        currentEditingFlow?.id === event.data?.id &&
        (currentEditingFlow?.flow === 'editing' ||
          currentEditingFlow?.flow === 'creation')

      if (shouldPreventCollapse && currentEditingFlow?.flow === 'editing') {
        shouldPreventCollapse = checkIsGridOpened(event.data?.id)
      }

      if (shouldPreventCollapse) {
        toast.custom(
          `Save or cancel your changes before collapsing the ${
            currentEditingFlow?.type === 'list' ? 'List' : 'Metric'
          }`,
          {
            className: 'warning',
          },
        )
        return
      }
      toggleGridMasterDetail(event.data.id, event.node)
    },
    [
      checkIsGridOpened,
      currentEditingFlow?.flow,
      currentEditingFlow?.id,
      currentEditingFlow?.type,
      toggleGridMasterDetail,
    ],
  )

  const handleGridReady = useCallback(() => {
    setGridApiAvailable(true)
  }, [])
  const [visibleCount, setVisibleCount] = useState(0)

  const eventHandlers = useMemo<BaseGridEventHandlers<VersionTable>>(
    () => ({
      onCellClicked: handleCellClicked,
      getRowHeight: getRowHeight,
      onGridReady: handleGridReady,
      onRowSelected: rowSelection.handleRowSelected,
      onFilterChanged: ({ api }) => {
        setVisibleCount(() => api.getDisplayedRowCount())
      },
    }),
    [
      getRowHeight,
      handleCellClicked,
      handleGridReady,
      rowSelection.handleRowSelected,
    ],
  )

  const { quickFilterText, handleQuickFilterTextChange } = useQuickSearch(
    apiRef?.current,
  )

  useVersionPageDirectNavigationExecutor(
    version.uuid,
    useMemo(
      () => ({
        gridRef: gridApiAvailable ? gridRef : null,
      }),
      [gridApiAvailable],
    ),
  )

  const {
    isOpen: isShareModalOpened,
    open: openShareModal,
    close: closeShareModal,
  } = useModalState()

  const createListOrMetricUi = useCreateEntityModal(
    {
      versionId,
      isLiveActuals: true,
    },
    {
      versionUserLock,
    },
  )

  const rowData = useMemo(() => {
    const list = versionTables || []
    // https://github.com/ag-grid/ag-grid/issues/4856
    // aggrid 28 (at least) does not process correctly empty arrays
    // so virtual id === -1 is used as (Blank)
    const mappedLabelsList = list.map((r) => {
      if (r.type === 'report') {
        return r
      }
      const ids =
        r.info?.label_ids && (r.info?.label_ids || []).length > 0
          ? r.info?.label_ids
          : [-1]
      return { ...r, info: { ...r.info, label_ids: ids } }
    })

    if (
      maybeActiveReportsEditorApi?.active &&
      maybeActiveReportsEditorApi?.new
    ) {
      return [
        generateVersionPlaceholder({
          id: maybeActiveReportsEditorApi?.reportDefinition.id,
          label: maybeActiveReportsEditorApi?.reportDefinition.name,
          type: 'report',
        }),
        ...mappedLabelsList,
      ]
    }

    if (
      currentEditingFlow?.flow === 'creation' &&
      currentEditingFlow.type !== null
    ) {
      return [
        generateVersionPlaceholder({
          id: currentEditingFlow.id,
          label: currentEditingFlow.label,
          type: currentEditingFlow.type,
          source: currentEditingFlow.source,
        }),
        ...mappedLabelsList,
      ]
    }

    return mappedLabelsList
  }, [
    versionTables,
    currentEditingFlow?.flow,
    currentEditingFlow?.type,
    currentEditingFlow?.id,
    currentEditingFlow?.label,
    currentEditingFlow?.source,
    maybeActiveReportsEditorApi?.active,
    maybeActiveReportsEditorApi?.reportDefinition.id,
    maybeActiveReportsEditorApi?.reportDefinition.name,
    maybeActiveReportsEditorApi?.new,
  ])

  const scrollToRowAndExpand = useCallback((rowId: string) => {
    const rowNode = apiRef.current?.getRowNode(rowId)
    if (!rowNode) {
      return
    }
    apiRef.current?.setRowNodeExpanded(rowNode, true)
    setTimeout(() => {
      apiRef.current?.ensureNodeVisible(rowNode, 'top')
    }, 100)
  }, [])

  useLinkToEntity(gridApiAvailable ? scrollToRowAndExpand : null)

  const createdReportId =
    reportEditorApi && !reportEditorApi.active
      ? reportEditorApi.lastCreatedReportId
      : null
  useEffect(() => {
    if (!createdReportId) {
      return
    }
    setWaitingForTable(createdReportId)
  }, [createdReportId])

  useEffect(() => {
    if (waitingForTable === null) {
      return
    }
    if (
      rowData.find(
        (table) => !table.creationDummy && table.id === waitingForTable,
      )
    ) {
      scrollToRowAndExpand(`${waitingForTable}:existing`)
      setWaitingForTable(null)
    }
  }, [rowData, scrollToRowAndExpand, waitingForTable])

  useEffect(() => {
    if (
      currentEditingFlow?.flow === 'creation' &&
      currentEditingFlow.type !== null
    ) {
      scrollToRowAndExpand(`${currentEditingFlow?.id || ''}:new`)
    }
  }, [
    currentEditingFlow?.flow,
    currentEditingFlow?.id,
    currentEditingFlow.type,
    scrollToRowAndExpand,
  ])

  useEffect(() => {
    if (
      maybeActiveReportsEditorApi?.active &&
      maybeActiveReportsEditorApi?.new
    ) {
      scrollToRowAndExpand(
        `${maybeActiveReportsEditorApi.reportDefinition.id}:new`,
      )
    }
  }, [
    maybeActiveReportsEditorApi?.active,
    maybeActiveReportsEditorApi?.new,
    version.uuid,
    maybeActiveReportsEditorApi?.reportDefinition.id,
    scrollToRowAndExpand,
  ])

  const editProps = useMemo(() => ({ rowIdField: '_rowId' }), [])

  const deletionMayBeApplied =
    hasEditingAccess &&
    rowSelection.selectedRows.length === 1 &&
    editable &&
    !deleteEntityApi.dataIsLoading &&
    !deleteEntityApi.deletionIsInProgress

  const deleteEntityButton = useMemo(() => {
    if (!hasEditingAccess) {
      return null
    }

    if (versionUserLock.editIsBlocked) {
      return (
        <DeleteRowsButton
          title={versionIsLockedMessage(versionUserLock, 'Deleting an entity')}
          onClick={() => {
            deleteEntityApi.requestEntityDeletion(
              rowSelection.selectedRows[0].id,
            )
          }}
          disabled
        />
      )
    }

    return (
      <DeleteRowsButton
        title={
          !deletionMayBeApplied
            ? 'To delete a Metric, List or a Report, ensure that only one entity is selected'
            : 'Delete'
        }
        onClick={() => {
          deleteEntityApi.requestEntityDeletion(rowSelection.selectedRows[0].id)
        }}
        disabled={!deletionMayBeApplied}
      />
    )
  }, [
    deleteEntityApi,
    deletionMayBeApplied,
    hasEditingAccess,
    rowSelection.selectedRows,
    versionUserLock,
  ])

  const agGridContext = useMemo<VersionTableAgGridContext>(
    () => ({
      versionId,
    }),
    [versionId],
  )

  return (
    <StyledPageContainer>
      <StyledVersionTitle>
        <StyledVersionsListQuickSearch>
          <QuickSearch
            value={quickFilterText}
            onChange={handleQuickFilterTextChange}
            totalCount={rowData.length}
            visibleCount={visibleCount}
            data-testid="version-page-quick-search"
          />
        </StyledVersionsListQuickSearch>

        {historyAllowed ? (
          <StyledHistoryButton
            data-testid="history-actuals"
            title="Version history"
            onClick={openHistory}
          >
            <HistoryIcon />
          </StyledHistoryButton>
        ) : null}

        {hasEditingAccess ? (
          <StyledShareButton data-testid="share-actuals" title="Share version">
            <ShareIcon onClick={openShareModal} />
          </StyledShareButton>
        ) : null}

        {hasEditingAccess && createListOrMetricUi.button}
      </StyledVersionTitle>

      <StyledVersionTablesGrid data-testid="version-page-main-grid">
        <MasterDetailContextProvider value={masterDetailContextValue}>
          <MemoGrid
            gridRef={gridRef}
            apiRef={apiRef}
            rowData={rowData}
            columns={columns}
            masterDetailProps={masterDetailProps}
            eventHandlers={eventHandlers}
            editProps={editProps}
            checkboxSelection={
              hasEditingAccess ? checkboxSelectionCallback : false
            }
            topbar={
              hasEditingAccess ? (
                <TopBar leftContent={deleteEntityButton} />
              ) : undefined
            }
            agContext={agGridContext}
          />
        </MasterDetailContextProvider>
      </StyledVersionTablesGrid>

      <>
        <Modal
          open={isShareModalOpened}
          onRequestClose={closeShareModal}
          title={'Share actual'}
          width={400}
          keepMounted={true}
        >
          <VersionsShareForm
            closeParentModal={closeShareModal}
            selectedVersions={[version]}
            users={users}
          />
        </Modal>
        {createListOrMetricUi.modal}
        {exitSettingsEditingPopup}
        {deleteEntityApi.modals}
        {isPowerUser && <VersionLevelHistory versionLabel={version.name} />}
      </>
    </StyledPageContainer>
  )
}

const MemoGrid = React.memo<BaseGridProps<VersionTable>>(BaseGridWithContext)

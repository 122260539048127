/**
 Types for describe duck state and extend root state
 */

import { Maybe } from '@fintastic/shared/util/types'

/**
 Will be used in @reduxjs/toolkit to generate action types' names
 and to add duck reducer to the root state
 */
export const namespace = 'layout' as const

// extending root state to use it in selectors
export type RootState = {
  [namespace]: State
}

// duck state
export type State = {
  isSidebarOpened: boolean
  sidebarSubmenu: Maybe<string>
  isDiscussionsOpened: boolean
  currentDiscussionObjectId: Maybe<string>
  isBottomDrawerOpened: boolean
}

export const initialState: State = {
  isSidebarOpened: false,
  isDiscussionsOpened: false,
  sidebarSubmenu: null,
  currentDiscussionObjectId: null,
  isBottomDrawerOpened: false,
}

import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { ChartDebugDataTable } from '../../debug/ChartDebugDataTable/ChartDebugDataTable'
import { ChartDebugParams } from '../../debug/ChartDebugParams/ChartDebugParams'
import { Chart } from '../../../../chart/Chart'
import {
  ChartDebugDisplayMode,
  ChartRequestParams,
  ChartSettings,
  MultiversionChartData,
} from '../../../../../types'
import {
  StyledChartContent,
  StyledChartPanel,
} from './MetricChartPanels.styled'
import { MetricChartPanelsError } from './MetricChartPanelsError'
import { MetricChartEmpty } from './MetricChartEmpty'

type MetricChartPanelsProps = {
  chartData: Maybe<MultiversionChartData>
  displayMode: ChartDebugDisplayMode
  loading: boolean
  isDesignMode?: boolean
  chartSettings: ChartSettings
  request: ChartRequestParams
  showZoomPanPanel?: boolean
}

// @todo: get rid of debug panels after charts stabilized?
export const MetricChartPanels: React.FC<MetricChartPanelsProps> = ({
  chartData,
  displayMode,
  loading,
  isDesignMode,
  chartSettings,
  request,
  showZoomPanPanel = false,
}) => {
  const shouldShowError =
    !loading && (!chartData || chartData.allVersionsFailed)

  if (shouldShowError) {
    return <MetricChartPanelsError chartData={chartData} />
  }

  const shouldShowEmpty =
    !loading &&
    chartData?.data &&
    chartData.data.length === 0 &&
    !chartData.allVersionsFailed

  if (shouldShowEmpty) {
    return <MetricChartEmpty />
  }

  return (
    <StyledChartContent data-testid="chart-content">
      <StyledChartPanel>
        {displayMode === 'chart' && (
          <Chart
            isDesignMode={isDesignMode}
            loading={loading}
            displaySettings={chartSettings}
            chartData={chartData}
            showZoomPanPanel={showZoomPanPanel}
          />
        )}

        {displayMode === 'table' && (
          <ChartDebugDataTable chartData={chartData} />
        )}

        {displayMode === 'debug' && (
          <ChartDebugParams
            chartSettings={chartSettings}
            request={request}
            chartData={chartData}
          />
        )}
      </StyledChartPanel>
    </StyledChartContent>
  )
}

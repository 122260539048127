import { Maybe } from '@fintastic/shared/util/types'
import { useCallback, useMemo, useState, useRef } from 'react'
import { TokenType, TokenWithIndex } from '../tokens/types'

export const useTokenDetailsPopperApi = (options: {
  supportedTokenTypes: TokenType[]
}) => {
  const optionsRef = useRef(options)
  optionsRef.current = options

  const [openedToken, setOpenedToken] = useState<Maybe<TokenWithIndex>>(null)

  const openTokenDetails = useCallback((token: TokenWithIndex) => {
    if (!optionsRef.current.supportedTokenTypes.includes(token.type)) {
      return
    }
    setOpenedToken(token)
  }, [])

  const closeTokenDetails = useCallback(() => {
    setOpenedToken(null)
  }, [])

  return useMemo(
    () =>
      ({
        openedToken,
        openTokenDetails,
        closeTokenDetails,
      } as const),
    [closeTokenDetails, openTokenDetails, openedToken],
  )
}

import React from 'react'
import { StyledCalcTypeIcon, StyledType, StyledTypeText } from './Type.styled'
import { Typography } from '@mui/material'
import { FunctionAXIcon, FunctionFXIcon } from '@fintastic/shared/ui/icons'

export const Type: React.FC<{
  calculated: boolean
  liveActuals: boolean
}> = ({ calculated, liveActuals }) => (
  <StyledType>
    <StyledTypeText>
      <Typography variant="body1">List</Typography>
    </StyledTypeText>
    {calculated && (
      <StyledCalcTypeIcon>
        {liveActuals ? <FunctionAXIcon /> : <FunctionFXIcon />}
      </StyledCalcTypeIcon>
    )}
  </StyledType>
)

import { useAwaitingEventIds } from '@fintastic/web/util/metric-data-editing'
import { useCallback, useMemo, useRef } from 'react'
import {
  getListCellsByEventId,
  MetricCells,
  useSubscribeOnCellDataUpdateEvent,
} from '@fintastic/web/data-access/metric-data-editing'
import {
  MetricDataValue,
  MetricWithoutData,
  ParsedColumnId,
} from '@fintastic/web/util/metrics-and-lists'
import { SetListColumnCellDataValuePayload } from '@fintastic/web/feature/metrics-and-lists'
import {
  isNullish,
  Nullable,
} from '@fintastic/shared/util/functional-programming'
import { DimensionId, TimeDimensionId } from '@fintastic/web/util/dimensions'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useQueryClient } from 'react-query'
import { invalidateListColumnsCache } from '@fintastic/web/data-access/metrics-and-lists'
import keyBy from 'lodash/keyBy'
import { useConnectionStatus } from '@fintastic/web/feature/realtime'

export const useCellLevelUpdate = (
  versionId: string,
  listId: string,
  deps: {
    rowDimension: DimensionId
    updateCellDataValue: (payload: SetListColumnCellDataValuePayload) => void
    periodSelection: PeriodSelection
    columnsMetadata: MetricWithoutData[]
  },
) => {
  const queryClient = useQueryClient()
  const awaitingEventIds = useAwaitingEventIds()

  const isTabActive = useConnectionStatus().tabIsVisible
  const isTabActiveRef = useRef(isTabActive)
  isTabActiveRef.current = isTabActive

  const {
    updateCellDataValue,
    rowDimension,
    periodSelection,
    columnsMetadata,
  } = deps

  const columnsMap = useMemo(
    () => keyBy(columnsMetadata, 'id'),
    [columnsMetadata],
  )

  const handleEventIdReceived = useCallback(
    (versionIdFromEvent: string, id: string) => {
      if (awaitingEventIds.ids.includes(id)) {
        awaitingEventIds.remove(id)
        return
      }

      getListCellsByEventId(id, versionIdFromEvent, deps.periodSelection)
        .then(({ data: { cells } }) => {
          const cellUpdates = metricCellsToUpdateCoordinates(
            cells,
            {
              versionId: versionIdFromEvent,
              listId,
              timeDimension: periodSelection.aggregationDimensionId,
              rowDimension,
            },
            (entityId, value) => {
              const mask = columnsMap[entityId]?.metadata.value?.mask
              if (isNullish(mask)) {
                return value
              }
              return value === mask ? null : value
            },
          )

          console.log('perform cell level update (list)', {
            versionId,
            eventId: id,
            listId,
            rowDimension,
            periodSelection,
            cellsFromBE: cells,
            mappedOnTheClientCells: cellUpdates,
          })

          cellUpdates.forEach((update) => {
            try {
              updateCellDataValue(update)
            } catch (e) {
              console.error(e)
            }
          })
        })
        .catch((e) => {
          console.error(e)
          invalidateListColumnsCache(
            queryClient,
            {
              versionId: versionIdFromEvent,
              listId,
            },
            true,
          )
        })
    },
    [
      awaitingEventIds,
      columnsMap,
      deps.periodSelection,
      listId,
      periodSelection,
      queryClient,
      rowDimension,
      updateCellDataValue,
      versionId,
    ],
  )

  useSubscribeOnCellDataUpdateEvent(
    useMemo(() => [versionId], [versionId]),
    useCallback(
      (event) => {
        if (
          !event.successful ||
          event.updateData.action !== 'edit_list_column_data'
        ) {
          return
        }

        if (!isTabActiveRef.current) {
          invalidateListColumnsCache(queryClient, {
            versionId,
            listId,
          })
          return
        }

        const includesListOrColumn =
          event.updateData.user_modified_entities.includes(listId) ||
          Boolean(
            event.updateData.user_modified_entities.find(
              (entityId) =>
                ParsedColumnId.fromString(entityId)?.listId === listId,
            ),
          )
        if (!includesListOrColumn) {
          return
        }

        handleEventIdReceived(event.versionId, event.updateData.event_id)
      },
      [handleEventIdReceived, listId, queryClient, versionId],
    ),
  )

  return useMemo(
    () =>
      ({
        awaitEventId: awaitingEventIds.add,
      } as const),
    [awaitingEventIds.add],
  )
}

const metricCellsToUpdateCoordinates = (
  cellsList: MetricCells[],
  genericCoordinates: {
    versionId: string
    listId: string
    timeDimension?: Nullable<TimeDimensionId>
    rowDimension: DimensionId
  },
  mapValue: (entityId: string, value: MetricDataValue) => MetricDataValue,
): SetListColumnCellDataValuePayload[] => {
  const result: SetListColumnCellDataValuePayload[] = []

  for (let i = 0; i < cellsList.length; i++) {
    const cells = cellsList[i]
    const parsedColumnId = ParsedColumnId.fromString(cells.entity_id)
    if (
      !parsedColumnId ||
      parsedColumnId.listId !== genericCoordinates.listId
    ) {
      continue
    }
    for (let j = 0; j < cells.values.length; j++) {
      const value = mapValue(cells.entity_id, cells.values[j])
      result.push({
        value,
        coordinates: {
          versionId: genericCoordinates.versionId,
          metricId: cells.entity_id,
          rowDimension: genericCoordinates.rowDimension,
          timeDimension: genericCoordinates.timeDimension || undefined,
          dimensions: Object.fromEntries(
            cells.dimension_ids.map((dimId, dimIndex) => [
              dimId,
              cells.dimension_value_ids[dimIndex][j],
            ]),
          ),
        },
      })
    }
  }

  return result
}

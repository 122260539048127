import { LinearProgress } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Maybe, Uuid } from '@fintastic/shared/util/types'
import { StyledMetricChartRoot } from './MetricChartContainer.styled'
import {
  useLoadVersionsList,
  useVersionsListMap,
} from '@fintastic/web/data-access/versions'
import { ChartDebugPanel } from './components/debug/ChartDebugPanel/ChartDebugPanel'
import { ChartDebugDisplayMode, ChartSettings } from '../../types'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useChartData } from '../../hooks'
import { useIsFintasticUser } from '@fintastic/web/feature/auth'
import { MetricChartContent, MetricChartTopPanel } from './components'
import { getChartWidgetSettingsOrDefaults } from '../../consts'
import {
  getMetricGridError,
  useMetricInVersions,
  useSetBaseTimeDimensionEffect,
} from '@fintastic/web/feature/metrics-and-lists'
import { useSetBaseTimeDimensionFromErrorEffect } from '@fintastic/web/data-access/base-time-dimension'
import { compact } from 'lodash'

type MetricChartProps = {
  metricId: Uuid
  widgetId: string
  versions: string[]
  title?: string
  isDesignMode?: boolean
  periodSelectorComponent: React.ReactNode
  isCollapsedVert?: boolean
  widgetChartSettings?: ChartSettings
  referenceSettings?: ChartSettings
  periodSelectionValue: PeriodSelection
  collapseButton: React.ReactNode
  handleUpdateChartWidgetSettings?: (settings: Maybe<ChartSettings>) => void
}

export const MetricChartContainer: React.FC<MetricChartProps> = ({
  title,
  versions,
  isDesignMode,
  metricId,
  widgetId,
  periodSelectorComponent,
  widgetChartSettings,
  referenceSettings,
  periodSelectionValue,
  isCollapsedVert,
  collapseButton,
  handleUpdateChartWidgetSettings,
}) => {
  const versionsListQuery = useLoadVersionsList({
    versionsIds: versions,
    withLiveActuals: true,
  })

  const versionsMetadata = useVersionsListMap(
    useMemo(() => versionsListQuery.data || [], [versionsListQuery.data]),
  )

  // current sub-tab for debug mode
  const [displayMode, setDisplayMode] = useState<ChartDebugDisplayMode>('chart')

  const { isLoading, request, data, invalidateChartQueries } = useChartData(
    versions,
    metricId,
    periodSelectionValue,
    widgetChartSettings?.dimensions || [],
    versionsMetadata,
    !isCollapsedVert,
  )

  // for period selection
  const metricInVersionsQuery = useMetricInVersions(
    versions,
    metricId,
    true,
    periodSelectionValue,
  )

  const existingMetrics = useMemo(
    () =>
      metricInVersionsQuery.metricsWithVersion.filter(
        (versionMetric) => !!versionMetric.metric,
      ),
    [metricInVersionsQuery.metricsWithVersion],
  )

  const error = useMemo(
    () =>
      getMetricGridError(versions, {
        metrics: metricInVersionsQuery.metricsWithVersion,
      }),
    [metricInVersionsQuery.metricsWithVersion, versions],
  )

  const dataLoading =
    versionsListQuery.isLoading || isLoading || metricInVersionsQuery.isLoading

  const [showDebugPanels, setShowDebugPanels] = useState(false)
  const isDebugModalEnabled = useIsFintasticUser()

  useEffect(() => {
    const downHandler = (event: KeyboardEvent) => {
      if (
        event.ctrlKey &&
        event.shiftKey &&
        event.key === 'D' &&
        isDebugModalEnabled &&
        !isCollapsedVert
      ) {
        setShowDebugPanels((v) => !v)
      }
    }

    window.addEventListener('keydown', downHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [isCollapsedVert, isDebugModalEnabled])

  useSetBaseTimeDimensionEffect(
    useMemo(
      () =>
        compact(
          existingMetrics.map((i) => i.metric?.metadata.base_time_dimension_id),
        ),
      [existingMetrics],
    ),
    !metricInVersionsQuery.isLoading,
  )

  useSetBaseTimeDimensionFromErrorEffect(error)

  const [showZoomPan, setShowZoomPan] = useState(false)

  const toggleZoomPan = useCallback(() => {
    setShowZoomPan(!showZoomPan)
  }, [showZoomPan])

  return (
    <StyledMetricChartRoot>
      <MetricChartTopPanel
        metricId={metricId}
        versions={versions}
        title={title}
        isDesignMode={isDesignMode}
        showDebugPanels={showDebugPanels}
        isCollapsedVert={isCollapsedVert}
        invalidateQueries={invalidateChartQueries}
        periodSelectionValue={periodSelectionValue}
        collapseButton={collapseButton}
        periodSelectorComponent={periodSelectorComponent}
        allowZoomPan={false /*!isDesignMode && !isCollapsedVert */}
        showZoomPanPanel={showZoomPan}
        toggleZoomPan={toggleZoomPan}
      />

      {dataLoading && !isCollapsedVert && (
        <LinearProgress
          style={{
            position: 'absolute',
            top: '48px',
            left: 0,
            right: 0,
            zIndex: 2,
            height: '3px',
          }}
          data-testid="linear-progress"
        />
      )}

      {!isCollapsedVert && (
        <>
          <MetricChartContent
            loading={dataLoading}
            metricId={metricId}
            chartData={data}
            displayMode={displayMode}
            versions={versions}
            request={request}
            widgetId={widgetId}
            chartSettings={getChartWidgetSettingsOrDefaults(
              widgetChartSettings,
            )}
            referenceSettings={referenceSettings}
            handleUpdateChartWidgetSettings={handleUpdateChartWidgetSettings}
            isDesignMode={Boolean(isDesignMode)}
            showZoomPanPanel={showZoomPan}
          />

          <ChartDebugPanel
            open={showDebugPanels}
            mode={displayMode}
            onSetMode={setDisplayMode}
          />
        </>
      )}
    </StyledMetricChartRoot>
  )
}

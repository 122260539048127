import { styled, Typography } from '@mui/material'

export const StyledDimensions = styled('div')`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
`

export const StyledDimensionsTitle = styled('div')`
  padding-top: 2px;

  color: #6e665e;
`

export const StyledDimensionsList = styled('div')`
  padding-top: 4px;
`

export const StyledDimensionsListItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 8px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const StyledDimensionsListItemIcon = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  min-width: 24px;

  svg {
    width: 24px;
    height: auto;
  }
`

export const StyledDimensionsListItemName = styled('div')`
  width: calc(100% - 32px);
`

export const StyledDimensionChip = styled(Typography)`
  overflow: hidden;

  display: inline-block;
  padding: 4px 8px;

  max-width: 100%;

  text-overflow: ellipsis;
  line-height: 16px;
  white-space: nowrap;

  color: #7042c2;
  background: #faf7f5;
  border-radius: 4px;
`

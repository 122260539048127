import {
  DirectNavigationTarget,
  DirectNavigationTaskError,
  DirectNavigationTaskParams,
} from './types'
import {
  createContext,
  useCallback,
  useRef,
  useMemo,
  useContext,
  useEffect,
} from 'react'
import { mapTargetToTaskId } from './utils'
import {
  DistrubutedMonoTaskStateApi,
  useDistrubutedMonoTaskState,
  useInactivityTimoutExecutor,
} from '@fintastic/shared/util/distributed-mono-task'
import { toast } from '@fintastic/shared/ui/toast-framework'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

type TaskStateApi = DistrubutedMonoTaskStateApi<
  DirectNavigationTaskParams,
  DirectNavigationTaskError
>

export const DirectNavigaionProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const taskStateApi = useDistrubutedMonoTaskState<
    DirectNavigationTaskParams,
    DirectNavigationTaskError
  >()
  useInactivityTimoutExecutor(
    taskStateApi.state,
    taskStateApi.update,
    useMemo(
      () => ({
        timout: 4_000,
      }),
      [],
    ),
  )

  useEffect(() => {
    if (taskStateApi.state?.status !== 'failed') {
      return
    }
    const firstFailedSubtask = taskStateApi.state.task.subtasks.find(
      (s) => s.status === 'failed',
    )
    if (!firstFailedSubtask) {
      return
    }
    const error = firstFailedSubtask.error
    let errorText =
      'The row or cell cannot be highlighted. Something went wrong.'
    if ('getUiMessage' in error) {
      errorText = error.getUiMessage()
    }
    let errorSeverity: 'error' | 'warning' = 'error'
    if ('getSeverity' in error) {
      errorSeverity = error.getSeverity()
    }
    if (errorSeverity === 'warning') {
      toast.custom(errorText, {
        className: 'warning',
        icon: <InfoOutlinedIcon />,
      })
    } else {
      toast.error(errorText)
    }
    console.error(error)
    console.log('direct navigation state:', taskStateApi.state)
  }, [taskStateApi.state?.status, taskStateApi.state?.task.id])

  const taskStateApiRef = useRef<TaskStateApi>(taskStateApi)
  taskStateApiRef.current = taskStateApi

  const navigateTo = useCallback((target: DirectNavigationTarget) => {
    const state = taskStateApiRef.current.state

    const taskId = mapTargetToTaskId(target)

    if (
      state?.task?.id === taskId &&
      (state.status === 'running' || state.status === 'pending')
    ) {
      // Stop because the same task is already running.
      return
    }

    taskStateApiRef.current.init(taskId, target)
  }, [])

  const { state, update } = taskStateApi
  const triggetContextValue = useMemo(
    () => ({
      state,
      navigateTo,
    }),
    [navigateTo, state],
  )
  const executorContextValue = useMemo(
    () => ({
      state,
      update,
    }),
    [state, update],
  )

  return (
    <DirectNavigationTriggerContext.Provider value={triggetContextValue}>
      <DirectNavigationExecutorContext.Provider value={executorContextValue}>
        {children}
      </DirectNavigationExecutorContext.Provider>
    </DirectNavigationTriggerContext.Provider>
  )
}

const DirectNavigationTriggerContext = createContext<{
  state: TaskStateApi['state']
  navigateTo: (target: DirectNavigationTarget) => void
}>({
  state: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  navigateTo: () => {},
})

const DirectNavigationExecutorContext = createContext<{
  state: TaskStateApi['state']
  update: TaskStateApi['update']
}>({
  state: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  update: () => {},
})

export const useDirectNavigationTriggerApi = () =>
  useContext(DirectNavigationTriggerContext)

export const useDirectNavigationExecutorApi = () =>
  useContext(DirectNavigationExecutorContext)

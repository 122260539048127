import { cloneDeep } from 'lodash'
import React, {
  useState,
  useCallback,
  useContext,
  createContext,
  useMemo,
} from 'react'
import {
  PUSHER_EVENT_CELL_DATA_UPDATE,
  useSubscribeToTenantEvent,
} from '@fintastic/web/data-access/service-pusher'
import { DataUpdateEvent } from '@fintastic/web/util/metric-data-editing'
import { differenceInSeconds } from 'date-fns/differenceInSeconds'

export type DataUpdateOperationState = {
  versionId: string
  streamEventId: string // a unique id
  state: OperationState
  lastUpdated: Date
} & {
  type: 'lists/addLine'
  params: {
    listId: string
  }
}

type OperationState =
  | { status: 'running' }
  | { status: 'succeed' }
  | {
      status: 'failed'
      error: unknown
    }

export type DataUpdateOperationGlobalStateContextValue = {
  operations: DataUpdateOperationState[]
  startOperation: (
    operation: Omit<DataUpdateOperationState, 'state' | 'lastUpdated'>,
  ) => void
}

const OPERATION_CLEAN_UP_IN = 10 * 60

const DataUpdateOperationGlobalStateContext =
  createContext<DataUpdateOperationGlobalStateContextValue>({
    operations: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    startOperation: () => {},
  })

export const useDataUpdateOperationGlobalStateContext = () =>
  useContext(DataUpdateOperationGlobalStateContext)

export const DataUpdateOperationGlobalStateProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [operations, setOperations] = useState<DataUpdateOperationState[]>([])

  const startOperation = useCallback(
    (operation: Omit<DataUpdateOperationState, 'state' | 'lastUpdated'>) => {
      setOperations((ops) => {
        const newOps = ops.filter(
          (op) =>
            differenceInSeconds(new Date(), op.lastUpdated) <
            OPERATION_CLEAN_UP_IN,
        )
        if (newOps.some((op) => op.streamEventId === operation.streamEventId)) {
          return newOps
        }
        return [
          ...newOps,
          {
            ...operation,
            lastUpdated: new Date(),
            state: {
              status: 'running',
            },
          },
        ]
      })
    },
    [],
  )

  const finishOperation = useCallback(
    (
      streamEventId: string,
      state: Exclude<OperationState, { status: 'running' }>,
    ) => {
      setOperations((ops) => {
        const newOps = ops.filter(
          (op) =>
            differenceInSeconds(new Date(), op.lastUpdated) <
            OPERATION_CLEAN_UP_IN,
        )
        const operationIndex = newOps.findIndex(
          (op) => op.streamEventId === streamEventId,
        )
        if (operationIndex === -1) {
          return newOps
        }
        const currentOperationState = newOps[operationIndex]
        if (currentOperationState.state.status !== 'running') {
          return newOps
        }

        newOps[operationIndex] = cloneDeep(currentOperationState)
        newOps[operationIndex].state = state
        newOps[operationIndex].lastUpdated = new Date()
        return newOps
      })
    },
    [],
  )

  useSubscribeToTenantEvent<DataUpdateEvent>(
    PUSHER_EVENT_CELL_DATA_UPDATE,
    useCallback(
      (event) => {
        finishOperation(
          event.update.event_id,
          event.success
            ? {
                status: 'succeed',
              }
            : {
                status: 'failed',
                error: {
                  error: event.error,
                  error_code: event.error_code,
                  error_details: event.error_details,
                  error_string: event.error_string,
                },
              },
        )
      },
      [finishOperation],
    ),
  )

  const contextValue = useMemo<DataUpdateOperationGlobalStateContextValue>(
    () => ({
      operations,
      startOperation,
    }),
    [operations, startOperation],
  )

  return (
    <DataUpdateOperationGlobalStateContext.Provider value={contextValue}>
      {children}
    </DataUpdateOperationGlobalStateContext.Provider>
  )
}

import { QueryClient, useQuery } from 'react-query'
import { queryKeys } from './query-keys'
import { getMyUncompletedTasks } from '@fintastic/web/data-access/calc'

export const useLoadMyUncompletedTasks = (enabled: boolean) =>
  useQuery(
    queryKeys.myUncompletedTasks,
    async () => {
      const result = await getMyUncompletedTasks()
      return result.data.pending
    },
    {
      refetchInterval: 3_000,
      refetchOnMount: false,
      cacheTime: 60_000,
      enabled,
      retry: 4,
      retryDelay: 100,
    },
  )

export const invalidateMyUncompletedTasks = (qc: QueryClient) =>
  qc.invalidateQueries(queryKeys.myUncompletedTasks)

import { useCallback, useMemo, useState } from 'react'
import {
  useLoadVersionDependencyGraph,
  useLoadVersionEntities,
} from '@fintastic/web/data-access/versions'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import { MetricSettingsPanelDataProviderProps } from '../../../../settings-panel/metric'
import { currencies } from '@fintastic/shared/data-access/currencies'
import { DependencyGraphWrapper } from '@fintastic/web/util/versions'
import { useLoadListOfMetrics } from '@fintastic/web/data-access/metrics-and-lists'

type Result = Omit<MetricSettingsPanelDataProviderProps, 'metric' | 'children'>

export function useSettingsPanelData(versionId: Maybe<string>) {
  const [shouldLoadData, setShouldLoadData] = useState(false)

  const entitiesQuery = useLoadVersionEntities(versionId, shouldLoadData)
  const dependenciesQuery = useLoadVersionDependencyGraph(
    versionId,
    shouldLoadData,
  )
  const dependencyGraph = useMemo(
    () =>
      !dependenciesQuery.data
        ? null
        : new DependencyGraphWrapper(dependenciesQuery.data),
    [dependenciesQuery.data],
  )
  const metricsList = useLoadListOfMetrics(versionId)

  const handleLoadData = useCallback(() => {
    setShouldLoadData(true)
  }, [])

  return useMemo<Result>(
    () => ({
      versionId,
      isLoading:
        entitiesQuery.isLoading ||
        dependenciesQuery.isLoading ||
        metricsList.isLoading,
      isLoaded:
        entitiesQuery.data !== undefined &&
        dependenciesQuery.data !== undefined &&
        metricsList.data !== undefined,
      load: handleLoadData,
      metricsList: toMaybe(metricsList.data),
      dimensionsList: toMaybe(entitiesQuery.data?.dimensions),
      currenciesList: currencies,
      dependencyGraph,
    }),
    [
      versionId,
      entitiesQuery.isLoading,
      entitiesQuery.data,
      dependenciesQuery.isLoading,
      dependenciesQuery.data,
      metricsList.isLoading,
      metricsList.data,
      handleLoadData,
      dependencyGraph,
    ],
  )
}

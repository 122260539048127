import React, { useCallback, useMemo } from 'react'
import { Board } from '../../../types'
import {
  FormLayout,
  TextField,
  useTextFieldState,
} from '@fintastic/shared/ui/form-framework'
import { useBoardFolderNameValidation } from '../../../hooks/useBoardFolderNameValidation'
import { useBoardMoveToNewFolder } from '../../../hooks/useBoardMoveToNewFolder'

export type BoardMoveToNewFolderFormProps = {
  boardId: Board['id']
  closeParentModal?: () => void
}

export const BoardMoveToNewFolderForm: React.FC<
  BoardMoveToNewFolderFormProps
> = (props) => {
  const { closeParentModal, boardId } = props

  const folderNameFieldState = useTextFieldState()
  const folderNameValidation = useBoardFolderNameValidation({
    folderName: folderNameFieldState.trimmedValue,
  })
  const isSubmitEnabled =
    folderNameFieldState.trimmedValue.length > 0 && folderNameValidation.isValid

  const { moveBoardToNewFolder, mutationState } = useBoardMoveToNewFolder()

  const isSending = mutationState.isLoading
  const submitErrorMessage = useMemo(() => {
    if (!mutationState.error) {
      return null
    }
    console.error(mutationState.error)
    return "Can't move a board to the new folder"
  }, [mutationState.error])

  const handleSubmit = useCallback(() => {
    moveBoardToNewFolder(boardId, folderNameFieldState.trimmedValue, () => {
      if (closeParentModal) {
        closeParentModal()
      }
    })
  }, [
    boardId,
    closeParentModal,
    folderNameFieldState.trimmedValue,
    moveBoardToNewFolder,
  ])

  return (
    <FormLayout
      submitEnabled={!isSending && isSubmitEnabled}
      showLoader={isSending}
      submitButtonText="Create Folder"
      onCancel={closeParentModal}
      submitErrorMessage={submitErrorMessage}
      onSubmit={handleSubmit}
    >
      <TextField
        label="New folder name"
        placeholder="Folder name"
        value={folderNameFieldState.value}
        onChange={folderNameFieldState.setValue}
        error={folderNameFieldState.touched && !folderNameValidation.isValid}
        autoFocus={true}
        errorMessage={
          folderNameFieldState.touched ? folderNameValidation.error : ''
        }
        fullWidth={true}
      />
    </FormLayout>
  )
}

import { useQueriesWithMemo } from '@fintastic/shared/data-access/react-query'
import { useMemo } from 'react'
import { ListWithoutData } from '@fintastic/web/util/metrics-and-lists'
import { getList } from '../api/lists-api'
import { isOriginallyEmpty } from '../api/helpers/originally-empty'
import { metricsAndListsCacheKeys } from '../cache/cache-keys'
import { PeriodSelection } from '@fintastic/web/util/period-selector'

export const makeLoadListQueryFn =
  (vid: string, listId: string, periodSelection?: PeriodSelection) =>
  async () => {
    const response = await getList(vid, listId, periodSelection, false)
    return {
      originallyEmpty: isOriginallyEmpty(response.headers),
      list: response.data,
    }
  }

/** @todo: Rename to useLoadListsWithoutData */
export const useLoadListInDifferentVersions = (
  versionId: string[],
  listId: string,
  periodSelection?: PeriodSelection, // @todo: Do we need period here?
  enabled = true,
  refetchOnlyIfInvalid = false,
) => {
  const querySettings = useMemo(
    () =>
      versionId.map(
        (vid) =>
          ({
            queryKey: metricsAndListsCacheKeys.listWithoutData(
              vid,
              listId,
              periodSelection,
            ),
            queryFn: makeLoadListQueryFn(vid, listId, periodSelection),
            enabled,
            retry: false,
            staleTime: refetchOnlyIfInvalid ? Infinity : undefined,
          } as const),
      ),
    [versionId, listId, periodSelection, enabled, refetchOnlyIfInvalid],
  )

  return useQueriesWithMemo<
    {
      list: ListWithoutData
      originallyEmpty: boolean
    }[],
    Error
  >(querySettings)
}

import {
  useDirectNavigationExecutor,
  DirectNavigationErrorWithMeta,
} from '@fintastic/web/feature/direct-navigation'
import { useMemo } from 'react'
import type { Maybe } from '@fintastic/shared/util/types'
import {
  createFieldKey,
  MetricGridRow,
} from '@fintastic/web/util/metrics-and-lists'
import {
  DimensionId,
  ParsedRowDimensionValueId,
  TimeDimensionId,
  TimeDimensionValueId,
} from '@fintastic/web/util/dimensions'
import { WidgetContextValue } from '@fintastic/shared/ui/widgets-framework'
import { sleep, call, Operation } from 'effection'
import { findRowInMetricOrListGrid } from '@fintastic/web/feature/metrics-and-lists'
import {
  isNullish,
  Nullable,
} from '@fintastic/shared/util/functional-programming'
import { GridRef } from '@fintastic/shared/util/ag-grid'
import { loadManuallyDeletedListRows } from '@fintastic/web/data-access/metrics-and-lists'
import { QueryClient } from 'react-query'
import { GetDependencyOptions } from '@fintastic/shared/util/distributed-mono-task'

type Depedencies = {
  isLoading: boolean
  gridRef: Maybe<GridRef<MetricGridRow>>
  timeDimensionId: Maybe<TimeDimensionId>
  rowDimensionId: Maybe<DimensionId>
  widgetContext: Maybe<WidgetContextValue>
  timeColumnIds: string[]
  visibleColumnIds: string[]
  allExistingColumnIds: string[]
  queryClient: QueryClient
}

export const useListGridDirectNavigationExecutor = (
  params: {
    versionId: string
    listId: string
  },
  dependencies: Depedencies,
) => {
  useDirectNavigationExecutor<Depedencies>(
    useMemo(
      () => ({
        subtaskId: 'listGrid/navigateToCellOrRows',
        shouldStartExecution: ({ task }) =>
          (task.params.type === 'listColumnCell' ||
            task.params.type === 'listRows') &&
          params.versionId === task.params.coordinates.versionId &&
          task.params.coordinates.listId === params.listId,
        dependencies,
        executor: function* ({ taskParams, getDependency, checkDependency }) {
          if (
            taskParams.type !== 'listColumnCell' &&
            taskParams.type !== 'listRows'
          ) {
            throw new Error('unsupported target type')
          }

          const { widgetContext } = yield* getDependency(
            (deps) => ({ widgetContext: deps.widgetContext }),
            {
              retries: 0,
              retryInterval: 0,
            },
          )

          if (widgetContext) {
            if (widgetContext.isCollapsedVert) {
              widgetContext.toggleCollapsedVert(false)
              yield* sleep(400)
            }

            const scrollRequested = widgetContext.scrollToTheWidget()
            if (scrollRequested) {
              yield* sleep(200)
            }
          }

          yield* checkDependency((deps) => !deps.isLoading, {
            retries: 30,
            retryInterval: 1000,
            debugLabel: 'isLoading',
          })

          const gridApi = yield* getDependency(
            (deps) => deps.gridRef?.current?.api,
            {
              retries: 5,
              retryInterval: 1000,
              debugLabel: 'gridApi',
            },
          )

          if (taskParams.type === 'listRows') {
            if (
              yield* isRowDeleted({
                ...taskParams.coordinates,
                getDependency,
              })
            ) {
              throw new RowDeleted()
            }

            const rowNode = findRowInMetricOrListGrid(
              gridApi,
              taskParams.coordinates.rowDimensionValueId,
            )
            if (!rowNode) {
              throw new RowNotFound()
            }
            if (!rowNode.displayed) {
              throw new RowIsNotVisible()
            }

            if (taskParams.options?.select) {
              const columnApi = yield* getDependency(
                (deps) => deps.gridRef?.current?.columnApi,
                {
                  retries: 5,
                  retryInterval: 1000,
                  debugLabel: 'columnApi',
                },
              )

              gridApi.clearRangeSelection()
              gridApi.addCellRange({
                rowStartIndex: rowNode.rowIndex,
                rowEndIndex: rowNode.rowIndex,
                columns: columnApi.getAllDisplayedColumns(),
              })
              yield* sleep(200)
            }

            gridApi.ensureNodeVisible(rowNode, 'middle')

            if (taskParams.options?.highlight) {
              yield* sleep(100)

              gridApi.flashCells({
                rowNodes: [rowNode],
                flashDelay: 200,
                fadeDelay: 2000,
              })
            }

            return {
              final: true,
            }
          }

          if (taskParams.type === 'listColumnCell') {
            const allExistingColumnIds = yield* getDependency(
              (deps) => deps.allExistingColumnIds,
              {
                retries: 0,
                retryInterval: 0,
                debugLabel: 'allExistingColumnIds',
              },
            )
            if (
              !allExistingColumnIds.includes(taskParams.coordinates.columnId)
            ) {
              throw new ColumnDeleted()
            }

            const visibleColumnIds = yield* getDependency(
              (deps) => deps.visibleColumnIds,
              {
                retries: 0,
                retryInterval: 0,
                debugLabel: 'visibleColumnIds',
              },
            )
            if (!visibleColumnIds.includes(taskParams.coordinates.columnId)) {
              throw new ColumnIsHidden()
            }

            const { timeDimensionId } = yield* getDependency(
              (deps) => ({ timeDimensionId: deps.timeDimensionId }),
              {
                retries: 0,
                retryInterval: 0,
              },
            )
            const timeColumnIds = yield* getDependency(
              (deps) => deps.timeColumnIds,
              {
                retries: 0,
                retryInterval: 0,
                debugLabel: 'timeColumnIds',
              },
            )
            if (
              !isNullish(timeDimensionId) &&
              timeColumnIds.includes(taskParams.coordinates.columnId) &&
              taskParams.coordinates.dimensions.find(
                ([dimId]) => dimId === timeDimensionId,
              ) === undefined
            ) {
              throw new TimeDimensionDoesNotMatch()
            }
            let period: Maybe<TimeDimensionValueId> = null
            if (timeDimensionId) {
              const targetPeriod = taskParams.coordinates.dimensions.find(
                ([dimId]) => dimId === timeDimensionId,
              )?.[1]
              if (targetPeriod) {
                period = targetPeriod
              }
            }

            const columnApi = yield* getDependency(
              (deps) => deps.gridRef?.current?.columnApi,
              {
                retries: 5,
                retryInterval: 1000,
                debugLabel: 'columnApi',
              },
            )
            const column = columnApi.getColumn(
              createFieldKey(
                taskParams.coordinates.versionId,
                taskParams.coordinates.columnId,
                period,
              ),
            )
            if (!column) {
              throw new CurrentPeriodSelectionDoesNotIncludeTargetPeriod()
            }

            const rowDimensionId = yield* getDependency(
              (deps) => deps.rowDimensionId,
              {
                retries: 5,
                retryInterval: 1000,
                debugLabel: 'rowDimensionId',
              },
            )

            const rowId = taskParams.coordinates.dimensions.find(
              ([dimId]) => dimId === rowDimensionId,
            )?.[1]
            if (!rowId) {
              throw new RowDimensionFromCoordinatedDoesNotMatchToTheList()
            }

            if (
              yield* isRowDeleted({
                versionId: taskParams.coordinates.versionId,
                listId: taskParams.coordinates.listId,
                rowDimensionValueId: rowId,
                getDependency,
              })
            ) {
              throw new RowDeleted()
            }

            const rowNode = findRowInMetricOrListGrid(gridApi, rowId)
            if (!rowNode) {
              throw new RowNotFound()
            }
            if (!rowNode.displayed) {
              throw new RowIsNotVisible()
            }

            if (taskParams.options?.select) {
              gridApi.clearRangeSelection()
              gridApi.addCellRange({
                rowStartIndex: rowNode.rowIndex,
                rowEndIndex: rowNode.rowIndex,
                columns: [column],
              })
              yield* sleep(100)
            }

            gridApi.ensureNodeVisible(rowNode, 'middle')
            yield* sleep(100)
            gridApi.ensureColumnVisible(column, 'middle')

            if (taskParams.options?.highlight) {
              yield* sleep(100)
              gridApi.flashCells({
                rowNodes: [rowNode],
                columns: [column],
                flashDelay: 200,
                fadeDelay: 2000,
              })
            }

            return {
              final: true,
            }
          }

          return
        },
      }),
      [dependencies, params.listId, params.versionId],
    ),
  )
}

function* isRowDeleted({
  versionId,
  listId,
  rowDimensionValueId,
  getDependency,
}: {
  versionId: string
  listId: string
  rowDimensionValueId: string
  getDependency: <TResult>(
    selector: (deps: Depedencies) => Nullable<TResult>,
    options?: GetDependencyOptions,
  ) => Operation<TResult>
}): Operation<boolean> {
  const queryClient = yield* getDependency((deps) => deps.queryClient)

  const rowDimValueId =
    ParsedRowDimensionValueId.fromString(rowDimensionValueId) ||
    ParsedRowDimensionValueId.fromRangeDimIdString(rowDimensionValueId, listId)
  if (!rowDimValueId) {
    throw new InvalidRowDimensionValueId()
  }
  const rowIndex = rowDimValueId.toNumericIndex()
  if (rowIndex === null) {
    throw new InvalidRowDimensionValueId()
  }

  const deletedRowsRanges = yield* call(async () => {
    try {
      return loadManuallyDeletedListRows(queryClient, {
        versionId,
        listId,
      })
    } catch (e) {
      throw new FailedToLoadListOfDeletedRows(e as Error)
    }
  })

  return deletedRowsRanges.some(
    ([from, to]) => rowIndex >= from && rowIndex <= to,
  )
}

class RowNotFound extends Error implements DirectNavigationErrorWithMeta {
  constructor() {
    super()
    this.name = 'RowNotFound'
    Object.setPrototypeOf(this, RowNotFound.prototype)
  }

  getUiMessage(): string {
    return "To highlight cells and rows, ensure that filters and group-by aren't applied and select relevant time period and show-by option."
  }

  getSeverity() {
    return 'warning' as const
  }
}

class RowIsNotVisible extends Error implements DirectNavigationErrorWithMeta {
  constructor() {
    super()
    this.name = 'RowIsNotVisible'
    Object.setPrototypeOf(this, RowIsNotVisible.prototype)
  }

  getUiMessage(): string {
    return "To highlight cells and rows, ensure that filters and group-by aren't applied and select relevant time period and show-by option."
  }

  getSeverity() {
    return 'warning' as const
  }
}

class RowDeleted extends Error implements DirectNavigationErrorWithMeta {
  constructor() {
    super()
    this.name = 'RowDeleted'
    Object.setPrototypeOf(this, RowDeleted.prototype)
  }

  getUiMessage(): string {
    return 'The row or cell cannot be highlighted because the row has been deleted and is no longer available.'
  }

  getSeverity() {
    return 'error' as const
  }
}

class ColumnDeleted extends Error implements DirectNavigationErrorWithMeta {
  constructor() {
    super()
    this.name = 'ColumnDeleted'
    Object.setPrototypeOf(this, ColumnDeleted.prototype)
  }

  getUiMessage(): string {
    return 'The cell cannot be highlighted because the column has been deleted and is no longer available.'
  }

  getSeverity() {
    return 'error' as const
  }
}

class ColumnIsHidden extends Error implements DirectNavigationErrorWithMeta {
  constructor() {
    super()
    this.name = 'ColumnIsHidden'
    Object.setPrototypeOf(this, ColumnIsHidden.prototype)
  }

  getUiMessage(): string {
    return "To highlight cells and rows, ensure that filters and group-by aren't applied and select relevant time period and show-by option."
  }

  getSeverity() {
    return 'warning' as const
  }
}

class TimeDimensionDoesNotMatch
  extends Error
  implements DirectNavigationErrorWithMeta
{
  constructor() {
    super()
    this.name = 'TimeDimensionDoesNotMatch'
    Object.setPrototypeOf(this, TimeDimensionDoesNotMatch.prototype)
  }

  getUiMessage(): string {
    return "To highlight cells and rows, ensure that filters and group-by aren't applied and select relevant time period and show-by option."
  }

  getSeverity() {
    return 'warning' as const
  }
}

class CurrentPeriodSelectionDoesNotIncludeTargetPeriod
  extends Error
  implements DirectNavigationErrorWithMeta
{
  constructor() {
    super()
    this.name = 'CurrentPeriodSelectionDoesNotIncludeTargetPeriod'
    Object.setPrototypeOf(
      this,
      CurrentPeriodSelectionDoesNotIncludeTargetPeriod.prototype,
    )
  }

  getUiMessage(): string {
    return "To highlight cells and rows, ensure that filters and group-by aren't applied and select relevant time period and show-by option."
  }

  getSeverity() {
    return 'warning' as const
  }
}

class FailedToLoadListOfDeletedRows extends Error {
  constructor(public cause: Error) {
    super()
    this.name = 'FailedToLoadListOfDeletedRows'
    Object.setPrototypeOf(this, FailedToLoadListOfDeletedRows.prototype)
  }
}

class RowDimensionFromCoordinatedDoesNotMatchToTheList extends Error {
  constructor() {
    super()
    this.name = 'RowDimensionFromCoordinatedDoesNotMatchToTheList'
    Object.setPrototypeOf(
      this,
      RowDimensionFromCoordinatedDoesNotMatchToTheList.prototype,
    )
  }
}

class InvalidRowDimensionValueId extends Error {
  constructor() {
    super()
    this.name = 'InvalidRowDimensionValueId'
    Object.setPrototypeOf(this, InvalidRowDimensionValueId.prototype)
  }
}

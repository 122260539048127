import { Maybe } from '@fintastic/shared/util/types'
import { DimensionId, DimensionValueId } from './types'
import { ParsedListId } from '@fintastic/web/util/metrics-and-lists'

export const DIMENSION_ID_PREFIX = 'Dim'
export const DIM_VALUE_OTHER_POSTFIX = '_other'

export const DIMENSION_ID_ROW_DIM_POSTFIX = '_row_dim'

const dimIdRegExp = new RegExp(`^${DIMENSION_ID_PREFIX}\\.[^.]+$`)
const dimValueIdRegExp = new RegExp(`^${DIMENSION_ID_PREFIX}\\.[^.]+\\..+$`)
const dimValueOtherIdRegExp = new RegExp(
  `^${DIMENSION_ID_PREFIX}\\.[^.]+\\.${DIM_VALUE_OTHER_POSTFIX}$`,
)

export const idLooksLikeDimValueOther = (id: string): boolean =>
  dimValueOtherIdRegExp.test(id)

export const idLooksLikeDimension = (id: string): id is DimensionId =>
  dimIdRegExp.test(id)

export const idLooksLikeDimensionValue = (id: string): id is DimensionValueId =>
  dimValueIdRegExp.test(id)

export class ParsedDimensionId {
  constructor(public readonly idWithoutPrefix: string) {}

  public static fromString(id: string): Maybe<ParsedDimensionId> {
    if (!idLooksLikeDimension(id)) {
      return null
    }

    const [, dimensionIdWithoutPrefix] = id.split('.')

    return new ParsedDimensionId(dimensionIdWithoutPrefix)
  }

  public toString(): string {
    return `${DIMENSION_ID_PREFIX}.${this.idWithoutPrefix}`
  }
}

export class ParsedRowDimensionId extends ParsedDimensionId {
  constructor(
    public readonly idWithoutPrefix: string,
    public readonly kind: 'range' | 'row_dim',
  ) {
    super(idWithoutPrefix)
  }

  public static fromString(id: string): Maybe<ParsedRowDimensionId> {
    const parsedDimId = ParsedDimensionId.fromString(id)
    if (!parsedDimId) {
      return null
    }
    if (!parsedDimId.idWithoutPrefix.endsWith(DIMENSION_ID_ROW_DIM_POSTFIX)) {
      return null
    }
    return new ParsedRowDimensionId(parsedDimId.idWithoutPrefix, 'row_dim')
  }

  public static fromRangeDimIdString(
    id: string,
    listId: string,
  ): Maybe<ParsedRowDimensionId> {
    const parsedDimId = ParsedDimensionId.fromString(id)
    if (!parsedDimId) {
      return null
    }
    const parsedListId = ParsedListId.fromString(listId)
    if (!parsedListId) {
      return null
    }
    if (
      parsedDimId.idWithoutPrefix !== `${parsedListId.idWithoutPrefix}_range`
    ) {
      return null
    }
    return new ParsedRowDimensionId(parsedDimId.idWithoutPrefix, 'range')
  }
}

export class ParsedDimensionValueId {
  constructor(
    public readonly dimensionId: string,
    public readonly valueIdWithoutPrefix: string,
  ) {}

  public static fromString(id: string): Maybe<ParsedDimensionValueId> {
    if (!idLooksLikeDimensionValue(id)) {
      return null
    }

    const [, dimensionIdWithoutPrefix, ...valueIdParts] = id.split('.')
    if (
      valueIdParts.length === 0 ||
      (valueIdParts.length === 1 && valueIdParts[0] === '')
    ) {
      return null
    }

    return new ParsedDimensionValueId(
      `${DIMENSION_ID_PREFIX}.${dimensionIdWithoutPrefix}`,
      valueIdParts.join('.'),
    )
  }

  public toString() {
    return this.toWrappedString()
  }

  public toWrappedString(wrapper: (s: string) => string = (s) => s): string {
    return `${wrapper(this.dimensionId)}.${wrapper(this.valueIdWithoutPrefix)}`
  }
}

export class ParsedRowDimensionValueId extends ParsedDimensionValueId {
  constructor(
    public readonly dimensionId: string,
    public readonly valueIdWithoutPrefix: string,
    public readonly kind: 'range' | 'row_dim',
  ) {
    super(dimensionId, valueIdWithoutPrefix)
  }

  public static fromString(id: string): Maybe<ParsedRowDimensionValueId> {
    const parsedDimValueId = ParsedDimensionValueId.fromString(id)
    if (!parsedDimValueId) {
      return null
    }
    const parsedDimId = ParsedRowDimensionId.fromString(
      parsedDimValueId.dimensionId,
    )
    if (!parsedDimId) {
      return null
    }
    return new ParsedRowDimensionValueId(
      parsedDimValueId.dimensionId,
      parsedDimValueId.valueIdWithoutPrefix,
      parsedDimId.kind,
    )
  }

  public static fromRangeDimIdString(
    id: string,
    listId: string,
  ): Maybe<ParsedRowDimensionValueId> {
    const parsedDimValueId = ParsedDimensionValueId.fromString(id)
    if (!parsedDimValueId) {
      return null
    }

    const parsedDimId = ParsedRowDimensionId.fromRangeDimIdString(
      parsedDimValueId.dimensionId,
      listId,
    )
    if (!parsedDimId) {
      return null
    }

    return new ParsedRowDimensionValueId(
      parsedDimValueId.dimensionId,
      parsedDimValueId.valueIdWithoutPrefix,

      parsedDimId.kind,
    )
  }

  toNumericIndex(): Maybe<number> {
    const parsed = parseInt(this.valueIdWithoutPrefix)
    return Number.isNaN(parsed) ? null : parsed
  }
}

import type { ICellRendererParams } from 'ag-grid-community'
import { VersionTable, VersionTableAgGridContext } from '../types'
import { TagsTag } from '@fintastic/shared/ui/components'
import { Box, Skeleton } from '@mui/material'
import { useMemo } from 'react'
import { useLoadVersionEntities } from '@fintastic/web/data-access/versions'
import { DimensionCompactIcon } from '@fintastic/shared/ui/icons'
import { cleanupDimensionName } from '@fintastic/web/util/metrics-and-lists'

export const FilterDimensionCellRenderer = ({
  value,
  context,
}: ICellRendererParams<VersionTable>) => {
  const entitiesQuery = useLoadVersionEntities(
    (context as VersionTableAgGridContext).versionId,
  )

  const label = useMemo(() => {
    const uncleanedLabel = entitiesQuery.data?.dimensions?.find(
      (d) => d.id === value,
    )?.label

    return uncleanedLabel ? cleanupDimensionName(uncleanedLabel) : undefined
  }, [entitiesQuery.data?.dimensions, value])

  if (value === '(Select All)') {
    return <span>(Select All)</span>
  }

  if (value === '-1') {
    return <span>(Blanks)</span>
  }

  if (entitiesQuery.isLoading && !label) {
    return <Skeleton width={80} />
  }

  if (!label) {
    return <span>(Unknown dimension #{value})</span>
  }

  return (
    <Box
      py="3px"
      sx={{
        background: 'white',
        overflow: 'hidden',
        height: '24px',
        boxSizing: 'border-box',
      }}
    >
      <Box marginTop="-1.5px">
        <TagsTag
          tag={{
            id: value,
            title: label,
          }}
          icon={
            <DimensionCompactIcon fontSize="small" style={{ color: 'black' }} />
          }
        />
      </Box>
    </Box>
  )
}

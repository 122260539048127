import React from 'react'
import { createSvgIcon } from '@mui/material'

export const DimensionCompactIcon = createSvgIcon(
  <>
    <path
      d="M21.0917 7.79417L12.5833 3.39334C12.4009 3.29914 12.1986 3.24998 11.9933 3.24998C11.788 3.24998 11.5857 3.29914 11.4033 3.39334L2.89666 7.79417C2.81286 7.83723 2.74256 7.90256 2.69347 7.98298C2.64438 8.06339 2.61841 8.15579 2.61841 8.25C2.61841 8.34422 2.64438 8.43661 2.69347 8.51703C2.74256 8.59744 2.81286 8.66277 2.89666 8.70583L11.4042 13.1067C11.5866 13.2009 11.7889 13.25 11.9942 13.25C12.1994 13.25 12.4018 13.2009 12.5842 13.1067L21.0917 8.70583C21.1752 8.66256 21.2453 8.59716 21.2942 8.51677C21.3431 8.43638 21.3689 8.3441 21.3689 8.25C21.3689 8.15591 21.3431 8.06362 21.2942 7.98323C21.2453 7.90284 21.1752 7.83744 21.0917 7.79417Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M2.63165 12.625L11.4042 16.8567C11.5866 16.9509 11.7889 17 11.9942 17C12.1994 17 12.4017 16.9509 12.5842 16.8567L21.3817 12.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M2.63165 16.375L11.4042 20.6067C11.5866 20.7009 11.7889 20.75 11.9942 20.75C12.1994 20.75 12.4017 20.7009 12.5842 20.6067L21.3817 16.375"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </>,
  'DimensionCompactIcon',
)

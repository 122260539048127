import React, { useCallback, useMemo } from 'react'
import { ViewLayout } from '@fintastic/shared/ui/view-layout'
import { useActionsContext } from '../../connection/actions/actions-context'
import { useDataContext } from '../../connection/data/data-context'
import { DimensionsChangeCallback } from '../../settings/dimensions/types'
import {
  DimensionId,
  resolveDimensionValues,
} from '@fintastic/web/util/dimensions'
import { Dimension } from '@fintastic/shared/util/types'
import { Box, Divider } from '@mui/material'
import { ReadonlyAlert } from '../../../shared/ReadonlyAlert'
import { metricAdtCommonSelectors } from '@fintastic/web/data-access/metrics-and-lists'
import {
  TimeDimensionSelector,
  InputMetricDimensions,
  CalculatedMetricDimensions,
} from '../../settings/dimensions'
import { compact } from 'lodash'

export const MetricDimensions: React.FC = () => {
  const { metric, dimensionsList, metricsList } = useDataContext()
  const { readonly, actions, flow } = useActionsContext()

  const usedAsWeghtsIn = useMemo(() => {
    if (!metric || !metricsList) {
      return []
    }
    return metricsList.filter(
      (m) => metricAdtCommonSelectors.weightsMetricId(m) === metric.id(),
    )
  }, [metric, metricsList])

  const allWeightedMetricsDimensionIds = useMemo(() => {
    if (usedAsWeghtsIn.length === 0) {
      return null
    }
    const set = new Set<DimensionId>()
    usedAsWeghtsIn.forEach((weightedMetric) => {
      weightedMetric.metadata.dimensions.forEach((dim) => {
        set.add(dim.id)
      })
    })
    return set
  }, [usedAsWeghtsIn])

  const allowedDimensions = useMemo<Dimension[]>(() => {
    if (!metric || !dimensionsList) {
      return []
    }

    const localRangeDims = metric.getLocalRangeDimensions()

    return dimensionsList
      .filter((d) => {
        if (allWeightedMetricsDimensionIds) {
          return (
            metric.data().hasDimension(d.id) ||
            allWeightedMetricsDimensionIds.has(d.id)
          )
        }

        if (d.type === 'Range') {
          return metric.data().hasDimension(d.id)
        }

        return true
      })
      .map<Dimension>((d) => {
        if (d.type !== 'Range') {
          return d
        }
        return {
          ...d,
          values: resolveDimensionValues(d, localRangeDims) || {},
        }
      })
      .filter((d) => !!d.values)
  }, [allWeightedMetricsDimensionIds, dimensionsList, metric])

  const handleChangeDimensions = useCallback<DimensionsChangeCallback>(
    (newDimensions, changedDimensionId) => {
      const changedDimension = newDimensions.find(
        (d) => d.id === changedDimensionId,
      )
      if (changedDimension !== undefined) {
        actions.addDimension(changedDimension)
        return
      }
      actions.removeDimension(changedDimensionId)
    },
    [actions],
  )

  const allowTimeDimensions = !!metric?.allowedToHaveTimeDimension()
  const metricDimensionIds = metric?.data().dimensions()
  const metricDimensions = useMemo(
    () =>
      compact(
        (metricDimensionIds || []).map((dimId) =>
          allowedDimensions.find((dim) => dim.id === dimId),
        ),
      ),
    [allowedDimensions, metricDimensionIds],
  )

  if (!metric) {
    return null
  }

  return (
    <ViewLayout title={'Dimensions'}>
      {allowTimeDimensions ? (
        <>
          <TimeDimensionSelector
            enabled={
              flow === 'creation' || metric?.allowedToChangeTimeDimension()
            }
          />
          <Box my={2}>
            <Divider />
          </Box>
        </>
      ) : null}

      {metric.source() === 'input' ? (
        <InputMetricDimensions
          metricDimensions={metricDimensions}
          allDimensions={allowedDimensions}
          onChangeMetricDimensions={handleChangeDimensions}
          readonly={
            readonly || metricDimensions.length === allowedDimensions.length
          }
          isNewMetric={flow === 'creation'}
          helpTooltipContent={
            usedAsWeghtsIn.length > 0
              ? "You have selected 'Weighted Average' as the aggregation function for this metric. For metrics of this type, you can only add dimensions that are included in the weighted metric"
              : undefined
          }
        />
      ) : (
        <CalculatedMetricDimensions metricDimensions={metricDimensions} />
      )}

      {metric.isCalculated() && (
        <Box mt={1}>
          <ReadonlyAlert
            title={'Defined by formula'}
            description={'Edit formula to add or remove new dimensions.'}
          />
        </Box>
      )}
    </ViewLayout>
  )
}

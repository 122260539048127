import {
  getTenantOverrideParams,
  initAuth0Client,
  TENANT_OVERRIDE_KEY,
} from '@fintastic/web/data-access/service-auth0'
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from '@fintastic/shared/util/hooks'
import {
  EnvironmentVariables,
  isProduction,
  loadEnvVariables,
  resolveTenantConfig,
  environment,
  TenantConfigsSet,
} from '@fintastic/web/data-access/environment'
import { LicenseManager } from 'ag-grid-enterprise'
import { shouldUseTestAuth0Client } from '@fintastic/web/data-access/testing-auth0-client'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import tenantConfigsJson from './tenant-config.json'
import { initAxios } from '@fintastic/web/data-access/service-axios'
import { initPusherClient } from '@fintastic/web/data-access/service-pusher'
import { syntaxInjector } from '@fintastic/web/data-access/formulas'

export const init = async () => {
  // read tentant override from URL and save it to local storage if present
  const { tenantName } = getTenantOverrideParams()
  if (tenantName.length) {
    setLocalStorageValue(TENANT_OVERRIDE_KEY, JSON.stringify(tenantName))
  }

  // load env variables
  const tenantOverride = getLocalStorageValue(TENANT_OVERRIDE_KEY)
  const tenantConfig = resolveTenantConfig(
    tenantConfigsJson as TenantConfigsSet,
    tenantOverride,
  )
  if (!tenantConfig) {
    throw new Error(
      'Could not find a tenant config for current origin or tenant override',
    )
  }
  loadEnvVariables(tenantConfig)

  const env = environment()

  // init libs
  initLibs(env)

  // inject additional scripts
  syntaxInjector.inject(env.REACT_APP_API_URL)
}

const initLibs = (env: Readonly<EnvironmentVariables>) => {
  initAxios(env)
  initAuth0Client(env)
  initPusherClient(env)

  if (env.REACT_APP_AGGRID_LICENSE) {
    LicenseManager.setLicenseKey(env.REACT_APP_AGGRID_LICENSE)
  }

  if (
    env.REACT_APP_LOGROCKET_APP_ID &&
    !(window as any).Cypress &&
    !shouldUseTestAuth0Client()
  ) {
    LogRocket.init(env.REACT_APP_LOGROCKET_APP_ID)
  }

  if (env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: env.REACT_APP_SENTRY_DSN,
      environment: env.REACT_APP_SENTRY_ENVIRONMENT || 'development',
      integrations: [
        Sentry.browserTracingIntegration,
        Sentry.browserProfilingIntegration,
      ],
      tracePropagationTargets: [
        /^https:\/\/app\.staging\.fintastic\.ai/,
        /^https:\/\/app\.fintastic\.ai/,
      ],
      tracesSampleRate: 1.0,
      profilesSampleRate: 1.0,
      enabled: isProduction,
    })
  }
}

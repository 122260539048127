import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  StyledLateralContainer,
  StyledLateralRoot,
} from './LateralPanel.styled'
import { triggerWindowResizeSmoothly } from '@fintastic/shared/util/web-api'
import { layoutConfig } from '../../layout-config'

export const LateralPanel: React.FC<
  PropsWithChildren & { isOpen: boolean }
> = ({ children, isOpen }) => {
  const [openAtLeastOnce, setOpenAtLeastOnce] = useState(isOpen)

  useEffect(() => triggerWindowResizeSmoothly(300, 10), [isOpen])

  useEffect(() => {
    if (isOpen) {
      setOpenAtLeastOnce(true)
    }
  }, [isOpen])

  return (
    <StyledLateralRoot
      sx={{
        paddingLeft: isOpen ? `${layoutConfig.lateralPanel.width}px` : 0,
      }}
    >
      <StyledLateralContainer
        sx={{
          transform: isOpen
            ? 'translate(0, 0)'
            : `translate(${layoutConfig.lateralPanel.width + 1}px)`,
        }}
      >
        {openAtLeastOnce && children}
      </StyledLateralContainer>
    </StyledLateralRoot>
  )
}

import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'
import { Maybe } from '@fintastic/shared/util/types'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'

const LOCAL_OVERRIDE: Maybe<boolean> = null

export const useIsHistoryAllowed = () => {
  const isPowerUser = Boolean(useRoleLevelAccess(['power_user']))
  const featureFlagEnabled = useIsFeatureEnabled('enable_audit_log')
  return LOCAL_OVERRIDE === null
    ? featureFlagEnabled && isPowerUser
    : LOCAL_OVERRIDE
}

import { axios } from '@fintastic/web/data-access/service-axios'
import {
  Metric,
  MetricDisplaySettings,
} from '@fintastic/web/util/metrics-and-lists'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { mapPeriodSelectionToParams } from './helpers/map-period-selection-to-params'
import Qs from 'qs'
import { endpoints } from './metrics-endpoints'
import { AxiosError } from 'axios'

export type ChartAggRequestDimensionRegular = {
  dimension_uid: string
  aggregate: boolean
  time_dimension: boolean
}

export type ChartAggRequestDimensionVersion = {
  version: boolean
}

export const isChartAggRequestDimensionVersion = (
  x: unknown,
): x is ChartAggRequestDimensionVersion =>
  typeof (x as ChartAggRequestDimensionVersion).version !== 'undefined'

export type ChartAggRequestDimension =
  | ChartAggRequestDimensionRegular
  | ChartAggRequestDimensionVersion

export function getMetricChartWithoutData(versionId: string, metricId: string) {
  return axios.post<Metric>(
    endpoints.metricChartDims(versionId, metricId),
    {},
    {
      params: {
        with_data: true,
        with_dimensions: true,
      },
      paramsSerializer: (p: unknown) =>
        Qs.stringify(p, { arrayFormat: 'repeat' }),
    },
  )
}

export type ShapeSizeX = number
export type ShapeSizeY = number

export type RawChartDataMultiversionWarning = {
  error_code: string
  version_id: string
}

export type RawChartDataMultiversionGlobalError = {
  error_code: number
  error_message: string
  original_error_details?: any
}

export type ChartAggResponseDimension = ChartAggRequestDimension

export type RawChartDataMultiversion = {
  labels: {
    x: Array<string>
    y: string[][]
  }
  metadata: {
    dimensions: {
      x: Array<string>
      y: Array<string>
    }
    indexes: {
      x: Record<string, string>
      y: Record<string, string[]>
    }
    display_config: MetricDisplaySettings
    shape: [ShapeSizeX, ShapeSizeY]
  }
  values: Array<Array<number>>
  warnings?: Array<RawChartDataMultiversionWarning>
  used_dimensions?: Array<ChartAggResponseDimension>
  dimension_labels?: Array<{ uid: string; label: string }>
}

export const isRawChartDataMultiversionData = (
  x: unknown,
): x is RawChartDataMultiversion =>
  typeof (x as RawChartDataMultiversion).values !== 'undefined'

export const isRawChartDataMultiversionError = (
  x: unknown,
): x is RawChartDataMultiversionGlobalError =>
  typeof (x as RawChartDataMultiversionGlobalError).error_code !== 'undefined'

export type ChartDataResponseMultiversion = {
  result: RawChartDataMultiversion
}

export function getMetricChartMultiversion(
  versionIds: string[],
  metricId: string,
  periodSelection?: PeriodSelection,
  dimensions?: ChartAggRequestDimension[],
  includeData = true,
) {
  return axios.post<ChartDataResponseMultiversion>(
    endpoints.metricChartMultiversion(),
    {
      metric_id: metricId,
      dimensions,
      versions: versionIds,
    },
    {
      params: {
        ...mapPeriodSelectionToParams(periodSelection),
        with_data: includeData,
      },
      paramsSerializer: (p: unknown) =>
        Qs.stringify(p, { arrayFormat: 'repeat' }),
    },
  )
}

export async function loadChartDataMultiversion(
  versionIds: string[],
  metricId: string,
  periodSelection?: PeriodSelection,
  dimensions?: ChartAggRequestDimension[],
): Promise<RawChartDataMultiversion | RawChartDataMultiversionGlobalError> {
  try {
    const response = await getMetricChartMultiversion(
      versionIds,
      metricId,
      periodSelection,
      dimensions,
    )

    return response?.data?.result
  } catch (err) {
    let errorCode = 520 // unknown server error
    let errorMessage = ''
    let originalErrorDetails = undefined

    if ((err as AxiosError).code === 'ERR_NETWORK') {
      errorCode = 500
    }

    if (
      typeof (err as AxiosError).response?.status !== 'undefined' &&
      !Number.isNaN((err as AxiosError).response?.status) &&
      Number((err as AxiosError).response?.status) > 0
    ) {
      errorCode = Number((err as AxiosError).response?.status)
    }

    if (typeof (err as AxiosError).response?.data !== 'undefined') {
      originalErrorDetails = (err as AxiosError).response?.data
    }

    if (typeof (err as AxiosError).message !== 'undefined') {
      errorMessage = (err as AxiosError).message
    }

    return {
      error_code: errorCode,
      error_message: errorMessage || 'Error on requesting data',
      original_error_details: originalErrorDetails,
    }
  }
}

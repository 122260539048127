import React, {
  createContext,
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useAuthUserInfo } from '@fintastic/web/feature/auth'
import { useQueryClient } from 'react-query'
import {
  CalculationProgressEvent,
  invalidateMyUncompletedTasks,
  useLoadMyUncompletedTasks,
} from '@fintastic/web/data-access/calc'
import { usePusherChannel } from '@fintastic/shared/data-access/pusher-react'
import {
  PUSHER_EVENT_CALCULATION_PROGRESS,
  PUSHER_EVENT_CELL_DATA_UPDATE,
} from '@fintastic/web/data-access/service-pusher'
import { DataUpdateEvent } from '@fintastic/web/util/metric-data-editing'
import { Maybe } from '@fintastic/shared/util/types'
import { debounce } from 'lodash'

export const AwaitForMyTasksProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [enabled, setEnabled] = useState<boolean>(true)
  const [tasksCheckedAtLeastOnce, setTasksCheckedAtLeastOnce] =
    useState<boolean>(false)

  const user = useAuthUserInfo()
  const authenticated = user !== undefined
  const queryClient = useQueryClient()
  const query = useLoadMyUncompletedTasks(authenticated && enabled)

  const [completedTasks, setCompletedTasks] = useState<Record<string, 1>>({})
  const [allTasks, setAllTasks] = useState<string[]>([])

  const [skippedByUser, setSkippedByUser] = useState(false)
  const skip = useCallback(() => {
    setSkippedByUser(true)
  }, [])

  const hasUncompletedTasks = useMemo(
    () =>
      skippedByUser
        ? false
        : allTasks.some((taskId) => !completedTasks[taskId]),
    [allTasks, completedTasks, skippedByUser],
  )

  const progress = useMemo(() => {
    if (!(hasUncompletedTasks && enabled)) {
      return 0
    }
    return Math.min(
      (Object.values(completedTasks).length / allTasks.length) * 100,
      100,
    )
  }, [allTasks.length, completedTasks, enabled, hasUncompletedTasks])

  const contextValue = useMemo<ContextValue>(
    () => ({
      isLoading: query.isError
        ? false
        : enabled
        ? query.isIdle || query.isLoading || !tasksCheckedAtLeastOnce
        : false,
      hasUncompletedTasks: query.isError
        ? false
        : enabled
        ? hasUncompletedTasks
        : false,
      progress,
      skip,
    }),
    [
      enabled,
      hasUncompletedTasks,
      progress,
      query.isError,
      query.isIdle,
      query.isLoading,
      tasksCheckedAtLeastOnce,
      skip,
    ],
  )

  useEffect(() => {
    if (query.data === undefined || !enabled) {
      return
    }

    const uncompletedTasks = query.data.map((task) => task.id)
    const completedTaskIds = allTasks.filter(
      (id) => !uncompletedTasks.includes(id),
    )
    const newTaskIds = uncompletedTasks.filter((id) => !allTasks.includes(id))

    startTransition(() => {
      if (completedTaskIds.length > 0) {
        setCompletedTasks((current) => {
          const newValue = {
            ...current,
          }
          completedTaskIds.forEach((id) => {
            newValue[id] = 1
          })
          return newValue
        })
      }

      if (newTaskIds.length > 0) {
        setAllTasks((current) => [...current, ...newTaskIds])
      }

      setTasksCheckedAtLeastOnce(true)
    })
  }, [allTasks, enabled, query.data, tasksCheckedAtLeastOnce])

  useEffect(() => {
    if (!query.isError && !tasksCheckedAtLeastOnce) {
      return
    }
    if (
      query.isError ||
      (!query.isIdle &&
        !query.isLoading &&
        !query.isFetching &&
        !hasUncompletedTasks)
    ) {
      setEnabled(false)
    }
  }, [
    hasUncompletedTasks,
    query.isError,
    query.isFetching,
    query.isIdle,
    query.isLoading,
    tasksCheckedAtLeastOnce,
  ])

  const channel = usePusherChannel(user?.tenant_id || null)
  const pusherEventHandlerRef =
    useRef<Maybe<(eventName: string, _event: unknown) => void>>(null)
  const debouncedInvalidationFn = useMemo(
    () => debounce(invalidateMyUncompletedTasks, 200),
    [],
  )

  useEffect(() => {
    if (!authenticated || !channel) {
      return
    }

    const handler = (eventName: string, _event: unknown) => {
      if (eventName === PUSHER_EVENT_CALCULATION_PROGRESS) {
        const event = _event as CalculationProgressEvent
        if (event.user === user.email) {
          debouncedInvalidationFn(queryClient)
        }
      }

      if (eventName === PUSHER_EVENT_CELL_DATA_UPDATE) {
        const event = _event as DataUpdateEvent
        if (event.user_email === user.email) {
          debouncedInvalidationFn(queryClient)
        }
      }
    }
    channel.bind_global(handler)
    pusherEventHandlerRef.current = handler

    return () => {
      if (pusherEventHandlerRef.current) {
        channel.unbind_global(pusherEventHandlerRef.current)
        pusherEventHandlerRef.current = null
      }
    }
  }, [
    authenticated,
    channel,
    debouncedInvalidationFn,
    queryClient,
    user?.email,
  ])

  useEffect(() => {
    if (!enabled && pusherEventHandlerRef.current && channel) {
      channel.unbind_global(pusherEventHandlerRef.current)
      pusherEventHandlerRef.current = null
    }
  }, [channel, enabled])

  return (
    <AwaitForMyTasksContext.Provider value={contextValue}>
      {children}
    </AwaitForMyTasksContext.Provider>
  )
}

type ContextValue = {
  isLoading: boolean
  hasUncompletedTasks: boolean
  progress: number
  skip: () => void
}

const AwaitForMyTasksContext = createContext<ContextValue>({
  isLoading: true,
  hasUncompletedTasks: false,
  progress: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  skip: () => {},
})

export const useAwaitForMyTasks = () => useContext(AwaitForMyTasksContext)

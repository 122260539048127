import React, { useMemo, useRef } from 'react'
import {
  StyledAppLayoutDrawerContainer,
  StyledAppLayoutLateralContainer,
  StyledAppLayoutLogoContainer,
  StyledAppLayoutMainContainer,
  StyledAppLayoutPageContainer,
  StyledAppLayoutPageContent,
  StyledAppLayoutRoot,
  StyledAppLayoutSidebarContainer,
  StyledAppLayoutTopbarPlaceholder,
} from './AppLayout.styled'
import { Sidebar } from '../Sidebar'
import { Discussions } from '../Lateral/Discussions/Discussions'
import { Logo } from '../Logo'
import { useLayoutStateIsDiscussionsOpened } from '../../ducks/layout/hooks'
import { layoutConfig } from '../../layout-config'
import { LayoutSidebarConfig } from '../../types'
import {
  AppLayoutContextProvider,
  useAppLayoutContextValue,
} from '../../contexts/app-layout-context'
import { LateralPanel } from '../Lateral/LateralPanel'
import {
  AddLinesDrawer,
  addLinesPayloadType,
  AddLinesStateContext,
  useAddLinesDrawerContextValues,
} from '@fintastic/web/feature/list-add-lines'
import {
  BottomDrawer,
  DrawerContextProvider,
  DrawerContextValue,
} from '@fintastic/shared/ui/drawer-framework'
import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'
import { useLateralPanelTabs } from './lateral-panel-tabs'

export type AppLayoutProps = {
  children: React.ReactNode
  sidebarConfig: LayoutSidebarConfig
  entityLevelHistory: React.ReactNode
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  sidebarConfig,
  entityLevelHistory,
}) => {
  const [isDiscussionsOpen, setIsDiscussionOpen] =
    useLayoutStateIsDiscussionsOpened()
  const historyApi = useHistoryLogGlobalApi()
  const isHistoryOpen =
    !!historyApi?.state.isOpen && historyApi.state.level === 'entity'
  const closeHistory = historyApi?.close
  const appLayoutContextValue = useAppLayoutContextValue(sidebarConfig)

  const lateralPanelContent = useLateralPanelTabs(
    useMemo(
      () => [
        {
          key: 'history',
          isOpen: isHistoryOpen,
          close: () => {
            closeHistory?.()
          },
          content: entityLevelHistory,
        },
        {
          key: 'comments',
          isOpen: isDiscussionsOpen,
          close: () => {
            setTimeout(() => setIsDiscussionOpen(false), 1)
          },
          content: <Discussions />,
        },
      ],
      [
        closeHistory,
        entityLevelHistory,
        isDiscussionsOpen,
        isHistoryOpen,
        setIsDiscussionOpen,
      ],
    ),
  )

  const drawerAddLinesContextValues = useAddLinesDrawerContextValues()

  const drawerContexts = useMemo<DrawerContextValue>(
    () =>
      // set of contexts for drawer, {[code]: {context}}
      ({
        [addLinesPayloadType]: drawerAddLinesContextValues.publicContext,
      }),
    [drawerAddLinesContextValues],
  )

  const pageRef = useRef<HTMLDivElement>(null)

  const contentWidth = `calc(100vw - ${
    layoutConfig.sidebar.bar.width +
    (lateralPanelContent !== null ? layoutConfig.lateralPanel.width : 0)
  }px)`

  return (
    <AppLayoutContextProvider value={appLayoutContextValue}>
      <StyledAppLayoutRoot data-testid="layout-v2-app-root">
        <DrawerContextProvider value={drawerContexts}>
          <StyledAppLayoutTopbarPlaceholder />
          <StyledAppLayoutMainContainer>
            <StyledAppLayoutLogoContainer>
              <Logo />
            </StyledAppLayoutLogoContainer>
            <StyledAppLayoutSidebarContainer>
              <Sidebar />
            </StyledAppLayoutSidebarContainer>
            <StyledAppLayoutPageContainer ref={pageRef}>
              <StyledAppLayoutPageContent
                sx={{
                  width: contentWidth,
                }}
              >
                {children}
              </StyledAppLayoutPageContent>
            </StyledAppLayoutPageContainer>
            <StyledAppLayoutLateralContainer>
              <LateralPanel isOpen={lateralPanelContent !== null}>
                {lateralPanelContent}
              </LateralPanel>
            </StyledAppLayoutLateralContainer>
          </StyledAppLayoutMainContainer>
          <StyledAppLayoutDrawerContainer
            sx={{
              width: contentWidth,
            }}
          >
            <BottomDrawer pageRef={pageRef}>
              <AddLinesStateContext.Provider
                value={drawerAddLinesContextValues.stateContext}
              >
                <AddLinesDrawer />
              </AddLinesStateContext.Provider>
            </BottomDrawer>
          </StyledAppLayoutDrawerContainer>
        </DrawerContextProvider>
      </StyledAppLayoutRoot>
    </AppLayoutContextProvider>
  )
}

import Pusher from 'pusher-js'
import { EnvironmentVariables } from '@fintastic/web/data-access/environment'
import { Maybe } from '@fintastic/shared/util/types'

const pusherClientRef: {
  client: Maybe<Pusher>
} = {
  client: null,
}

export const pusherClient = () => {
  if (!pusherClientRef.client) {
    throw new Error('pusher client is not initialized')
  }
  return pusherClientRef.client
}

export const initPusherClient = (env: Readonly<EnvironmentVariables>) => {
  pusherClientRef.client = new Pusher(env.REACT_APP_PUSHER_APP_KEY, {
    cluster: 'eu',
    enabledTransports: ['ws'],
    forceTLS: true,
  })
}

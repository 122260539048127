import React, { MouseEventHandler, useCallback } from 'react'
import { Token, type TokenWithIndex } from './types'
import { StyledTokenChipRoot } from './TokenChip.styled'
import { useTokenValidation } from '../validation/useTokenValidation'

export type TokenChipProps = {
  token: Token
  index: number
  noPaddings?: boolean
  interactive?: boolean
  onTokenMouseOver?: (token: TokenWithIndex) => void
  onTokenMouseLeave?: (token: TokenWithIndex) => void
}

// @todo add tests
export const TokenChip: React.FC<TokenChipProps> = ({
  token,
  noPaddings = false,
  index,
  interactive,
  onTokenMouseOver,
  onTokenMouseLeave,
}) => {
  const { error, handleHover } = useTokenValidation(token.start, token.stop)

  const handleMouseOver = useCallback<MouseEventHandler<HTMLSpanElement>>(
    (event) => {
      if (interactive) {
        onTokenMouseOver?.({
          ...token,
          index,
        })
      }
      handleHover?.({
        node: event.target as HTMLElement,
        ...token,
      })
    },
    [handleHover, index, interactive, onTokenMouseOver, token],
  )

  const handleMouseLeave = useCallback<
    MouseEventHandler<HTMLSpanElement>
  >(() => {
    if (interactive) {
      onTokenMouseLeave?.({
        ...token,
        index,
      })
    }
    handleHover?.(null)
  }, [handleHover, index, interactive, onTokenMouseLeave, token])

  return (
    <StyledTokenChipRoot
      tokenType={token.type}
      noPaddings={noPaddings}
      interactive={interactive}
      error={!!error}
      data-testid="formula-editor-token-chip"
      data-token-type={token.type}
      onMouseOver={
        ((handleHover && error) || interactive) &&
        token.start !== undefined &&
        token.stop !== undefined
          ? handleMouseOver
          : undefined
      }
      onMouseLeave={
        (handleHover && error) || interactive ? handleMouseLeave : undefined
      }
    >
      {token.text}
    </StyledTokenChipRoot>
  )
}

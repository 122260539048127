import { useState, useEffect } from 'react'

export const useIsOnline = () => {
  const [online, setOnline] = useState(window.navigator.onLine)

  useEffect(() => {
    const handler = () => {
      setOnline(window.navigator.onLine)
    }
    window.addEventListener('online', handler)
    window.addEventListener('offline', handler)
    return () => {
      window.removeEventListener('online', handler)
      window.removeEventListener('offline', handler)
    }
  }, [])

  return online
}

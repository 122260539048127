import {
  PusherConnectionState,
  usePusherConnectionState,
} from '@fintastic/web/data-access/service-pusher'
import { createContext, useMemo, useRef, useContext } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  useIsOnline,
  usePageVisibilityApi,
} from '@fintastic/shared/util/web-api'
import { usePusher } from '@fintastic/shared/data-access/pusher-react'
import { useAuthUserInfo } from '@fintastic/web/feature/auth'
import { usePrevValues } from '@fintastic/shared/util/hooks'

export type ConnectionStatusContextValue = {
  networkOnline: boolean
  prevNetworkOnline: boolean | undefined
  pusherConnection: Maybe<PusherConnectionState>
  prevPusherConnection: Maybe<PusherConnectionState> | undefined
  doublePrevPusherConnection: Maybe<PusherConnectionState> | undefined
  tabIsVisible: boolean
  prevTabIsVisible: boolean | undefined
  networkRecoverCounter: number
  pusherRecoverCounter: number
}

export const ConnectionStatusContext =
  createContext<ConnectionStatusContextValue>({
    networkOnline: true,
    prevNetworkOnline: undefined,
    pusherConnection: null,
    prevPusherConnection: undefined,
    doublePrevPusherConnection: undefined,
    tabIsVisible: true,
    prevTabIsVisible: undefined,
    networkRecoverCounter: 0,
    pusherRecoverCounter: 0,
  })

export const ConnectionStatusProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const pusherClient = usePusher()
  const authenticated = useAuthUserInfo() !== undefined

  const networkOnline = useIsOnline()
  const [prevNetworkOnline] = usePrevValues(networkOnline)
  const netrworkRecoverCounterRef = useRef(0)

  const pusherConnection = usePusherConnectionState(pusherClient, authenticated)
  const [prevPusherConnection, doublePrevPusherConnection] = usePrevValues(
    pusherConnection,
    2,
  )
  const pusherRecoverCounterRef = useRef(0)

  const tabIsVisible = usePageVisibilityApi()
  const [prevTabIsVisible] = usePrevValues(tabIsVisible)

  const contextValue = useMemo<ConnectionStatusContextValue>(() => {
    if (networkOnline && prevNetworkOnline === false) {
      netrworkRecoverCounterRef.current += 1
    }

    if (
      pusherConnection === 'connected' &&
      (prevPusherConnection === 'unavailable' ||
        prevPusherConnection === 'failed' ||
        (prevPusherConnection === 'connecting' &&
          (doublePrevPusherConnection === 'unavailable' ||
            doublePrevPusherConnection === 'failed')))
    ) {
      pusherRecoverCounterRef.current += 1
    }

    return {
      networkOnline,
      prevNetworkOnline,
      pusherConnection,
      prevPusherConnection,
      doublePrevPusherConnection,
      tabIsVisible,
      prevTabIsVisible,
      networkRecoverCounter: netrworkRecoverCounterRef.current,
      pusherRecoverCounter: pusherRecoverCounterRef.current,
    } as ConnectionStatusContextValue
  }, [
    doublePrevPusherConnection,
    networkOnline,
    prevNetworkOnline,
    prevPusherConnection,
    prevTabIsVisible,
    pusherConnection,
    tabIsVisible,
  ])

  return (
    <ConnectionStatusContext.Provider value={contextValue}>
      {children}
    </ConnectionStatusContext.Provider>
  )
}

export const useConnectionStatus = () => useContext(ConnectionStatusContext)

import React, { useCallback, useMemo, useState } from 'react'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { MetricChartSortableColumn } from './MetricChartSortableColumn'
import {
  StyledSorter,
  StyledSorterHeaders,
  StyledSorterPanel,
} from './MetricChartColumns.styled'
import { MetricChartDimensions } from '../../../../../types'
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'

import { InlineInfoTooltip } from '@fintastic/shared/ui/components'

export type MetricChartColumnsUIProps = {
  disabled?: boolean
  dimensions: MetricChartDimensions
  onSetDimensionsOrder: (dimensions: MetricChartDimensions) => void
  onSetDimensionAggregateState: (id: string, checked: boolean) => void
}

const columnLineHeight = 32

export const MetricChartSortableColumns: React.FC<
  MetricChartColumnsUIProps
> = ({
  disabled,
  dimensions,
  onSetDimensionsOrder,
  onSetDimensionAggregateState,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const [inDrag, setInDrag] = useState(false)

  const handleDragStart = useCallback(() => {
    setInDrag(() => true)
  }, [])

  const draggableDims = useMemo(() => [...dimensions], [dimensions])

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const oldIndex = dimensions.findIndex((dim) => dim.id === active.id)
        const newIndex = dimensions.findIndex((dim) => dim.id === over?.id)

        const newData = arrayMove(dimensions, oldIndex, newIndex)
        onSetDimensionsOrder(newData)
      }
      setInDrag(() => false)
    },
    [dimensions, onSetDimensionsOrder],
  )

  return (
    <StyledSorter
      disabled={disabled}
      data-testid="metric-chart-sortable-columns"
    >
      <StyledSorterHeaders>
        <span>Aggregate</span>
        <InlineInfoTooltip
          tooltip={
            'When enabled, the chart displays aggregated data without a breakdown by the selected dimension.'
          }
        />
      </StyledSorterHeaders>

      <StyledSorterPanel
        className={
          'ag-theme-alpine ag-column-drop-vertical-list' +
          (inDrag ? ' dragging' : 'not-dragging')
        }
        style={{
          height: dimensions.length * columnLineHeight + 'px',
        }}
        data-testid="dimensions-sorter-panel"
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[
            restrictToVerticalAxis,
            restrictToFirstScrollableAncestor,
          ]}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext
            items={draggableDims}
            strategy={verticalListSortingStrategy}
          >
            {draggableDims.map((dimension) => (
              <MetricChartSortableColumn
                key={dimension.id}
                dimension={dimension}
                onSetDimensionAggregateState={onSetDimensionAggregateState}
              />
            ))}
          </SortableContext>
        </DndContext>
      </StyledSorterPanel>
    </StyledSorter>
  )
}

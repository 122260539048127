import { axios } from '@fintastic/web/data-access/service-axios'
import { CalculateIntent } from '../types'
import { endpoints } from './endpoints'

export function sendVersionChangeIntent<
  T extends CalculateIntent = CalculateIntent,
>(versionId: string, intent: T) {
  return axios.patch<{ result: string }>(endpoints.calc(versionId), intent)
}

type UncompletedTask = {
  id: string
}

export const getMyUncompletedTasks = () =>
  axios.get<{ pending: UncompletedTask[] }>(
    '/planning/api/v2/progress/tasks/',
    {
      timeout: 10_000,
    },
  )

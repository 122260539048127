import React, { useCallback, useMemo } from 'react'
import { MetricChartWidgetSettings } from './types'
import { UsePeriodSelectionComponentReadyValue } from '@fintastic/web/feature/period-selector'
import {
  useWidgetContextImplementation,
  WidgetContext,
  WidgetDependenciesWarning,
  WidgetInvisibleContainer,
  WidgetRendererProps,
} from '@fintastic/shared/ui/widgets-framework'
import {
  ChartSettings,
  MetricChartContainer,
} from '@fintastic/web/feature/charts'
import { Panel } from '@fintastic/shared/ui/panel-framework'
import { IconButton } from '@mui/material'
import { CollapseIcon, ExpandIcon } from '@fintastic/shared/ui/icons'
import { Maybe } from '@fintastic/shared/util/types'

export type MetricChartWidgetProps =
  WidgetRendererProps<MetricChartWidgetSettings> & {
    periodSelection: UsePeriodSelectionComponentReadyValue
  }

export const MetricChartWidget: React.FC<MetricChartWidgetProps> = (props) => {
  const {
    widget,
    referenceWidget,
    isEnoughSpace,
    boardParams,
    periodSelection,
    isCollapsedVert,
    onChangeSettings,
  } = props

  const widgetContext = useWidgetContextImplementation(
    props,
    !!boardParams.isDesignMode,
  )

  const handleUpdateChartWidgetSettings = useCallback(
    (chartSettings: Maybe<ChartSettings>) => {
      if (chartSettings) {
        onChangeSettings(widget.id, {
          ...widget.settings,
          chartSettings,
        })
      } else {
        onChangeSettings(widget.id, {
          ...widget.settings,
          chartSettings: undefined,
        })
      }
    },
    [onChangeSettings, widget.id, widget.settings],
  )

  const collapseButton = useMemo(
    () => (
      <IconButton
        onClick={() => widgetContext?.toggleCollapsedVert(!isCollapsedVert)}
        color="secondary"
      >
        {isCollapsedVert ? (
          <ExpandIcon data-testid="expand-icon" />
        ) : (
          <CollapseIcon data-testid="collapse-icon" />
        )}
      </IconButton>
    ),
    [isCollapsedVert, widgetContext],
  )

  const widgetContent = useMemo(() => {
    if (!isEnoughSpace) {
      return (
        <WidgetDependenciesWarning>Not enough space</WidgetDependenciesWarning>
      )
    }

    return (
      <WidgetContext.Provider value={widgetContext}>
        <Panel
          sx={{
            display: 'flex',
            flexFlow: 'column nowrap',
          }}
          fullHeight={true}
          data-testid="base-chart-root"
        >
          <MetricChartContainer
            widgetId={widget.id}
            metricId={widget.settings.metricId}
            referenceSettings={referenceWidget?.settings?.chartSettings}
            isDesignMode={boardParams.isDesignMode}
            versions={boardParams.versions}
            title={widget.settings.displayLabel}
            widgetChartSettings={widget.settings.chartSettings}
            handleUpdateChartWidgetSettings={handleUpdateChartWidgetSettings}
            periodSelectorComponent={periodSelection.periodSelectorComponent}
            periodSelectionValue={periodSelection.contextValue.periodSelection}
            isCollapsedVert={isCollapsedVert}
            collapseButton={
              widgetContext.collapsable && widgetContext.draggable
                ? collapseButton
                : undefined
            }
          />
        </Panel>
      </WidgetContext.Provider>
    )
  }, [
    boardParams.isDesignMode,
    boardParams.versions,
    collapseButton,
    isEnoughSpace,
    periodSelection.contextValue.periodSelection,
    periodSelection.periodSelectorComponent,
    widget.id,
    widget.settings.chartSettings,
    widget.settings.displayLabel,
    widget.settings.metricId,
    widgetContext,
  ])

  return (
    <WidgetInvisibleContainer
      data-testid={`board-widget-${widget.id}`}
      data-widget-type={widget.type}
    >
      {widgetContent}
    </WidgetInvisibleContainer>
  )
}

import { QueryClient } from 'react-query'

export const invalidateMetricChartsCache = (
  qc: QueryClient,
  versionId: string,
  metricId?: string,
) => {
  // @todo @ipomazkin-fin @oleksii - add usage of "inActiveTab"
  qc.invalidateQueries({
    predicate: (query) => {
      const key = query.queryKey
      if (key[0] !== 'metricChartMultiversion') {
        return false
      }
      // check the version id match
      if (!`${key[1]}`.includes(versionId)) {
        return false
      }

      if (metricId === undefined) {
        return true
      }

      // check the metric id match
      if (key[3] === metricId) {
        return true
      }
      return false
    },
  })
}

import { QueryClient } from 'react-query'
import { metricsAndListsCacheKeys } from './cache-keys'
import { inActiveTab } from '@fintastic/shared/util/web-api'

export async function invalidateListsListCache(
  queryClient: QueryClient,
  versionId: string,
) {
  inActiveTab(async () => {
    await Promise.all([
      queryClient.invalidateQueries(
        metricsAndListsCacheKeys.lists(versionId, true),
      ),
      queryClient.invalidateQueries(
        metricsAndListsCacheKeys.lists(versionId, false),
      ),
    ])
  })
}

export function triggerWindowResizeSmoothly(timeoutMs = 300, frequencyMs = 20) {
  const isEnabledReference = { enabled: true }
  let proceedMs = -frequencyMs

  const proceed = () => {
    if (!isEnabledReference.enabled) {
      return
    }

    window.dispatchEvent(new Event('resize'))

    proceedMs += frequencyMs
    if (proceedMs < timeoutMs) {
      setTimeout(() => {
        proceed()
      }, frequencyMs)
    }
  }

  proceed()

  return () => {
    isEnabledReference.enabled = false
  }
}

import {
  useDirectNavigationExecutor,
  DirectNavigationErrorWithMeta,
} from '@fintastic/web/feature/direct-navigation'
import { useMemo } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import {
  createFieldKey,
  createRowKey,
  MetricGridRow,
} from '@fintastic/web/util/metrics-and-lists'
import {
  TimeDimensionId,
  TimeDimensionValueId,
} from '@fintastic/web/util/dimensions'
import { findRowInMetricOrListGrid } from '@fintastic/web/feature/metrics-and-lists'
import { sleep } from 'effection'
import { WidgetContextValue } from '@fintastic/shared/ui/widgets-framework'
import { isNullish } from '@fintastic/shared/util/functional-programming'
import { GridRef } from '@fintastic/shared/util/ag-grid'

type Depedencies = {
  gridRef: Maybe<GridRef<MetricGridRow>>
  isLoading: boolean
  widgetContext: Maybe<WidgetContextValue>
  timeDimensionId?: TimeDimensionId
}

export const useMetricGridDirectNavigationExecutor = (
  params: {
    versionIds: string[]
    metricId: string
  },
  dependencies: Depedencies,
) => {
  useDirectNavigationExecutor<Depedencies>(
    useMemo(
      () => ({
        subtaskId: 'metricGrid/goToMetricCell',
        shouldStartExecution: ({ task }) =>
          task.params.type === 'metricCell' &&
          params.versionIds.includes(task.params.coordinates.versionId) &&
          task.params.coordinates.metricId === params.metricId,
        dependencies,
        executor: function* ({ taskParams, getDependency, checkDependency }) {
          if (taskParams.type !== 'metricCell') {
            throw new Error('unsupported target type')
          }

          const { widgetContext } = yield* getDependency(
            (deps) => ({ widgetContext: deps.widgetContext }),
            {
              retries: 0,
              retryInterval: 0,
            },
          )

          if (widgetContext) {
            if (widgetContext.isCollapsedVert) {
              widgetContext.toggleCollapsedVert(false)
              yield* sleep(400)
            }

            const scrollRequested = widgetContext.scrollToTheWidget()
            if (scrollRequested) {
              yield* sleep(200)
            }
          }

          yield* checkDependency((deps) => !deps.isLoading, {
            retries: 30,
            retryInterval: 1000,
            debugLabel: 'isLoading',
          })

          const { timeDimensionId } = yield* getDependency(
            (deps) => ({
              timeDimensionId: deps.timeDimensionId,
            }),
            {
              retries: 0,
              retryInterval: 0,
            },
          )
          if (
            !isNullish(timeDimensionId) &&
            taskParams.coordinates.dimensions.find(
              ([dimId]) => dimId === timeDimensionId,
            ) === undefined
          ) {
            throw new TimeDimensionDoesNotMatch()
          }

          const columnApi = yield* getDependency(
            (deps) => deps.gridRef?.current?.columnApi,
            {
              retries: 5,
              retryInterval: 1000,
              debugLabel: 'columnApi',
            },
          )

          let period: Maybe<TimeDimensionValueId> = null
          if (timeDimensionId) {
            const targetPeriod = taskParams.coordinates.dimensions.find(
              ([dimId]) => dimId === timeDimensionId,
            )?.[1]
            if (targetPeriod) {
              period = targetPeriod
            }
          }

          const column = columnApi.getColumn(
            createFieldKey(
              taskParams.coordinates.versionId,
              taskParams.coordinates.metricId,
              period,
            ),
          )
          if (!column) {
            throw new CurrentPeriodSelectionDoesNotIncludeTargetPeriod()
          }

          const gridApi = yield* getDependency(
            (deps) => deps.gridRef?.current?.api,
            {
              retries: 5,
              retryInterval: 1000,
              debugLabel: 'gridApi',
            },
          )

          const rowId = createRowKey(
            Object.fromEntries(
              taskParams.coordinates.dimensions.filter(
                ([dimId]) => dimId !== timeDimensionId,
              ),
            ),
          )
          const rowNode = findRowInMetricOrListGrid(gridApi, rowId)

          if (!rowNode) {
            throw new TargetCombintationOfCategoricalDimensionsDoesNotExit()
          }

          if (!rowNode.displayed) {
            throw new CellIsNotVisible()
          }

          if (taskParams.options?.select) {
            gridApi.clearRangeSelection()
            gridApi.addCellRange({
              rowStartIndex: rowNode.rowIndex,
              rowEndIndex: rowNode.rowIndex,
              columns: [column],
            })
            yield* sleep(100)
          }

          gridApi.ensureNodeVisible(rowNode, 'middle')
          gridApi.ensureColumnVisible(column, 'middle')

          if (taskParams.options?.highlight) {
            yield* sleep(100)
            gridApi.flashCells({
              rowNodes: [rowNode],
              columns: [column],
              flashDelay: 200,
              fadeDelay: 2000,
            })
          }

          return {
            final: true,
          }
        },
      }),
      [dependencies, params.metricId, params.versionIds],
    ),
  )
}

class TimeDimensionDoesNotMatch
  extends Error
  implements DirectNavigationErrorWithMeta
{
  constructor() {
    super()
    this.name = 'TimeDimensionDoesNotMatch'
    Object.setPrototypeOf(this, TimeDimensionDoesNotMatch.prototype)
  }

  getUiMessage(): string {
    return "To highlight cells and rows, ensure that filters and group-by aren't applied and select relevant time period and show-by option."
  }

  getSeverity() {
    return 'warning' as const
  }
}

class CurrentPeriodSelectionDoesNotIncludeTargetPeriod
  extends Error
  implements DirectNavigationErrorWithMeta
{
  constructor() {
    super()
    this.name = 'CurrentPeriodSelectionDoesNotIncludeTargetPeriod'
    Object.setPrototypeOf(
      this,
      CurrentPeriodSelectionDoesNotIncludeTargetPeriod.prototype,
    )
  }

  getUiMessage(): string {
    return "To highlight cells and rows, ensure that filters and group-by aren't applied and select relevant time period and show-by option."
  }

  getSeverity() {
    return 'warning' as const
  }
}

class TargetCombintationOfCategoricalDimensionsDoesNotExit
  extends Error
  implements DirectNavigationErrorWithMeta
{
  constructor() {
    super()
    this.name = 'TargetCombintationOfCategoricalDimensionsDoesNotExit'
    Object.setPrototypeOf(
      this,
      TargetCombintationOfCategoricalDimensionsDoesNotExit.prototype,
    )
  }

  getUiMessage(): string {
    return 'The cell cannot be highlighted because the metric structure has changed and it is no longer available.'
  }

  getSeverity() {
    return 'error' as const
  }
}

class CellIsNotVisible extends Error implements DirectNavigationErrorWithMeta {
  constructor() {
    super()
    this.name = 'CellIsNotVisible'
    Object.setPrototypeOf(this, CellIsNotVisible.prototype)
  }

  getUiMessage(): string {
    return "To highlight cells and rows, ensure that filters and group-by aren't applied and select relevant time period and show-by option."
  }

  getSeverity() {
    return 'warning' as const
  }
}

import { injectSyntaxScript } from './injectSyntaxScript'

export class SyntaxInjector {
  constructor(
    public started: boolean = false,
    public finished: boolean = false,
    public isError: boolean = false,
  ) {}

  public inject(apiUrl: string) {
    if (this.started) {
      return
    }

    this.started = true
    this.finished = false
    this.isError = false

    injectSyntaxScript(apiUrl)
      .then(() => {
        this.finished = true
      })
      .catch((e) => {
        this.finished = true
        this.isError = true
        console.error(
          "Can't inject syntax api script. Details:",
          (e as Error)?.message,
        )
      })
  }

  public isFinished() {
    return this.started && this.finished
  }

  public async wait() {
    if (this.isFinished()) {
      return
    }

    const waitingTimoutLimit = 1000
    let secondsPass = 0

    // eslint-disable-next-line no-constant-condition
    while (true) {
      await new Promise((res) => setTimeout(res, 10))
      secondsPass += 10
      if (this.isFinished() || secondsPass >= waitingTimoutLimit) {
        return
      }
    }
  }
}

import { QueryClient } from 'react-query'
import { metricsAndListsCacheKeys } from '@fintastic/web/data-access/metrics-and-lists'
import { inActiveTab } from '@fintastic/shared/util/web-api'

export const invalidateListDeletedRows = (
  qc: QueryClient,
  versionId: string,
  listId: string,
) => {
  inActiveTab(() => {
    qc.invalidateQueries(
      metricsAndListsCacheKeys.listDeletedRows({ versionId, listId }),
    )
  })
}

import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Button, Typography } from '@mui/material'
import VersionsChipsContainer from './versions-chips-container/VersionsChipsContainer'
import VersionsPopover from './versions-popover/VersionsPopover'
import type { Maybe } from '@fintastic/shared/util/types'
import { texts } from '@fintastic/web/data-access/versions'
import { ArrowDropDown } from '@mui/icons-material'
import {
  StyledApplyButtonContainer,
  StyledVersionButton,
  StyledVersionButtonIcon,
  StyledVersionButtonText,
  StyledVersionContainer,
} from './VersionsAndDiffSelect.styled'
import Popover from '@mui/material/Popover'
import { VersionSelectorItem } from './types'
import { DiffMode, DiffPart } from '@fintastic/web/util/versions'

type VersionsAndDiffSelectProps = {
  versions: VersionSelectorItem[]
  selectedVersions: string[]
  selectVersions: (versions: string[]) => void
  selectDiffs: (diffs: [string, string, DiffMode][]) => void
  selectedDiffs: [string, string, DiffMode][]
  noPaddings?: boolean
  showApplyButton?: boolean
  enableDiffsSelection?: boolean
}

export const VersionsAndDiffSelect: React.FC<VersionsAndDiffSelectProps> = ({
  versions,
  selectedVersions,
  selectVersions,
  selectDiffs,
  selectedDiffs,
  noPaddings = false,
  showApplyButton = false,
  enableDiffsSelection = true,
}) => {
  const areVersionsAvailable = !!versions.length

  const [anchorEl, setAnchorEl] = useState<Maybe<HTMLElement>>(null)
  const [localSelectedVersions, setLocalSelectedVersions] =
    useState(selectedVersions)
  const [localSelectedDiffs, setLocalDiffs] =
    useState<[string, string, DiffMode][]>(selectedDiffs)

  const destroying = useRef(false)

  useEffect(() => {
    destroying.current = false
    return () => {
      destroying.current = true
    }
  }, [])

  const getDiffPairLabels = useCallback(
    (diff: string[]): [string, string] => {
      const version1 = versions.find(
        (version) => version.id === diff[DiffPart.firstVersion],
      )
      const version2 = versions.find(
        (version) => version.id === diff[DiffPart.secondVersion],
      )

      return [version1?.name || 'unknown 1', version2?.name || 'unknown 2']
    },
    [versions],
  )

  const handleVersions = useCallback(
    (version: { id: string }, updateStore?: boolean) => {
      let newVersions = [...localSelectedVersions]
      const isVersionExist = newVersions.some((item) => item === version.id)
      let nextDiffs = null
      if (isVersionExist) {
        if (newVersions.length === 1) {
          return
        }
        // to filter the columns that connects to the version
        const filteredColumns = localSelectedDiffs.filter(
          (column) => !column.includes(version.id),
        )
        setLocalDiffs(filteredColumns)
        nextDiffs = filteredColumns

        newVersions = newVersions.filter((item) => item !== version.id)
      } else {
        newVersions.push(version.id)
      }
      setLocalSelectedVersions(newVersions)
      if (updateStore) {
        selectVersions(newVersions)
      }
      if (updateStore && nextDiffs) {
        selectDiffs(nextDiffs)
      }
    },
    [
      setLocalSelectedVersions,
      localSelectedVersions,
      setLocalDiffs,
      localSelectedDiffs,
      selectVersions,
      selectDiffs,
    ],
  )

  useEffect(() => {
    setLocalSelectedVersions(selectedVersions)
  }, [selectedVersions])

  useEffect(() => {
    setLocalDiffs(selectedDiffs)
  }, [selectedDiffs])

  const openPopover = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      if (anchorEl) {
        setAnchorEl(null)
        return
      }

      if (event) {
        setAnchorEl(event.currentTarget)
      } else {
        setAnchorEl(null)
      }
    },
    [anchorEl],
  )

  const closePopoverAndApply = useCallback(() => {
    setAnchorEl(null)

    setTimeout(() => {
      if (!destroying.current) {
        selectVersions(localSelectedVersions)
        selectDiffs(localSelectedDiffs)
      }
    }, 20)
  }, [selectVersions, selectDiffs, localSelectedVersions, localSelectedDiffs])

  const closePopoverAndCancel = useCallback(() => {
    setAnchorEl(null)
    setTimeout(() => {
      if (!destroying.current) {
        selectVersions(selectedVersions)
        selectDiffs(selectedDiffs)
      }
    }, 20)
  }, [selectVersions, selectDiffs, selectedVersions, selectedDiffs])

  return (
    <StyledVersionContainer
      data-testid="versions-and-diff-select-root"
      sx={{
        width: '100%',
        ...(noPaddings
          ? {
              padding: 0,
            }
          : {}),
      }}
    >
      <StyledVersionButton
        onClick={openPopover}
        opened={anchorEl !== null}
        data-testid={'versions-and-diff-select-button'}
      >
        <StyledVersionButtonText>
          <Typography data-testid="versions-and-diff-select-label">
            Versions
          </Typography>
        </StyledVersionButtonText>
        <StyledVersionButtonIcon className="icon">
          <ArrowDropDown fontSize="small" />
        </StyledVersionButtonIcon>
      </StyledVersionButton>

      {!areVersionsAvailable && (
        <Typography data-testid="versions-and-diff-select-nothing-shared">
          {texts.noVersionsSharedWithUser}
        </Typography>
      )}

      {areVersionsAvailable && (
        <>
          <VersionsChipsContainer
            selectedVersions={selectedVersions}
            versions={versions}
            handleVersions={handleVersions}
            selectedDiffs={selectedDiffs}
            getDiffPairLabels={getDiffPairLabels}
            selectDiffs={selectDiffs}
            hideDiffs={!enableDiffsSelection}
          />

          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={
              showApplyButton ? closePopoverAndCancel : closePopoverAndApply
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            data-testid="versions-and-diff-select-popover-container"
          >
            <VersionsPopover
              versions={versions}
              localSelectedDiffs={localSelectedDiffs}
              localSelectedVersions={localSelectedVersions}
              setLocalDiffs={setLocalDiffs}
              handleVersions={handleVersions}
              getDiffPairLabels={getDiffPairLabels}
              hideDiffs={!enableDiffsSelection}
            />
            {showApplyButton && (
              <StyledApplyButtonContainer>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={closePopoverAndApply}
                  data-testid="versions-and-diff-select-apply"
                >
                  Apply
                </Button>
              </StyledApplyButtonContainer>
            )}
          </Popover>
        </>
      )}
    </StyledVersionContainer>
  )
}

export default VersionsAndDiffSelect

import { useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { RowToImportDefinition } from './types'

export const useImportRowsState = () => {
  const [rowsToImport, setRowsToImport] =
    useState<Maybe<RowToImportDefinition>>(null)

  return useMemo(
    () => ({
      rowsToImport,
      setRowsToImport,
    }),
    [rowsToImport],
  )
}

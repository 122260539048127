import React, { useCallback, useMemo } from 'react'
import {
  useHistoryLogGlobalApi,
  useHistoryLogParamsState,
  useLoadHistoryLog,
} from '@fintastic/web/data-access/history'
import {
  StyledLayoutRoot,
  StyledRoot,
  StyledWidthContainer,
  StyledHeader,
  StyledMain,
  StyledFilters,
  StyledTable,
  StyledPagination,
} from './VersionLevelHistory.styled'
import { Pagination } from '../pagination'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { useLocalFiltersAndOrderingParamsState } from '../filters-and-ordering'
import { FiltersAndOrdering } from './FiltersAndOrdering'
import { SupportedEntry, supportedEntryTypes } from './supported-entry-types'
import { Table } from './Table'
import { Header } from './Header'
import { useLoadVersionEntities } from '@fintastic/web/data-access/versions'
import {
  ErrorStatus,
  InitialLoadingStatus,
  NoResultsStatus,
} from '../status-indication'
import { keyBy, pick } from 'lodash'

export const VersionLevelHistory: React.FC<{
  versionLabel: string
}> = ({ versionLabel }) => {
  const globalApi = useHistoryLogGlobalApi()
  const versionId =
    globalApi?.state.level === 'version' ? globalApi.state.versionId : null
  const { params, changeParams, changeCurrentPage, reset, defaultParams } =
    useHistoryLogParamsState(
      useMemo(
        () =>
          versionId
            ? {
                paginationLimit: 50,
                filter: () => ({
                  parent_entity_id: [versionId],
                  parent_entity_type: ['version'],
                }),
              }
            : {},
        [versionId],
      ),
      `version-level/${versionId}`,
    )

  const localFiltersAndOrderingStateApi = useLocalFiltersAndOrderingParamsState(
    useMemo(() => pick(params, ['filter', 'desc']), [params]),
    useMemo(() => pick(defaultParams, ['filter', 'desc']), [defaultParams]),
  )
  const applyLocalFiltersAndOrdering = useCallback(() => {
    changeParams(localFiltersAndOrderingStateApi.localParams)
  }, [changeParams, localFiltersAndOrderingStateApi.localParams])

  const logQuery = useLoadHistoryLog(
    params,
    'version',
    !!globalApi?.state.isOpen && versionId !== null,
  )
  const entries = useMemo(() => {
    if (!logQuery.data) {
      return []
    }
    return logQuery.data.result.filter(({ _type }) =>
      supportedEntryTypes.includes(_type),
    ) as SupportedEntry[]
  }, [logQuery.data])

  const entitiesQuery = useLoadVersionEntities(
    versionId,
    !!globalApi?.state.isOpen && versionId !== null,
  )
  const dimensions = useMemo(
    () => keyBy(entitiesQuery.data?.dimensions || [], 'id'),
    [entitiesQuery.data?.dimensions],
  )

  const isBusy =
    logQuery.isFetching || logQuery.isLoading || entitiesQuery.isLoading
  const isInitialLoading = logQuery.isLoading || entitiesQuery.isLoading
  const isError =
    !isInitialLoading && (logQuery.isError || entitiesQuery.isError)

  return (
    <StyledRoot
      open={globalApi?.state.level === 'version' && globalApi?.state.isOpen}
      onClose={globalApi?.close}
      anchor="right"
    >
      <StyledWidthContainer>
        {globalApi && versionId !== null && (
          <StyledLayoutRoot>
            <StyledHeader>
              <Header onClose={globalApi.close} label={versionLabel} />
            </StyledHeader>
            <FintasticThemeProvider applyLegacyTheme={false}>
              <StyledMain>
                <StyledFilters>
                  <FiltersAndOrdering
                    localParamsStateApi={localFiltersAndOrderingStateApi}
                    isBusy={isBusy}
                    resetToDefaults={reset}
                    apply={applyLocalFiltersAndOrdering}
                  />
                </StyledFilters>
                <StyledTable>
                  {isInitialLoading && <InitialLoadingStatus />}
                  {isError && <ErrorStatus message={'Cannot load hisory'} />}
                  {!isInitialLoading && !isError && (
                    <>
                      {entries.length === 0 ? (
                        <NoResultsStatus />
                      ) : (
                        <Table
                          entries={entries}
                          isBusy={isBusy}
                          orderingDesc={params.desc}
                          dimensions={dimensions}
                        />
                      )}
                    </>
                  )}
                </StyledTable>
                {entries.length > 0 && (
                  <StyledPagination>
                    <Pagination
                      goToPage={changeCurrentPage}
                      entriesPerPage={params.pagination.limit}
                      offset={params.pagination.offset}
                      totalEntries={logQuery.data?.total || 0}
                      isBusy={isBusy}
                    />
                  </StyledPagination>
                )}
              </StyledMain>
            </FintasticThemeProvider>
          </StyledLayoutRoot>
        )}
      </StyledWidthContainer>
    </StyledRoot>
  )
}

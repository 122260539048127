import { QueryClient } from 'react-query'
import {
  ParsedColumnId,
  idLooksLikeColumn,
  idLooksLikeList,
  idLooksLikeMetric,
} from '@fintastic/web/util/metrics-and-lists'
import {
  invalidateListCache,
  invalidateListColumnsCache,
  invalidateMetricCache,
  invalidateMetricChartsCache,
  invalidateV2MetricCache,
} from '@fintastic/web/data-access/metrics-and-lists'
import { invalidatePaginatedListColumnsCache } from '@fintastic/web/data-access/metrics-and-lists'

export async function performAffectedEntitiesInvalidation(
  queryClient: QueryClient,
  versionId: string,
  affectedEntities: string[],
) {
  const invalidationPromises: Promise<void>[] = []
  const listsColumns: Record<string, string[]> = {}

  affectedEntities.forEach((entityId) => {
    if (idLooksLikeMetric(entityId)) {
      invalidationPromises.push(
        invalidateMetricCache(queryClient, versionId, entityId),
      )
      invalidateMetricChartsCache(queryClient, versionId, entityId)
      return
    }

    if (idLooksLikeList(entityId)) {
      invalidationPromises.push(
        invalidateListCache(queryClient, versionId, entityId),
      )
      invalidationPromises.push(
        invalidateListColumnsCache(queryClient, {
          versionId,
          listId: entityId,
        }),
      )
      invalidateV2MetricCache.invalidateList(queryClient, versionId, entityId)
      return
    }

    if (idLooksLikeColumn(entityId)) {
      const parsedColumnId = ParsedColumnId.fromString(entityId)
      if (!parsedColumnId) {
        return
      }
      if (listsColumns[parsedColumnId.listId] === undefined) {
        listsColumns[parsedColumnId.listId] = []
      }
      listsColumns[parsedColumnId.listId].push(entityId)
      invalidatePaginatedListColumnsCache(
        queryClient,
        versionId,
        parsedColumnId.listId,
      )

      invalidateV2MetricCache.invalidateListColumn(
        queryClient,
        versionId,
        parsedColumnId.listId,
        entityId,
      )
    }
  })

  Object.entries(listsColumns).forEach(([listId, columnId]) => {
    invalidationPromises.push(
      invalidateListColumnsCache(queryClient, {
        versionId,
        listId,
        columnId,
      }),
    )
  })

  return Promise.all(invalidationPromises)
}

import { Box, styled } from '@mui/material'

export const StyledMetricChartFormula = styled(Box)`
  display: flex;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-flow: column nowrap;
  position: absolute;
  z-index: 10;
  top: 48px;
  background: #fff;

  animation-name: showFormulas;
  animation-duration: 0.5s;
  animation-direction: normal;
  animation-play-state: running;

  @keyframes showFormulas {
    0% {
      opacity: 0;
      max-height: 0;
      transform: translateY(-6px);
    }
    100% {
      opacity: 1;
      max-height: 2000px;
      transform: translateY(0);
    }
  }
`

import React from 'react'
import { PanelToolbarIconButton } from '@fintastic/shared/ui/panel-framework'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import CenterFocusWeakOutlinedIcon from '@mui/icons-material/CenterFocusWeakOutlined'
import { Button } from '@mui/material'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'

export type MetricChartTopBarRightContentProps = {
  showDebugPanels: boolean
  invalidateQueries?: () => void
  periodSelectorComponent?: React.ReactNode
  allowZoomPanPanel?: boolean
  showZoomPanPanel?: boolean
  toggleZoomPan?: () => void
}

export const MetricChartTopBarRightContent: React.FC<
  MetricChartTopBarRightContentProps
> = ({
  showDebugPanels,
  invalidateQueries,
  periodSelectorComponent,
  allowZoomPanPanel,
  showZoomPanPanel,
  toggleZoomPan,
}) => (
  <>
    {showDebugPanels && (
      <PanelToolbarIconButton
        data-testid="refreshChart"
        title={'Refresh'}
        onClick={invalidateQueries}
      >
        <CachedOutlinedIcon />
      </PanelToolbarIconButton>
    )}
    {allowZoomPanPanel && (
      <FintasticThemeProvider applyLegacyTheme={true}>
        <Button
          onClick={toggleZoomPan}
          variant={showZoomPanPanel ? 'contained' : 'outlined'}
          color={'primary'}
          sx={{
            px: 0.4,
          }}
          data-testid={'showZoomPanPanel'}
        >
          <CenterFocusWeakOutlinedIcon fontSize="medium" />
        </Button>
      </FintasticThemeProvider>
    )}
    {periodSelectorComponent}
  </>
)

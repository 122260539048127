import { AgGridCellRendererProps } from './types'
import { StyledAgGridDefaultCellRendererRoot } from './AgGridDefaultCellRenderer/AgGridDefaultCellRenderer.styled'
import { FormControl } from '@mui/material'
import { CheckboxColored } from '@fintastic/shared/ui/components'
import { useFeatureConfig } from '@fintastic/web/feature/config'

export const CheckboxCellRenderer: React.FC<
  AgGridCellRendererProps<{
    fallbackForNonEditable?: string
  }>
> = ({ column, value, node, fallbackForNonEditable }) => {
  const isEditable = column?.isCellEditable(node) || false

  const customColorForEditableCells = useFeatureConfig(
    'custom_color_for_editable_cells',
  )

  if (!isEditable && fallbackForNonEditable) {
    return <>{fallbackForNonEditable}</>
  }

  if (value === null || value === undefined) {
    return (
      <StyledAgGridDefaultCellRendererRoot
        sx={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {'-'}
      </StyledAgGridDefaultCellRendererRoot>
    )
  }

  return (
    <StyledAgGridDefaultCellRendererRoot
      sx={{
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FormControl fullWidth>
        <CheckboxColored
          value={value}
          checked={value === 1}
          indeterminate={value === null || value === undefined}
          readOnly
          colorOverride={
            customColorForEditableCells.enabled
              ? customColorForEditableCells.value
              : undefined
          }
        />
      </FormControl>
    </StyledAgGridDefaultCellRendererRoot>
  )
}

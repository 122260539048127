import { QueryClient } from 'react-query'
import { versionsCacheKeys } from './cache-keys'
import { inActiveTab } from '@fintastic/shared/util/web-api'

export async function invalidateVersionAccessCache(
  queryClient: QueryClient,
  versionIds: string | string[],
) {
  const ids = Array.isArray(versionIds) ? versionIds : [versionIds]
  inActiveTab(() => {
    Promise.all(
      ids.map((id) =>
        queryClient.invalidateQueries(versionsCacheKeys.versionAccess(id)),
      ),
    )
  })
}

export const endpoints = {
  metrics: (versionId: string) =>
    `/planning/api/v2/versions/${versionId}/metrics/`,

  metric: (versionId: string, metricId: string) =>
    `${endpoints.metrics(versionId)}${metricId}/`,

  metricChart: (versionId: string, metricId: string) =>
    `${endpoints.metrics(versionId)}${metricId}/`,

  metricChartMultiversion: () => '/planning/api/v2/data/metrics/?format=chart',

  metricChartDims: (versionId: string, metricId: string) =>
    `${endpoints.metrics(versionId)}${metricId}/`,
} as const

import React, { useMemo } from 'react'
import {
  ChartSettings,
  ChartSettingsIcon,
  MetricChartDimensions,
} from '@fintastic/web/feature/charts'
import { ChartSettingsContextWrapper } from './ChartSettingsContextWrapper'
import { Maybe } from '@fintastic/shared/util/types'
import { ChartTab, ChartTabs } from '../../../settings-controls/side-tabs'
import { isEqual } from 'lodash'

export type MetricChartSettingsPanelProps = {
  loading: boolean
  widgetId: string
  metricId: string
  versions: string[]
  chartSettings: ChartSettings
  referenceSettings?: ChartSettings
  isDesignMode: boolean
  usedDimensions?: MetricChartDimensions
  hasDifferentDimensions?: boolean
  handleUpdateChartWidgetSettings?: (
    chartSettings: Maybe<ChartSettings>,
  ) => void
}

export const MetricChartSettingsPanel: React.FC<
  MetricChartSettingsPanelProps
> = ({
  widgetId,
  loading,
  metricId,
  versions,
  chartSettings,
  referenceSettings,
  usedDimensions,
  hasDifferentDimensions,
  isDesignMode,
  handleUpdateChartWidgetSettings,
}) => {
  const settingsChanged = useMemo(
    () => !isEqual(chartSettings, referenceSettings),
    [chartSettings, referenceSettings],
  )

  return (
    <ChartTabs>
      <ChartTab
        icon={<ChartSettingsIcon />}
        title={'Customize chart'}
        highlight={!isDesignMode && settingsChanged}
        disabled={isDesignMode}
        id={'settings'}
      >
        <ChartSettingsContextWrapper
          widgetId={widgetId}
          loading={loading}
          metricId={metricId}
          versions={versions}
          chartSettings={chartSettings}
          referenceSettings={referenceSettings}
          usedDimensions={usedDimensions}
          hasDifferentDimensions={hasDifferentDimensions}
          handleUpdateChartWidgetSettings={handleUpdateChartWidgetSettings}
        />
      </ChartTab>
    </ChartTabs>
  )
}

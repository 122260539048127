import type { AllEffect, Effect } from 'redux-saga/effects'
import { all, put, takeEvery } from 'redux-saga/effects'
import type { Saga } from 'redux-saga'
import { actions } from './slice'
import { authLoadUserInfoSaga } from '@fintastic/web/feature/auth'
import { configLoadSaga } from '@fintastic/web/feature/config'
import { syntaxInjectSaga } from '@fintastic/web/data-access/formulas'
import { Maybe } from '@fintastic/shared/util/types'
import { AxiosError } from 'axios'
import { LoginError } from '../../shared/types'

export function* appInitializationSaga() {
  yield all([syntaxInjectSaga(), authLoadUserInfoSaga(), configLoadSaga()])
}

function* onEmitStart() {
  yield put(actions.start())

  const result: { error: Maybe<LoginError> } = { error: null }
  try {
    yield appInitializationSaga()
  } catch (err) {
    console.error('app initialization failed:', err)

    let code: LoginError['code'] = 'other_error'

    if ((err as AxiosError).isAxiosError) {
      if ((err as AxiosError).response?.status === 404) {
        code = 'unknown_user'
      }
      // @todo: more codes
    }

    result.error = {
      error: (err as Error)?.message,
      code,
    }
  }

  yield put(actions.finish(result))
}

function* watchersSaga(): Iterator<AllEffect<Effect>> {
  yield all([takeEvery(actions.emitStart, onEmitStart)])
}

const sagas: Saga[] = [watchersSaga]

export default sagas

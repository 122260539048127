import React, { useCallback, useState } from 'react'
import {
  useAuthAccessToken,
  useAuthTenantName,
  useAuthUserInfo,
} from '@fintastic/web/feature/auth'
import {
  StyledUserPanelEmail,
  StyledUserPanelLogout,
  StyledUserPanelLogoutCont,
  StyledUserPanelPopoverContent,
  StyledUserPanelRoot,
  StyledUserPanelTenant,
} from './UserPanel.styled'
import { Avatar, ButtonBase, Popover } from '@mui/material'
import { Maybe } from '@fintastic/shared/util/types'
import { useAuth0 } from '@fintastic/shared/data-access/auth0-react'

export const UserPanel: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<Maybe<HTMLElement>>(null)

  const userInfo = useAuthUserInfo()
  const { user } = useAuthAccessToken()
  const tenantName = useAuthTenantName()
  const { logout } = useAuth0()

  const handleShowPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const handleHidePopover = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleLogout = useCallback(() => {
    logout({
      returnTo: window?.location.origin,
    })
  }, [logout])

  return (
    <StyledUserPanelRoot>
      <Avatar
        onClick={handleShowPopover}
        src={user?.picture}
        alt={user?.email}
        data-testid="user-avatar"
        {...{
          component: ButtonBase,
        }}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleHidePopover}
        sx={{
          marginLeft: 1,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <StyledUserPanelPopoverContent data-testid={'logout-popover'}>
          <StyledUserPanelEmail data-testid={'user-email'}>
            {userInfo?.email}
          </StyledUserPanelEmail>
          <StyledUserPanelTenant data-testid={'tenant-name'}>
            {tenantName}
          </StyledUserPanelTenant>
          <StyledUserPanelLogoutCont>
            <StyledUserPanelLogout
              onClick={handleLogout}
              variant="contained"
              data-testid="user-logout"
            >
              Log Out
            </StyledUserPanelLogout>
          </StyledUserPanelLogoutCont>
        </StyledUserPanelPopoverContent>
      </Popover>
    </StyledUserPanelRoot>
  )
}

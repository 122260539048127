import { QueryClient } from 'react-query'
import { versionsCacheKeys, versionsListRefreshKeys } from './cache-keys'
import { inActiveTab } from '@fintastic/shared/util/web-api'

export function invalidateVersionsListCache(queryClient: QueryClient) {
  inActiveTab(() => {
    const invalidators = versionsListRefreshKeys.map((key) =>
      // targeted
      queryClient.invalidateQueries(key),
    )
    invalidators.push(
      // full
      queryClient.invalidateQueries({
        queryKey: [versionsCacheKeys.versionsList()[0]],
        exact: false,
      }),
    )

    Promise.all(invalidators)
  })
}

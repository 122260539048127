const callbacksQueue: Array<() => void> = []

const executeCurrentQueue = () => {
  if (callbacksQueue.length === 0) {
    return
  }
  const toExecute = callbacksQueue.splice(0, callbacksQueue.length)
  for (let i = 0; i < toExecute.length; i++) {
    toExecute[i]()
  }
}

let initialised = false

const initialise = () => {
  initialised = true
  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
      executeCurrentQueue()
    }
  })
}

export const inActiveTab = (cb: () => void) => {
  if (!initialised) {
    initialise()
  }
  if (document.hidden) {
    callbacksQueue.push(cb)
    return
  }
  executeCurrentQueue()
  cb()
}

import React, { useCallback, useEffect, useMemo } from 'react'
import { BoardFolder } from '../../../types'
import {
  FormLayout,
  TextField,
  useTextFieldState,
} from '@fintastic/shared/ui/form-framework'
import { useBoardFolderNameValidation } from '../../../hooks/useBoardFolderNameValidation'
import { useBoardFolderUpdateMutation } from '../../../hooks/useBoardFolderUpdateMutation'
import { useBoardsFoldersListQuery } from '../../../hooks/useBoardsFoldersListQuery'
import { Maybe } from '@fintastic/shared/util/types'

export type FolderRenameFormProps = {
  folderId: BoardFolder['id']
  closeParentModal?: () => void
}

export const FolderRenameForm: React.FC<FolderRenameFormProps> = (props) => {
  const { closeParentModal, folderId } = props

  const folderNameFieldState = useTextFieldState()
  const folderNameValidation = useBoardFolderNameValidation({
    folderName: folderNameFieldState.trimmedValue,
    editingFolderId: folderId,
  })
  const isSubmitEnabled =
    folderNameFieldState.trimmedValue.length > 0 && folderNameValidation.isValid

  const foldersListQuery = useBoardsFoldersListQuery()
  const folderData: Maybe<BoardFolder | undefined> = useMemo(
    () =>
      foldersListQuery.data
        ? foldersListQuery.data.find((folder) => folder.id === folderId)
        : null,
    [folderId, foldersListQuery.data],
  )

  const isLoading = foldersListQuery.isLoading

  useEffect(() => {
    if (folderData === undefined) {
      if (closeParentModal) {
        closeParentModal()
      }
      return
    }

    if (folderData === null) {
      return
    }

    folderNameFieldState.setValue(folderData.name)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderData, folderNameFieldState.setValue, closeParentModal])

  const folderUpdateMutation = useBoardFolderUpdateMutation({ folderId })
  const isSending = folderUpdateMutation.isLoading
  const submitErrorMessage = useMemo(() => {
    if (!folderUpdateMutation.error) {
      return null
    }
    console.error(folderUpdateMutation.error)
    return "Can't save the folder"
  }, [folderUpdateMutation.error])

  const handleSubmit = useCallback(() => {
    folderUpdateMutation.mutate(
      {
        name: folderNameFieldState.trimmedValue,
      },
      {
        onSuccess: (data) => {
          if (closeParentModal) {
            closeParentModal()
          }
        },
      },
    )
  }, [
    folderUpdateMutation,
    folderNameFieldState.trimmedValue,
    closeParentModal,
  ])

  return (
    <FormLayout
      submitEnabled={!isSending && isSubmitEnabled}
      showLoader={isSending || isLoading}
      submitButtonText="Save Folder"
      submitErrorMessage={submitErrorMessage}
      onSubmit={handleSubmit}
      onCancel={closeParentModal}
    >
      <TextField
        label="Folder name"
        placeholder="Folder name"
        fullWidth={true}
        autoFocus={true}
        value={folderNameFieldState.value}
        onChange={folderNameFieldState.setValue}
        error={!folderNameValidation.isValid}
        errorMessage={folderNameValidation.error}
      />
    </FormLayout>
  )
}

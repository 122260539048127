import { SelectedCellsForAggregationProcessValueCallback } from '@fintastic/web/feature/selected-cell-aggregation'
import {
  GenericReportAggregatesRowMaskedData,
  ValueDefinition,
} from '@fintastic/web/util/generic-report'
import { SelectedCell } from '@fintastic/web/util/selected-cell-aggregation'
import { Maybe } from '@fintastic/shared/util/types'
import {
  BLANK_VALUE,
  type BlankOrMaskedValue,
} from '@fintastic/web/util/blanks-and-masked'

export const extractSelectedCellFromEvent: SelectedCellsForAggregationProcessValueCallback<
  GenericReportAggregatesRowMaskedData
> = (v, node, column, event): Maybe<SelectedCell> => {
  const field = column.getColDef()!.field

  if (field === undefined) {
    return {
      format: 'string',
      value: '',
    }
  }

  const value = node.aggData
    ? node.aggData[field]
    : (event.api.getValue(column, node) as
        | BlankOrMaskedValue
        | string
        | null
        | undefined)

  const valueDefinitionsMap: Record<string, ValueDefinition> | undefined =
    event.context.valueDefinitionMap
  const uom = valueDefinitionsMap?.[field]?.uom || null

  if (typeof value === 'number') {
    return {
      value: value === undefined || value === null ? BLANK_VALUE : value,
      format: uom === 'percentage' ? 'percent' : 'number',
      decimals: uom === 'percentage' ? 2 : 0,
    }
  }

  return {
    format: 'string',
    value: String(value),
  }
}

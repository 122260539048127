import { Maybe } from '@fintastic/shared/util/types'
import { metricsAndListsCacheKeys } from '../cache'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { MetricChartDimensions } from '@fintastic/web/feature/charts'
import { useCallback, useMemo } from 'react'
import { queryClient } from '@fintastic/web/data-access/service-query-client'
import { mapChartDimensionsToRequestDimensions } from '@fintastic/web/data-access/metrics-and-lists'

export function useInvalidateMetricChartData(
  versionIds: string[],
  metricId: string,
  periodSelection?: PeriodSelection,
  dimensions?: Maybe<MetricChartDimensions>,
) {
  const chartRequestDimensions =
    mapChartDimensionsToRequestDimensions(dimensions)

  const queryKey = useMemo(
    () =>
      metricsAndListsCacheKeys.metricChartMultiversion(
        versionIds,
        metricId,
        periodSelection,
        chartRequestDimensions,
      ),
    [chartRequestDimensions, metricId, periodSelection, versionIds],
  )

  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey,
    })
  }, [queryKey])

  return {
    invalidateQueries,
  }
}

import { Box, Button } from '@mui/material'

import React, { useCallback, useEffect, useState } from 'react'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { useAuth0 } from '@fintastic/shared/data-access/auth0-react'
import { useTenantOverride } from '@fintastic/web/data-access/service-auth0'
import { ErrorAlert } from '@fintastic/shared/ui/components'

const AUTO_LOGIN_URL_TOKEN = 'auto-login'
const ACCESS_DENIED_URL_TOKEN = 'access-denied'

const isAccessDenied = (error?: Error): boolean =>
  error?.message === 'Access denied'

const isPopupClosed = (error?: Error): boolean =>
  error?.message === 'Popup closed'

export type LoginProps = {
  isUnknownUser?: boolean
  tenantLogin: string | undefined
}

// there can be no auth error (login flow passed, user is valid) but isUnknownUser === true
// if the user is an unknown Google user or is not present in the tenant
export const Login: React.FC<LoginProps> = ({
  isUnknownUser = false,
  tenantLogin,
}) => {
  const { tenantName } = useTenantOverride()

  const loginText = isUnknownUser
    ? 'Retry with a different user name'
    : tenantName
    ? `Login to ${tenantName}`
    : 'Proceed to Login'

  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const organization = params.get('organization')
  const invitation = params.get('invitation')
  const organizationName = params.get('organization_name')

  const { loginWithPopup, error: authError, logout } = useAuth0()

  const [attemptedToLogin, setAttemptedToLogin] = useState(false)
  const [loggedOutDueToAccessDenied, setLoggedOutDueToAccessDenied] =
    useState(false)

  const handleLogin = useCallback(async () => {
    setAttemptedToLogin(true)

    if (isUnknownUser) {
      logout({
        returnTo: `${window?.location.origin}`,
      })
      return
    }

    if (isAccessDenied(authError) || isUnknownUser) {
      logout({
        returnTo: `${window?.location.origin}?${AUTO_LOGIN_URL_TOKEN}`,
      })
      return
    }

    if (invitation && organization && organizationName) {
      await loginWithPopup({ invitation, organization })
    } else {
      await loginWithPopup()
    }
  }, [
    authError,
    isUnknownUser,
    invitation,
    organization,
    organizationName,
    logout,
    loginWithPopup,
  ])

  useEffect(() => {
    if (
      organizationName &&
      tenantLogin &&
      window.location.host.split('.').includes(tenantLogin)
    ) {
      window.location.href = window.location.href.replace(
        tenantLogin,
        organizationName,
      )
    }
  }, [organizationName, tenantLogin])

  useEffect(() => {
    if (window.location.search.includes(AUTO_LOGIN_URL_TOKEN)) {
      void handleLogin()
      window?.history.replaceState(
        null,
        document.title,
        document.location.origin + document.location.pathname,
      )
    }
  }, [handleLogin])

  useEffect(() => {
    if (!attemptedToLogin && isAccessDenied(authError)) {
      logout({
        returnTo: `${window?.location.origin}?${ACCESS_DENIED_URL_TOKEN}`,
      })
    }
    if (authError) {
      console.log('error in login', authError)
    }
  }, [attemptedToLogin, authError, logout])

  useEffect(() => {
    if (window.location.search.includes(ACCESS_DENIED_URL_TOKEN)) {
      setLoggedOutDueToAccessDenied(true)
    }
  }, [setLoggedOutDueToAccessDenied])

  const showWelcomeMessage =
    (!authError || isPopupClosed(authError)) && !isUnknownUser
  const showUnknownUserMessage = !authError && isUnknownUser
  const showGenericAccessDeniedMessage = authError && !isPopupClosed(authError)

  return (
    <Box mt={3} width="100%">
      {showWelcomeMessage && (
        <ErrorAlert
          title="Welcome to fintastic"
          message="Please login into your account"
          severity="info"
          icon={<SecurityRoundedIcon fontSize="inherit" />}
          alignment="left"
          noPaddings={true}
          data-testid={'login-welcome'}
        />
      )}
      {showUnknownUserMessage && (
        <ErrorAlert
          title="Login failed. The username provided doesn’t exist."
          message="Please check your details or contact your administrator for assistance."
          severity="info"
          icon={<SecurityRoundedIcon fontSize="inherit" />}
          alignment="left"
          noPaddings={true}
          data-testid={'login-error-invalid-user'}
        />
      )}
      {showGenericAccessDeniedMessage && (
        <ErrorAlert
          title="Access denied"
          message={undefined}
          severity="warning"
          alignment="left"
          noPaddings={true}
          data-testid={'login-error-access-denied'}
        />
      )}
      {loggedOutDueToAccessDenied && !authError && (
        <ErrorAlert
          title="Access denied"
          message="Login with another account."
          severity="warning"
          alignment="left"
          noPaddings={true}
          data-testid={'login-error-login-with-other-account'}
        />
      )}
      <Box mt={3}>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          fullWidth
          onClick={handleLogin}
          data-testid={'login-button'}
        >
          {loginText}
        </Button>
      </Box>
    </Box>
  )
}

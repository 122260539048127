import {
  DataUpdate,
  FailedDataUpdateEventWrapper,
  logEvent,
  SuccessfulDataUpdateEventWrapper,
} from '@fintastic/web/util/metric-data-editing'
import { Maybe } from '@fintastic/shared/util/types'
import { QueryClient } from 'react-query'
import { invalidateReportsCache } from '@fintastic/web/util/generic-report'
import {
  getVersionDependenciesGraph,
  invalidateVersionUserLockerCache,
} from '@fintastic/web/data-access/versions'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { invalidateEverythingForVersion } from './invalidateEverythingForVersion'
import { getAffectedEntities } from './getAffectedEntities'
import { performAffectedEntitiesInvalidation } from './performAffectedEntitiesInvalidation'
import {
  invalidateListDeletedRows,
  invalidateListPermissions,
  invalidateV2MetricCache,
} from '@fintastic/web/data-access/metrics-and-lists'
import { invalidateEntitiesAffectedByList } from './invalidation-utils'
import {
  GENERIC_CALC_FAILED_MESSAGE,
  GENERIC_CALC_SUCCEED_MESSAGE,
} from './notification-text'
import {
  ParsedColumnId,
  idLooksLikeColumn,
} from '@fintastic/web/util/metrics-and-lists'
import { invalidateHistoryLog } from '@fintastic/web/data-access/history'
import { compact, uniq } from 'lodash'

type Context = {
  userEmail: Maybe<string>
}

type Handler<
  T extends SuccessfulDataUpdateEventWrapper | FailedDataUpdateEventWrapper =
    | SuccessfulDataUpdateEventWrapper
    | FailedDataUpdateEventWrapper,
> = (event: T, queryClient: QueryClient, context: Context) => Promise<void>

export const handleCellDataUpdateEvent: Handler = async (
  event,
  queryClient,
  context,
) => {
  logEvent(event)
  showToast(event, context.userEmail)

  if (event instanceof FailedDataUpdateEventWrapper) {
    return invalidateEverythingForVersion(event.versionId, queryClient)
  }

  /**
   * If list column is edited, we have to reload it before we do any other requests
   * Otherwise we could use stale data for subsequent requests
   */
  await refetchListCacheOnChangeListRows(event, queryClient, context)

  /**
   * We need to wait unit React hooks react to the new data loaded in refetchListCacheOnChangeListRows
   * Otherwise useQuery starts to load data faster than useMemo gives updated parameters
   */
  await new Promise((resolve) => {
    setTimeout(async () => {
      invalidateReportsCache(queryClient, [event.versionId])
      const parallelPromises: Promise<unknown>[] = [
        invalidateVersionUserLockerCache(queryClient, [event.versionId]),
        tryToReloadAffectedEntities(event, queryClient, context),
        invalidateListCacheOnAddRows(event, queryClient, context),
        invalidateListCacheOnRemoveRows(event, queryClient, context),
        invalidateHistoryLogCache(event, queryClient, context),
        tryToInvalidateListPermissions(event, queryClient, context),
      ]

      await Promise.all(parallelPromises)
      resolve(true)
    }, 100)
  })
}

const tryToReloadAffectedEntities: Handler<
  SuccessfulDataUpdateEventWrapper
> = async (event, queryClient, context) => {
  if (
    event.updateData.action !== 'edit_list_column_data' &&
    event.updateData.action !== 'edit_metric_data'
  ) {
    return
  }

  const dependenciesGraph = await getVersionDependenciesGraph(
    queryClient,
    event.versionId,
  )
  if (!dependenciesGraph) {
    await invalidateEverythingForVersion(event.versionId, queryClient)
    return
  }

  const entitiesToReload = getAffectedEntities(
    event.updateData.user_modified_entities,
    dependenciesGraph,
    !event.successful,
  )

  await performAffectedEntitiesInvalidation(
    queryClient,
    event.versionId,
    entitiesToReload,
  )
}

const refetchListCacheOnChangeListRows: Handler<
  SuccessfulDataUpdateEventWrapper
> = async (event, queryClient, context) => {
  if (event.updateData.action !== 'edit_list_column_data') {
    return
  }

  await Promise.all(
    event.updateData.user_modified_entities.map(async (entityId) => {
      if (!idLooksLikeColumn(entityId)) {
        return
      }

      const parsedColumnId = ParsedColumnId.fromString(entityId)

      if (!parsedColumnId) {
        return
      }
      await invalidateV2MetricCache.refetchListColumn(
        queryClient,
        event.versionId,
        parsedColumnId?.listId,
        entityId,
      )
    }),
  )
}

const invalidateListCacheOnAddRows: Handler<
  SuccessfulDataUpdateEventWrapper
> = async (event, queryClient, context) => {
  if (
    event.updateData.action !== 'add_list_rows' &&
    event.updateData.action !== 'add_complete_list_rows'
  ) {
    return
  }

  const promises: Promise<void>[] = []

  invalidateV2MetricCache.invalidateList(
    queryClient,
    event.versionId,
    event.updateData.list_id,
  )

  promises.push(
    invalidateEntitiesAffectedByList(
      queryClient,
      event.versionId,
      event.updateData.list_id,
      [],
    ),
  )

  await Promise.all(promises)
}

const invalidateListCacheOnRemoveRows: Handler<
  SuccessfulDataUpdateEventWrapper
> = async (event, queryClient, context) => {
  if (event.updateData.action !== 'delete_list_rows') {
    return
  }

  const promises: Promise<void>[] = []

  invalidateV2MetricCache.invalidateList(
    queryClient,
    event.versionId,
    event.updateData.list_id,
  )

  promises.push(
    invalidateEntitiesAffectedByList(
      queryClient,
      event.versionId,
      event.updateData.list_id,
      [],
    ),
  )

  invalidateListDeletedRows(
    queryClient,
    event.versionId,
    event.updateData.list_id,
  )

  await Promise.all(promises)
}

const invalidateHistoryLogCache: Handler<
  SuccessfulDataUpdateEventWrapper
> = async (event, queryClient) => {
  const dataUpdate = event.updateData

  // because BE writes data to the log not immediately
  await new Promise((resolve) => setTimeout(resolve, 1000 * 3))

  const promises: Promise<void>[] = []

  if (
    dataUpdate.action === 'edit_metric_data' ||
    dataUpdate.action === 'edit_list_column_data'
  ) {
    invalidateHistoryLog(queryClient, {
      level: 'version',
      versionId: [event.versionId],
    })

    invalidateHistoryLog(queryClient, {
      level: 'entity',
      versionId: [event.versionId],
      entityId: dataUpdate.user_modified_entities.reduce<string[]>(
        (ids, id) => {
          const columnId = ParsedColumnId.fromString(id)
          ids.push(columnId?.listId || id)
          return ids
        },
        [],
      ),
    })
  }

  if (
    dataUpdate.action === 'add_list_rows' ||
    dataUpdate.action === 'add_complete_list_rows' ||
    dataUpdate.action === 'duplicate_list_rows' ||
    dataUpdate.action === 'delete_list_rows'
  ) {
    invalidateHistoryLog(queryClient, {
      level: 'version',
      versionId: [event.versionId],
    })

    invalidateHistoryLog(queryClient, {
      level: 'entity',
      versionId: [event.versionId],
      entityId: [dataUpdate.list_id],
    })
  }

  await Promise.all(promises)
}

const tryToInvalidateListPermissions: Handler<
  SuccessfulDataUpdateEventWrapper
> = async (event, queryClient, context) => {
  const expectedActions: DataUpdate['action'][] = [
    'delete_list_rows',
    'add_list_rows',
    'add_complete_list_rows',
    'edit_list_column_data',
  ]

  if (!expectedActions.includes(event.updateData.action)) {
    return
  }

  const promises: Promise<void>[] = []

  if (event.userEmail !== context.userEmail) {
    const update = event.updateData
    if (
      update.action === 'delete_list_rows' ||
      update.action === 'add_list_rows' ||
      update.action === 'add_complete_list_rows'
    ) {
      invalidateListPermissions(queryClient, event.versionId, update.list_id)
    }
    if (update.action === 'edit_list_column_data') {
      const parsedListIds = uniq(
        compact(
          update.user_modified_entities.map(ParsedColumnId.fromString),
        ).map((pid) => pid.listId),
      )
      parsedListIds.forEach((listId) => {
        invalidateListPermissions(queryClient, event.versionId, listId)
      })
    }
  }

  await Promise.all(promises)
}

const showToast = (
  event: SuccessfulDataUpdateEventWrapper | FailedDataUpdateEventWrapper,
  userEmail: Maybe<string>,
) => {
  if (!userEmail || event.userEmail !== userEmail) {
    return
  }

  if (!event.successful) {
    toast.error(GENERIC_CALC_FAILED_MESSAGE)
    console.error(`Data edit failed. Error: ${event.errorText}`, event)
    return
  }

  if (event.updateData.action === 'duplicate_list_rows') {
    // toast logic is handeled separately in the list
    return
  }

  toast.success(GENERIC_CALC_SUCCEED_MESSAGE)
}

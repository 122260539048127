import {
  BaseGridAgChartDataType,
  BaseGridColumnId,
  baseGridColumnsMatches,
  BaseGridContextProp,
  baseGridDefaultColumnMatch,
  BuildBaseGridColumnDefinitionParams,
} from '@fintastic/shared/ui/grid-framework'
import type {
  LegacyListGridColumnData,
  LegacyListGridColumnOptionsSourceDimension,
  LegacyListGridColumnOptionsSourceList,
  LegacyListGridDimensionValueMap,
  LegacyListGridValueMap,
} from '@fintastic/web/feature/legacy-list-grid'
import type {
  ColDef,
  ICellRendererParams,
  ISetFilterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  AgGridColDefBuilder,
  extendEditableColDefProp,
} from '@fintastic/shared/util/ag-grid'
import {
  AgGridCustomCellEditorName,
  AgGridSelectboxCellEditorProps,
  ExternalLinkCellRenderer,
  isMasked,
} from '@fintastic/shared/ui/ag-grid'
import type {
  DimensionsConfig,
  DimensionValue,
  DimensionValueId,
} from '@fintastic/shared/util/types'
import { sortDimensionOptions } from './sort-dimension-options'

export type BuildListGridColumnDefinitionParams = Omit<
  BuildBaseGridColumnDefinitionParams,
  'dataType'
> & {
  dataType?: LegacyListGridColumnData
  options?:
    | LegacyListGridColumnOptionsSourceList
    | LegacyListGridColumnOptionsSourceDimension
    | Record<string, string>
  optionAllowedToBeChosen?: (option: string) => boolean
  dimensionsConfig?: DimensionsConfig
  allowedDimensions?: LegacyListGridDimensionValueMap
}

const legacyListColumnsMatches = (context: BaseGridContextProp = {}) => [
  {
    matcher: () => true,
    builder: (colDef: ColDef, params: BuildListGridColumnDefinitionParams) => {
      const { field } = params
      return {
        ...colDef,
        editable: extendEditableColDefProp<LegacyListGridValueMap>(
          colDef.editable,
          (editableCbParams) =>
            !isMasked(field, editableCbParams.data as LegacyListGridValueMap),
        ),
        valueSetter: ({ newValue, data, colDef }: ValueSetterParams) => {
          const field = colDef.field
          if (!field) {
            return false
          }
          // eslint-disable-next-line no-param-reassign
          data[field] = newValue
          return true
        },
      }
    },
  },
  {
    matcher: (params: BuildListGridColumnDefinitionParams) =>
      params.dataType === 'dimension',
    builder: (colDef: ColDef, params: BuildListGridColumnDefinitionParams) => {
      let dimensionValues: Record<DimensionValueId, DimensionValue>
      if (typeof params.options === 'string' && params.dimensionsConfig) {
        // backwards compatibility for older non id approach
        if (params.dimensionsConfig[params.options]?.values) {
          dimensionValues =
            Object.fromEntries(
              params.dimensionsConfig[params.options]?.values?.map((v) => [
                v,
                v,
              ]),
            ) || {}
        } else {
          dimensionValues = {}
        }
      } else if (typeof params.options === 'object') {
        dimensionValues = params.options as Record<string, string>
      } else {
        dimensionValues = {}
      }

      const dimensionColDef: ColDef = {
        ...colDef,
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(dimensionValues),
        } as ISetFilterParams,
        valueGetter: ({ data }) => {
          if (!colDef.field || !data) {
            return null
          }
          return data[colDef.field]
        },
        valueFormatter: ({ value }) =>
          dimensionValues[value] !== undefined
            ? dimensionValues[value] || value
            : value,
        valueParser: ({ newValue: dimensionValueLabelOrId }) => {
          const dimValuePair = Object.entries(dimensionValues).find(
            ([dimValueId, dimValueLabel]) =>
              dimValueLabel === dimensionValueLabelOrId ||
              dimValueId === dimensionValueLabelOrId,
          )
          return dimValuePair?.[0] || dimensionValueLabelOrId
        },
        filterValueGetter: ({ data }) => {
          if (!colDef.field || !data) {
            return null
          }
          const value = data[colDef.field]
          return dimensionValues[value] !== undefined
            ? dimensionValues[value] || value
            : value
        },
        chartDataType: 'category' as BaseGridAgChartDataType,
      }

      let dimensionOptions = sortDimensionOptions(
        Object.entries(dimensionValues).map(([k, v]) => ({
          label: v,
          value: k,
        })),
      )

      if (params.optionAllowedToBeChosen) {
        dimensionOptions = dimensionOptions.filter(({ value }) =>
          params.optionAllowedToBeChosen!(value),
        )
      }

      dimensionColDef.cellEditor = 'selectBoxCellEditor'
      dimensionColDef.cellEditorParams = {
        options: dimensionOptions,
      }

      return dimensionColDef
    },
  },
  {
    matcher: ({ dataType }: BuildListGridColumnDefinitionParams) =>
      ['salesforce_account', 'netsuite_account'].includes(dataType || ''),
    builder: (
      colDef: ColDef,
      { dataType }: BuildListGridColumnDefinitionParams,
    ) => {
      const linkResolver = context?.linkResolver
      if (!linkResolver) {
        console.error('received id column but not link resolver specified')
        return colDef
      }
      return {
        ...colDef,
        cellRenderer: ExternalLinkCellRenderer,
        cellRendererParams: {
          linkResolver: (value: any, params: ICellRendererParams) =>
            linkResolver(dataType as BaseGridColumnId, value, params),
        },
        chartDataType: 'category' as BaseGridAgChartDataType,
      }
    },
  },
  {
    matcher: (params: BuildListGridColumnDefinitionParams) =>
      params.dataType === 'values',
    builder: (colDef: ColDef, params: BuildListGridColumnDefinitionParams) => {
      const options = params.options instanceof Array ? params.options : []

      const cellEditor: AgGridCustomCellEditorName = 'selectBoxCellEditor'
      const cellEditorProps: AgGridSelectboxCellEditorProps = {
        options: [
          ...options
            .sort((a, b) => a.localeCompare(b))
            .map((option) => ({
              label: option,
              value: option,
            })),
        ],
      }

      return {
        ...colDef,
        filter: 'agSetColumnFilter',
        cellEditor,
        cellEditorParams: cellEditorProps,
        chartDataType: 'category' as BaseGridAgChartDataType,
      }
    },
  },
  {
    matcher: () => true,
    builder: (colDef: ColDef, params: BuildListGridColumnDefinitionParams) => {
      if (params.getOverridingCellEditor !== undefined) {
        return {
          ...colDef,
          cellEditor: params.getOverridingCellEditor(colDef.cellEditor),
        }
      }
      return colDef
    },
  },
  {
    matcher: () => true,
    builder: (colDef: ColDef, params: BuildListGridColumnDefinitionParams) => {
      if (params.overridingAggFunction) {
        return {
          ...colDef,
          aggFunc: params.overridingAggFunction,
        }
      }
      return colDef
    },
  },
  {
    matcher: () => true,
    builder: (colDef: ColDef, params: BuildListGridColumnDefinitionParams) => {
      const selector = params.customCellRendererSelector
      if (selector) {
        colDef.cellRendererSelector = (finalCellRendererParams) =>
          selector(
            colDef.cellRenderer || null,
            colDef.cellRendererParams || null,
            finalCellRendererParams,
          )
      }
      return colDef
    },
  },
]

export const buildListGridColumnDefinition =
  // @todo add generic types everywhere


    <TData = any>(context: BaseGridContextProp = {}) =>
    (params: BuildListGridColumnDefinitionParams): ColDef<TData> =>
      new AgGridColDefBuilder<
        BuildBaseGridColumnDefinitionParams &
          BuildListGridColumnDefinitionParams
      >([
        baseGridDefaultColumnMatch(context),
        ...baseGridColumnsMatches(context),
        ...legacyListColumnsMatches(context),
      ]).build(
        params as BuildBaseGridColumnDefinitionParams &
          BuildListGridColumnDefinitionParams,
      )

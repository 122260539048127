import { QueryClient } from 'react-query'
import { metricsAndListsCacheKeys } from './cache-keys'
import { inActiveTab } from '@fintastic/shared/util/web-api'

export async function invalidateMetricsListCache(
  queryClient: QueryClient,
  versionId: string,
) {
  inActiveTab(() => {
    Promise.all([
      queryClient.invalidateQueries(
        metricsAndListsCacheKeys.metrics(versionId, true),
      ),
      queryClient.invalidateQueries(
        metricsAndListsCacheKeys.metrics(versionId, false),
      ),
    ])
  })
}

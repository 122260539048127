import { useHiddenColumnsLocalStorage } from '../../../features/columns-visibility'
import { useCallback, useEffect, useMemo } from 'react'
import {
  useDefaultHiddenColumnsIds,
  ColumnsPanelContextValue,
} from '../../../features/columns-visibility'
import { Maybe } from '@fintastic/shared/util/types'
import {
  getColumnType,
  ListWithOptionalData,
} from '@fintastic/web/util/metrics-and-lists'

export const useLocalColumnsVisibility = (
  versionId: string,
  listId: string,
  list: Maybe<ListWithOptionalData>,
  onColumnsVisibilityChanged?: () => void,
) => {
  const [hiddenColumnIds, setHiddenColumnsIds] = useHiddenColumnsLocalStorage(
    versionId,
    listId,
  )

  const changeHiddenColumns = useCallback(
    (columnIds: string[]) => {
      setHiddenColumnsIds(columnIds)
      onColumnsVisibilityChanged?.()
    },
    [onColumnsVisibilityChanged, setHiddenColumnsIds],
  )

  const defaultHiddenColumns = useDefaultHiddenColumnsIds(list)
  const allColumnIds = useMemo(
    () => list?.metrics.map((m) => m.id) || [],
    [list?.metrics],
  )

  const visibleColumnIds = useMemo(() => {
    const hiddenColumns = hiddenColumnIds || []
    return allColumnIds.filter((id) => !hiddenColumns.includes(id))
  }, [allColumnIds, hiddenColumnIds])

  useEffect(() => {
    if (hiddenColumnIds === null && defaultHiddenColumns?.length) {
      setHiddenColumnsIds(defaultHiddenColumns)
    }
  }, [defaultHiddenColumns, hiddenColumnIds, setHiddenColumnsIds])

  const columnsPanelContextValue = useMemo<ColumnsPanelContextValue>(
    () => ({
      hiddenColumns: hiddenColumnIds || [],
      defaultHiddenColumns: defaultHiddenColumns || [],
      onChangeVisibility: changeHiddenColumns,
      columns: (list?.metrics || []).map((m) => ({
        label: m.label,
        id: m.id,
        type: getColumnType(m),
      })),
    }),
    [hiddenColumnIds, defaultHiddenColumns, changeHiddenColumns, list?.metrics],
  )

  return useMemo(
    () =>
      ({
        hiddenColumnIds,
        visibleColumnIds,
        changeHiddenColumns,
        columnsPanelContextValue,
      } as const),
    [
      changeHiddenColumns,
      columnsPanelContextValue,
      hiddenColumnIds,
      visibleColumnIds,
    ],
  )
}

import React, { useCallback } from 'react'
import {
  useCurrentDiscussionObjectId,
  useLayoutStateIsDiscussionsOpened,
} from '../../../ducks/layout/hooks'
import { CenteredCircularProgress } from '@fintastic/shared/ui/components'

const CommentsSidebarContainer = React.lazy(
  () => import('@fintastic/web/feature/comments'),
)

export const Discussions: React.FC = () => {
  const [, setIsDiscussionOpen] = useLayoutStateIsDiscussionsOpened()
  const [currentDiscussionObjectId, setCurrentDiscussionObjectId] =
    useCurrentDiscussionObjectId()

  const handleCloseSidebar = useCallback(() => {
    setIsDiscussionOpen(false)
    setCurrentDiscussionObjectId(null)
  }, [setCurrentDiscussionObjectId, setIsDiscussionOpen])

  return (
    <React.Suspense fallback={<CenteredCircularProgress />}>
      <CommentsSidebarContainer
        currentDiscussionObjectId={currentDiscussionObjectId}
        setCurrentDiscussionObjectId={setCurrentDiscussionObjectId}
        onClose={handleCloseSidebar}
      />
    </React.Suspense>
  )
}

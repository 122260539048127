import React from 'react'
import { MetricChartPanels } from './MetricChartPanels/MetricChartPanels'
import { MetricChartSettingsPanel } from '../settings-panel/MetricChartSettingsPanel'
import {
  ChartDebugDisplayMode,
  ChartRequestParams,
  ChartSettings,
  MultiversionChartData,
} from '@fintastic/web/feature/charts'
import { StyledChartAndSettingsContainer } from './MetricChartContent.styled'
import { Maybe } from '@fintastic/shared/util/types'

export type MetricChartContentProps = {
  chartData: MultiversionChartData
  displayMode: ChartDebugDisplayMode
  loading: boolean
  metricId: string
  request: ChartRequestParams
  versions: string[]

  widgetId: string

  chartSettings: ChartSettings
  referenceSettings?: ChartSettings
  isDesignMode: boolean

  handleUpdateChartWidgetSettings?: (
    chartSettings: Maybe<ChartSettings>,
  ) => void
  showZoomPanPanel?: boolean
}

export const MetricChartContent: React.FC<MetricChartContentProps> = ({
  chartData,
  displayMode,
  loading,
  metricId,
  request,
  versions,
  chartSettings,
  referenceSettings,
  widgetId,
  isDesignMode,
  handleUpdateChartWidgetSettings,
  showZoomPanPanel = false,
}) => (
  <StyledChartAndSettingsContainer>
    <MetricChartPanels
      chartData={chartData}
      displayMode={displayMode}
      chartSettings={chartSettings}
      loading={loading}
      request={request}
      isDesignMode={isDesignMode}
      showZoomPanPanel={showZoomPanPanel}
    />

    <MetricChartSettingsPanel
      loading={loading}
      usedDimensions={chartData?.usedDimensions}
      hasDifferentDimensions={chartData?.hasDifferentDimensions}
      widgetId={widgetId}
      metricId={metricId}
      versions={versions}
      chartSettings={chartSettings}
      referenceSettings={referenceSettings}
      isDesignMode={isDesignMode}
      handleUpdateChartWidgetSettings={handleUpdateChartWidgetSettings}
    />
  </StyledChartAndSettingsContainer>
)

import {
  ChartApiErrorCode,
  ChartDataError,
  MetricChartDimensions,
  MetricChartDimensionsEssential,
} from '@fintastic/web/feature/charts'
import { uniq } from 'lodash'
import { RawChartDataMultiversion } from '@fintastic/web/data-access/metrics-and-lists'

export const extractDimensionEssential = (
  dimensions?: MetricChartDimensions,
): MetricChartDimensionsEssential[] =>
  dimensions
    ? dimensions.map((d) => ({
        id: d.id,
        aggregate: !!d.aggregate,
        time_dimension: d.type === 'Time', // because of already created charts
      }))
    : []

function hasErrorCode(code: ChartApiErrorCode, data: RawChartDataMultiversion) {
  return (data.warnings || []).some((w) => w.error_code === code)
}

export const getMostSevereMVFlowError = (
  data: RawChartDataMultiversion,
  versions: string[],
): {
  dataError?: ChartDataError
  allVersionsFailed: boolean
} => {
  // https://github.com/fintastic-ai/fintastic/blob/af/FIN-8553-multi-version-metrics/backend/planning_data_api/planning_data_api/error_codes.py#L6-L49
  const hasNoErrors =
    (data.warnings || []).filter(
      (v) => Boolean(v.version_id) && Boolean(v.error_code),
    ).length === 0

  // ordered by priority
  let errorCode: ChartDataError | undefined = undefined
  errorCode ??= hasErrorCode('TIME_DIM_LESS_THAN_ENTITY_TIME_RESOLUTION', data)
    ? 'INVALID_RESOLUTION'
    : undefined
  errorCode ??= hasErrorCode('VERSION_NOT_FOUND', data)
    ? 'VERSION_NOT_FOUND'
    : undefined
  errorCode ??= hasErrorCode('METRIC_NOT_FOUND', data)
    ? 'METRIC_NOT_FOUND'
    : undefined
  errorCode ??= hasErrorCode('VERSION_FORBIDDEN', data)
    ? 'VERSION_FORBIDDEN'
    : undefined
  errorCode ??= hasErrorCode('METRIC_FORBIDDEN', data)
    ? 'METRIC_FORBIDDEN'
    : undefined
  errorCode ??= hasErrorCode('DIMENSION_NOT_IN_CALENDAR', data)
    ? 'DIMENSION_NOT_IN_CALENDAR'
    : undefined
  errorCode ??= hasErrorCode('METRIC_ORIGINALLY_EMPTY', data)
    ? 'ORIGINALLY_EMPTY'
    : undefined
  errorCode ??= !hasNoErrors ? 'UNKNOWN_ERROR' : undefined

  // all the versions in the list were failed
  const allVersionsFailed =
    !hasNoErrors &&
    Boolean(
      uniq(
        (data.warnings || [])
          .filter((v) => Boolean(v.version_id) && Boolean(v.error_code))
          .map((w) => w.version_id),
      ).length === versions.length,
    )

  return {
    dataError: errorCode,
    allVersionsFailed,
  }
}

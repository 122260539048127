import { QueryClient } from 'react-query'
import { queryKeys } from './query-keys'
import { inActiveTab } from '@fintastic/shared/util/web-api'

export const invalidateReportsListCache = (
  qc: QueryClient,
  versionId: string,
) => {
  inActiveTab(() => {
    qc.invalidateQueries({
      queryKey: queryKeys.reportsList(versionId),
      exact: true,
    })
  })
}

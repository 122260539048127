import { Box, styled } from '@mui/material'
import { layoutConfig } from '../../layout-config'
import { styledMixins } from '@fintastic/shared/ui/mui-style-mixins'

export const StyledAppLayoutRoot = styled(Box)`
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100vh;
`

export const StyledAppLayoutTopbarPlaceholder = styled(Box)`
  width: 100%;
  height: ${layoutConfig.topbar.height}px;
  min-height: ${layoutConfig.topbar.height}px;
`

export const StyledAppLayoutMainContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
`

export const StyledAppLayoutLogoContainer = styled(Box)(({ theme }) => ({
  'position': 'absolute',
  'top': 0,
  'left': 0,

  ...styledMixins.alignContentCenter(),

  'width': theme.spacingFromPixels(layoutConfig.sidebar.bar.width),
  'height': theme.spacingFromPixels(layoutConfig.topbar.height),

  'background': theme.palette.common.white,
  'border': '1px solid transparent',
  'borderRightColor': theme.palette.divider,

  'fontSize': 0,

  '.fintastic-logo': {
    width: 32,
    height: 'auto',
  },
}))

export const StyledAppLayoutSidebarContainer = styled(Box)`
  position: relative;
  z-index: 10;
  height: 100%;
`

export const StyledAppLayoutPageContainer = styled(Box)`
  width: 100%;
  height: calc(100vh - ${layoutConfig.topbar.height}px);
  transition: padding ${({ theme }) => theme.transitions.duration.standard}ms;
`

export const StyledAppLayoutPageContent = styled(Box)`
  width: 100%;
  height: 100%;
`

export const StyledAppLayoutLateralContainer = styled(Box)`
  height: 100%;
`
export const StyledAppLayoutDrawerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: ${layoutConfig.sidebar.bar.width}px;
  right: 0;
  z-index: 1;
`

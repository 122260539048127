import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Maybe } from '@fintastic/shared/util/types'

export const useItemHoverDebouncer = <TItem>(
  currentItem: Maybe<TItem>,
  options: {
    itemsAreEqual: (a: TItem, b: TItem) => boolean
    startThresholdMs: number
    endThresholdMs: number
    onHoverStarted: (item: TItem) => void
    onHoverEnded: () => void
  },
) => {
  const optionsRef = useRef(options)
  optionsRef.current = options

  const currentItemRef = useRef(currentItem)
  currentItemRef.current = currentItem

  const scheduledItemRef = useRef<
    Maybe<
      | {
          type: 'start'
          item: TItem
          timeoutId: number
        }
      | {
          type: 'end'
          timeoutId: number
        }
    >
  >(null)

  const clear = useCallback(() => {
    if (!scheduledItemRef.current) {
      return
    }
    clearInterval(scheduledItemRef.current.timeoutId)
    scheduledItemRef.current = null
  }, [])

  const hoverStartCallback = useCallback(
    (item: TItem) => {
      clear()
      optionsRef.current.onHoverStarted(item)
    },
    [clear],
  )

  const requestHoverStart = useCallback(
    (item: TItem) => {
      if (
        currentItemRef.current &&
        optionsRef.current.itemsAreEqual(item, currentItemRef.current)
      ) {
        clear()
        return
      }

      if (
        scheduledItemRef.current?.type === 'start' &&
        optionsRef.current.itemsAreEqual(scheduledItemRef.current.item, item)
      ) {
        return
      }

      clear()

      scheduledItemRef.current = {
        type: 'start',
        timeoutId: setTimeout(() => {
          hoverStartCallback(item)
        }, optionsRef.current.startThresholdMs) as unknown as number,
        item,
      }
    },
    [clear, hoverStartCallback],
  )

  const hoverEndCallback = useCallback(() => {
    clear()
    optionsRef.current.onHoverEnded()
  }, [clear])

  const requestHoverEnd = useCallback(() => {
    if (scheduledItemRef.current?.type === 'end') {
      return
    }
    clear()
    scheduledItemRef.current = {
      type: 'end',
      timeoutId: setTimeout(
        hoverEndCallback,
        optionsRef.current.endThresholdMs,
      ) as unknown as number,
    }
  }, [clear, hoverEndCallback])

  useEffect(() => clear, [clear])

  return useMemo(
    () => ({
      requestHoverStart,
      requestHoverEnd,
      clear,
    }),
    [clear, requestHoverEnd, requestHoverStart],
  )
}

import { Maybe } from '@fintastic/shared/util/types'
import { useLoadListInDifferentVersions } from '@fintastic/web/data-access/metrics-and-lists'
import {
  useLoadVersion,
  useLoadVersionEntities,
} from '@fintastic/web/data-access/versions'
import { useMemo } from 'react'

export const useListMetadataViewCache = (
  params: Maybe<{ versionId: string; listId: string }>,
) => {
  const listQuery = useLoadListInDifferentVersions(
    [params?.versionId || ''],
    params?.listId || '',
    undefined,
    params !== null,
    true,
  )[0]
  const entitiesQuery = useLoadVersionEntities(params?.versionId)
  const versionQuery = useLoadVersion(params?.versionId)
  const queries = [listQuery, entitiesQuery, versionQuery]
  const isLoading = queries.some((q) => q.isLoading)
  const isFetching = queries.some((q) => q.isFetching)
  const isError = queries.some((q) => q.isError)
  const isSuccess = queries.some((q) => q.isSuccess)

  return useMemo(
    () =>
      ({
        isLoading: isLoading || isFetching,
        isError,
        isSuccess,
        isLiveActuals: !!versionQuery.data?.is_live_actuals,
        list: listQuery.data?.list || null,
        allDimensionsInVersion: entitiesQuery.data?.dimensions || null,
      } as const),
    [
      entitiesQuery.data?.dimensions,
      isError,
      isFetching,
      isLoading,
      isSuccess,
      listQuery.data?.list,
      versionQuery.data?.is_live_actuals,
    ],
  )
}

import {
  ChartDisplaySettings,
  defaultChartXAxisAlignment,
  defaultChartXAxisFormat,
} from '@fintastic/web/feature/charts'

const compactXAxisFormatter = (text: string) =>
  (text || '').replaceAll(' ', '\n')

const standardXAxisFormatter = (text: string) => text

// @todo: find a way how to get correct option types from ECharts
export const processXAxis = (
  option: any,
  settings: ChartDisplaySettings,
  headers: string[],
) => {
  if (!option || !option.xAxis?.axisLabel) {
    return
  }

  const isSloping =
    settings?.xAxisAlignment === defaultChartXAxisAlignment ||
    !settings?.xAxisAlignment

  const xLabelsRotation = isSloping
    ? 30
    : settings?.xAxisAlignment === 'vertical'
    ? 90
    : 0

  const actualHeaders = headers || []

  const maxLineLength = actualHeaders.reduce((prev, cur) => {
    let curLength = (cur || '').length
    if (settings?.xAxisFormat === 'compact') {
      curLength = (cur || '')
        .split(' ')
        .reduce((p, c) => Math.max(p, (c || '').length), 1)
    }
    return Math.max(curLength, prev)
  }, 1)

  const labelsHeight =
    settings?.xAxisAlignment === 'horizontal'
      ? 20
      : isSloping
      ? maxLineLength * 8 * Math.sin(Math.PI / 6) // we can't calculate real height of letter so use average assuming only first letter is a capital one
      : maxLineLength * 9

  const xAxisFormatter =
    settings?.xAxisFormat === defaultChartXAxisFormat || !settings?.xAxisFormat
      ? standardXAxisFormatter
      : compactXAxisFormatter

  if (!option.xAxis) {
    option.xAxis = {}
  }

  if (!option.xAxis.axisLabel) {
    option.xAxis.axisLabel = {}
  }

  option.xAxis.axisLabel = {
    ...option.xAxis.axisLabel,
    rotate: xLabelsRotation,
    formatter: xAxisFormatter,
    margin: isSloping ? 16 : 12,
  }

  if (settings.xAxisAlignment === 'sloping') {
    option.grid = {
      ...option.grid,
      bottom: (option.grid.bottom || 0) + labelsHeight + 20,
    }
  } else {
    option.grid = {
      ...option.grid,
      bottom: (option.grid.bottom || 0) + labelsHeight,
    }
  }
}

import { Checkbox, CheckboxProps, styled } from '@mui/material'
import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'

export const CheckboxColored: React.FC<CheckboxColoredProps> = (props) => <CheckboxColoredMui {...props} />

export type CheckboxColoredProps = CheckboxProps & {
  colorOverride?: Maybe<string>
}

const CheckboxColoredMui = styled(Checkbox)<CheckboxColoredProps>`
  ${({ colorOverride }) => colorOverride ? `color: ${colorOverride}!important;` : ''}
`

import axiosLib from 'axios'
import { EnvironmentVariables } from '@fintastic/web/data-access/environment'
import { axiosAuthRequestInterceptor } from '@fintastic/web/data-access/auth-axios-interceptor'

const DEV_SESSION_STORAGE_KEY = '_fin_dev_axios_config'

type DevOverrideConfig = {
  timeout?: number
}

export const axios = axiosLib.create({
  baseURL: '<to-be-loaded-from-env>',
  timeout: 40_000,
})

axios.interceptors.request.use(
  axiosAuthRequestInterceptor.onFulfilled,
  axiosAuthRequestInterceptor.onRejected,
)

export const initAxios = (env: Readonly<EnvironmentVariables>) => {
  axios.defaults.baseURL = env.REACT_APP_API_URL
  tryToReadAndApplyDevOverrideConfig()
}

const setAxiosDevOverrideConfig = (config: DevOverrideConfig) => {
  sessionStorage.setItem(DEV_SESSION_STORAGE_KEY, JSON.stringify(config))
  tryToReadAndApplyDevOverrideConfig()
}

;(window as any).fintasticSetAxiosDevOverrideConfig = setAxiosDevOverrideConfig

const tryToReadAndApplyDevOverrideConfig = () => {
  try {
    const devOverrideConfigJson = sessionStorage.getItem(
      DEV_SESSION_STORAGE_KEY,
    )
    if (!devOverrideConfigJson) {
      return
    }
    const devOverrideConfig = JSON.parse(
      devOverrideConfigJson,
    ) as DevOverrideConfig
    if (devOverrideConfig.timeout === undefined) {
      return
    }
    axios.defaults.timeout = devOverrideConfig.timeout
  } catch (e) {
    // pass
  }
}

import { AddLinesDataPayload } from './types'
import { axios } from '@fintastic/web/data-access/service-axios'
import { endpoints } from './endpoints'
import { AxiosResponse } from 'axios'

export const saveNewRows = async (
  versionId: string,
  listId: string,
  data: AddLinesDataPayload,
) =>
  axios.post<
    AddLinesDataPayload,
    AxiosResponse<{
      stream_event_id: string
    }>
  >(endpoints.newRows(versionId, listId), data)

import { axios } from '@fintastic/web/data-access/service-axios'
import {
  CompactMetric,
  CompactMetricData,
  Metric,
  MetricDisplaySettings,
  MetricOrListSource,
  SparseMetricData,
} from '@fintastic/web/util/metrics-and-lists'
import {
  getMetricTimeDimensionIndex,
  MetricMetadata,
  UnfoldedMetricDataValues,
  unfoldMetricDataValues,
} from '@fintastic/web/util/metrics-and-lists'
import type { Maybe } from '@fintastic/shared/util/types'
import { compactToSparse } from '../utils/compact-to-sparse'
import { DimensionId } from '@fintastic/web/util/dimensions'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { mapPeriodSelectionToParams } from './helpers/map-period-selection-to-params'
import Qs from 'qs'
import { endpoints } from './metrics-endpoints'

export function getMetrics(versionId: string, showHidden: boolean) {
  return axios.get<{
    result: CompactMetric[]
  }>(endpoints.metrics(versionId), {
    params: {
      show_hidden: encodeURIComponent(showHidden),
    },
  })
}

export function getMetric(
  versionId: string,
  metricId: string,
  periodSelection?: PeriodSelection,
  includeData = true,
) {
  return axios.get<Metric>(endpoints.metric(versionId, metricId), {
    params: {
      ...mapPeriodSelectionToParams(periodSelection),
      with_data: includeData,
    },
    paramsSerializer: (p) => Qs.stringify(p, { arrayFormat: 'repeat' }),
  })
}

export type SaveMetricPayload = {
  name: string
  dimensions: string[]
  value: MetricMetadata['value']
  display_settings: MetricDisplaySettings
  formula: Maybe<string>
  description: string
  source: MetricOrListSource
  data: UnfoldedMetricDataValues
  time_dim_index: number
}

export type SaveMetricPayloadSparse = {
  name: string
  dimensions: string[]
  value: MetricMetadata['value']
  display_settings: MetricDisplaySettings
  formula: Maybe<string>
  description: string
  source: MetricOrListSource
  time_dim_index: number
  data: SparseMetricData
}

export function metricToSavePayload<
  TOmitData = boolean,
  TResult = TOmitData extends true
    ? Omit<SaveMetricPayload, 'data'>
    : SaveMetricPayload,
>(metric: Metric, rowDimId?: DimensionId, omitData?: TOmitData): TResult {
  const result = {
    name: metric.label,
    dimensions: !rowDimId
      ? metric.data.indexes
      : metric.data.indexes.filter((d) => d !== rowDimId),
    source: metric.source,
    formula: metric.metadata.formula,
    description: metric.metadata.description,
    value: metric.metadata.value,
    display_settings: {
      ...metric.metadata.display_config,
    },
    time_dim_index: getMetricTimeDimensionIndex(metric),
  }

  if (!omitData) {
    ;(result as SaveMetricPayload).data =
      metric.source === 'calculated'
        ? []
        : unfoldMetricDataValues(
            metric.data.values,
            metric.data.dimensions.map((d) => d.length),
          )
  }

  return result as TResult
}

// @todo remove
export function metricToSavePayloadSparse(
  metric: Metric,
): SaveMetricPayloadSparse {
  return {
    name: metric.label,
    source: metric.source,
    dimensions: metric.data.indexes,
    formula: metric.metadata.formula,
    description: metric.metadata.description,
    value: metric.metadata.value,
    display_settings: {
      ...metric.metadata.display_config,
    },
    time_dim_index: getMetricTimeDimensionIndex(metric),
    data: compactToSparse(metric.data as unknown as CompactMetricData),
  }
}

export function createMetric(versionId: string, metric: Metric) {
  const mappedMetric =
    metric.source === 'calculated'
      ? metricToSavePayload(metric)
      : metricToSavePayloadSparse(metric)
  return axios.post(endpoints.metrics(versionId), mappedMetric)
}

export function updateMetric(versionId: string, metric: Metric) {
  const mappedMetric =
    metric.source === 'calculated'
      ? metricToSavePayload(metric)
      : metricToSavePayloadSparse(metric)
  return axios.put(endpoints.metric(versionId, metric.id), mappedMetric)
}

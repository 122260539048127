import React from 'react'
import { StyledHeader, StyledTypeIcon, StyledName } from './Header.styled'
import { WidgetListIcon } from '@fintastic/shared/ui/icons'
import { Typography } from '@mui/material'

export const Header: React.FC<{ label: string }> = ({ label }) => (
  <StyledHeader>
    <StyledTypeIcon>
      <WidgetListIcon />
    </StyledTypeIcon>
    <StyledName>
      <Typography variant="body1" fontWeight="bold">
        {label}
      </Typography>
    </StyledName>
  </StyledHeader>
)

import { useQueries } from 'react-query'
import { loadVersionEntities } from './version-entities-query-handler'
import { useMemo, useRef } from 'react'
import { versionsCacheKeys } from '../../cache'
import { compact } from 'lodash'
import { VersionEntities } from '@fintastic/web/util/versions'

export function useLoadMultipleVersionsEntities(
  versionIds: string[],
  enabled = true,
) {
  const sortedVersions = useMemo(() => compact(versionIds).sort(), [versionIds])

  const queries = useQueries(
    sortedVersions.map((versionId) => ({
      queryKey: versionsCacheKeys.versionEntities(versionId),
      queryFn: () => loadVersionEntities(versionId),
      enabled,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 1,
    })),
  )

  const isFetching = !!queries.find((i) => i.isFetching)
  const isLoading = !!queries.find((i) => i.isLoading)
  const hasError = queries.find((i) => i.isError)

  const memoKey = queries
    .map(
      (query, queryIndex) => `${versionIds[queryIndex]}-${query.dataUpdatedAt}`,
    )
    .join(';')

  const queriesRef = useRef(queries)
  queriesRef.current = queries

  return useMemo<{
    isFetching: boolean
    isLoading: boolean
    data: VersionEntityWithVersionId[]
    memoKey: string
    isError: boolean
    error?: unknown
  }>(
    () => ({
      isFetching,
      isLoading,
      data: queriesRef.current
        .filter((i) => !!i.data)
        .map(({ data }, index) => ({
          entities: data,
          versionId: sortedVersions[index],
        })),
      memoKey,
      isError: !!hasError,
      error: hasError?.error,
    }),
    [memoKey, isFetching, isLoading, hasError],
  )
}

export type VersionEntityWithVersionId = {
  versionId: string
  entities?: VersionEntities
}

import { useCallback, useMemo } from 'react'
import {
  useIsLocalStorageOverrideDisabled,
  useReadDeeplinkValue,
  useSyncDeeplinkValue,
} from '@fintastic/web/util/deeplink'
import {
  areVersionsDefault,
  BOARD_PARAMS_LOCAL_STORAGE_KEYS,
  deeplinkDiffToNormalDiff,
  normalDiffToDeeplinkDiff,
} from './utils'
import { useLocalStorage } from '@fintastic/shared/util/hooks'
import { useValidateVersionsIds } from '../useValidateVersionsIds'
import { useResetThreadId } from '@fintastic/web/data-access/comments'
import { DiffMode } from '@fintastic/web/util/versions'

const defaultDiffs: Array<[string, string, DiffMode]> = []

export function useBoardsParamsVersions(defaultVersions: string[]) {
  const localStorageOverrideDisabled = useIsLocalStorageOverrideDisabled()

  const [deeplinkVersions, setDeeplinkVersions] = useSyncDeeplinkValue<
    string[]
  >({
    key: 'v',
    defaultValue: defaultVersions,
  })

  const [localStorageVersions, setLocalStorageVersions] = useLocalStorage<
    string[]
  >(BOARD_PARAMS_LOCAL_STORAGE_KEYS.version, defaultVersions)

  const resetThreadId = useResetThreadId()

  const setVersions = useCallback(
    (versionsIds: string[]) => {
      // When switching versions, we cannot be sure thread is still available
      resetThreadId()
      setLocalStorageVersions(versionsIds)
      setDeeplinkVersions(versionsIds)
    },
    [setDeeplinkVersions, setLocalStorageVersions, resetThreadId],
  )

  const versionListValidator = useValidateVersionsIds()

  const { versions, deeplinkInvalidated } = useMemo<{
    versions: string[]
    deeplinkInvalidated: boolean
  }>(() => {
    if (defaultVersions.length === 0) {
      return { versions: [], deeplinkInvalidated: false }
    }

    if (
      areVersionsDefault(deeplinkVersions, defaultVersions) &&
      localStorageVersions.length &&
      !localStorageOverrideDisabled
    ) {
      const validatedLocalStorageVersions =
        versionListValidator(localStorageVersions)

      if (validatedLocalStorageVersions.length) {
        return {
          versions: validatedLocalStorageVersions,
          deeplinkInvalidated: false,
        }
      }
    }

    if (deeplinkVersions.length) {
      const validatedDeeplinkVersions = versionListValidator(deeplinkVersions)
      if (validatedDeeplinkVersions.length) {
        return {
          versions: validatedDeeplinkVersions,
          deeplinkInvalidated:
            validatedDeeplinkVersions.length !== deeplinkVersions.length,
        }
      }
    }

    return { versions: defaultVersions, deeplinkInvalidated: false }
  }, [
    defaultVersions,
    deeplinkVersions,
    localStorageVersions,
    localStorageOverrideDisabled,
    versionListValidator,
  ])

  return useMemo(
    () => ({
      versions,
      setVersions,
      deeplinkInvalidated,
    }),
    [setVersions, versions, deeplinkInvalidated],
  )
}

const deeplinkDefaultValue = normalDiffToDeeplinkDiff(defaultDiffs)

const useReadDeeplinkDiff = (defaultVersions: string[]) => {
  const deeplinkDiffs = useReadDeeplinkValue<string[]>('diff')

  const localStorageOverrideDisabled = useIsLocalStorageOverrideDisabled()

  const [localStorageDiffs] = useLocalStorage<string[]>(
    BOARD_PARAMS_LOCAL_STORAGE_KEYS.diffs,
    deeplinkDefaultValue,
    {
      syncBetweenTabs: false,
    },
  )

  const { versions } = useBoardsParamsVersions(defaultVersions)

  return useMemo(() => {
    if (deeplinkDiffs?.length) {
      const allDeeplinkDiffExists = deeplinkDiffToNormalDiff(
        deeplinkDiffs,
      ).every(([a, b]) => versions.includes(a) && versions.includes(b))
      if (allDeeplinkDiffExists) {
        return deeplinkDiffToNormalDiff(deeplinkDiffs)
      }
    }

    if (localStorageDiffs?.length && !localStorageOverrideDisabled) {
      const allLSDiffExists = deeplinkDiffToNormalDiff(localStorageDiffs).every(
        ([a, b]) => versions.includes(a) && versions.includes(b),
      )
      if (allLSDiffExists) {
        return deeplinkDiffToNormalDiff(localStorageDiffs)
      }
    }

    return defaultDiffs
  }, [deeplinkDiffs, localStorageDiffs, localStorageOverrideDisabled, versions])
}

export function useBoardsParamsDiffs(defaultVersions: string[]) {
  const [, setDeeplinkDiffs] = useSyncDeeplinkValue<string[]>({
    key: 'diff',
    defaultValue: deeplinkDefaultValue,
  })

  const [, setLocalStorageDiffs] = useLocalStorage<string[]>(
    BOARD_PARAMS_LOCAL_STORAGE_KEYS.diffs,
    deeplinkDefaultValue,
    {
      syncBetweenTabs: false,
    },
  )
  const resetThreadId = useResetThreadId()

  const setDiffHandler = useCallback(
    (diffsValue: Array<[string, string, DiffMode]>) => {
      const nextDiffs = normalDiffToDeeplinkDiff(diffsValue)
      resetThreadId()
      setLocalStorageDiffs(nextDiffs)
      setDeeplinkDiffs(nextDiffs)
    },
    [setDeeplinkDiffs, setLocalStorageDiffs, resetThreadId],
  )

  const diffs = useReadDeeplinkDiff(defaultVersions)

  return useMemo(
    () => ({
      diffs,
      setDiffs: setDiffHandler,
    }),
    [diffs, setDiffHandler],
  )
}

const defaultVersionsFallback: string[] = []

export function useBoardsParams(defaultVersions = defaultVersionsFallback) {
  const { diffs } = useBoardsParamsDiffs(defaultVersions)
  const { versions } = useBoardsParamsVersions(defaultVersions)

  return useMemo(
    () => ({
      diffs,
      versions,
    }),
    [diffs, versions],
  )
}

export * from './useBoardParamsLocalStorageSyncEffect'

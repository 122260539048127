import { ColDef } from 'ag-grid-community'
import { MetricGridRow } from '../../types'
import {
  BaseGridColumnType,
  BuildBaseGridColumnDefinitionParams,
  buildGroupOfColumns,
} from '@fintastic/shared/ui/grid-framework'
import { createValueColumn, CreateValueColumnParams } from './createValueColumn'
import { createHiddenWeightsColumn } from '../weighted-average/createHiddenWeightsColumn'
import { VersionMetric } from '../../../../utils/metrics'
import {
  DimensionLabelMap,
  DimensionValueId,
  Maybe,
  RollUpFunction,
  toMaybe,
} from '@fintastic/shared/util/types'
import { BuildListGridColumnDefinitionParams } from '../../../../utils/buildListGridColumnDefinition'
import {
  createDiffFieldKey,
  createFieldKey,
  isDataTypeNumeric,
  MetricDataValueType,
  metricDiffValueGetter,
} from '@fintastic/web/util/metrics-and-lists'
import { LegacyListGridColumnData } from '@fintastic/web/feature/legacy-list-grid'
import { Currency } from '@fintastic/shared/data-access/currencies'
import { ValueColumnFieldAndRollUp } from './types'
import { ColumnColorsGridProps } from '../../../column-color-selector/types'
import { partial } from 'lodash'
import uniq from 'lodash/uniq'
import { VersionEntitiesContextValue } from '@fintastic/web/data-access/versions'
import { isValueColumnAggregationCellEditingAllowed } from './isValueColumnAggregationCellEditingAllowed'
import {
  DiffMode,
  DiffPart,
  VersionUserLockParsed,
} from '@fintastic/web/util/versions'

type Diff = [string, string, DiffMode]

type DiffColumnContext = {
  data: VersionMetric[]
  diffs?: Diff[]
  dataType: LegacyListGridColumnData
  rollUpFunction: RollUpFunction
}

const createDiffsColumns = (
  period: Maybe<string> = null,
  { data, diffs, rollUpFunction, dataType }: DiffColumnContext,
) => {
  if (
    !isDataTypeNumeric(dataType as unknown as MetricDataValueType) ||
    data.length === 0
  ) {
    return []
  }

  if (!diffs || diffs.filter(Boolean).length === 0) {
    return []
  }

  const metricId = data[0].metricId

  const columns: BuildBaseGridColumnDefinitionParams<MetricGridRow>[] = []

  diffs.forEach((diff: Diff) => {
    if (!diff) {
      return
    }
    const diffLeftColField = createFieldKey(
      diff[DiffPart.firstVersion],
      metricId,
      period,
    )
    const diffRightColField = createFieldKey(
      diff[DiffPart.secondVersion],
      metricId,
      period,
    )
    const diffLeftVersion = data.find(
      (v) => v.versionId === diff[DiffPart.firstVersion],
    )
    const diffRightVersion = data.find(
      (v) => v.versionId === diff[DiffPart.secondVersion],
    )

    if (!diffLeftVersion || !diffRightVersion) {
      return
    }

    const isPercentage = diff[DiffPart.mode] === 'percent'
    const isMixed = diff[DiffPart.mode] === 'mixed'

    const defaultMetricDataTypeFallback = (diffLeftVersion.metricMetadata.value
      .type ?? 'number') as BaseGridColumnType

    const diffValueGetter = partial(
      metricDiffValueGetter,
      diffLeftColField,
      diffRightColField,
      isPercentage ? 'percentage' : 'subtract',
    )

    columns.push({
      field:
        createDiffFieldKey(diff, metricId, period) +
        (isPercentage ? '_percent' : '_num'),
      title: 'Variance' + (isPercentage ? ' %' : ''),
      tooltip: `${diffLeftVersion.versionLabel} vs ${diffRightVersion.versionLabel}`,
      dataType: isPercentage ? 'percentage' : defaultMetricDataTypeFallback,
      rollUpFunction: rollUpFunction,
      isEditable: () => false,
      disableFilter: true,
      displaySettings: diffLeftVersion.displaySettings,
      valueGetter: diffValueGetter,
      overridingAggFunction: diffValueGetter,
    })

    if (isMixed) {
      const diffValueGetter = partial(
        metricDiffValueGetter,
        diffLeftColField,
        diffRightColField,
        'percentage',
      )
      // second column - always percentage
      columns.push({
        field: createDiffFieldKey(diff, metricId, period) + '_percent',
        title: 'Variance %',
        tooltip: `${diffLeftVersion.versionLabel} vs ${diffRightVersion.versionLabel}`,
        dataType: 'percentage',
        rollUpFunction: rollUpFunction,
        isEditable: () => false,
        disableFilter: true,
        displaySettings: diffLeftVersion.displaySettings,
        valueGetter: diffValueGetter,
        overridingAggFunction: diffValueGetter,
      })
    }
  })

  return columns
}

export type CreateMetricColumnsParams = {
  timeDimension?: string
  data: VersionMetric[]
  versionsEntities: VersionEntitiesContextValue
  useTwoLevels: boolean
  columnBuilder: (params: BuildListGridColumnDefinitionParams) => ColDef
  dimensions: DimensionLabelMap
  valueColumnCommonParams: Pick<
    CreateValueColumnParams,
    | 'dimensions'
    | 'nonTimeDimensions'
    | 'readonly'
    | 'dataType'
    | 'dimensionId'
    | 'timeDimension'
    | 'onUpdate'
    | 'period'
    | 'restPeriods'
  >
  diffs?: Diff[]
  dataType: LegacyListGridColumnData
  currencies: Currency[] | undefined
  blockedVersionUserLocks: VersionUserLockParsed[]
} & Partial<ColumnColorsGridProps>

/**
 * @todo @ipomazkin-fin - refactor this part later
 * @deprecated
 */
export function createMetricColumns({
  timeDimension,
  data,
  useTwoLevels,
  columnBuilder,
  dimensions,
  valueColumnCommonParams,
  diffs,
  dataType,
  currencies,
  enableColumnColors,
  columnColors,
  handleUpdateColumnColors,
  versionsEntities,
  blockedVersionUserLocks,
}: CreateMetricColumnsParams): {
  colDefs: ColDef<MetricGridRow>[]
  valueColumnsWithRollups: ValueColumnFieldAndRollUp[]
  periodValues: string[]
} {
  const columns: ColDef<MetricGridRow>[] = []
  const valueColumnsWithRollups: ValueColumnFieldAndRollUp[] = []

  const diffColumnsContext: DiffColumnContext = {
    data,
    diffs,
    rollUpFunction: 'sum',
    dataType,
  }

  const valueColumnMergedCommonParams = {
    ...valueColumnCommonParams,
    enableNewFormatting: true,
    currencies,
  } as const

  let periodValues: DimensionValueId[] = []

  if (
    timeDimension &&
    data.length > 0 &&
    data[0].metricData.indexes.length > 0
  ) {
    const allIncludedPeriods = uniq(
      data.flatMap(
        (d) =>
          d.metricData.dimensions[d.metricData.indexes.indexOf(timeDimension)],
      ),
    )

    periodValues = uniq(
      data.flatMap((d) => {
        const wrappedDimension = toMaybe(
          versionsEntities.entities[d.versionId]?.findDimensionById(
            timeDimension,
          )?.dimension,
        )
        return wrappedDimension?.type === 'Time'
          ? wrappedDimension.orderedValuesIds
          : []
      }),
    ).filter((dim) => allIncludedPeriods.includes(dim))

    const metricsWithPreparedPeriods = data.map(
      (d) =>
        ({
          metric: d,
          periods:
            d.metricData.dimensions[d.metricData.indexes.indexOf(timeDimension)]
              ?.slice()
              .sort(
                (a, b) => periodValues.indexOf(a) - periodValues.indexOf(b),
              ) || [],
        } as const),
    )

    for (
      let periodIndex = 0;
      periodIndex < periodValues.length;
      periodIndex++
    ) {
      const period = periodValues[periodIndex]

      if (useTwoLevels) {
        const childColumns: BuildBaseGridColumnDefinitionParams[] = []

        metricsWithPreparedPeriods.forEach(
          ({ metric, periods: existingPeriods }) => {
            const existingPeriodIndex = existingPeriods.indexOf(period)
            const periodExist = existingPeriodIndex !== -1

            const metricBaseTimeDimensionId =
              metric.metricMetadata.base_time_dimension_id

            const column = createValueColumn({
              ...valueColumnMergedCommonParams,
              dataType: metric.metricMetadata.value.type,
              metric,
              useVersionNameInTitle: true,
              period,
              restPeriods: periodExist
                ? existingPeriods.slice(existingPeriodIndex + 1)
                : [],
              blockedVersionUserLocks,
              enableColumnColors,
              columnColors,
              handleUpdateColumnColors,
              periodExistInMetric: periodExist,
              valueColumnAggregationCellEditingAllowed: Boolean(
                metricBaseTimeDimensionId &&
                  isValueColumnAggregationCellEditingAllowed(
                    versionsEntities,
                    metric.versionId,
                    metricBaseTimeDimensionId,
                  ),
              ),
            })
            if (column.rollUpFunction) {
              valueColumnsWithRollups.push({
                field: column.field,
                rollUpFunction: column.rollUpFunction,
              })
            }
            childColumns.push(column)

            if (metric.metricMetadata.value.weights_metric_id !== null) {
              childColumns.push(
                createHiddenWeightsColumn(
                  metric.versionId,
                  metric.metricId,
                  period,
                ),
              )
            }
          },
        )

        childColumns.push(...createDiffsColumns(period, diffColumnsContext))

        columns.push(
          buildGroupOfColumns(columnBuilder)({
            title:
              // @todo remove dimensions metadata usage
              dimensions[timeDimension].values?.[period] || 'Value',
            children: childColumns,
          }),
        )
      } else {
        const metricBaseTimeDimensionId =
          data[0].metricMetadata.base_time_dimension_id

        const valueColumn = createValueColumn({
          ...valueColumnMergedCommonParams,
          dataType: data[0].metricMetadata.value.type,
          metric: data[0],
          useVersionNameInTitle: false,
          period,
          restPeriods: periodValues.slice(periodIndex + 1),
          blockedVersionUserLocks,
          enableColumnColors,
          columnColors,
          handleUpdateColumnColors,
          valueColumnAggregationCellEditingAllowed: Boolean(
            metricBaseTimeDimensionId &&
              isValueColumnAggregationCellEditingAllowed(
                versionsEntities,
                data[0].versionId,
                metricBaseTimeDimensionId,
              ),
          ),
        })

        columns.push(columnBuilder(valueColumn))

        if (valueColumn.rollUpFunction) {
          valueColumnsWithRollups.push({
            field: valueColumn.field,
            rollUpFunction: valueColumn.rollUpFunction,
          })
        }

        if (data[0].metricMetadata.value.weights_metric_id !== null) {
          columns.push(
            columnBuilder(
              createHiddenWeightsColumn(
                data[0].versionId,
                data[0].metricId,
                period,
              ),
            ),
          )
        }

        columns.push(
          ...createDiffsColumns(period, diffColumnsContext).map((col) =>
            columnBuilder(col),
          ),
        )
      }
    }
  } else {
    for (let i = 0; i < data.length; i++) {
      const versionMetric = data[i]
      const metricBaseTimeDimensionId =
        versionMetric.metricMetadata.base_time_dimension_id
      const valueColumn = createValueColumn({
        ...valueColumnMergedCommonParams,
        dataType: versionMetric.metricMetadata.value.type,
        metric: versionMetric,
        useVersionNameInTitle: data.length > 1,
        blockedVersionUserLocks,
        enableColumnColors,
        columnColors,
        handleUpdateColumnColors,
        valueColumnAggregationCellEditingAllowed: Boolean(
          metricBaseTimeDimensionId &&
            isValueColumnAggregationCellEditingAllowed(
              versionsEntities,
              versionMetric.versionId,
              metricBaseTimeDimensionId,
            ),
        ),
      })
      if (valueColumn.rollUpFunction) {
        valueColumnsWithRollups.push({
          field: valueColumn.field,
          rollUpFunction: valueColumn.rollUpFunction,
        })
      }
      columns.push(columnBuilder(valueColumn))
      if (versionMetric.metricMetadata.value.weights_metric_id !== null) {
        columns.push(
          columnBuilder(
            createHiddenWeightsColumn(
              versionMetric.versionId,
              versionMetric.metricId,
              null,
            ),
          ),
        )
      }
    }
    columns.push(
      ...createDiffsColumns(undefined, diffColumnsContext).map((col) =>
        columnBuilder(col),
      ),
    )
  }

  return { colDefs: columns, valueColumnsWithRollups, periodValues }
}

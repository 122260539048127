import React, { useCallback, useMemo } from 'react'
import {
  FilterByBoolean,
  FilterListAPIOperatorBooleanUiValue,
  filterUtils,
  FilterValue,
} from '@fintastic/web/util/filters'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import { uniq } from 'lodash'

export const BooleanFilterInput: React.FC<BooleanFilterInputProps> = ({
  filter,
  filterValue,
  onChange,
  disableClear,
}) => {
  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const currentValue = filterValue.value ? filterValue.value : defaultValue
      const nextValue = checked
        ? uniq([...currentValue, event.target.value])
        : currentValue.filter((i) => i !== event.target.value)

      onChange({
        operator: 'bool_in',
        value: nextValue as FilterListAPIOperatorBooleanUiValue['value'],
      })
    },
    [filterValue.value, onChange],
  )

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange({
        operator: 'bool_in',
        value: checked ? defaultValue : [],
      })
    },
    [onChange],
  )

  const handleClearFilter = useCallback(() => {
    onChange({
      operator: 'bool_in',
      value: null,
    })
  }, [onChange])

  const isEmpty = useMemo<boolean>(
    () => filterUtils.isFilterValueEmpty(filter, filterValue),
    [filter, filterValue],
  )

  return (
    <Box>
      <Box py={1}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value="select_all"
                checked={isEmpty}
                onChange={handleSelectAll}
              />
            }
            label="Select all"
          />
          <FormControlLabel
            control={
              <Checkbox
                value="true"
                checked={isEmpty || filterValue.value?.includes('true')}
                onChange={handleValueChange}
              />
            }
            label="True"
          />
          <FormControlLabel
            control={
              <Checkbox
                value="false"
                checked={isEmpty || filterValue.value?.includes('false')}
                onChange={handleValueChange}
              />
            }
            label="False"
          />
          <FormControlLabel
            control={
              <Checkbox
                value="null"
                checked={isEmpty || filterValue.value?.includes('null')}
                onChange={handleValueChange}
              />
            }
            label="Blank"
          />
        </FormGroup>
      </Box>
      {disableClear ? null : (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            disabled={isEmpty}
            onClick={handleClearFilter}
          >
            Clear
          </Button>
        </Box>
      )}
    </Box>
  )
}

BooleanFilterInput.displayName = 'BooleanFilterInput'

export type BooleanFilterInputProps = {
  filter: FilterByBoolean
  filterValue: Partial<
    FilterValue<FilterListAPIOperatorBooleanUiValue['value']>
  >
  onChange: (
    nextValue: FilterValue<FilterListAPIOperatorBooleanUiValue['value']>,
  ) => void
  disableClear?: boolean
}

const defaultValue: Exclude<
  FilterListAPIOperatorBooleanUiValue['value'],
  null
> = ['false', 'null', 'true']

import React from 'react'
import { AppLayout } from '@fintastic/shared/ui/app-layout-framework'
import { ErrorBoundary } from '@fintastic/shared/ui/components'
import { Outlet } from 'react-router-dom'
import { useSidebarConfig } from './sidebar/useSidebarConfig'
import { useCentralisedVersionsEventsListener } from '@fintastic/web/data-access/calc'
import { ModelExplorerProvider } from '@fintastic/web/feature/model-explorer'
import { usePusherReconnectionFixture } from '@fintastic/web/data-access/service-pusher'
import { HistoryLogGlobalApiProvider } from '@fintastic/web/data-access/history'
import { EntityLevelHistory } from '@fintastic/web/feature/history'
import { useVersionPageGlobalRouterBlocker } from '@fintastic/web/feature/versions'

const errorBoundarySentryExtras = {
  caughtIn: 'RootRoute',
}

export const RootRoute: React.FC = () => {
  useCentralisedVersionsEventsListener()
  usePusherReconnectionFixture()
  useVersionPageGlobalRouterBlocker()
  const sidebarConfig = useSidebarConfig()

  return (
    <ModelExplorerProvider>
      <HistoryLogGlobalApiProvider>
        <AppLayout
          sidebarConfig={sidebarConfig}
          entityLevelHistory={<EntityLevelHistory />}
        >
          <ErrorBoundary sentryScopeExtras={errorBoundarySentryExtras}>
            <Outlet />
          </ErrorBoundary>
        </AppLayout>
      </HistoryLogGlobalApiProvider>
    </ModelExplorerProvider>
  )
}

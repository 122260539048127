import { useCallback, useMemo, useRef } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { FormulaInputApi } from './FormulaInput'

export type FormulaInputPublicApi = ReturnType<typeof useFormulaInputApi>

export function useFormulaInputApi() {
  const formulaInputApiRef = useRef<Maybe<FormulaInputApi>>(null)

  const focus = useCallback(() => {
    if (!formulaInputApiRef.current) {
      return
    }
    formulaInputApiRef.current.focus()
  }, [])

  const getTokensNodes = useCallback(() => {
    if (!formulaInputApiRef.current) {
      return []
    }
    return formulaInputApiRef.current.getTokensNodes()
  }, [])

  const moveCaret = useCallback((position: number) => {
    if (!formulaInputApiRef.current) {
      return
    }
    return formulaInputApiRef.current.moveCaret(position)
  }, [])

  const simulateFormulaChange = useCallback((newFormula: string) => {
    if (!formulaInputApiRef.current) {
      return
    }
    return formulaInputApiRef.current.simulateFormulaChange(newFormula)
  }, [])

  return useMemo(
    () =>
      ({
        formulaInputApiRef,
        focusFormulaInput: focus,
        getTokensNodes,
        moveCaret,
        simulateFormulaChange,
      } as const),
    [moveCaret, getTokensNodes, focus, simulateFormulaChange],
  )
}

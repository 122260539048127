import React, { useCallback } from 'react'
import { ChipProps } from '@mui/material/Chip'
import { Tag } from './types'
import { CancelOutlined } from '@mui/icons-material'
import { Avatar } from '@mui/material'
import { TagsTagChip } from './TagsTag.styled'

export type TagsTagProps = {
  onDelete?: (tag: Tag) => void
  tag: Tag
  hidden?: boolean
  icon?: ChipProps['icon']
}

export const TagsTag: React.FC<TagsTagProps> = ({
  onDelete,
  tag,
  hidden,
  icon,
}) => {
  const handleDelete = useCallback(() => {
    onDelete?.(tag)
  }, [onDelete, tag])

  return (
    <TagsTagChip
      label={tag.title}
      onDelete={onDelete ? handleDelete : undefined}
      deleteIcon={onDelete ? <CancelOutlined /> : undefined}
      size="small"
      avatar={
        tag.color ? (
          <Avatar sx={{ background: tag.color }}> </Avatar>
        ) : undefined
      }
      icon={icon}
      data-tag={tag.title}
      data-testid="tag"
      sx={hidden ? { visibility: 'hidden' } : undefined}
      variant={tag.color ? 'filled' : 'outlined'}
    />
  )
}

import { useEffect, useRef, useState } from 'react'
import type { User } from '@fintastic/shared/data-access/auth0-react'
import { useEnvironmentVariables } from '@fintastic/web/data-access/environment'
import { Maybe } from '@fintastic/shared/util/types'
import { AUTH0_SCOPE } from '@fintastic/web/data-access/service-auth0'
import { useAuth0 } from '@fintastic/shared/data-access/auth0-react'

export type UseAccessTokenResult = {
  isLoading: boolean
  token: Maybe<string>
  error: Maybe<string>
  isAuthenticated: boolean
  user: Maybe<User>
}

export function useAuthAccessToken(): UseAccessTokenResult {
  const [token, setToken] = useState<Maybe<string>>(null)
  const [error, setError] = useState<Maybe<string>>(null)
  const setTokenRef = useRef(setToken)
  const setErrorRef = useRef(setError)
  const { getAccessTokenSilently, isAuthenticated, user, isLoading } =
    useAuth0()
  const audience = useEnvironmentVariables().REACT_APP_AUTH0_AUDIENCE

  useEffect(() => {
    setTokenRef.current = setToken
    setErrorRef.current = setError
  }, [setToken, setError])

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    const lc = { mounted: true }

    getAccessTokenSilently({
      audience,
      scope: AUTH0_SCOPE.join(','),
    })
      .then((accessToken) => {
        if (!lc.mounted) {
          return
        }
        setTokenRef.current(accessToken)
        setErrorRef.current(null)
      })
      .catch((e) => {
        if (!lc.mounted) {
          return
        }
        console.error(e)
        setTokenRef.current(null)
        setErrorRef.current(e.message || 'Something went wrong.')
      })

    // eslint-disable-next-line consistent-return
    return () => {
      lc.mounted = false
    }
  }, [isAuthenticated, getAccessTokenSilently, audience])

  return {
    isLoading,
    token,
    error,
    isAuthenticated,
    user: user || null,
  }
}

import {
  HistoryLogEntryChangeMetricValue,
  HistoryLogEntryChangeColumnValue,
} from '@fintastic/web/util/history'
import {
  DimensionId,
  idLooksLikeDimensionValue,
  ParsedDimensionValueId,
  VersionDimension,
} from '@fintastic/web/util/dimensions'
import { pipeNullable } from '@fintastic/shared/util/functional-programming'
import React, { useMemo } from 'react'
import { Box, createSvgIcon } from '@mui/material'
import { format } from 'numerable'
import { createValueFormatter } from '@fintastic/web/util/metrics-and-lists'
import { currencies } from '@fintastic/shared/data-access/currencies'

type Entry = HistoryLogEntryChangeMetricValue | HistoryLogEntryChangeColumnValue

type Value =
  | HistoryLogEntryChangeMetricValue['new_value']
  | HistoryLogEntryChangeColumnValue['old_value']
  | HistoryLogEntryChangeColumnValue['new_value']
  | HistoryLogEntryChangeMetricValue['old_value']

type FormattedValue =
  | {
      isBlank: true
      string: string
    }
  | {
      string: string
    }

const formatValue = (
  entry: Entry,
  valueGetter: (entry: Entry) => Value,
  dimensions: Record<DimensionId, VersionDimension>,
): FormattedValue => {
  const value = valueGetter(entry)

  if (value === null || value === '' || value === 'NaT') {
    return {
      isBlank: true,
      string: 'Blank',
    }
  }

  const formatter = entry.meta
    ? createValueFormatter(
        entry.meta.value.type,
        entry.meta.display_config,
        currencies.find(
          (c) => c.code === entry.meta?.display_config.currency_name,
        ),
      )
    : null

  if (formatter !== null) {
    return {
      string: formatter({ value }),
    }
  }

  let string = `${value}`

  if (entry.meta?.value?.type === 'boolean') {
    return {
      string: value === 0 ? 'False' : 'True',
    }
  }

  if (entry.meta?.value?.type === 'datetime') {
    return {
      string: `${value}`,
    }
  }

  if (idLooksLikeDimensionValue(string)) {
    string =
      pipeNullable(
        ParsedDimensionValueId.fromString(string),
        (parsedDimId) => dimensions[parsedDimId.dimensionId],
        (dimension) =>
          dimension.type === 'Category' ? dimension.values[string] : null,
      ) || string
  } else if (typeof value === 'number') {
    string = format(value, '0[.]00')
  } else if (typeof value === 'string') {
    string = `"${string}"`
  }

  return {
    string,
  }
}

export const oldToNewValueTransitionClassNames = {
  root: 'history-details-old-to-new-transition',
  icon: 'icon',
  value: 'value',
  valueWithBlankModificator: 'is-blank',
}

export const OldToNewValueTransition: React.FC<{
  entry: Entry
  dimensions: Record<DimensionId, VersionDimension>
}> = ({ entry, dimensions }) => {
  const oldValue = useMemo(
    () => formatValue(entry, ({ old_value }) => old_value, dimensions),
    [dimensions, entry],
  )
  const newValue = useMemo(
    () => formatValue(entry, ({ new_value }) => new_value, dimensions),
    [dimensions, entry],
  )

  return (
    <Box
      component="span"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
      }}
      className={oldToNewValueTransitionClassNames.root}
    >
      <FormattedValueDisplay value={oldValue} />
      <Icon
        className={oldToNewValueTransitionClassNames.icon}
        sx={{ width: '0.82em', height: 'auto' }}
      />
      <FormattedValueDisplay value={newValue} />
    </Box>
  )
}

const FormattedValueDisplay: React.FC<{ value: FormattedValue }> = ({
  value,
}) => (
  <span
    className={`${oldToNewValueTransitionClassNames.value} ${
      'isBlank' in value
        ? oldToNewValueTransitionClassNames.valueWithBlankModificator
        : ''
    }`}
  >
    {value.string}
  </span>
)

const Icon = createSvgIcon(
  <>
    <path
      d="M6 12L16.8 12"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.2 8.39999L18 12L13.2 15.6" fill="currentColor" />
  </>,
  'HistoryTransitionIcon',
)

import React, { useEffect, useMemo } from 'react'
import {
  MetricMetadataView,
  useMetricMetadataViewCache,
} from '@fintastic/web/feature/metric-metadata-view'
import { Box } from '@mui/material'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import {
  MetricConfigurableDataValueType,
  ParsedMetricId,
} from '@fintastic/web/util/metrics-and-lists'

export const MetricDetails: React.FC<{
  metricId: string
  versionId: string
  onLoadingStateChanged: () => void
}> = ({ metricId, versionId, onLoadingStateChanged }) => {
  const { isLoading, isError, metric, dimensions, isLiveActuals } =
    useMetricMetadataViewCache({
      versionId,
      metricId,
    })

  const label = useMemo(
    () =>
      titleFormatter(ParsedMetricId.fromString(metricId)?.idWithoutPrefix) ||
      '',
    [metricId],
  )

  useEffect(() => {
    onLoadingStateChanged?.()
  }, [isLoading, isError, onLoadingStateChanged])

  return (
    <Box width={420}>
      <MetricMetadataView.Header label={label} />
      {isLoading && <MetricMetadataView.Loader />}
      {!isLoading && isError && <MetricMetadataView.Error />}
      {!isLoading && !isError && metric && (
        <MetricMetadataView.ScrollContainer>
          <MetricMetadataView.Type
            calculated={metric.source === 'calculated'}
            liveActuals={!!isLiveActuals}
            dataType={
              metric.metadata.value.type as MetricConfigurableDataValueType
            }
          />
          <MetricMetadataView.Dimensions
            label="Dimensions"
            dimensions={dimensions || []}
          />
        </MetricMetadataView.ScrollContainer>
      )}
    </Box>
  )
}

import { Maybe } from '@fintastic/shared/util/types'
import { MetricMetadata } from '@fintastic/web/util/metrics-and-lists'
import {
  ChartColumnData,
  ChartDecimalPlaces,
  ChartGridMode,
  ChartLegendFormat,
  ChartLegendPosition,
  ChartNumberFormat,
  ChartTheme,
  ChartType,
  ChartXAxisFormat,
  MetricChartDimensions,
} from '@fintastic/web/feature/charts'
import { ChartXAxisAlignment } from './chart-x-axis-types'
import { ChartAggResponseDimension } from '@fintastic/web/data-access/metrics-and-lists'

export type ChartDisplaySettings = {
  type?: ChartType
  theme?: ChartTheme
  legendPosition?: ChartLegendPosition
  legendFormat?: ChartLegendFormat
  xAxisAlignment?: ChartXAxisAlignment
  xAxisFormat?: ChartXAxisFormat
  gridMode?: ChartGridMode
  numberFormat?: ChartNumberFormat
  decimalPlaces?: ChartDecimalPlaces
}

export type ChartDataSettings = {
  dimensions?: MetricChartDimensions
}

export type ChartSettings = ChartDisplaySettings & ChartDataSettings

//https://github.com/fintastic-ai/fintastic/blob/af/FIN-8553-multi-version-metrics/backend/planning_data_api/planning_data_api/error_codes.py#L6-L49
export type ChartApiErrorCode =
  | 'RESTRICTED_ENTITY_NOT_ALLOWED_IN_FORMULA'
  | 'FILTERED_OR_MASKED_COORDINATES_IN_LIST_EDIT_INTENT'
  | 'NOT_APPLICABLE_FOR_CURRENT_ROLE'
  | 'REQUIRED_LIST_COLUMNS_NOT_PROVIDED'
  | 'ONLY_INPUT_LIST_COLUMNS_ARE_ALLOWED'
  | 'INVALID_DIMENSION_VALUE'
  | 'NO_REQUESTED_ROWS_FOUND'
  | 'REPORT_LOOKUP_DUPLICATES'
  | 'REPORT_LOOKUP_DIMENSION_NOT_FOUND'
  | 'REPORT_LOOKUP_COLUMN_NOT_1D'
  | 'VERSION_NOT_FOUND'
  | 'METRIC_NOT_FOUND'
  | 'LIST_NOT_FOUND'
  | 'VERSION_FORBIDDEN'
  | 'METRIC_FORBIDDEN'
  | 'LIST_FORBIDDEN'
  | 'METRIC_ORIGINALLY_EMPTY'
  | 'DIMENSION_NOT_IN_CALENDAR'
  | 'TIME_DIM_LESS_THAN_ENTITY_TIME_RESOLUTION'

export type ChartDataError =
  | 'VERSION_NOT_FOUND'
  | 'METRIC_NOT_FOUND'
  | 'VERSION_FORBIDDEN'
  | 'METRIC_FORBIDDEN'
  | 'INVALID_RESOLUTION'
  | 'ORIGINALLY_EMPTY'
  | 'DIMENSION_NOT_IN_CALENDAR'
  | 'INTERNAL_ERROR'
  | 'UNKNOWN_ERROR'
  | 'TIME_DIM_ABSENT'

export type MultiversionChartData = {
  data: ChartColumnData
  metadata: Maybe<MetricMetadata>
  usedDimensions?: MetricChartDimensions
  hasDifferentDimensions?: boolean

  dataMostSevereError?: ChartDataError
  versionsRequested: number
  versionsPresent: number

  allVersionsFailed: boolean
}

export type WidgetWithChartSettings = {
  chartSettings?: ChartSettings
}

export function isWidgetWithChartSettings(
  value: Record<string, unknown>,
): value is WidgetWithChartSettings {
  return typeof value.chartSettings !== 'undefined'
}

import { ListPermissions } from '@fintastic/web/data-access/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'

export type ListPermissionsApi = ReturnType<typeof makeApi>

export const makeApi = (deps: {
  isPowerUser: boolean
  isInputList: Maybe<boolean>
  permissionsQuery: {
    isError: boolean
    isLoading: boolean
    isFetching: boolean
    isSuccess: boolean
    data: ListPermissions | undefined
  }
}) => {
  if (deps.isPowerUser) {
    return {
      ready: true,
      allowAll: true,
    } as const
  }

  if (deps.isInputList === false) {
    return {
      ready: true,
      canAddRows: false,
      canDeleteRows: false,
      canDuplicateRows: false,
    }
  }

  if (deps.permissionsQuery.isError) {
    return {
      ready: false,
      error: true,
      errorMessage: 'Cannot load list permissions.',
    } as const
  }

  if (
    deps.permissionsQuery.isLoading ||
    !deps.permissionsQuery.data ||
    deps.isInputList === null
  ) {
    return {
      ready: false,
      loading: true,
    } as const
  }

  const permissions = deps.permissionsQuery.data

  return {
    ready: true,
    canAddRows: permissions.can_add_rows,
    canDeleteRows: permissions.can_delete_rows,
    canDuplicateRows: permissions.can_duplicate_rows,
  } as const
}

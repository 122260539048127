import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'
import { useEffect } from 'react'

export const useHistoryAutoClose = (selectedVersionIds: string[]) => {
  const historyApi = useHistoryLogGlobalApi()

  useEffect(() => {
    if (
      historyApi?.state?.level === 'entity' &&
      !selectedVersionIds.includes(historyApi.state.versionId)
    ) {
      historyApi.close()
    }
  }, [historyApi, selectedVersionIds])
}

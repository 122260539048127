import React, { useMemo } from 'react'
import { ErrorAlert } from './ErrorAlert'
import * as Sentry from '@sentry/react'

export type ErrorBoundaryProps = {
  children: React.ReactNode
  title?: string
  message?: string
  sentryScopeExtras?: Record<string, any>
  errorComponent?: React.ReactNode
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  title,
  message,
  sentryScopeExtras,
  errorComponent,
}) => {
  const beforeCapture = useMemo<
    Sentry.ErrorBoundaryProps['beforeCapture'] | undefined
  >(() => {
    if (!sentryScopeExtras) {
      return undefined
    }
    return (scope) => {
      scope.setExtras(sentryScopeExtras)
      scope.setTag('caughtBy', 'Sentry Error Boundary')
    }
  }, [sentryScopeExtras])

  return (
    <Sentry.ErrorBoundary
      beforeCapture={beforeCapture}
      fallback={({ resetError }) => (
        <>
          <ErrorAlert
            tryAgainCallback={resetError}
            title={title}
            message={message}
          />
          {errorComponent}
        </>
      )}
      children={children}
    />
  )
}

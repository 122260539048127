import {
  GenericReportCategoryOptions,
  MonthOverMonthContext,
} from '@fintastic/web/util/generic-report'
import { useContext, useMemo } from 'react'
import { Period } from '@fintastic/web/util/period-selector'
import { compact, negate } from 'lodash'
import { makeReportTableTimeDiffValueGetter } from '../time-diff-logic'
import { AgGridColumn } from 'ag-grid-react'
import { generateDiffFieldId } from '../../utils/field-id'
import { makeReportTableDiffAggFunc } from '../logic'
import { valueFormatterMaybeCalculatedRowFn } from '../GenericReportTable'
import { cellRendererSelector } from '../cell-renderer/cell-renderer-selector'
import { isTotalPeriodName } from '../../utils/is-total-period-name'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'

export type PeriodOverPeriodDiffColumnsProps = {
  allPeriods: Period[]
  options: GenericReportCategoryOptions
  reportsOrVersionsIds: string[]
}

export const usePeriodOverPeriodDiffColumns = ({
  allPeriods,
  reportsOrVersionsIds,
  options,
}: PeriodOverPeriodDiffColumnsProps) => {
  const monthOverMonthContext = useContext(MonthOverMonthContext)
  const multiplePeriodOverPeriodDiffEnabled = useIsFeatureEnabled(
    'multiple_period_over_period_diff',
  )

  return useMemo(() => {
    if (!monthOverMonthContext.active) {
      return null
    }

    const periodsToGenerate = compact(
      allPeriods.filter(negate(isTotalPeriodName)).map((period, index) => {
        if (index === allPeriods.length - 1) {
          return null
        }

        if (isTotalPeriodName(allPeriods[index + 1])) {
          return null
        }

        return {
          periods: [period, allPeriods[index + 1]],
          periodLabel: `${period} vs ${allPeriods[index + 1]}`,
          periodLabelPercentage: `${period} vs ${allPeriods[index + 1]} (%)`,
        }
      }),
    )

    const shouldGenerateNewPeriodsKey =
      multiplePeriodOverPeriodDiffEnabled && periodsToGenerate.length > 1

    return periodsToGenerate.map(
      ({ periods, periodLabel, periodLabelPercentage }) => {
        const valueGetterSubtract = makeReportTableTimeDiffValueGetter(
          periods[1],
          periods[0],
          reportsOrVersionsIds[0],
          'subtract',
        )
        const valueGetterPercentage = makeReportTableTimeDiffValueGetter(
          periods[1],
          periods[0],
          reportsOrVersionsIds[0],
          'percentage',
        )

        const periodsKey = `${periods[0]}_${periods[1]}`

        return (
          <AgGridColumn
            key={`amount_two_period_diff_root_${periodsKey}`}
            headerName="Variance"
            marryChildren
            suppressColumnsToolPanel
          >
            {compact([
              <AgGridColumn
                field={potentialMultiplePeriodKey(
                  generateDiffFieldId('mom'),
                  periodsKey,
                  shouldGenerateNewPeriodsKey,
                )}
                key="amount_two_period_diff"
                hide={false}
                sortable={true}
                filter={false}
                type="numericColumn"
                suppressMovable
                suppressColumnsToolPanel
                valueGetter={valueGetterSubtract}
                aggFunc={makeReportTableDiffAggFunc(
                  periods[1],
                  periods[0],
                  '_timestamp',
                  'subtract',
                )}
                headerName={periodLabel}
                valueFormatter={valueFormatterMaybeCalculatedRowFn()}
                cellRendererSelector={cellRendererSelector}
              />,
              options.enable_percentage_diff ? (
                <AgGridColumn
                  field={potentialMultiplePeriodKey(
                    generateDiffFieldId('mom_percentage'),
                    periodsKey,
                    shouldGenerateNewPeriodsKey,
                  )}
                  key="amount_two_period_diff_percentage"
                  hide={false}
                  sortable={true}
                  filter={false}
                  type="numericColumn"
                  suppressMovable
                  suppressColumnsToolPanel
                  valueGetter={valueGetterPercentage}
                  aggFunc={makeReportTableDiffAggFunc(
                    periods[1],
                    periods[0],
                    '_timestamp',
                    'percentage',
                  )}
                  headerName={periodLabelPercentage}
                  valueFormatter={valueFormatterMaybeCalculatedRowFn(true)}
                  cellRendererSelector={cellRendererSelector}
                />
              ) : null,
            ])}
          </AgGridColumn>
        )
      },
    )
  }, [
    allPeriods,
    monthOverMonthContext.active,
    multiplePeriodOverPeriodDiffEnabled,
    options.enable_percentage_diff,
    reportsOrVersionsIds,
  ])
}

const potentialMultiplePeriodKey = (
  key: string,
  periodsKey: string,
  multiplePeriodOverPeriodDiffEnabled: boolean,
): string => {
  if (!multiplePeriodOverPeriodDiffEnabled) {
    return key
  }

  return `${key}_${periodsKey}`
}

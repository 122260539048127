import { Maybe } from '@fintastic/shared/util/types'
import { useQuery } from 'react-query'
import { createVersionUserLockerQueryHandler } from './version-user-locker-query-handler'
import { versionsCacheKeys } from '../../cache'
import { useConnectionStatus } from '@fintastic/web/feature/realtime'

export function useLoadVersionUserLocker(id?: Maybe<string>) {
  const isTabActive = useConnectionStatus().tabIsVisible

  return useQuery(
    versionsCacheKeys.versionUserLock(id || ''),
    createVersionUserLockerQueryHandler(id),
    {
      enabled: id !== null && id !== undefined,
      refetchInterval: isTabActive ? 60 * 1000 : false,
    },
  )
}

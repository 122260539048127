import {
  HistoryLogAction,
  HistoryLogEntry,
  HistoryLogEntryType,
} from '@fintastic/web/util/history'
import { Maybe } from '@fintastic/shared/util/types'

const mappingRules: Record<
  HistoryLogAction,
  | HistoryLogEntryType
  | ((entry: Omit<HistoryLogEntry, '_type'>) => HistoryLogEntryType)
> = {
  change_value: (entry) => {
    if (
      'target_entity_type' in entry &&
      entry.entity_type === 'list' &&
      entry.target_entity_type === 'list_column'
    ) {
      return 'change_column_value'
    }
    return 'change_metric_value'
  },
  add_line: 'add_list_line',
  delete_line: 'delete_list_line',
  duplicate_line: 'duplicate_list_line',
}

export const mapHistoryLogEntryToType = (
  entry: Omit<HistoryLogEntry, '_type'>,
): Maybe<HistoryLogEntryType> => {
  const rule = mappingRules[entry.action] || null
  return typeof rule === 'function' ? rule(entry) : rule
}

import { MetricDataValueType } from '@fintastic/web/util/metrics-and-lists'

export type DataTypeGroup = 'numeric' | 'text' | 'logic' | 'date' | 'other'

const dataTypeToGroupMap: Partial<Record<MetricDataValueType, DataTypeGroup>> =
  {
    number: 'numeric',
    integer: 'numeric',
    currency: 'numeric',
    percentage: 'numeric',
    percentage_integer: 'numeric',
    string: 'text',
    boolean: 'logic',
    datetime: 'date',
    dimension: 'other',
    values: 'other',
  }

export function getDataTypeGroup(type: MetricDataValueType): DataTypeGroup {
  return dataTypeToGroupMap[type as never] || 'other'
}

export function isDataTypeNumeric(type: MetricDataValueType): boolean {
  return getDataTypeGroup(type) === 'numeric'
}

export function isDataTypeLogic(type: MetricDataValueType): boolean {
  return getDataTypeGroup(type) === 'logic'
}

export const isDataTypeAggregatable = (type: MetricDataValueType): boolean =>
  isDataTypeNumeric(type) || isDataTypeLogic(type)

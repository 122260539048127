import { useMemo } from 'react'

import { useDirectNavigationExecutorApi } from '@fintastic/web/feature/direct-navigation'
import { DirectNavigationTaskParams, DirectNavigationTaskError } from './types'
import {
  ShouldStartExecutionPredicate,
  SubtaskExecutor,
  useDistributedMonoTaskExecutor,
} from '@fintastic/shared/util/distributed-mono-task'
import { DirectNavigationSubtaskRuntimeError } from './errors'

const mapError = (error: Error): DirectNavigationSubtaskRuntimeError => {
  const newError = new DirectNavigationSubtaskRuntimeError(error)
  newError.stack = error.stack
  return newError
}

export const useDirectNavigationExecutor = <TDependencies>(params: {
  subtaskId: string
  shouldStartExecution: ShouldStartExecutionPredicate<DirectNavigationTaskParams>
  executor: SubtaskExecutor<DirectNavigationTaskParams, TDependencies>
  dependencies: TDependencies
}) => {
  const { state, update } = useDirectNavigationExecutorApi()

  useDistributedMonoTaskExecutor<
    DirectNavigationTaskParams,
    DirectNavigationTaskError,
    TDependencies
  >(
    useMemo(
      () => ({
        taskState: state,
        update,
        keepAliveInterval: 1000,
        mapSubtaskRuntimeError: mapError,
        ...params,
      }),
      [params, state, update],
    ),
  )
}

import { Auth0Client } from '@auth0/auth0-spa-js'
import { EnvironmentVariables } from '@fintastic/web/data-access/environment'
import { AUTH0_SCOPE } from './auth0-config'
import {
  TestingAuth0Client,
  shouldUseTestAuth0Client,
} from '@fintastic/web/data-access/testing-auth0-client'
import { Maybe } from '@fintastic/shared/util/types'

const clientRef: {
  client: Maybe<Auth0Client>
} = {
  client: null,
}

export const initAuth0Client = (env: Readonly<EnvironmentVariables>) => {
  let client = null as unknown as Auth0Client

  if (shouldUseTestAuth0Client()) {
    client = new TestingAuth0Client() as unknown as Auth0Client
  } else if (!navigator.userAgent.includes('jsdom')) {
    client = new Auth0Client({
      domain: env.REACT_APP_AUTH0_DOMAIN,
      client_id: env.REACT_APP_AUTH0_CLIENT_ID,
      scope: AUTH0_SCOPE.join(','),
      audience: env.REACT_APP_AUTH0_AUDIENCE,
      // Empty string should be undefined
      organization: env.REACT_APP_AUTH0_ORG_ID || undefined,
      redirect_uri: window?.location.origin,
    })
  }

  clientRef.client = client
}

export const auth0Client = () => {
  if (!clientRef.client) {
    throw new Error('auth0 client is not initialized')
  }
  return clientRef.client
}

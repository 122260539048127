import { useDispatch } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { setIsDiscussionsOpened, setIsSidebarOpened } from './index'
import { useLayoutStateIsDiscussionsOpened } from './hooks-discussions'
import { useLayoutStateIsSidebarOpened } from './hooks-sidebar'
import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'

export function useCloseLateralOnDestroy() {
  const dispatch = useDispatch()
  const closeHistory = useHistoryLogGlobalApi()?.close
  useEffect(
    () => () => {
      dispatch(setIsDiscussionsOpened(false))
      dispatch(setIsSidebarOpened(false))
      closeHistory?.()
    },
    [dispatch, closeHistory],
  )
}

export function useCloseAllLaterals() {
  // use real hooks to correct teardown
  const [, handleOpenDiscussions] = useLayoutStateIsDiscussionsOpened()
  const [, handleOpenSidebar] = useLayoutStateIsSidebarOpened()
  const closeHistory = useHistoryLogGlobalApi()?.close

  const closeAllLaterals = useCallback(() => {
    handleOpenDiscussions(false)
    handleOpenSidebar(false)
    closeHistory?.()
  }, [handleOpenDiscussions, handleOpenSidebar, closeHistory])

  return { closeAllLaterals }
}

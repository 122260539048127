import React, { useEffect, useMemo } from 'react'
import {
  ListMetadataView,
  useListMetadataViewCache,
} from '@fintastic/web/feature/list-metadata-view'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { Box } from '@mui/material'
import { ParsedListId } from '@fintastic/web/util/metrics-and-lists'

export const ListDetails: React.FC<{
  listId: string
  versionId: string
  onLoadingStateChanged: () => void
}> = ({ versionId, listId, onLoadingStateChanged }) => {
  const { isLoading, isError, list, allDimensionsInVersion, isLiveActuals } =
    useListMetadataViewCache({
      versionId,
      listId,
    })

  const label = useMemo(
    () =>
      titleFormatter(ParsedListId.fromString(listId)?.idWithoutPrefix) || '',
    [listId],
  )

  useEffect(() => {
    onLoadingStateChanged?.()
  }, [isLoading, isError, onLoadingStateChanged])

  return (
    <Box width={420}>
      <ListMetadataView.Header label={label} />
      {isLoading && <ListMetadataView.Loader />}
      {!isLoading && isError && <ListMetadataView.Error />}
      {!isLoading && !isError && (
        <ListMetadataView.ScrollContainer>
          <ListMetadataView.Type
            calculated={list?.source === 'calculated'}
            liveActuals={!!isLiveActuals}
          />
          <ListMetadataView.Columns
            title="Columns"
            columns={list?.metrics || []}
            allVersionDimensions={allDimensionsInVersion || []}
            liveActuals={!!isLiveActuals}
          />
        </ListMetadataView.ScrollContainer>
      )}
    </Box>
  )
}

import { QueryClient } from 'react-query'
import { metricsAndListsCacheKeys } from './cache-keys'
import { invalidatePaginatedListColumnsCache } from './invalidatePaginatedListColumnsCache'
import { invalidateV2MetricCache } from '../v2-cache'
import { inActiveTab } from '@fintastic/shared/util/web-api'

// @todo: Accept multiple list Ids
export async function invalidateListCache(
  queryClient: QueryClient,
  versionId: string,
  listId: string,
) {
  const listKey = metricsAndListsCacheKeys.list(versionId, listId)
  const listWithoutDataKey = metricsAndListsCacheKeys.listWithoutData(
    versionId,
    listId,
  )

  inActiveTab(() => {
    Promise.all([
      queryClient.invalidateQueries(listKey.slice(0, listKey.length - 1)),
      queryClient.invalidateQueries(listWithoutDataKey),
    ])
  })

  await Promise.all([
    invalidatePaginatedListColumnsCache(queryClient, versionId, listId),
    invalidateV2MetricCache.invalidateList(queryClient, versionId, listId),
  ])
}

import React, { useMemo } from 'react'
import { SupportedEntry } from '../supported-entry-types'
import { DimensionId, VersionDimension } from '@fintastic/web/util/dimensions'
import {
  StyledDetails,
  StyledHeader,
  StyledHeaderAuthor,
  StyledHeaderIcon,
  StyledHeaderText,
  StyledHeaderTimeAndAction,
  StyledRoot,
} from './Entry.styled'
import { Typography } from '@mui/material'
import { historyLogEntryAdt } from '@fintastic/web/util/history'
import {
  ChangeColumnValueShortDetails,
  ChangeMetricValueShortDetails,
  AddListLineShortDetails,
  DeleteListLineShortDetails,
  DuplicateListLineShortDetails,
} from '../../entry-details'
import { HistoryIcon } from '@fintastic/shared/ui/icons'

export const Entry: React.FC<{
  entry: SupportedEntry
  dimensions: Record<DimensionId, VersionDimension>
  onRequestDirectNavtigation?: (entry: SupportedEntry) => void
}> = ({ entry, dimensions, onRequestDirectNavtigation }) => {
  const requestDirectNavigation = useMemo(() => {
    if (!onRequestDirectNavtigation) {
      return undefined
    }
    return () => onRequestDirectNavtigation(entry)
  }, [entry, onRequestDirectNavtigation])

  const details = useMemo(() => {
    if (entry._type === 'change_column_value') {
      return (
        <Typography variant="body1">
          <ChangeColumnValueShortDetails
            entry={entry}
            dimensions={dimensions}
          />
        </Typography>
      )
    }

    if (entry._type === 'change_metric_value') {
      return (
        <Typography variant="body1">
          <ChangeMetricValueShortDetails
            entry={entry}
            dimensions={dimensions}
          />
        </Typography>
      )
    }

    if (entry._type === 'add_list_line') {
      return (
        <Typography variant="body1">
          <AddListLineShortDetails entry={entry} />
        </Typography>
      )
    }

    if (entry._type === 'delete_list_line') {
      return (
        <Typography variant="body1">
          <DeleteListLineShortDetails entry={entry} />
        </Typography>
      )
    }

    if (entry._type === 'duplicate_list_line') {
      return (
        <Typography variant="body1">
          <DuplicateListLineShortDetails entry={entry} />
        </Typography>
      )
    }

    return null
  }, [dimensions, entry])

  return (
    <StyledRoot
      onClick={requestDirectNavigation}
      clickable={!!requestDirectNavigation}
    >
      <StyledHeader>
        <StyledHeaderIcon>
          <HistoryIcon />
        </StyledHeaderIcon>
        <StyledHeaderText>
          <StyledHeaderTimeAndAction>
            <Typography variant="body2">
              <span>
                {`${historyLogEntryAdt.getTimestampShortTime(
                  entry,
                )} (${historyLogEntryAdt.getTimestampGmtTimeZone(entry)})`}
              </span>
              {' • '}
              {historyLogEntryAdt.getActionLabel(entry)}
            </Typography>
          </StyledHeaderTimeAndAction>
          <StyledHeaderAuthor>
            <Typography variant="body2" color="#757575">
              {entry.user_email}
            </Typography>
          </StyledHeaderAuthor>
        </StyledHeaderText>
      </StyledHeader>

      {details && <StyledDetails>{details}</StyledDetails>}
    </StyledRoot>
  )
}

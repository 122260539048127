import { styled } from '@mui/material'

export const StyledHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 14px;
`

export const StyledTypeIcon = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: #047d34;

  svg {
    width: 24px;
    height: auto;
  }
`

export const StyledName = styled('div')`
  margin-left: 8px;
`

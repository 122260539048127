import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'
import { useMemo } from 'react'
import {
  MetricV2CacheListColumnParams,
  useV2ListColumnsQuery,
} from '@fintastic/web/data-access/metrics-and-lists'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'

export const useNoRowsWithPermittedDims = ({
  listId,
  versionId,
  visibleColumnIds,
}: {
  listId: string
  versionId: string
  visibleColumnIds: string[]
}) => {
  const periodSelection = usePeriodSelectorContext()
  const isPowerUser = useRoleLevelAccess('power_user')

  // use the same logic as in filters to read the same cache
  const mappedColumnIds = useMemo<MetricV2CacheListColumnParams[]>(
    () =>
      visibleColumnIds.map<MetricV2CacheListColumnParams>((metricId) => ({
        listId,
        versionId,
        metricId,
        // No need for real data, just metadata
        pagination: {
          offset: 0,
          limit: 0,
        },
        period: periodSelection,
      })),
    [listId, periodSelection, versionId, visibleColumnIds],
  )

  const listColumnsQuery = useV2ListColumnsQuery(mappedColumnIds, !isPowerUser)

  return useMemo(() => {
    if (isPowerUser) {
      return {
        loading: false,
        matches: false,
      }
    }

    if (listColumnsQuery.isLoading) {
      return {
        loading: true,
      } as const
    }

    const noRowsWithPermittedDimensions = listColumnsQuery.data
      .filter((c) => c?.metadata?.value?.type === 'dimension')
      .every((c) => {
        const dimensionId = c!.metadata.value.dimension_id
        const dimensionMetadata = c!.metadata.dimensions.find(
          (d) => d.id === dimensionId,
        )
        if (!dimensionMetadata) {
          return false
        }
        return (dimensionMetadata.viewable || []).length === 0
      })

    return {
      loading: false,
      matches: noRowsWithPermittedDimensions,
    } as const
  }, [isPowerUser, listColumnsQuery.data, listColumnsQuery.isLoading])
}

import React from 'react'
import { InlineInfoTooltip } from '@fintastic/shared/ui/components'

export const MainSectionTitle: React.FC = () => (
  <span>
    Customize chart
    <InlineInfoTooltip
      tooltip={
        'Chart customizations you make will apply only to you. To update the chart settings for all users, go to the Board menu, select ‘Edit’, and modify the chart widget settings'
      }
    />
  </span>
)

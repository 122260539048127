import { SupportedEntry } from './supported-entry-types'
import {
  HistoryLogEntryChangeMetricValue,
  HistoryLogEntryChangeColumnValue,
  HistoryLogEntryAddListLine,
  HistoryLogEntryDuplicateListLine,
} from '@fintastic/web/util/history'
import {
  DirectNavigationMetricCellTarget,
  DirectNavigationListColumnCellTarget,
  DirectNavigationListRowsTarget,
  DirectNavigationTarget,
} from '@fintastic/web/feature/direct-navigation'
import { Maybe } from '@fintastic/shared/util/types'

const newListRowMapper = (
  entry: HistoryLogEntryAddListLine | HistoryLogEntryDuplicateListLine,
): DirectNavigationListRowsTarget => ({
  type: 'listRows',
  coordinates: {
    versionId: entry.parent_entity_id,
    listId: entry.entity_id,
    rowDimensionValueId: entry.dim_value_ids[0],
  },
  options: {
    highlight: true,
    select: true,
  },
})

const mappers = {
  change_metric_value: (
    entry: HistoryLogEntryChangeMetricValue,
  ): DirectNavigationMetricCellTarget => ({
    type: 'metricCell',
    coordinates: {
      versionId: entry.parent_entity_id,
      metricId: entry.entity_id,
      dimensions: entry.dim_ids.map((dimId, dimIndex) => [
        dimId,
        entry.dim_value_ids[dimIndex],
      ]),
    },
    options: {
      highlight: true,
      select: true,
    },
  }),
  change_column_value: (
    entry: HistoryLogEntryChangeColumnValue,
  ): DirectNavigationListColumnCellTarget => ({
    type: 'listColumnCell',
    coordinates: {
      versionId: entry.parent_entity_id,
      listId: entry.entity_id,
      columnId: entry.target_entity_id,
      dimensions: entry.dim_ids.map((dimId, dimIndex) => [
        dimId,
        entry.dim_value_ids[dimIndex],
      ]),
    },
    options: {
      highlight: true,
      select: true,
    },
  }),
  add_list_line: newListRowMapper,
  duplicate_list_line: newListRowMapper,
}

export const entrySupportsDirectNavigation = (entry: SupportedEntry): boolean =>
  mappers[entry._type as keyof typeof mappers] !== undefined

export const mapEntryToDirectNavigationTarget = (
  entry: SupportedEntry,
): Maybe<DirectNavigationTarget> =>
  // Cast to any is easier here.
  mappers[entry._type as keyof typeof mappers]?.(entry as any)

import { Box, Button, styled } from '@mui/material'

export const StyledBoardTitleBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  justify-content: flex-start;
  margin: 0 0 20px auto;
  padding: 4px 0 0 0;
`
export const StyledTitleContextMenuButton = styled(Button)`
  padding: 0;
  position: relative;

  width: 40px;
  min-width: 40px;
  margin-top: 4px;
  z-index: 2;

  &.Mui-disabled {
    opacity: 0.3;
  }

  &.design-menu {
    transform: translateX(20px);
  }
`
export const StyledBoardStickyTitleBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 20px auto;
  padding: 4px 0 0 0;
  background: rgb(252, 250, 248);
  position: sticky;
  top: 0;
  z-index: 10;
  &:before {
    display: block;
    content: '';
    position: absolute;
    background: rgb(252, 250, 248);
    top: -10px;
    bottom: -10px;
    left: -20px;
    right: -30px;
  }
`

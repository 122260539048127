import { QueryClient } from 'react-query'
import { metricsAndListsCacheKeys } from '@fintastic/web/data-access/metrics-and-lists'
import { inActiveTab } from '@fintastic/shared/util/web-api'

export const invalidatePaginatedListColumnsCache = (
  qc: QueryClient,
  versionId: string,
  listId: string,
) => {
  const paginatedListData = metricsAndListsCacheKeys.paginatedListColumns(
    versionId,
    listId,
    null,
    null,
    0,
    0,
  )
  inActiveTab(() => {
    qc.invalidateQueries(paginatedListData.slice(0, 4))
  })
}

import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { keyBy, pick } from 'lodash'
import {
  useLoadVersion,
  useLoadVersionEntities,
} from '@fintastic/web/data-access/versions'
import {
  HistoryLogFilters,
  HistoryLogGlobalApi,
  useHistoryLogGlobalApi,
  useHistoryLogParamsState,
  useLoadHistoryLog,
} from '@fintastic/web/data-access/history'
import { useLocalFiltersAndOrderingParamsState } from '../filters-and-ordering'
import { SupportedEntry, supportedEntryTypes } from './supported-entry-types'
import {
  StyledFilters,
  StyledHeader,
  StyledList,
  StyledPagination,
  StyledRoot,
} from './EntityLevelHistory.styled'
import { Header } from './Header'
import { FiltersAndOrdering } from './FiltersAndOrdering'
import { EntriesList } from './EntriesList'
import { Pagination } from '../pagination'
import {
  ErrorStatus,
  InitialLoadingStatus,
  NoResultsStatus,
} from '../status-indication'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { CollapsibleContainer } from '@fintastic/shared/ui/components'
import { Maybe } from '@fintastic/shared/util/types'
import {
  idLooksLikeList,
  idLooksLikeMetric,
} from '@fintastic/web/util/metrics-and-lists'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { resolveRelevantActionsForEntityLevel } from '@fintastic/web/util/history'
import { useLocation } from '@fintastic/shared/util/memory-router'

export const EntityLevelHistory: React.FC = () => {
  const globalApi = useHistoryLogGlobalApi()
  const globalState =
    globalApi?.state.level === 'entity' ? globalApi.state : null

  if (!globalState) {
    return null
  }

  return (
    <Content
      key={`${globalState.versionId}-${globalState.entityId}`}
      close={globalApi!.close}
      globalState={globalState}
    />
  )
}

const Content: React.FC<{
  globalState: Extract<HistoryLogGlobalApi['state'], { level: 'entity' }>
  close: () => void
}> = ({ globalState, close }) => {
  const path = useLocation().path
  const pathRef = useRef(path)
  useEffect(() => {
    if (pathRef.current !== path) {
      close()
    }
  }, [close, path])

  const versionId = globalState ? globalState.versionId : null
  const entityType: Maybe<'list' | 'metric'> = useMemo(() => {
    if (!globalState) {
      return null
    }

    if (idLooksLikeList(globalState.entityId)) {
      return 'list'
    }

    if (idLooksLikeMetric(globalState.entityId)) {
      return 'metric'
    }

    return null
  }, [globalState])
  const relevantActionTypes = useMemo(
    () => (entityType ? resolveRelevantActionsForEntityLevel(entityType) : []),
    [entityType],
  )

  const entitiesQuery = useLoadVersionEntities(versionId, versionId !== null)
  const versionQuery = useLoadVersion(versionId)

  const { params, changeParams, changeCurrentPage, reset, defaultParams } =
    useHistoryLogParamsState(
      useMemo(() => {
        if (!globalState) {
          return {}
        }

        const override: Parameters<typeof useHistoryLogParamsState>[0] = {
          paginationLimit: 30,
          filter: (baseFilter) => {
            const result: Pick<
              HistoryLogFilters,
              | 'entity_id'
              | 'parent_entity_type'
              | 'parent_entity_id'
              | 'action'
              | 'entity_type'
            > = {
              parent_entity_id: [globalState.versionId],
              parent_entity_type: ['version' as const],
              entity_id: [globalState.entityId],
            }

            if (entityType) {
              result.entity_type = [entityType]
            }

            if (relevantActionTypes.length > 0) {
              const allowedFromCurrentlySelected = (
                baseFilter.action || []
              ).filter((a) => relevantActionTypes.includes(a))
              result.action =
                allowedFromCurrentlySelected.length > 0
                  ? allowedFromCurrentlySelected
                  : relevantActionTypes
            }

            return result
          },
        }

        return override
      }, [entityType, globalState, relevantActionTypes]),
      `entity-level/${globalState?.versionId}/${globalState?.entityId}`,
    )

  const localFiltersAndOrderingStateApi = useLocalFiltersAndOrderingParamsState(
    useMemo(() => pick(params, ['filter', 'desc']), [params]),
    useMemo(() => pick(defaultParams, ['filter', 'desc']), [defaultParams]),
  )
  const applyLocalFiltersAndOrdering = useCallback(() => {
    changeParams(localFiltersAndOrderingStateApi.localParams)
  }, [changeParams, localFiltersAndOrderingStateApi.localParams])

  const logQuery = useLoadHistoryLog(params, 'entity', versionId !== null)
  const entries = useMemo(() => {
    if (!logQuery.data) {
      return []
    }
    return logQuery.data.result.filter(({ _type }) =>
      supportedEntryTypes.includes(_type),
    ) as SupportedEntry[]
  }, [logQuery.data])

  const dimensions = useMemo(
    () => keyBy(entitiesQuery.data?.dimensions || [], 'id'),
    [entitiesQuery.data?.dimensions],
  )
  const entityLabel = useMemo(() => {
    if (!globalState) {
      return ''
    }

    if (idLooksLikeList(globalState.entityId)) {
      return titleFormatter(
        entitiesQuery.data?.lists.find((l) => l.id === globalState.entityId)
          ?.label || '',
      )
    }

    if (idLooksLikeMetric(globalState.entityId)) {
      return titleFormatter(
        entitiesQuery.data?.metrics.find((m) => m.id === globalState.entityId)
          ?.label || '',
      )
    }

    return ''
  }, [entitiesQuery.data?.lists, entitiesQuery.data?.metrics, globalState])

  const versionLabel = useMemo(() => {
    if (!globalState) {
      return ''
    }

    return versionQuery.data?.name || ''
  }, [globalState, versionQuery.data?.name])

  const label = useMemo(() => {
    if (versionLabel === '' && entityLabel === '') {
      return ''
    }

    if (versionLabel !== '' && entityLabel !== '') {
      return `${versionLabel} / ${entityLabel}`
    }

    return `${versionLabel}${entityLabel}`
  }, [entityLabel, versionLabel])

  const isBusy =
    logQuery.isFetching ||
    logQuery.isLoading ||
    entitiesQuery.isLoading ||
    versionQuery.isLoading
  const isInitialLoading =
    logQuery.isLoading || entitiesQuery.isLoading || versionQuery.isLoading
  const isError =
    !isInitialLoading &&
    (logQuery.isError || entitiesQuery.isError || versionQuery.isError)

  const listContainerRef = useRef<Maybe<HTMLDivElement>>(null)
  useEffect(() => {
    listContainerRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [params.pagination.offset])

  return (
    <StyledRoot>
      <StyledHeader>
        <Header onClose={close} label={label} />
      </StyledHeader>
      <FintasticThemeProvider applyLegacyTheme={false}>
        <StyledFilters>
          <CollapsibleContainer
            closeTitleText="Hide filters"
            openTitleText="Show filters"
            counter={localFiltersAndOrderingStateApi.diffsFromDefaultsCounter}
            initialOpen={
              localFiltersAndOrderingStateApi.diffsFromDefaultsCounter > 0
            }
          >
            <FiltersAndOrdering
              localParamsStateApi={localFiltersAndOrderingStateApi}
              isBusy={isBusy}
              resetToDefaults={reset}
              apply={applyLocalFiltersAndOrdering}
              relevantActionTypes={relevantActionTypes}
            />
          </CollapsibleContainer>
        </StyledFilters>
        <StyledList ref={listContainerRef}>
          {isInitialLoading && <InitialLoadingStatus />}
          {isError && <ErrorStatus message={'Cannot load hisory'} />}
          {!isInitialLoading && !isError && (
            <>
              {entries.length === 0 ? (
                <NoResultsStatus />
              ) : (
                <EntriesList
                  entries={entries}
                  isBusy={isBusy}
                  dimensions={dimensions}
                />
              )}
            </>
          )}
        </StyledList>
        {entries.length > 0 && (
          <StyledPagination>
            <Pagination
              goToPage={changeCurrentPage}
              entriesPerPage={params.pagination.limit}
              offset={params.pagination.offset}
              totalEntries={logQuery.data?.total || 0}
              isBusy={isBusy}
              siblingCount={0}
              boundaryCount={1}
            />
          </StyledPagination>
        )}
      </FintasticThemeProvider>
    </StyledRoot>
  )
}

import { axios } from '@fintastic/web/data-access/service-axios'
import {
  CompactMetricOrList,
  List,
  ListWithoutData,
  Metric,
  MetricOrListSource,
  MetricWithoutData,
} from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import { metricToSavePayload, SaveMetricPayload } from './metrics-api'
import { EditableList } from '../types'
import { mapFormula } from '@fintastic/web/util/formulas'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { mapPeriodSelectionToParams } from './helpers/map-period-selection-to-params'
import {
  DimensionId,
  DimensionValueId,
  ParsedDimensionValueId,
  RangeDimensionValueId,
} from '@fintastic/web/util/dimensions'
import { FilterListAPIPayload } from '@fintastic/web/util/filters'
import Qs from 'qs'
import { pick } from 'lodash'

const endpoints = {
  lists: (versionId: string) => `/planning/api/v2/versions/${versionId}/lists/`,
  list: (versionId: string, listId: string) =>
    `${endpoints.lists(versionId)}${listId}/`,
  listRows: (versionId: string, listId: string) =>
    `${endpoints.lists(versionId)}${listId}/rows/`,
  listColumns: (versionId: string, listId: string) =>
    `${endpoints.list(versionId, listId)}metrics/`,
} as const

export function getLists(versionId: string, showHidden: boolean) {
  return axios.get<{
    result: CompactMetricOrList[]
  }>(endpoints.lists(versionId), {
    params: {
      show_hidden: encodeURIComponent(showHidden),
    },
  })
}

const sortListColumns = (
  a: MetricWithoutData | Metric,
  b: MetricWithoutData | Metric,
) => a.metadata.display_config.ordering - b.metadata.display_config.ordering

export function getList<
  TIncludeData extends boolean | undefined = undefined,
  TResult = TIncludeData extends false ? ListWithoutData : List,
>(
  versionId: string,
  listId: string,
  periodSelection?: PeriodSelection,
  includeData?: TIncludeData,
) {
  return axios.get<TResult>(endpoints.list(versionId, listId), {
    params: {
      with_data: includeData,
      ...mapPeriodSelectionToParams(periodSelection),
    },
    paramsSerializer: (p) => Qs.stringify(p, { arrayFormat: 'repeat' }),
    transformResponse: (rawJson: string) => {
      const result: ListWithoutData = JSON.parse(rawJson)

      return {
        ...result,
        // @todo ask to move it to BE or move it on UI to ADTs
        metrics: [...(result?.metrics || [])].sort(sortListColumns),
      }
    },
  })
}

export const getListForEditMode = async (
  versionId: string,
  listId: string,
): Promise<List> => {
  // @todo make it in one request

  const listMetadata = await axios.get<List>(
    endpoints.list(versionId, listId),
    {
      params: {
        with_data: false,
      },
    },
  )

  const firstLineOfData = await axios.post<{ result: Metric[]; total: number }>(
    endpoints.listColumns(versionId, listId),
    {},
    {
      paramsSerializer: (p: any) => Qs.stringify(p, { arrayFormat: 'repeat' }),
      params: {
        metric_id: listMetadata.data.metrics.map(({ id }) => id),
        with_data: true,
        limit: 1,
        offset: 0,
      },
    },
  )

  return {
    ...listMetadata.data,
    metrics: firstLineOfData.data.result.sort(sortListColumns),
  }
}

type SaveListPayload = {
  name: string
  metrics: Array<
    | Omit<SaveMetricPayload, 'data'>
    | {
        name: string
        value: {
          roll_up_function: SaveMetricPayload['value']['roll_up_function']
          category_agg: SaveMetricPayload['value']['category_agg']
          type: SaveMetricPayload['value']['type']
        }
      }
  >
  formula: Maybe<string>
  description: string
  source: MetricOrListSource
}

function listToSavePayload(list: EditableList): SaveListPayload {
  const listColumnsLabelToIdMap: Record<string, string> = Object.fromEntries(
    list.metrics.map((m) => [
      `${list.id.slice('Table.'.length)}.${m.label}`,
      m.newColumn ? `${list.id}.${m.label}` : m.id,
    ]),
  )

  return {
    name: list.label,
    description: '',
    formula: list.metadata.formula,
    source: list.source,
    metrics:
      list.source === 'calculated'
        ? list.metrics
            .filter((m) => {
              const type = m.metadata.value.type
              return (
                type !== 'datetime' && type !== 'string' && type !== 'dimension'
              )
            })
            .map((m) => {
              const metricPayload = {
                name: m.label,
                value: pick(m.metadata.value, [
                  'roll_up_function',
                  'category_agg',
                  'type',
                ]),
              }
              return metricPayload
            })
        : list.metrics.map((metric, index) => {
            const { metadata, ...restMetricProps } = metric
            const { dimensions, ...restMetadataProps } = metadata
            const metricPayload = metricToSavePayload(
              {
                ...restMetricProps,
                metadata: {
                  ...restMetadataProps,
                  dimensions: dimensions.filter(
                    (dim) => dim.id !== list.row_dim,
                  ),
                },
              },
              list.row_dim,
              true,
            )
            if (metric.newColumn) {
              metricPayload.display_settings.is_visible =
                metric.source !== 'calculated'
            }
            if (metric.metadata.value.type === 'dimension') {
              metricPayload.dimensions = []
            }
            if (
              metric.source === 'calculated' &&
              metricPayload.formula !== null
            ) {
              metricPayload.formula = mapFormula(
                metricPayload.formula,
                listColumnsLabelToIdMap,
              )
            }
            // @todo ask to move it to BE or move it on UI to ADTs
            metricPayload.display_settings.ordering = index
            return metricPayload
          }),
  }
}

export function createList(versionId: string, list: EditableList) {
  return axios.post(endpoints.lists(versionId), listToSavePayload(list))
}

export type ColumnMissingNoopAggregationErrorPayload = {
  detail: [
    {
      loc: string[]
      msg: string
      type: 'value_error.missingnoopaggregation'
    },
  ]
}

export type ListErrorResponseWithDetailResponse = {
  detail?: string
}

export function updateList(versionId: string, list: EditableList) {
  return axios.put(endpoints.list(versionId, list.id), listToSavePayload(list))
}

type GetListColumnsQueryParams = Partial<{
  metric_id: string[]
  time_dim_id: DimensionId
  from_dim_val: DimensionValueId
  to_dim_val: DimensionValueId
  aggregation: DimensionValueId
  with_data: boolean
  match_row_dim_value_id: DimensionValueId[]
  periods: DimensionValueId[]
}>

export function getListColumns<
  TIncludeData extends boolean | undefined = undefined,
  TResult = TIncludeData extends false ? MetricWithoutData[] : Metric[],
>(
  versionId: string,
  listId: string,
  metricIds: string[],
  periodSelection?: Maybe<PeriodSelection>,
  includeData?: TIncludeData,
  rowIds?: string[],
  filters?: FilterListAPIPayload,
  pagination?: {
    offset: number
    limit: number
  },
) {
  return axios.post<{
    result: TResult
    total: number
    limit: number
    offset: number
  }>(endpoints.listColumns(versionId, listId), filters ?? {}, {
    params: {
      metric_id: metricIds,
      with_data: includeData,
      match_row_dim_value_id: rowIds,
      ...mapPeriodSelectionToParams(periodSelection),
      ...(pagination ?? {}),
    } as GetListColumnsQueryParams,
    paramsSerializer: (params: GetListColumnsQueryParams) => {
      const urlParams = new URLSearchParams()

      if (pagination) {
        urlParams.append('limit', `${pagination.limit}`)
        urlParams.append('offset', `${pagination.offset}`)
      }
      if (params.from_dim_val !== undefined) {
        urlParams.append('from_dim_val', params.from_dim_val)
      }
      if (params.to_dim_val !== undefined) {
        urlParams.append('to_dim_val', `${params.to_dim_val}`)
      }
      if (params.time_dim_id !== undefined) {
        urlParams.append('time_dim_id', `${params.time_dim_id}`)
      }
      if (params.periods !== undefined && params.periods?.length) {
        params.periods.forEach((periodId) => {
          urlParams.append('periods', periodId)
        })
      }
      if (params.aggregation !== undefined) {
        urlParams.append('aggregation', params.aggregation)
      }
      if (params.with_data !== undefined) {
        urlParams.append('with_data', `${params.with_data ? 1 : 0}`)
      }
      if (params.metric_id !== undefined && params.metric_id.length > 0) {
        params.metric_id.forEach((metricId) => {
          urlParams.append('metric_id', metricId)
        })
      }
      if (
        params.match_row_dim_value_id !== undefined &&
        params.match_row_dim_value_id.length > 0
      ) {
        params.match_row_dim_value_id.forEach((rowId) => {
          urlParams.append('match_row_dim_value_id', rowId)
        })
      }
      return urlParams.toString()
    },
    transformResponse: (rawJson: string) => {
      let response
      try {
        response = JSON.parse(rawJson)
      } catch (e) {
        console.error(e)
      }

      if (!response?.result) {
        return response
      }

      return {
        // @todo ask to move it to BE or move it on UI to ADTs
        result: [...response.result].sort(sortListColumns),
        total: response.total,
        limit: response.limit,
        offset: response.offset,
      }
    },
  })
}

export const addListRows = (
  versionId: string,
  listId: string,
  numberOfRows: number,
) =>
  axios.post<{ stream_event_id: string }>(
    endpoints.listRows(versionId, listId),
    {
      row_count: numberOfRows,
    },
  )

export type DimensionPermissionsForLists = {
  unrestricted_dim_ids: DimensionId[]
  allowed_dim_value_ids: DimensionValueId[]
  lists: Array<{
    list_id: string
    allowed_dim_value_ids: Maybe<DimensionValueId[]>
    non_masking_dim_value_ids: Maybe<DimensionValueId[] | '*'>
  }>
}

export const getDimensionsPermissionsForLists = (versionId: string) =>
  axios.get<DimensionPermissionsForLists>(
    `/planning/api/v2/versions/${versionId}/dimension_values/available/`,
  )

export const deleteListRows = (
  versionId: string,
  listId: string,
  rowDimValueIds: RangeDimensionValueId[],
) =>
  axios.post<{
    stream_event_id: string
  }>(`/planning/api/v2/versions/${versionId}/lists/${listId}/delete_rows/`, {
    row_dim_values: rowDimValueIds.flatMap((dimValueId) => {
      const parsedDimValueId = ParsedDimensionValueId.fromString(dimValueId)
      if (!parsedDimValueId) {
        return []
      }
      const rowIndex = parseInt(parsedDimValueId.valueIdWithoutPrefix)
      if (Number.isNaN(rowIndex)) {
        return []
      }
      return rowIndex
    }),
  })

export const duplicateListRows = (
  versionId: string,
  listId: string,
  rowDimValueIds: RangeDimensionValueId[],
) =>
  axios.post<{
    stream_event_id: string
  }>(`/planning/api/v2/versions/${versionId}/lists/${listId}/duplicate_rows/`, {
    row_dim_values: rowDimValueIds.flatMap((dimValueId) => {
      const parsedDimValueId = ParsedDimensionValueId.fromString(dimValueId)
      if (!parsedDimValueId) {
        return []
      }
      const rowIndex = parseInt(parsedDimValueId.valueIdWithoutPrefix)
      if (Number.isNaN(rowIndex)) {
        return []
      }
      return rowIndex
    }),
  })

export type ListPermissions = {
  can_add_rows: boolean
  can_delete_rows: boolean
  can_duplicate_rows: boolean
}

/**
 * Fails with 403 error if current user role is power_user.
 * For power use all the pemissions are allowed.
 */
export const getListPermissions = (versionId: string, listId: string) =>
  axios.get<ListPermissions>(
    `/planning/api/v2/versions/${versionId}/lists/${listId}/permissions/`,
  )

type UnsignedInteger = number
export type DeletedListRowsRanges = Array<
  [fromIndex: UnsignedInteger, toIndex: UnsignedInteger]
>

export const getDeletedListRows = (versionId: string, listId: string) =>
  axios.get<{
    deleted_row_values_ranges: DeletedListRowsRanges
  }>(
    `/planning/api/v2/versions/${versionId}/lists/${listId}/deleted_row_values/`,
  )

import { Query, QueryClient, QueryKey } from 'react-query'
import { metricsAndListsCacheKeys } from '@fintastic/web/data-access/metrics-and-lists'
import { inActiveTab } from '@fintastic/shared/util/web-api'

type Filters = {
  versionId: string
  listId?: string
  columnId?: string[]
}

const predicateWithFilters =
  (filters: Filters) => (query: Query<unknown, unknown, unknown, QueryKey>) => {
    if (query.isFetching()) {
      return false
    }

    const exampleColumnQueryKey = metricsAndListsCacheKeys.listColumn()

    const queryKey = query.queryKey
    if (
      queryKey.length !== exampleColumnQueryKey.length ||
      queryKey[0] !== exampleColumnQueryKey[0] ||
      queryKey[1] !== filters.versionId ||
      queryKey[2] !== exampleColumnQueryKey[2] ||
      (filters.listId && !`${queryKey[3]}`.startsWith(`${filters.listId}.`))
    ) {
      return false
    }

    if (
      filters.columnId &&
      filters.columnId.length &&
      !filters.columnId.includes(`${queryKey[3]}`)
    ) {
      return false
    }

    return true
  }

const getListColumnsLoaderPartialQueryKey = (filters: Filters) => {
  const columnsLoaderKey = metricsAndListsCacheKeys.listColumnsLoader(
    filters.versionId,
    filters.listId,
  )

  return filters.listId
    ? columnsLoaderKey.slice(0, 4)
    : columnsLoaderKey.slice(0, 3)
}

export async function invalidateListColumnsCache(
  queryClient: QueryClient,
  filters: Filters,
  triggerImmediateRefetch = true,
) {
  inActiveTab(async () => {
    await queryClient.invalidateQueries({
      predicate: predicateWithFilters(filters),
    })

    if (triggerImmediateRefetch) {
      await queryClient.invalidateQueries(
        getListColumnsLoaderPartialQueryKey(filters),
      )
    }
  })
}

export function removeListColumnsCache(
  queryClient: QueryClient,
  filters: Filters,
) {
  queryClient.removeQueries({
    predicate: predicateWithFilters(filters),
  })

  queryClient.removeQueries(getListColumnsLoaderPartialQueryKey(filters))
}

export async function resetListColumnsCache(
  queryClient: QueryClient,
  filters: Filters,
) {
  await queryClient.resetQueries({
    predicate: predicateWithFilters(filters),
  })

  await queryClient.resetQueries(getListColumnsLoaderPartialQueryKey(filters))
}

import React from 'react'
import { ColumnsList } from './ColumnsList'
import {
  StyledColumns,
  StyledColumnsHeader,
  StyledFooter,
  StyledRoot,
} from './ColumnsSettings.styled'
import { Box, Typography } from '@mui/material'
import { useColumnsSettingsContext } from './columns-settings-context'
import { ReadonlyAlert } from '../../../shared/ReadonlyAlert'

export const ColumnsSettings: React.FC = () => {
  const { columns, calculated } = useColumnsSettingsContext()

  return (
    <StyledRoot>
      {columns.length > 0 && (
        <StyledColumns>
          <ColumnsList
            bottomContent={
              <StyledColumnsHeader>
                <Typography variant="overline" color="text.secondary">
                  Default visibility
                </Typography>
              </StyledColumnsHeader>
            }
          />
        </StyledColumns>
      )}
      <StyledFooter>
        {calculated && (
          <Box py={1}>
            <ReadonlyAlert
              title={'Defined by formula'}
              description="Edit formula to add or remove new columns"
            />
          </Box>
        )}
      </StyledFooter>
    </StyledRoot>
  )
}

import { Maybe } from '@fintastic/shared/util/types'
import { getDeletedListRows } from '../api/lists-api'
import { QueryClient } from 'react-query'
import { metricsAndListsCacheKeys } from '../cache'

type Params = {
  versionId: string
  listId: string
}

const makeQueryFunction = (params: Maybe<Params>) => async () => {
  if (!params) {
    throw new Error('params are not defined')
  }
  const response = await getDeletedListRows(params.versionId, params.listId)
  return response.data.deleted_row_values_ranges
}

export const loadManuallyDeletedListRows = async (
  qc: QueryClient,
  params: Params,
) =>
  qc.fetchQuery(
    metricsAndListsCacheKeys.listDeletedRows(params),
    makeQueryFunction(params),
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: Infinity,
    },
  )

import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'
import { useEffect } from 'react'

export const useHistoryAutoClose = () => {
  const historyApi = useHistoryLogGlobalApi()

  useEffect(() => {
    if (historyApi?.state.level !== null) {
      historyApi?.close()
    }
  }, [historyApi, historyApi?.close])
}

import { useCallback, useMemo, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { Task, Subtask, UpdateTaskFunction } from './types'
import { deriveTaskStatus } from './utils'
import { cloneDeep } from 'lodash'

export type DistrubutedMonoTaskStateApi<
  TParams = void,
  TError extends Error = Error,
> = ReturnType<typeof useDistrubutedMonoTaskState<TParams, TError>>

export const useDistrubutedMonoTaskState = <
  TParams = void,
  TError extends Error = Error,
>() => {
  const [task, setTask] = useState<Maybe<Task<TParams, TError>>>(null)

  const init = useCallback((id: string, params: TParams) => {
    setTask({
      id,
      params,
      subtasks: [],
      lastUpdate: new Date(),
    })
  }, [])

  const update = useCallback<UpdateTaskFunction<TError>>((subtask) => {
    setTask((task) => {
      if (task === null) {
        // Task is not initialized.
        return task
      }

      const currentTaskStatus = deriveTaskStatus(task)
      if (currentTaskStatus === 'completed' || currentTaskStatus === 'failed') {
        // Task already stopped.
        return task
      }

      const newTask = cloneDeep(task)
      const newSubtask = cloneDeep(subtask) as Subtask<TError>
      const subtaskIndex = newTask.subtasks.findIndex(
        (s) => s.id === subtask.id,
      )
      if (subtaskIndex === -1) {
        newTask.subtasks.push(newSubtask)
      } else {
        newTask.subtasks.splice(subtaskIndex, 1, newSubtask)
      }
      newTask.lastUpdate = new Date()

      return newTask
    })
  }, [])

  const state = useMemo(
    () =>
      task === null
        ? null
        : {
            task,
            status: deriveTaskStatus(task),
          },
    [task],
  )

  return useMemo(
    () =>
      ({
        state,
        init,
        update,
      } as const),
    [init, state, update],
  )
}

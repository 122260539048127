import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Board as BoardType,
  BoardContextMenuBuilderForSidebarBoard,
  BoardContextMenuBuilderForSidebarFolder,
  BoardFolder,
  BoardsFoldersTreeNode,
} from '../../types'
import { PopoverOrigin, Tooltip } from '@mui/material'
import {
  StyledFolderButton,
  StyledFolderContent,
  StyledFolderContentContainer,
  StyledFolderIcon,
  StyledFolderRoot,
  StyledFolderTitle,
} from './Folder.styled'
import { Board } from './Board'
import { useBoardFolderExpandingState } from '../../ducks/boards-ui/hooks'
import { FolderCollapsedIcon } from './FolderCollapsedIcon'
import { FolderExpandedIcon } from './FolderExpandedIcon'
import { Maybe } from '@fintastic/shared/util/types'
import {
  StyledBoardContextMenuButton,
  StyledBoardContextMenuButtonCont,
} from './Board.styled'
import {
  ContextMenu,
  ContextMenuIcon,
  useContextMenuState,
} from '@fintastic/shared/ui/context-menu-framework'
import { useRoleLevelAccess } from '@fintastic/web/data-access/iam'

export type FolderProps = {
  folder: BoardFolder
  childNodes: BoardsFoldersTreeNode[]
  level: number
  activeBoard: Maybe<BoardType['id']>
  boardContextMenuBuilder: BoardContextMenuBuilderForSidebarBoard
  contextMenuBuilder: BoardContextMenuBuilderForSidebarFolder
}
export const Folder: React.FC<FolderProps> = (props) => {
  const {
    childNodes,
    folder,
    level,
    activeBoard,
    boardContextMenuBuilder,
    contextMenuBuilder,
  } = props
  const { isExpanded, toggle } = useBoardFolderExpandingState(folder.id)
  const mainButtonRef = useRef<Maybe<HTMLButtonElement>>(null)
  const titleRef = useRef<Maybe<HTMLElement>>(null)
  const [isTitleTooltipEnabled, setIsTitleTooltipEnabled] = useState(false)
  useEffect(() => {
    if (!titleRef.current) {
      return
    }
    setIsTitleTooltipEnabled(
      titleRef.current.offsetWidth < titleRef.current.scrollWidth,
    )
  }, [folder.name])
  const contextMenuState = useContextMenuState()
  const contextMenu = useMemo(
    () => contextMenuBuilder(folder.id),
    [folder.id, contextMenuBuilder],
  )
  const contextMenuOrigin = useMemo(() => {
    const anchorOrigin: PopoverOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    }
    const transformOrigin: PopoverOrigin = {
      vertical: 'top',
      horizontal: 32,
    }
    return {
      anchorOrigin,
      transformOrigin,
    }
  }, [])
  const userAllowedToManageBoards = !!useRoleLevelAccess([
    'power_user',
    'admin',
    'modeler',
  ])
  const contextMenuEnabled = contextMenu.length > 0 && userAllowedToManageBoards
  const handleOpenContextMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      if (mainButtonRef.current === null) {
        return
      }
      contextMenuState.handleClickOnAnchor({ target: mainButtonRef.current })
    },
    [contextMenuState],
  )

  const containsActiveBoard = useMemo(
    () => childNodes.some((node) => node.data.id === activeBoard),
    [childNodes, activeBoard],
  )

  const isArchive = folder.id === '_archive'

  return (
    <>
      <StyledFolderRoot
        data-testid={`boards-sidebar-folder-buttons-${folder.id}`}
        data-row-type={'boards-tree-entry-folder'}
        data-folder-name={folder.name}
        data-folder-id={folder.id}
        data-folder-open={isExpanded ? true : undefined}
        data-context-menu-open={contextMenuState.isOpen ? true : undefined}
      >
        <StyledFolderButton
          data-testid={`boards-sidebar-folder-button-${folder.id}`}
          onClick={toggle}
          level={level}
          ref={mainButtonRef}
        >
          <StyledFolderIcon active={containsActiveBoard}>
            {isExpanded ? <FolderExpandedIcon /> : <FolderCollapsedIcon />}
          </StyledFolderIcon>
          {isTitleTooltipEnabled ? (
            <Tooltip title={folder.name} placement="top" arrow>
              <StyledFolderTitle ref={titleRef} data-testid={'folder-label'}>
                {folder.name}
              </StyledFolderTitle>
            </Tooltip>
          ) : (
            <StyledFolderTitle
              ref={titleRef}
              active={containsActiveBoard}
              data-testid={'folder-label'}
            >
              {folder.name}
            </StyledFolderTitle>
          )}
        </StyledFolderButton>
        {contextMenuEnabled && (
          <>
            <StyledBoardContextMenuButtonCont>
              <StyledBoardContextMenuButton
                isVisible={contextMenuState.isOpen}
                onClick={handleOpenContextMenu}
                disableTouchRipple={true}
                className="boards-context-menu-button"
                data-testid={`boards-sidebar-folder-context-menu-button-${folder.id}`}
              >
                <ContextMenuIcon />
              </StyledBoardContextMenuButton>
            </StyledBoardContextMenuButtonCont>
            {contextMenuState.isOpen && (
              <ContextMenu
                menuItems={contextMenu}
                open={true}
                anchorEl={contextMenuState.anchorElement}
                onClose={contextMenuState.handleClose}
                data-testid={`boards-sidebar-folder-context-menu-${folder.id}`}
                {...contextMenuOrigin}
              />
            )}
          </>
        )}
      </StyledFolderRoot>
      <StyledFolderContent
        data-testid={`boards-sidebar-folder-content-${folder.id}`}
      >
        {isExpanded && (
          <StyledFolderContentContainer
            data-testid={'boards-sidebar-folder-content'}
          >
            {childNodes.map((node) =>
              node.type === 'folder' ? (
                <Folder
                  key={node.data.id}
                  level={level + 1}
                  folder={node.data}
                  childNodes={node.children}
                  activeBoard={activeBoard}
                  boardContextMenuBuilder={boardContextMenuBuilder}
                  contextMenuBuilder={contextMenuBuilder}
                />
              ) : (
                <Board
                  level={level + 1}
                  key={node.data.id}
                  board={node.data}
                  isActive={activeBoard === node.data.id}
                  isArchive={isArchive}
                  contextMenuBuilder={boardContextMenuBuilder}
                  folderId={folder.id}
                />
              ),
            )}
          </StyledFolderContentContainer>
        )}
      </StyledFolderContent>
    </>
  )
}

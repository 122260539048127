import { AgGridCellRendererProps } from './types'

export const BooleanValueFilterCellRenderer: React.FC<
  AgGridCellRendererProps
> = ({ value }) => {
  if (value === '(Select All)') {
    return <span>(Select All)</span>
  }

  if (value === '-1' || value === null) {
    return <span>(Blanks)</span>
  }

  if (value === '1') {
    return <span>True</span>
  }

  if (value === '0') {
    return <span>False</span>
  }

  return <span>{value}</span>
}

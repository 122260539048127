import { FormField } from '@fintastic/shared/ui/form-framework'
import React, { memo, useCallback } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { type ChartSettings, MetricChartDimensions } from '../../../../types'
import { OnChangeChartSettings } from '../../../../internal-types'
import { MetricChartGrouping } from './sortable-columns'
import { DimensionsLoadingSkeleton } from './DimensionsLoadingSkeleton'

export type MetricChartDimensionsListProps = {
  metricId: string
  loading?: boolean
  version?: Maybe<string>
  initialSettings?: Maybe<ChartSettings>
  chartSettings: Maybe<ChartSettings>
  onChangeChartSettings: OnChangeChartSettings
  hasDifferentDimensions?: boolean
}

export const MetricChartDimensionsList: React.FC<MetricChartDimensionsListProps> =
  memo(
    ({
      metricId,
      loading,
      version,
      chartSettings,
      onChangeChartSettings,
      hasDifferentDimensions,
    }) => {
      const handleSetDimensions = useCallback(
        (dimensions: MetricChartDimensions, initial?: boolean) => {
          onChangeChartSettings({
            dimensions,
          })
        },
        [onChangeChartSettings],
      )

      const showColumnsOrdering = !!metricId && !!version

      if (!showColumnsOrdering) {
        return null
      }

      if (loading) {
        return <DimensionsLoadingSkeleton />
      }

      return (
        <FormField fullWidth={true}>
          <MetricChartGrouping
            disabled={loading}
            currentDimensions={chartSettings?.dimensions || []}
            onColumnsUpdate={handleSetDimensions}
          />
        </FormField>
      )
    },
  )

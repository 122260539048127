import React from 'react'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import { EmptyFolderErrorIcon } from '@fintastic/shared/ui/icons'
import { StyledChartError } from './MetricChartPanels.styled'

export const MetricChartEmpty: React.FC = () => (
  <StyledChartError>
    <ErrorAlert
      title={'Empty chart'}
      message={'Nothing to show'}
      severity={'info'}
      icon={<EmptyFolderErrorIcon />}
    />
  </StyledChartError>
)

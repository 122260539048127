import { useMemo } from 'react'
import type { PeriodSelection } from '@fintastic/web/util/period-selector'
import {
  ChartColumnData,
  ChartRequestParams,
  MetricChartDimensions,
  MultiversionChartData,
} from '../types'
import {
  isChartAggRequestDimensionsEquals,
  isChartAggRequestDimensionVersion,
  isRawChartDataMultiversionError,
  mapChartDimensionsToRequestDimensions,
  useInvalidateMetricChartData,
  useLoadMetricChartDataMultiversion,
} from '@fintastic/web/data-access/metrics-and-lists'
import {
  MetricDataValue,
  MetricMetadata,
} from '@fintastic/web/util/metrics-and-lists'
import { Version } from '@fintastic/web/util/versions'
import {
  extractDimensionEssential,
  getMostSevereMVFlowError,
} from './chart-data-utils'
import { Maybe } from '@fintastic/shared/util/types'
import { DimensionType } from '@fintastic/web/util/dimensions'

export const useChartData = (
  versions: string[],
  metricId: string,
  periodSelection: PeriodSelection,
  dimensions: MetricChartDimensions,
  versionsMetadata: Record<string, Version>,
  enabled = true,
) => {
  const chartRequestDimensions = useMemo(
    () => mapChartDimensionsToRequestDimensions(dimensions),
    [dimensions],
  )

  const { data, isError, isLoading, isFetching } =
    useLoadMetricChartDataMultiversion(
      versions,
      metricId,
      periodSelection,
      chartRequestDimensions,
      enabled,
    )

  const chartData = useMemo<MultiversionChartData>(() => {
    const result: MultiversionChartData = {
      data: [] as ChartColumnData,
      metadata: null,
      usedDimensions: [] as MetricChartDimensions,
      hasDifferentDimensions: false,
      allVersionsFailed: false,
      versionsRequested: versions.length,
      versionsPresent: 0,
    }

    if (
      !enabled ||
      isFetching ||
      isLoading ||
      !data ||
      Object.keys(versionsMetadata).length === 0 ||
      chartRequestDimensions.length === 0
    ) {
      return result
    }

    // if any error thrown on network level
    if (isRawChartDataMultiversionError(data)) {
      result.allVersionsFailed = true
      result.dataMostSevereError = 'UNKNOWN_ERROR'

      if (data.error_code === 403) {
        result.dataMostSevereError = 'VERSION_FORBIDDEN'
        if (data.original_error_details?.code === 'METRIC_FORBIDDEN') {
          result.dataMostSevereError = 'METRIC_FORBIDDEN'
        }
      }

      if (data.error_code === 404) {
        result.dataMostSevereError = 'METRIC_NOT_FOUND'
      }

      if (data.error_code === 422) {
        // may be invalid dimensions
        result.dataMostSevereError = 'TIME_DIM_ABSENT' // 'INVALID_RESOLUTION' is now processed as a normal response
      }

      if (data.error_code >= 500) {
        result.dataMostSevereError = 'INTERNAL_ERROR'
      }
      return result
    }

    const headerRow = (data.labels.x || []).map((labelId) => {
      const mayBeVersion = data?.metadata?.indexes?.x?.[labelId] || labelId
      return (
        versionsMetadata[mayBeVersion]?.name || mayBeVersion || 'Unknown label'
      )
    })

    if (headerRow.length > 0) {
      headerRow.unshift('Labels') // cell 0:0
    }
    const dataRows: Array<MetricDataValue[]> = []
    const processedVersions = new Set<string>()

    const valuesToProcess = data.values || []
    //
    valuesToProcess.forEach((row: Maybe<number>[], rowIdx: number) => {
      const hasData = row.some((v) => typeof v !== 'undefined')
      if (!hasData) {
        return
      }

      const yLabelIds = data.labels.y[rowIdx] //  ['0', '1']

      const rowLabel = yLabelIds.map((labelYId, labelIdx) => {
        const labelId =
          data?.metadata?.indexes?.y?.[labelIdx][parseInt(labelYId, 10)]

        if (versionsMetadata[labelId]?.name) {
          processedVersions.add(labelId) // version found

          if (versions.length === 1) {
            // skip version name fore single version data
            return undefined
          }
        }

        return versionsMetadata[labelId]?.name || labelId || labelYId // version name
      })

      dataRows.push([rowLabel.filter(Boolean).join(',\n'), ...row])
    })

    if (dataRows.length > 0) {
      // makes no sense to keep only headers if no data
      dataRows.unshift(headerRow)
    }

    // correction for single aggregated value
    if (headerRow.length === 2) {
      if (dataRows.length > 1) {
        const singleLabel = headerRow[1]

        const fullInclude = dataRows.some((dr) => dr[0] === singleLabel)
        if (fullInclude) {
          headerRow[1] = 'Value'
        }
      }
    }

    result.data = dataRows
    result.versionsPresent = processedVersions.size

    result.metadata = {
      ...data.metadata,
    } as unknown as MetricMetadata

    const mvFlowError = getMostSevereMVFlowError(data, versions)
    result.dataMostSevereError = mvFlowError.dataError
    result.allVersionsFailed = mvFlowError.allVersionsFailed

    const rawUsedDimensions = data.used_dimensions || []

    const hasDifferentDimensions = !isChartAggRequestDimensionsEquals(
      data.used_dimensions || [],
      chartRequestDimensions,
    )

    result.hasDifferentDimensions = hasDifferentDimensions

    result.usedDimensions =
      hasDifferentDimensions &&
      rawUsedDimensions &&
      rawUsedDimensions.length > 0
        ? rawUsedDimensions.map((d) => {
            if (isChartAggRequestDimensionVersion(d)) {
              return {
                type: 'Version',
                id: 'Version',
                label: 'Version',
              }
            }
            return {
              type: d.time_dimension ? 'Time' : ('Category' as DimensionType), // @todo: range?
              id: d.dimension_uid,
              label:
                (data.dimension_labels || []).find(
                  (dl) => dl.uid === d.dimension_uid,
                )?.label || d.dimension_uid,
              aggregate: d.time_dimension ? undefined : d.aggregate,
            }
          })
        : dimensions || []

    return result
  }, [
    versions,
    enabled,
    isFetching,
    isLoading,
    data,
    versionsMetadata,
    chartRequestDimensions,
    dimensions,
  ])

  const { invalidateQueries } = useInvalidateMetricChartData(
    versions,
    metricId,
    periodSelection,
    dimensions || [],
  )

  return useMemo(() => {
    const request: ChartRequestParams = {
      versions: versions,
      metricId: metricId,
      dimensions: extractDimensionEssential(dimensions),
      periodSelection,
    }

    return {
      isLoading: isLoading || isFetching,
      isError,
      data: chartData,
      request,
      invalidateChartQueries: invalidateQueries,
    }
  }, [
    versions,
    metricId,
    dimensions,
    periodSelection,
    chartData,
    isLoading,
    isFetching,
    isError,
    invalidateQueries,
  ])
}

import { isEqual } from 'lodash'
import { useEffect, useRef } from 'react'
import {
  useIsLocalStorageOverrideDisabled,
  useSyncDeeplinkValue,
} from '@fintastic/web/util/deeplink'
import { useLocalStorage } from '@fintastic/shared/util/hooks'
import { areVersionsDefault, BOARD_PARAMS_LOCAL_STORAGE_KEYS } from './utils'
import { useValidateVersionsIds } from '../useValidateVersionsIds'

export const useBoardParamsLocalStorageSyncEffect = (
  defaultVersions: string[],
) => {
  const localStorageOverrideDisabled = useIsLocalStorageOverrideDisabled()

  // Versions
  const [localStorageVersions, setLocalStorageVersions] = useLocalStorage<
    string[]
  >(BOARD_PARAMS_LOCAL_STORAGE_KEYS.version, defaultVersions)

  const [deeplinkVersions, setDeeplinkVersions] = useSyncDeeplinkValue<
    string[]
  >({
    key: 'v',
    defaultValue: defaultVersions,
  })

  const versionListValidator = useValidateVersionsIds()

  // Diffs
  const [deeplinkDiffs, setDeeplinkDiffs] = useSyncDeeplinkValue<string[]>({
    key: 'diff',
    defaultValue: defaultDiffFallback,
  })

  const [localStorageDiffs, setLocalStorageDiffs] = useLocalStorage<string[]>(
    BOARD_PARAMS_LOCAL_STORAGE_KEYS.diffs,
    defaultDiffFallback,
    {
      syncBetweenTabs: false,
    },
  )

  const prevLocalStorageVersions = useRef<string[]>()

  // Put deeplink versions to Local Storage if they are not default
  useEffect(() => {
    if (defaultVersions.length === 0) {
      return
    }

    if (areVersionsDefault(deeplinkVersions, defaultVersions)) {
      return
    }

    const nextLsVersions = deeplinkVersions.length
      ? deeplinkVersions
      : defaultVersionsFallback

    // Prevent double set state action
    if (isEqual(prevLocalStorageVersions.current, nextLsVersions)) {
      return
    }

    setLocalStorageVersions(nextLsVersions)
    prevLocalStorageVersions.current = nextLsVersions
  }, [deeplinkVersions, defaultVersions, setLocalStorageVersions])

  // Put LS versions to URL if they are not default
  useEffect(() => {
    if (defaultVersions.length === 0) {
      return
    }

    if (localStorageOverrideDisabled) {
      return
    }

    if (!areVersionsDefault(deeplinkVersions, defaultVersions)) {
      return
    }

    const validatedLocalStorageVersions =
      versionListValidator(localStorageVersions)

    if (areVersionsDefault(validatedLocalStorageVersions, defaultVersions)) {
      return
    }

    if (!validatedLocalStorageVersions.length) {
      return
    }

    if (
      isEqual(prevLocalStorageVersions.current, validatedLocalStorageVersions)
    ) {
      return
    }

    prevLocalStorageVersions.current = validatedLocalStorageVersions
    setDeeplinkVersions(validatedLocalStorageVersions)
  }, [
    deeplinkVersions,
    defaultVersions,
    localStorageOverrideDisabled,
    localStorageVersions,
    setDeeplinkVersions,
    versionListValidator,
  ])

  // Put LS diffs to URL if they are not default
  const localStorageDiffsRef = useRef(localStorageDiffs)
  localStorageDiffsRef.current = localStorageDiffs
  useEffect(() => {
    if (localStorageOverrideDisabled) {
      return
    }

    if (deeplinkDiffs.length === 0 && localStorageDiffsRef.current.length) {
      setDeeplinkDiffs(localStorageDiffsRef.current)
    }
  }, [deeplinkDiffs.length, localStorageOverrideDisabled, setDeeplinkDiffs])

  // Put deeplink into LS
  useEffect(() => {
    if (!deeplinkDiffs.length) {
      return
    }

    if (isEqual(localStorageDiffsRef.current, deeplinkDiffs)) {
      return
    }

    setLocalStorageDiffs(deeplinkDiffs)
  }, [deeplinkDiffs, setLocalStorageDiffs])
}

const defaultVersionsFallback: string[] = []

const defaultDiffFallback: string[] = []

import React, { useMemo } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { SupportedEntry } from '../../../supported-entry-types'
import { DimensionId, VersionDimension } from '@fintastic/web/util/dimensions'
import { StyledRoot, StyledCellContent } from './DetailsCellRenderer.styled'
import { Popover } from '@mui/material'
import { Maybe } from '@fintastic/shared/util/types'
import {
  ChangeColumnValueDetailsCard,
  ChangeColumnValueShortDetails,
  ChangeMetricValueDetailsCard,
  ChangeMetricValueShortDetails,
  AddListLineShortDetails,
  DeleteListLineShortDetails,
  DuplicateListLineShortDetails,
} from '../../../../entry-details'

export const DetailsCellRenderer: React.FC<
  ICellRendererParams<SupportedEntry> & {
    dimensions: Record<DimensionId, VersionDimension>
  }
> = ({ dimensions, data }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const details = useMemo<
    Maybe<{ shortDetails: React.ReactNode; card?: React.ReactNode }>
  >(() => {
    if (!data) {
      return null
    }

    if (data._type === 'change_metric_value') {
      return {
        shortDetails: (
          <ChangeMetricValueShortDetails entry={data} dimensions={dimensions} />
        ),
        card: (
          <ChangeMetricValueDetailsCard entry={data} dimensions={dimensions} />
        ),
      }
    }

    if (data._type === 'change_column_value') {
      return {
        shortDetails: (
          <ChangeColumnValueShortDetails entry={data} dimensions={dimensions} />
        ),
        card: (
          <ChangeColumnValueDetailsCard entry={data} dimensions={dimensions} />
        ),
      }
    }

    if (data._type === 'add_list_line') {
      return {
        shortDetails: <AddListLineShortDetails entry={data} />,
      }
    }

    if (data._type === 'delete_list_line') {
      return {
        shortDetails: <DeleteListLineShortDetails entry={data} />,
      }
    }

    if (data._type === 'duplicate_list_line') {
      return {
        shortDetails: <DuplicateListLineShortDetails entry={data} />,
      }
    }

    return null
  }, [data, dimensions])

  if (!details) {
    return null
  }

  return (
    <StyledRoot
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <StyledCellContent>{details.shortDetails}</StyledCellContent>
      {details.card && (
        <Popover
          id="history-mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {details.card}
        </Popover>
      )}
    </StyledRoot>
  )
}

import React from 'react'
import {
  StyledMainContent,
  StyledPostContent,
  StyledRoot,
  StyledTitle,
} from './Section.styled'

export type SectionProps = {
  title: React.ReactNode
  children: React.ReactNode
  postContent?: React.ReactNode
  ['data-testid']?: string
}

export const Section: React.FC<SectionProps> = ({
  children,
  postContent,
  title,
  ...rest
}) => (
  <StyledRoot data-testid={rest['data-testid']}>
    <StyledTitle variant="overline">{title}</StyledTitle>
    <StyledMainContent>{children}</StyledMainContent>
    {postContent !== undefined && (
      <StyledPostContent>{postContent}</StyledPostContent>
    )}
  </StyledRoot>
)

import React, { useMemo } from 'react'
import { Editor, EditorProps } from '../../Editor'
import {
  Version,
  VersionWithRecognitionMap,
  VersionStateApi,
} from '../version-state/types'
import { Skeleton } from '@mui/material'
import { Maybe, toMaybe } from '@fintastic/shared/util/types'
import { useLoadVersionEntities } from '@fintastic/web/data-access/versions'
import {
  ClientOnlyMapping,
  createMappingDictionary,
  entitiesToRecognitionMap,
  mapFormula,
} from '@fintastic/web/util/formulas'
import { VersionEntities } from '@fintastic/web/util/versions'
import { ErrorAlert } from '@fintastic/shared/ui/components'

export const EditorWithVersionEntities: React.FC<
  Omit<EditorProps, 'versions' | 'clientOnlyEntityLabels'> & {
    versions: VersionStateApi<Version>
    clientOnlyMapping?: ClientOnlyMapping
  }
> = ({ onValidationFinished, saveFormulaApi, clientOnlyMapping, ...props }) => {
  const versionEntitiesQuery = useLoadVersionEntities(
    props.versions.version?.id || null,
  )

  const entities = useMemo<Maybe<VersionEntities>>(
    () => toMaybe(versionEntitiesQuery.data),
    [versionEntitiesQuery.data],
  )

  const idsToLabelsMap = useMemo(
    () => createMappingDictionary(entities, 'idToLabel', clientOnlyMapping),
    [entities, clientOnlyMapping],
  )

  const labelsToIdsMap = useMemo(
    () => createMappingDictionary(entities, 'labelToId', clientOnlyMapping),
    [entities, clientOnlyMapping],
  )

  const clientOnlyEntityLabels = useMemo(
    () => clientOnlyMapping?.metrics?.map((m) => m.label) || [],
    [clientOnlyMapping?.metrics],
  )

  const proxyOnValidationFinished = useMemo<typeof onValidationFinished>(() => {
    if (!onValidationFinished) {
      return
    }
    return (versionId, formula, invalid, context) => {
      onValidationFinished(
        versionId,
        mapFormula(formula, labelsToIdsMap),
        invalid,
        context,
      )
    }
  }, [labelsToIdsMap, onValidationFinished])

  const proxySaveFormulaApi = useMemo<typeof saveFormulaApi>(
    () => ({
      ...saveFormulaApi,
      save: (newFormula) =>
        saveFormulaApi.save(mapFormula(newFormula, labelsToIdsMap)),
    }),
    [labelsToIdsMap, saveFormulaApi],
  )

  const versionWithRecognitionMaps = useMemo<
    VersionStateApi<VersionWithRecognitionMap>
  >(() => {
    if (!entities || !props.versions.version?.id) {
      return {
        ...props.versions,
        version: null,
      }
    }

    const objectRecognitionMap = entitiesToRecognitionMap(
      entities,
      clientOnlyMapping,
    )

    return {
      ...props.versions,
      version: {
        ...props.versions.version,
        formula: mapFormula(props.versions.version.formula, idsToLabelsMap),
        objectRecognitionMap,
      },
    }
  }, [entities, props.versions, idsToLabelsMap, clientOnlyMapping])

  if (versionEntitiesQuery.isError) {
    return (
      <ErrorAlert
        title="Failed to load `objectRecognitionMap`"
        message={
          <div>
            <b>Error Message: </b>
            {versionEntitiesQuery.error?.message || 'Unknown Error'}
          </div>
        }
        severity="warning"
      />
    )
  }

  if (versionEntitiesQuery.isLoading || !versionWithRecognitionMaps?.version) {
    return (
      <Skeleton height={100} sx={{ borderRadius: '8px', margin: '8px 16px' }} />
    )
  }

  return (
    <Editor
      {...props}
      saveFormulaApi={proxySaveFormulaApi}
      onValidationFinished={proxyOnValidationFinished}
      versions={versionWithRecognitionMaps}
      clientOnlyEntityLabels={clientOnlyEntityLabels}
    />
  )
}

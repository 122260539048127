import { useQuery } from 'react-query'
import { getLists } from '../api/lists-api'
import { metricsAndListsCacheKeys } from '../cache'
import { Maybe } from '@fintastic/shared/util/types'

export function useLoadListOfLists(
  versionId: Maybe<string>,
  showHidden = false,
) {
  return useQuery(
    metricsAndListsCacheKeys.lists(versionId || '', showHidden),
    async () => {
      const response = await getLists(versionId || '', showHidden)
      return response.data.result
    },
    {
      enabled: versionId !== null,
    },
  )
}

import React, { useCallback } from 'react'
import { useBoardVersionsForEntity } from '@fintastic/shared/ui/components'
import { IconButton, MenuItem, Popover, Tooltip } from '@mui/material'
import { HistoryIcon } from '@fintastic/shared/ui/icons'
import { ArrowDropDown } from '@mui/icons-material'
import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'

export const EntityLevelHistoryMultiversionButton: React.FC<{
  entityId: string
  disabled: boolean
  versionIds: string[]
}> = ({ entityId, disabled, versionIds }) => {
  const boardVersionsOptions = useBoardVersionsForEntity(entityId)
  const options = boardVersionsOptions.options
  const historyApi = useHistoryLogGlobalApi()

  const currentEntityOpenedInOneOfTheVersions =
    historyApi?.state.isOpen &&
    historyApi?.state.level === 'entity' &&
    versionIds.includes(historyApi?.state.versionId) &&
    historyApi?.state.entityId === entityId

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl) && !disabled

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled || !event) {
        return
      }
      setAnchorEl(event.currentTarget)
    },
    [disabled],
  )

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleOpenHistory = useCallback(
    (versionId: string) => {
      if (options.length === 1 && currentEntityOpenedInOneOfTheVersions) {
        historyApi?.close()
        handleClose()
        return
      }
      historyApi?.openOnEntityLevel({
        versionId,
        entityId,
      })
      handleClose()
    },
    [
      options.length,
      currentEntityOpenedInOneOfTheVersions,
      historyApi,
      entityId,
      handleClose,
    ],
  )

  const derivedDisabled = boardVersionsOptions.isLoading || disabled

  const tooltipText = 'Open history'

  if (options.length === 1) {
    return (
      <Tooltip
        enterNextDelay={700}
        enterDelay={700}
        leaveDelay={0}
        title={
          currentEntityOpenedInOneOfTheVersions
            ? 'Hide history'
            : 'Show history'
        }
        disableInteractive={true}
        placement="top"
      >
        <span>
          <IconButton
            onClick={() => handleOpenHistory(options[0].value)}
            disabled={derivedDisabled || options[0].disabled}
            color={
              currentEntityOpenedInOneOfTheVersions ? 'primary' : 'secondary'
            }
            sx={{
              background: currentEntityOpenedInOneOfTheVersions
                ? 'rgba(255, 121, 51, 0.08)'
                : 'transparent',
            }}
          >
            <HistoryIcon fontSize="medium" />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  return (
    <>
      <Tooltip
        enterNextDelay={700}
        enterDelay={700}
        leaveDelay={0}
        title={tooltipText}
        disableInteractive={true}
        placement="top"
      >
        <span>
          <IconButton
            onClick={handleClick}
            disabled={derivedDisabled}
            color={
              currentEntityOpenedInOneOfTheVersions ? 'primary' : 'secondary'
            }
            sx={{
              width: 'auto',
              background: currentEntityOpenedInOneOfTheVersions
                ? 'rgba(255, 121, 51, 0.08)'
                : 'transparent',
            }}
          >
            <HistoryIcon fontSize="medium" />
            <ArrowDropDown
              color={derivedDisabled ? 'disabled' : undefined}
              fontSize="small"
            />
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            disabled={option.disabled}
            onClick={() => handleOpenHistory(option.value)}
            selected={
              currentEntityOpenedInOneOfTheVersions &&
              historyApi?.state.level === 'entity' &&
              historyApi.state.versionId === option.value
            }
          >
            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { sum } from 'lodash'
import { TagsTag } from './TagsTag'
import { Tag } from './types'
import { Box, ChipProps } from '@mui/material'
import { TagsExtraLabel } from './TagsExtraLabel'
import { TagsAddButton } from './TagsAddButton'

export type TagsProps = {
  onDelete?: (tag: Tag) => void
  onAddButtonClick?: React.MouseEventHandler<HTMLButtonElement>
  tags: Tag[]
  limit?: boolean
  singleline?: boolean
  showAddButton?: boolean
  icon?: ChipProps['icon']
}

export const Tags = React.forwardRef<HTMLDivElement, TagsProps>(
  (
    {
      tags,
      onAddButtonClick,
      onDelete,
      limit = false,
      singleline = false,
      showAddButton = false,
      icon,
    },
    forwardedRef,
  ) => {
    const [maxTagIndex, setMaxTagIndex] = useState<number>()

    const wrapperRef = useRef<HTMLDivElement>()

    useEffect(() => {
      if (!limit || !singleline) {
        return
      }

      if (!wrapperRef.current) {
        throw new Error('Tags: wrapperRef.current is not mounted yet!')
      }

      if (!tags.length) {
        return
      }

      // @todo re-made with useResizeObserver hook
      const resizeObserver = new ResizeObserver(() => {
        if (!wrapperRef.current) {
          return
        }

        const divTags =
          wrapperRef.current?.querySelectorAll<HTMLDivElement>('[data-tag]')

        if (!divTags?.length) {
          return
        }

        const containerWidth = wrapperRef.current?.clientWidth

        if (!containerWidth) {
          return
        }

        const tagWidthArray = Array.from(divTags).map(
          // Add gap if not the last element
          (tag, index) =>
            tag.clientWidth + (index === tags.length - 1 ? 0 : GAP),
        )

        if (sum(tagWidthArray) > containerWidth) {
          const calcMaxTagIndex = (remainingItemsValue = 0): number => {
            const effectiveAvailableContainerWidth =
              containerWidth - calcExtraLabelWidth(remainingItemsValue)
            let tagIndex = tagWidthArray.findIndex((width, index) => {
              if (!index) {
                return false
              }

              if (
                sum(tagWidthArray.slice(0, index + 1)) >
                effectiveAvailableContainerWidth
              ) {
                return true
              }

              return false
            })

            tagIndex = tagIndex === 0 ? 1 : tagIndex

            const remainingItemsForCurrentIndex = calcRemainingItems(
              tags,
              tagIndex,
            )
            const newLabelIsLongerThanOriginal = numberIsLonger(
              remainingItemsForCurrentIndex,
              remainingItemsValue,
            )

            if (newLabelIsLongerThanOriginal) {
              return calcMaxTagIndex(remainingItemsForCurrentIndex)
            }

            return tagIndex
          }

          setMaxTagIndex(calcMaxTagIndex())
        } else {
          setMaxTagIndex(-1)
        }
      })

      resizeObserver.observe(wrapperRef.current)

      return () => {
        resizeObserver.disconnect()
      }
    }, [limit, singleline, tags])

    const remainingItems = useMemo<number>(
      () => calcRemainingItems(tags, maxTagIndex || -1),
      [tags, maxTagIndex],
    )

    return (
      <Box
        display="flex"
        gap={`${GAP}px`}
        flexWrap={singleline ? 'nowrap' : 'wrap'}
        overflow={limit ? 'hidden' : undefined}
        ref={(instance: HTMLDivElement) => {
          wrapperRef.current = instance

          if (typeof forwardedRef === 'function') {
            forwardedRef(instance)
          } else if (typeof forwardedRef === 'object' && forwardedRef) {
            // eslint-disable-next-line no-param-reassign
            forwardedRef.current = instance
          }
        }}
        data-testid="tag-list"
      >
        {tags.map((tag, index) => {
          const insertButtonsPosition =
            limit && maxTagIndex !== undefined && maxTagIndex > 0
              ? index === maxTagIndex
              : false

          const extraLabel =
            limit && remainingItems > 0 ? (
              <TagsExtraLabel>+{remainingItems}</TagsExtraLabel>
            ) : null
          const allHidden = maxTagIndex === undefined && limit
          const hiddenByOverflow =
            maxTagIndex !== undefined && maxTagIndex > 0 && limit
              ? index >= maxTagIndex
              : false

          return (
            <React.Fragment key={tag.id}>
              {insertButtonsPosition ? extraLabel : null}
              <TagsTag
                tag={tag}
                onDelete={onDelete}
                hidden={hiddenByOverflow || allHidden}
                icon={icon}
              />
            </React.Fragment>
          )
        })}
        {showAddButton ? (
          <TagsAddButton
            onClick={onAddButtonClick}
            fullSize={tags.length === 0}
          />
        ) : null}
      </Box>
    )
  },
)

const GAP = 6

// Inprecise extra label calculator with glyph width assumption
const calcExtraLabelWidth = (extraLabelValue: number): number => {
  // Consider plus sign
  const extraLabelGlyphCount = Math.ceil(extraLabelValue / 10) + 1
  const GLYPH_WIDTH = 12

  return extraLabelGlyphCount * GLYPH_WIDTH
}

const calcRemainingItems = (tags: Tag[], maxTagIndex: number): number => {
  if (maxTagIndex === -1) {
    return 0
  }

  return tags.length - maxTagIndex
}

const numberIsLonger = (a: number, b: number): boolean => {
  if (!a && b) {
    return true
  }
  return `${a}`.length > `${b}`.length
}

import { isNumber } from 'lodash'
import { idLooksLikeDimValueOther } from '@fintastic/web/util/dimensions'

export const sortDimensionOptions = (
  dimensions: DimensionOption[],
): DimensionOption[] => {
  const [numbers, notNumbers] = dimensions.reduce<
    [numbers: DimensionOption[], notNumbers: DimensionOption[]]
  >(
    ([numbers, notNumbers], d) => {
      if (idLooksLikeDimValueOther(d.value)) {
        return [numbers, notNumbers]
      }

      if (isNumberRegexp.test(d.label)) {
        return [[...numbers, d], notNumbers]
      } else {
        return [numbers, [...notNumbers, d]]
      }
    },
    [[], []],
  )

  notNumbers.sort(({ label: a }, { label: b }) => a.localeCompare(b))
  numbers.sort(({ label: a }, { label: b }) => {
    if (a === b) {
      return 0
    }

    const parsedA = parseFloat(a)
    const parsedB = parseFloat(b)

    if (!isNumber(parsedA)) {
      return -1
    }

    if (!isNumber(parsedB)) {
      return 1
    }

    return parsedA > parsedB ? 1 : -1
  })

  const otherDim = dimensions.find((d) => idLooksLikeDimValueOther(d.value))

  return otherDim
    ? [...numbers, ...notNumbers, otherDim]
    : [...numbers, ...notNumbers]
}

const isNumberRegexp = /^[0-9]+$/

type DimensionOption = {
  label: string
  value: string
}

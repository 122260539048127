import { GridApi } from 'ag-grid-community/dist/lib/gridApi'
import {
  MetricGridRow,
  MetricWithoutData,
} from '@fintastic/web/util/metrics-and-lists'
import { Metric } from '@fintastic/web/util/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import { useCallback, useEffect, useRef } from 'react'
import { hideMaskedValuesMemorized } from '@fintastic/web/util/metrics-and-lists'
import { parseList } from '@fintastic/web/util/metrics-and-lists'
import { updateColumns } from '@fintastic/web/feature/metrics-and-lists'
import { RowNode } from 'ag-grid-community'
import {
  getListColumns,
  invalidateListColumnsCache,
} from '@fintastic/web/data-access/metrics-and-lists'
import keyBy from 'lodash/keyBy'
import { QueryClient, useQueryClient } from 'react-query'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { useSubscribeOnCellDataUpdateEvent } from '@fintastic/web/data-access/metric-data-editing'
import {
  AddListRowsDataUpdate,
  DuplicateListRowsDataUpdate,
  FailedDataUpdateEventWrapper,
  SuccessfulDataUpdateEventWrapper,
} from '@fintastic/web/util/metric-data-editing'
import { range, throttle } from 'lodash'
import toast from 'react-hot-toast/headless'
import { RangeDimensionId } from '@fintastic/web/util/dimensions'
import { useConnectionStatus } from '@fintastic/web/feature/realtime'

type Context = {
  columnIds: string[]
  columns: Record<string, Metric>
  versionId: string
  rowDimension: Maybe<string>
  timeDimension?: string
  periodSelection: PeriodSelection
  listId: string
  currentUserEmail: string
  isLoading: boolean
}

export function useRowData(
  gridApiRef: React.RefObject<Maybe<GridApi<MetricGridRow>>>,
  context: Context,
) {
  const {
    columnIds,
    columns,
    versionId,
    rowDimension,
    timeDimension,
    listId,
    isLoading,
  } = context
  const queryClient = useQueryClient()
  const isTabActive = useConnectionStatus().tabIsVisible
  const isTabActiveRef = useRef(isTabActive)
  isTabActiveRef.current = isTabActive

  const visibleColumnsRef = useRef<Record<string, Metric>>({})
  const prevTimeDimensionRef = useRef(timeDimension)
  const contextRef = useRef(context)
  contextRef.current = context

  useSubscribeOnCellDataUpdateEvent(
    [versionId],
    useCallback(
      async (event) => {
        if (event instanceof FailedDataUpdateEventWrapper) {
          return
        }

        if (
          event.updateData.action !== 'add_list_rows' &&
          event.updateData.action !== 'add_complete_list_rows' &&
          event.updateData.action !== 'duplicate_list_rows'
        ) {
          return
        }

        if (event.updateData.list_id !== listId) {
          return
        }

        if (event.versionId !== versionId) {
          return
        }

        if (!isTabActiveRef.current) {
          invalidateListColumnsCache(queryClient, {
            versionId,
            listId,
          })
          return
        }

        await handleNewRows(
          event,
          gridApiRef.current,
          queryClient,
          contextRef.current,
        )
      },
      [gridApiRef, listId, queryClient, versionId],
    ),
  )

  const throttledFullUpdateRef = useRef(throttledFullUpdate(gridApiRef))

  const waitForGridApi = useCallback(
    (tries = 10, pauseMs = 25) =>
      new Promise<GridApi<MetricGridRow>>((res, rej) => {
        if (gridApiRef.current) {
          res(gridApiRef.current)
          return
        }
        let counter = 0
        setTimeout(() => {
          if (gridApiRef.current) {
            res(gridApiRef.current)
            return
          }
          counter += 1
          if (counter === tries) {
            rej(new Error('grid api is not available'))
          }
        }, pauseMs)
      }),
    [gridApiRef],
  )

  useEffect(() => {
    const run = async () => {
      if (rowDimension === null || isLoading) {
        return
      }

      let changedColumns = columnIds.filter(
        (columnId) =>
          !equalMetricObjects(
            columns[columnId],
            visibleColumnsRef.current[columnId],
          ),
      )

      if (prevTimeDimensionRef.current !== timeDimension) {
        prevTimeDimensionRef.current = timeDimension
        changedColumns = columnIds
      }

      const atLeastOneColumnLoadedFirstTime = changedColumns.some(
        (id) => visibleColumnsRef.current[id] === undefined,
      )

      let gridApi = await waitForGridApi()
      const gridRowCount = gridApiRef.current?.getModel()?.getRowCount()

      if (
        atLeastOneColumnLoadedFirstTime ||
        changedColumns.length === columnIds.length ||
        !gridRowCount
      ) {
        // update whole table data
        const nextRowData = getParsedListRows({
          columnIds,
          columns,
          versionId,
          rowDimension,
          timeDimension,
        })

        gridApi = await waitForGridApi()
        gridApi.deselectAll()
        if (nextRowData.length) {
          gridApi.hideOverlay()
          throttledFullUpdateRef.current(nextRowData)
        } else {
          if (gridRowCount) {
            throttledFullUpdateRef.current([])
          }

          gridApi.showNoRowsOverlay()
        }
        console.log('full update', {
          newRows: nextRowData,
          atLeastOneColumnLoadedFirstTime,
          changedColumns,
          columnIds,
        })
      } else if (changedColumns.length && gridApi) {
        // partial update of the columns
        const rowsToPartialUpdate = getParsedListRows({
          columns,
          versionId,
          rowDimension,
          timeDimension,
          columnIds: changedColumns,
        })

        gridApi = await waitForGridApi()
        gridApi.deselectAll()
        gridApi.hideOverlay()
        gridApi = await waitForGridApi()

        // @todo: Row count might be the same but with different IDs. Need to make a proper check
        if (gridRowCount !== rowsToPartialUpdate.length) {
          invalidateListColumnsCache(queryClient, { versionId, listId }, true)
          console.log('invalid rows number! invalidate all rows', {
            partialRows: rowsToPartialUpdate,
            changedColumns,
            columnIds,
          })
        } else {
          updateColumns(gridApi, rowsToPartialUpdate)
          console.log('partial update', {
            partialRows: rowsToPartialUpdate,
            changedColumns,
            columnIds,
          })
        }
      }

      // always update the ref
      if (changedColumns.length) {
        visibleColumnsRef.current = Object.fromEntries(
          columnIds
            .filter((id) => Boolean(columns[id]))
            .map((id) => [id, columns[id]]),
        )
      }
    }

    run().catch(console.error)
  }, [
    columnIds,
    columns,
    rowDimension,
    timeDimension,
    isLoading,
    waitForGridApi,
    gridApiRef,
    queryClient,
    versionId,
    listId,
  ])
}

function getParsedListRows({
  columnIds,
  columns,
  versionId,
  rowDimension,
  timeDimension,
}: Pick<
  Context,
  'columns' | 'columnIds' | 'versionId' | 'rowDimension' | 'timeDimension'
>) {
  const maskedData = columnIds
    .filter((id) => Boolean(columns[id]))
    .map((id) => {
      const metric = columns[id]
      if (!metric.data) {
        return metric
      }

      metric.data.values = hideMaskedValuesMemorized(
        metric.data.values,
        metric.metadata.value.mask,
      )

      return metric
    })

  return (
    parseList(maskedData, rowDimension || '', versionId || '', timeDimension) ||
    []
  )
}

const highlightAddedRows = (gridApi: GridApi, rowIds: string[]) => {
  if (!rowIds.length) {
    return
  }

  gridApi.ensureNodeVisible(
    (r: RowNode<MetricGridRow>) => r.id === rowIds[rowIds.length - 1],
    'bottom',
  )

  setTimeout(() => {
    gridApi.flashCells({
      rowNodes: rowIds
        .map((rowId) => gridApi.getRowNode(rowId))
        .filter((n) => !!n) as RowNode<MetricGridRow>[],
      fadeDelay: 500,
    })
  }, 100)
}

const handleNewRows = async (
  event: SuccessfulDataUpdateEventWrapper,
  gridApi: Maybe<GridApi<MetricGridRow>> | undefined,
  queryClient: QueryClient,
  context: Context,
) => {
  if (!gridApi) {
    return
  }

  const dataUpdate = event.updateData as
    | AddListRowsDataUpdate
    | DuplicateListRowsDataUpdate

  // @todo remove after the stabilisation
  console.log('add created rows to the local aggrid state', {
    event,
    dataUpdate,
    context,
  })

  const createdListRowsIds: RangeDimensionId[] = []

  if (
    dataUpdate.action === 'add_list_rows' ||
    dataUpdate.action === 'add_complete_list_rows'
  ) {
    createdListRowsIds.push(
      ...range(
        dataUpdate.row_index_range[0],
        dataUpdate.row_index_range[1] + 1,
      ).map((index) => `${dataUpdate.row_dim}.${index}`),
    )
  }

  if (dataUpdate.action === 'duplicate_list_rows') {
    createdListRowsIds.push(
      ...dataUpdate.row_dim_values.map(
        (index) => `${dataUpdate.row_dim}.${index}`,
      ),
    )
  }

  let addedRowsData: Metric[]
  try {
    addedRowsData = (
      await getListColumns(
        context.versionId,
        dataUpdate.list_id,
        context.columnIds,
        context.periodSelection,
        true,
        createdListRowsIds,
      )
    ).data.result
  } catch (e) {
    console.error(e)
    await invalidateListColumnsCache(queryClient, {
      versionId: context.versionId,
      listId: dataUpdate.list_id,
    })
    return
  }

  const parsedListRows = getParsedListRows({
    ...context,
    columns: keyBy(addedRowsData, 'id'),
  })

  gridApi.deselectAll()
  gridApi.applyTransaction({
    add: parsedListRows,
  })

  // eslint-disable-next-line no-restricted-globals
  if (context.currentUserEmail === event.userEmail) {
    setTimeout(() => {
      highlightAddedRows(gridApi, createdListRowsIds)
    }, 300)

    toast.success(
      `${createdListRowsIds.length} line${
        createdListRowsIds.length > 1 ? 's' : ''
      } added successfully`,
    )
  }
}

const throttledFullUpdate = (
  gridApiRef: React.RefObject<Maybe<GridApi<MetricGridRow>>>,
) =>
  throttle((nextData: MetricGridRow[]) => {
    gridApiRef.current?.setRowData(nextData)
  }, 100)

const equalMetricObjects = (
  a: Metric | MetricWithoutData | undefined,
  b: Metric | MetricWithoutData | undefined,
) => {
  if (a === b) {
    return true
  }

  if (a?.__objectId !== undefined || b?.__objectId !== undefined) {
    return a?.__objectId === b?.__objectId
  }

  return false
}

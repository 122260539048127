import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { FactoryOpts, InputMask } from 'imask'
import {
  StyledErrorActions,
  StyledErrorPopover,
  StyledErrorPopoverPaper,
  StyledInput,
  StyledInputRoot,
} from './InlineFormulaInput.styled'
import { Button, Tooltip } from '@mui/material'
import {
  formulaMask,
  InlineFormulaCalculationResult,
  isPossibleFormula,
  parseInlineFormula,
} from './inline-formula.utils'
import { IMaskMixin } from 'react-imask'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { ModalHeader } from '@fintastic/shared/ui/modal-framework'

export type InlineFormulaInputProps = {
  value: Maybe<string>
  mask: FactoryOpts
  onConfirmFormulaValue: (value: Maybe<string>) => void
  onConfirmPlainValue: (value: string, maskRef: InputMask<FactoryOpts>) => void

  formatter?: (value: unknown) => string
}

const MaskedInput = IMaskMixin(({ inputRef, ...props }) => {
  const propsToUse = props as Record<string, unknown>
  return <StyledInput size="small" inputRef={inputRef} {...propsToUse} />
})

export const InlineFormulaInput: React.FC<InlineFormulaInputProps> = ({
  value,
  onConfirmFormulaValue,
  onConfirmPlainValue,
  mask,
  formatter,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<Maybe<string>>('') // for blur HTML event

  const [text, setText] = useState<Maybe<string>>(
    typeof value !== 'undefined' ? `${value}` : '',
  )

  useEffect(() => {
    setText(() => (typeof value !== 'undefined' ? `${value}` : ''))
    textRef.current = typeof value !== 'undefined' ? `${value}` : ''
  }, [value])

  const [calculationResult, setCalculationResult] =
    useState<Maybe<InlineFormulaCalculationResult>>(null)

  const [hasError, setHasError] = useState(false)

  const [isErrorOpen, setIsErrorOpen] = useState(false)

  const usedMask = useMemo(
    () =>
      // extend mask with formula option on the first place
      // -> dynamic mask auto selection
      ({
        mask: [formulaMask, mask],
      }),
    [mask],
  )

  const handleCloseMenu = useCallback(() => {
    setIsErrorOpen(() => false)
  }, [])

  const handleConfirmAttempt = useCallback(
    (value?: string) => {
      const toProcess = value || text
      if (!isPossibleFormula(toProcess)) {
        onConfirmPlainValue(toProcess || '', {
          unmaskedValue: toProcess,
        } as InputMask<FactoryOpts>)

        return true
      }

      const calc = parseInlineFormula(toProcess, mask)
      setCalculationResult(calc)

      setHasError(() => calc.error !== null)
      setIsErrorOpen(() => calc.error !== null)

      if (calc.value !== null) {
        onConfirmFormulaValue(calc.value.toString(10))
        return true
      }
      return false
    },
    [onConfirmFormulaValue, onConfirmPlainValue, mask, text],
  )

  const handleKeyDown = useCallback<React.KeyboardEventHandler>(
    (e) => {
      if (e.key !== 'Enter' && e.key !== 'Tab') {
        return
      }

      const parseSucceed = handleConfirmAttempt()

      if (!parseSucceed) {
        e.preventDefault()
        e.stopPropagation()
        return
      }
    },
    [handleConfirmAttempt],
  )

  const handleChangeInput = useCallback(
    (value: string, maskRef: InputMask<typeof mask>) => {
      const unmasked =
        maskRef.unmaskedValue === '' ? null : maskRef.unmaskedValue
      const nextValue = value === '-' ? '-' : unmasked
      // reset error popup after edit
      setHasError(() => false)
      setText(nextValue)
      textRef.current = nextValue
    },
    [],
  )

  const hasFormula = useMemo(() => isPossibleFormula(text), [text])

  const tooltipValue = useMemo(() => {
    if (!isPossibleFormula(text)) {
      return null
    }

    const calculationResult = parseInlineFormula(text, mask)
    if (typeof calculationResult.value !== 'number') {
      return null
    }

    if (formatter) {
      const isPercentage = mask.mask!.toString().includes('%')
      return formatter(calculationResult.value * (isPercentage ? 0.01 : 1))
    }

    return calculationResult.value?.toString()
  }, [formatter, mask, text])

  const showTooltip = tooltipValue !== null

  const handleKeepEditing = useCallback(() => {
    const element = containerRef.current?.querySelector('input[type=text]')

    if (element) {
      ;(element as HTMLInputElement).focus()
      setIsErrorOpen(() => false)
    }
  }, [])

  const handleDiscard = useCallback(() => {
    const element = containerRef.current?.querySelector('input[type=text]')

    if (element) {
      ;(element as HTMLInputElement).focus()
      setIsErrorOpen(() => false)
      setText(() => value)
      textRef.current = value
    }
  }, [value])

  useEffect(() => {
    const handleClickAway = (e: MouseEvent) => {
      const element = containerRef.current?.querySelector('input[type=text]')
      if (element && e.target !== element) {
        if (textRef.current) {
          handleConfirmAttempt(textRef.current)
        }
      }
    }

    document.addEventListener('mousedown', handleClickAway, { capture: true })

    return () => {
      document.removeEventListener('mousedown', handleClickAway, {
        capture: true,
      })
    }
  }, [handleConfirmAttempt])

  return (
    <StyledInputRoot ref={containerRef} data-testid={'inline-formula-root'}>
      <FintasticThemeProvider applyLegacyTheme={false}>
        <Tooltip open={showTooltip} placement="top" title={tooltipValue} arrow>
          <span className={'tooltip-holder'}></span>
        </Tooltip>
        <MaskedInput
          inFormula={hasFormula}
          data-testid={'inline-formula-input'}
          value={text}
          error={hasError}
          inputRef={inputRef}
          onAccept={handleChangeInput}
          onKeyDownCapture={handleKeyDown}
          {...(usedMask as unknown as any)}
        />

        <StyledErrorPopover
          open={isErrorOpen}
          anchorEl={containerRef.current}
          onClose={() => handleCloseMenu()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <StyledErrorPopoverPaper
            sx={{ minWidth: containerRef.current?.clientWidth }}
          >
            <span className={'arrow-top'}></span>

            <ModalHeader
              title={calculationResult?.errorTitle}
              description={calculationResult?.error}
              showCloseButton={false}
            />

            <StyledErrorActions>
              <Button
                variant={'contained'}
                color="primary"
                onClick={handleKeepEditing}
              >
                Back to formula editing
              </Button>

              <Button variant={'outlined'} onClick={handleDiscard}>
                Remove formula
              </Button>
            </StyledErrorActions>
          </StyledErrorPopoverPaper>
        </StyledErrorPopover>
      </FintasticThemeProvider>
    </StyledInputRoot>
  )
}

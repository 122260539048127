import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  AddLinesState,
  AddLinesTargetApi,
  AddLinesTargetParams,
  ListAddLinesTarget,
  ListParamsConfig,
} from '../types'
import { useLayoutStateIsBottomDrawerOpened } from '@fintastic/shared/ui/app-layout-framework'
import { isEqual } from 'lodash'
import { MAX_NEW_RECORDS_TO_EDIT, TooManyRecordsToEdit } from '../consts'
import { Maybe } from '@fintastic/shared/util/types'
import { toast } from '@fintastic/shared/ui/toast-framework'
import { RowToImportDefinition } from '../features/import-rows'

const emptyTarget: ListAddLinesTarget = {
  listId: '',
  versionId: '',
}

const emptyParams: AddLinesTargetParams = {
  numberOfRows: 0,
  periodSelection: undefined,
  calendarConfig: undefined,
  columnColors: [],
}

function isAddLinesPayloadEmpty(payload: Maybe<ListAddLinesTarget>): boolean {
  if (!payload) {
    return true
  }
  return !payload.versionId || !payload.listId
}

export const useAddLinesTargetApi = ({
  currentTarget,
  setCurrentTarget,
  state,
  setRowsToImport,
}: {
  currentTarget: ListAddLinesTarget
  setCurrentTarget: Dispatch<SetStateAction<ListAddLinesTarget>>
  state: AddLinesState
  setRowsToImport: (rowsToImport: RowToImportDefinition) => void
}): AddLinesTargetApi => {
  const [showReplaceTargetModal, setShowReplaceTargetModal] =
    useState<boolean>(false)

  const newTargetIntent = useRef<ListAddLinesTarget>({ ...emptyTarget })
  const newTargetParams = useRef<AddLinesTargetParams>({ ...emptyParams })

  const resetIntent = useCallback(() => {
    newTargetIntent.current = { ...emptyTarget }
    newTargetParams.current = { ...emptyParams }
  }, [])

  const [isOpened, setDrawerOpened] = useLayoutStateIsBottomDrawerOpened()

  const closeTarget = useCallback(() => {
    setDrawerOpened(false)

    setCurrentTarget(() => ({ ...emptyTarget }))
    state.data.reset()
    resetIntent()
  }, [state, resetIntent, setDrawerOpened, setCurrentTarget])

  const addRows = useCallback(
    (numberOfRows?: number, replace = false) => {
      if (typeof numberOfRows !== 'number') {
        return
      }

      try {
        state.data.generateRecords(numberOfRows, replace)
      } catch (ex: unknown) {
        if (ex instanceof TooManyRecordsToEdit) {
          toast.custom(
            `Number of new rows is restricted up to ${MAX_NEW_RECORDS_TO_EDIT} records`,
          )
        } else {
          toast.error('Something went wrong on adding records')
        }
      }
    },
    [state],
  )

  // commit change target dialog
  const commitSetTarget = useCallback(() => {
    setShowReplaceTargetModal(() => false)
    setCurrentTarget(newTargetIntent.current)

    addRows(newTargetParams.current.numberOfRows, true)
    if (newTargetParams.current.rowsToImport) {
      setRowsToImport(newTargetParams.current.rowsToImport)
    }

    if (newTargetParams.current.columnColors) {
      state.columnColors.setColumnColors(newTargetParams.current.columnColors)
    }
    resetIntent()

    setDrawerOpened(true)
  }, [
    setCurrentTarget,
    addRows,
    resetIntent,
    setDrawerOpened,
    setRowsToImport,
    state.columnColors,
  ])

  const updateTargetParams = useCallback(
    (params: ListParamsConfig) => {
      if (!params.listId || !params.versionId) {
        return
      }

      if (
        params.listId !== currentTarget.listId ||
        params.versionId !== currentTarget.versionId
      ) {
        throw new Error(
          `You can not update params of another list. Current: ${JSON.stringify(
            currentTarget || {},
            null,
            2,
          )}. 'Intent:', ${JSON.stringify(params || {}, null, 2)}`,
        )
      }

      const colorsEqual = isEqual(
        state.columnColors.columnColors,
        params?.columnColors ?? state.columnColors.columnColors,
      )

      const periodsEqual = isEqual(
        state.periodSelection,
        params?.periodSelection ?? state.periodSelection,
      )

      const calendarsEqual = isEqual(
        state.calendarConfig,
        params?.calendarConfig ?? state.calendarConfig,
      )

      if (!periodsEqual) {
        state.periodSelection.setSelection(params?.periodSelection ?? null)
      }

      if (!calendarsEqual) {
        state.calendarConfig.setCalendarConfig(params?.calendarConfig ?? null)
      }

      if (!colorsEqual) {
        state.columnColors.setColumnColors(params?.columnColors ?? [])
      }
    },
    [
      currentTarget,
      state.calendarConfig,
      state.columnColors,
      state.periodSelection,
    ],
  )

  const setTarget = useCallback(
    (newTarget: ListAddLinesTarget, params: AddLinesTargetParams) => {
      if (
        newTarget.listId === currentTarget.listId &&
        newTarget.versionId === currentTarget.versionId
      ) {
        // target not changed
        // need to use updateTargetParams() or addRows()
        return
      }

      // full replace, new ListId or VersionId
      if (
        !isOpened ||
        !state.data.changed ||
        isAddLinesPayloadEmpty(currentTarget)
      ) {
        // update data and silently assign
        newTargetIntent.current = { ...newTarget }
        newTargetParams.current = { ...params }

        setDrawerOpened(true)
        commitSetTarget()
        return
      }

      // targets differ => need to confirm
      newTargetIntent.current = { ...newTarget }
      newTargetParams.current = { ...params }

      setShowReplaceTargetModal(() => true)
    },
    [commitSetTarget, state, isOpened, currentTarget, setDrawerOpened],
  )

  // discard dialog
  const resetSetTarget = useCallback(() => {
    setShowReplaceTargetModal(() => false)
    resetIntent()
  }, [resetIntent])

  return useMemo<AddLinesTargetApi>(
    () =>
      ({
        setTarget,
        updateTargetParams,
        addRows,
        targetFlow: {
          showReplaceTargetModal,
          commitSetTarget,
          resetSetTarget,
          closeTarget,
        },
      } as const),
    [
      setTarget,
      updateTargetParams,
      addRows,
      showReplaceTargetModal,
      commitSetTarget,
      resetSetTarget,
      closeTarget,
    ],
  )
}

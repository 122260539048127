import { isAxiosError } from './axios'

export const makeRetryNTimesIfNot422 =
  (retries = 4) =>
  (failureCount: number, error: unknown) => {
    if (isAxiosError(error) && error.response?.status === 422) {
      return false
    }
    return failureCount < retries
  }

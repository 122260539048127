import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  AddNewLineButton,
  BaseGrid,
  BaseGridEventHandlers,
  BaseGridOnCellValueChangedMetadata,
  BaseGridProps,
  BaseGridRow,
  CollapseExpandButton,
  DeleteRowsButton,
  DuplicateRowsButton,
  ResetGridButton,
  ToggleGroupingButton,
  TopBar,
} from '@fintastic/shared/ui/grid-framework'
import type { Maybe } from '@fintastic/shared/util/types'
import { toMaybe } from '@fintastic/shared/util/types'
import {
  buildListGridColumnDefinition,
  BuildListGridColumnDefinitionParams,
  buildPopulateForwardProps,
  cellEditors,
  ColumnColorSelector,
  CUSTOM_CELL_VALUE_CHANGED_EVENT_SOURCE,
  extractChangeFromEvent,
  FilterCellForChangeCallback,
  getContextMenuItemsByMaxRows,
  GridColumnColorsCssWrapper,
  isValueColumnEditable,
  ListIcon,
  listSettingsToolPanelDefinition,
  removeTimeDimension,
  setListColumnCellDataValue,
  SetListColumnCellDataValuePayload,
  useProcessClipboardPaste,
} from '@fintastic/web/feature/metrics-and-lists'
import {
  ColumnColor,
  CompactMetricData,
  createFieldKey,
  extractSelectedCellFromEvent,
  MetricGridRow,
  MetricWithoutDataValues,
  resolveDimensionColumnColor,
  resolveValueColumnColor,
} from '@fintastic/web/util/metrics-and-lists'
import {
  CellValueChangedEvent,
  ColDef,
  GetContextMenuItemsParams,
  GridApi,
  type ProcessDataFromClipboardParams,
} from 'ag-grid-community'
import {
  PanelBottomBar,
  PanelDragHandle,
} from '@fintastic/shared/ui/panel-framework'
import {
  ErrorAlert,
  ModelExplorerVersionSelector,
  SingleVersionSelector,
} from '@fintastic/shared/ui/components'
import { ListGridApi, ListGridProps } from './types'
import { ListDoesNotExistError } from './features/errors/errors'
import {
  ColumnFormulaButton,
  FormulaEditorRouter,
  FormulasTabContextProvider,
  useFormulasTabs,
} from '../../features/formulas'
import type { AgGridDefaultColumnHeaderGroupProps } from '@fintastic/shared/ui/ag-grid'
import { AgGridDefaultColumnHeaderProps } from '@fintastic/shared/ui/ag-grid'
import { FormulaButton, FormulaCollapse } from '@fintastic/web/feature/formulas'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { Box, Button, SxProps, Tooltip } from '@mui/material'
import { currencies } from '@fintastic/shared/data-access/currencies'
import { columnsToolPanelDefinition } from '../../features/columns-visibility'
import { useRowData } from './features/table-data/useRowData'
import { useReferenceMemo } from '@fintastic/shared/util/hooks'
import { isEqual } from 'lodash'
import {
  UnsupportedGranularityAggregationError,
  usePeriodSelectorContext,
  usePeriodSelectorResolution,
} from '@fintastic/web/util/period-selector'
import { useVersionEntitiesContext } from '@fintastic/web/data-access/versions'
import { maxRowsToExportExcel } from '@fintastic/shared/util/ag-grid-export'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { useBaseTimeDimensionCurrentValue } from '@fintastic/web/data-access/base-time-dimension'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { useListEditorApi } from '@fintastic/web/feature/list-editor'
import {
  handleSelectedCellsForAggregation,
  SelectedCellAggregation,
  useSelectedCellAggregationAgGridContext,
} from '@fintastic/web/feature/selected-cell-aggregation'
import { mapListErrorToUi } from './features/errors/error-to-ui-mapping'
import { versionIsLockedMessage } from '@fintastic/web/util/versions'
import { useHandleLockedCellClick } from '../../features/data-editing/useHandleLockedCellClick'
import { useWidgetContext } from '@fintastic/shared/ui/widgets-framework'
import { extendEditableCallback } from '@fintastic/shared/util/ag-grid'
import {
  makeDimensionFilterCallback,
  makeEditableCallback,
} from '../../features/dimensions-permissions/ag-grid-callbacks'
import {
  FloatingPanelHasFilter,
  FloatingPanelTrigger,
  listFilterToolPanelDefinition,
} from '@fintastic/web/feature/filters'
import { useFilterContext } from '@fintastic/web/util/filters'
import { useAuthUserInfo } from '@fintastic/web/feature/auth'
import {
  NoResultsOverlay,
  NoResultsOverlayProps,
} from '../../features/no-results-overlay'
import {
  EntityLevelHistoryButton,
  useIsHistoryAllowed,
} from '@fintastic/web/feature/history'
import { useListGridDirectNavigationExecutor } from './features/direct-navigation'
import { useHistoryAutoClose } from './features/history'
import { AgGridReact } from 'ag-grid-react'
import { useQueryClient } from 'react-query'
import { useListAddLineIntegration } from './features/list-add-lines'

const themeWrapperSx: SxProps = {
  '&.at-least-one-column-hidden': {
    '[data-icon="custom-columns"]': {
      background: 'var(--fintastic-applied-sidebar-button-bg)',
    },
  },
  '&.column-filter-is-applied': {
    '[data-icon="native-filter"]': {
      background: 'var(--fintastic-applied-sidebar-button-bg)',
    },
  },
  '&.api-filter-is-applied': {
    '[data-icon="native-filter"]': {
      background: 'var(--fintastic-applied-sidebar-button-bg)',
    },
  },
}

const defaultColumnColors: ColumnColor[] = []

const ListGridComponent = (
  {
    readonly = false,
    isLoading = false,
    context,
    columns,
    dimensions,
    rowDimensionId,
    onUpdate,
    onDuplicate,
    onDelete,
    setGridSizeCallback,
    title,
    enableLocalVersionSelector,
    error,
    versionId,
    versionEditable,
    listFormula,
    listConnectedTable,
    source,
    isVersionPage,
    clientOnlyMapping,
    columnIds,
    atListOneColumnHidden = false,
    columnColors = defaultColumnColors,
    enableColumnColors,
    handleUpdateColumnColors,
    onReset,
    listId,
    periodSelectorComponent,
    isLiveActuals,
    versionUserLock,
    dimensionPermissions,
    permissions,
    listOriginallyEmpty,
    allExistingColumnIds,
    listMetadata,
    ...restProps
  }: ListGridProps,
  ref: ForwardedRef<ListGridApi>,
): JSX.Element => {
  const widgetContext = useWidgetContext()
  const queryClient = useQueryClient()

  useHistoryAutoClose(versionId, listId)

  const isError = !!error
  const isInputList = source === 'input'

  const gridRef = useRef<AgGridReact<MetricGridRow>>()
  const gridApi = useRef<Maybe<GridApi<MetricGridRow>>>(null)
  const [gridRefChangedCounter, updateGridRefChangedCounter] = useState(0)
  const handleGridRefChanged = useCallback(() => {
    updateGridRefChangedCounter((c) => c + 1)
  }, [])
  const [gridHaveRenderedData, setGridHaveRenderedData] = useState(false)

  useImperativeHandle(ref, () => ({
    removeRows: (rowIds) => {
      gridApi.current?.applyTransaction({
        remove: rowIds.map((_rowId) => ({
          _rowId,
        })),
      })
    },
    updateCellDataValue: (payload: SetListColumnCellDataValuePayload) => {
      if (!gridApi.current) {
        return
      }
      try {
        setListColumnCellDataValue(gridApi.current, payload)
      } catch (error) {
        console.error(error)
      }
    },
  }))

  const versionEntities = useVersionEntitiesContext()
  const listEditorApi = useListEditorApi()

  const formulasTabsState = useFormulasTabs()
  const {
    isOpen: formulaIsOpen,
    tab: formulasTab,
    column: formulasColumn,
    openListFormula,
    closeFormula,
  } = formulasTabsState

  useEffect(() => {
    if (source === 'calculated') {
      openListFormula()
    }
  }, [openListFormula, source])

  useEffect(() => {
    if (source === 'calculated') {
      closeFormula()
    }
  }, [closeFormula, source])

  // @todo remove dimensions metadata usage
  const allDimensionValuesLabelsOrig = useMemo<Record<string, string>>(
    () =>
      Object.values(dimensions).reduce(
        (acc, dim) => ({ ...acc, ...dim.values }),
        {},
      ),
    [dimensions],
  )

  const allDimensionValuesLabels = useReferenceMemo(
    allDimensionValuesLabelsOrig,
    isEqual,
  )

  const [selectedRows, setSelectedRows] = useState<BaseGridRow[]>(() => [])

  const { timeDimension } = useMemo(
    () => removeTimeDimension(dimensions),
    [dimensions],
  )

  const timeColumnIds = useMemo(
    () =>
      Object.values(columns)
        .filter((c) => c.metadata.base_time_dimension_id)
        .map((c) => c.id),
    [columns],
  )

  useListGridDirectNavigationExecutor(
    useMemo(
      () => ({
        versionId,
        listId,
      }),
      [listId, versionId],
    ),
    useMemo(
      () => ({
        isLoading,
        widgetContext,
        gridRef:
          gridHaveRenderedData && gridRefChangedCounter > -1 ? gridRef : null,
        rowDimensionId,
        timeDimensionId: timeDimension || null,
        timeColumnIds,
        visibleColumnIds: columnIds,
        allExistingColumnIds,
        queryClient,
      }),
      [
        isLoading,
        rowDimensionId,
        widgetContext,
        gridHaveRenderedData,
        allExistingColumnIds,
        timeDimension,
        gridRefChangedCounter,
        columnIds,
        timeColumnIds,
        queryClient,
      ],
    ),
  )

  const periodsOriginal = useMemo(() => {
    if (!timeDimension) {
      return []
    }
    // @todo remove dimensions metadata usage
    return Object.keys(dimensions[timeDimension].values || {}).sort()
  }, [dimensions, timeDimension])

  // @todo make sure we really need it
  const periods = useReferenceMemo(periodsOriginal, isEqual)
  const periodSelection = usePeriodSelectorContext()
  const periodResolution = usePeriodSelectorResolution()

  const errorUiMapping = useMemo(
    () =>
      !error
        ? null
        : mapListErrorToUi(error, {
            periodResolution,
          }),
    [error, periodResolution],
  )

  const currentUserEmail = useAuthUserInfo()?.email || ''

  useRowData(
    gridApi,
    useMemo(
      () => ({
        columns,
        columnIds,
        versionId,
        rowDimension: rowDimensionId,
        timeDimension,
        periodSelection,
        listId,
        currentUserEmail,
        isLoading,
      }),
      [
        columnIds,
        columns,
        currentUserEmail,
        isLoading,
        listId,
        periodSelection,
        rowDimensionId,
        timeDimension,
        versionId,
      ],
    ),
  )

  const containTimeSeriesData = useMemo<boolean>(() => {
    if (!timeDimension) {
      return false
    }

    return !!Object.values(columns).find(
      ({ data }) =>
        data &&
        data.indexes.length > 1 &&
        data?.indexes.includes(timeDimension),
    )
  }, [columns, timeDimension])

  const columnsDataIgnored = useMemo<Record<string, MetricWithoutDataValues>>(
    () =>
      Object.fromEntries(
        Object.entries(columns).map(([k, metric]) => [
          k,
          {
            ...metric,
            data: {
              ...metric.data,
              values: null,
            },
          },
        ]),
      ),
    [columns],
  )

  const columnsDataIgnoredMemo = useReferenceMemo(columnsDataIgnored, isEqual)

  const gridProps = useMemo<
    Omit<BaseGridProps<MetricGridRow>, 'rowData'>
  >(() => {
    const createValueColumn = (
      column: ListColumnWrapper,
      period: Maybe<string> = null,
      restPeriods: Maybe<string[]> = null,
    ): BuildListGridColumnDefinitionParams => {
      const isEditableBasic = isValueColumnEditable(
        readonly,
        versionEditable,
        column.source(),
      )

      const isDimensionColumn = column.type() === 'dimension'
      const isCalculatedDimensionColumn =
        column.type() === 'calculated' && column.dataType() === 'dimension'
      const dimensionMetricOptions =
        isDimensionColumn || isCalculatedDimensionColumn
          ? dimensions[column.valuesDimensionId() as string]?.values || {}
          : {}

      const dimensionMetricOtherValueId = `${column.valuesDimensionId()}._other`
      const isDimensionColumnClearPossible =
        dimensionMetricOptions[dimensionMetricOtherValueId] !== undefined

      const currentVersionEntitiesWrapper = versionEntities.entities[versionId]

      const aggregatedCellEditingEnabled =
        period &&
        currentVersionEntitiesWrapper &&
        column.aggregationCellEditingAllowed(currentVersionEntitiesWrapper)

      const isAggregatedDataEdtitingAllowed =
        !column.aggregatedByTime() || aggregatedCellEditingEnabled

      const isEditable = Boolean(
        isAggregatedDataEdtitingAllowed && isEditableBasic,
      )

      const field = createFieldKey(versionId, column.id(), period)

      return {
        field,
        dataType: column.dataType(),
        rollUpFunction: column.categoryAggregation(),
        isEditable: isDimensionColumn
          ? extendEditableCallback(
              () => isEditable,
              makeEditableCallback(
                dimensionPermissions,
                field,
                column.valuesDimensionId()!,
              ),
            )
          : () => isEditable,
        isRequired: isDimensionColumn ? !isDimensionColumnClearPossible : false,
        title: column.label(),
        ...(isDimensionColumn || isCalculatedDimensionColumn
          ? {
              options: dimensionMetricOptions,
              optionAllowedToBeChosen: makeDimensionFilterCallback(
                dimensionPermissions,
                column.valuesDimensionId()!,
              ),
            }
          : {}),
        enableCellChangeFlash: true,
        getCellClearValue: () => {
          if (isDimensionColumnClearPossible) {
            return dimensionMetricOtherValueId
          }
          return null
        },
        getOverridingCellEditor:
          aggregatedCellEditingEnabled && column.aggregatedByTime()
            ? (cellEditor: unknown) => {
                const newCellEditor = `${cellEditor}ForAggregatedTime`
                if (!cellEditors[newCellEditor as keyof typeof cellEditors]) {
                  return cellEditor
                }
                return newCellEditor
              }
            : undefined,
        displaySettings: column.displaySettings().unwrap(),
        currency: currencies.find(
          (c) => c.code === column.displaySettings().currency(),
        ),
        populateForward:
          rowDimensionId !== null &&
          period !== null &&
          !column.aggregatedByTime()
            ? buildPopulateForwardProps({
                isEditable,
                allowed: !column.aggregatedByTime(),
                populatedColumnsFields: (restPeriods || []).map((p) =>
                  createFieldKey(versionId, column.id(), p),
                ),
                hasPeriod: period !== null && !!restPeriods?.length,
                onPopulate: (value, context) => {
                  if (!timeDimension) {
                    return
                  }

                  context.populatedColumnsFields.forEach((field) => {
                    context.rowNode.setDataValue(
                      field,
                      value,
                      CUSTOM_CELL_VALUE_CHANGED_EVENT_SOURCE,
                    )
                  })

                  onUpdate?.([
                    {
                      versionId,
                      changes: (restPeriods || []).map((period) => ({
                        metricId: column.id(),
                        dimensions: {
                          [rowDimensionId]: context.rowData[rowDimensionId],
                          [timeDimension]: period,
                        },
                        value,
                      })),
                    },
                  ])
                },
              })
            : undefined,
      }
    }

    const columnBuilder = buildListGridColumnDefinition(context)

    const filterOnUpdate: FilterCellForChangeCallback = (
      originalEvent,
      editingCell,
      checkingCell,
    ) => {
      // later we may need filtering for required fields, but now we don't have ones
      if (originalEvent.newValue === '') {
        return true
      }

      if (versionId !== editingCell.versionId || !versionEditable) {
        return false
      }

      const checkingCellMetric = columnsDataIgnoredMemo[checkingCell.metricId]
      const editingCellMetric = columnsDataIgnoredMemo[editingCell.metricId]

      // filter out from the range the read-only versions
      if (!checkingCellMetric || !editingCellMetric) {
        return false
      }

      // filter out metrics that don't match to editing cell's data type
      if (
        checkingCellMetric.metadata.value.type !==
        editingCellMetric.metadata.value.type
      ) {
        return false
      }

      // filter out metrics that don't match to editing cell's dimension id
      return !(
        checkingCellMetric.metadata.value.type === 'dimension' &&
        editingCellMetric.metadata.value.type === 'dimension' &&
        checkingCellMetric.metadata.value.dimension_id !==
          editingCellMetric.metadata.value.dimension_id
      )
    }

    const valueColumns: ColDef<MetricGridRow>[] = columnIds
      .filter((metricId) => Boolean(columnsDataIgnoredMemo[metricId]))
      .map((metricId) => {
        const metric = columnsDataIgnoredMemo[metricId]
        const wrappedColumn = new ListColumnWrapper(
          metric as typeof metric & {
            data: CompactMetricData
          },
        )

        if (
          !timeDimension ||
          !containTimeSeriesData ||
          !wrappedColumn.hasTimeDimension()
        ) {
          return {
            ...columnBuilder(createValueColumn(wrappedColumn)),
            suppressMenu: true,
            headerComponentParams: {
              isCalculated: wrappedColumn.isCalculated(),
              contentAfterText: wrappedColumn.isCalculated() ? (
                <ColumnFormulaButton
                  metricId={wrappedColumn.id()}
                  formulaIcon={isLiveActuals ? 'ax' : 'fx'}
                  disabled={source === 'calculated'}
                />
              ) : undefined,
              contentBeforeMenu:
                enableColumnColors && handleUpdateColumnColors ? (
                  <ColumnColorSelector
                    columnColors={columnColors}
                    handleUpdateColumnColors={handleUpdateColumnColors}
                    currentColumnColumn={resolveDimensionColumnColor(
                      columnColors,
                      metric.id,
                      versionId,
                    )}
                  />
                ) : null,
              ContentAfterMenuComponent: FloatingPanelTrigger,
              ContentAfterTextComponent: FloatingPanelHasFilter,
            } as AgGridDefaultColumnHeaderProps,
          }
        }

        const wrappedTimeDimension = toMaybe(
          versionEntities?.entities[versionId]?.findDimensionById(timeDimension)
            ?.dimension,
        )
        const allOrderedPeriods = toMaybe(
          wrappedTimeDimension?.type === 'Time'
            ? wrappedTimeDimension.orderedValuesIds
            : null,
        )
        const periods = (wrappedColumn.periodsList() || []).sort(
          allOrderedPeriods
            ? (a, b) =>
                allOrderedPeriods?.indexOf(a) - allOrderedPeriods?.indexOf(b)
            : undefined,
        )

        return {
          headerName: wrappedColumn.hasTimeDimension()
            ? wrappedColumn.label()
            : '',
          headerGroupComponent: 'defaultColumnHeaderGroup',
          headerGroupComponentParams: {
            isCalculated: wrappedColumn.isCalculated(),
            contentAfterText: wrappedColumn.isCalculated() ? (
              <ColumnFormulaButton
                metricId={wrappedColumn.id()}
                formulaIcon={isLiveActuals ? 'ax' : 'fx'}
                disabled={source === 'calculated'}
              />
            ) : undefined,
          } as AgGridDefaultColumnHeaderGroupProps,
          // @todo remove dimensions metadata usage
          children: periods.map((periodDimValueId, periodIndex) => {
            const dimValueLabel =
              dimensions[timeDimension].values?.[periodDimValueId]

            return {
              ...columnBuilder(
                createValueColumn(
                  wrappedColumn,
                  periodDimValueId,
                  periods.slice(periodIndex + 1),
                ),
              ),
              headerName: dimValueLabel || '', // [undefined] during loading
              suppressPaste: wrappedColumn.aggregatedByTime(),
              suppressMenu: true,
              headerComponentParams: {
                contentBeforeMenu:
                  enableColumnColors && handleUpdateColumnColors ? (
                    <ColumnColorSelector
                      columnColors={columnColors}
                      currentColumnColumn={resolveValueColumnColor(
                        columnColors,
                        {
                          versionId,
                          metricId: metric.id,
                          period: periodDimValueId,
                        },
                      )}
                      handleUpdateColumnColors={handleUpdateColumnColors}
                    />
                  ) : null,
                ContentAfterMenuComponent: FloatingPanelTrigger,
                ContentAfterTextComponent: FloatingPanelHasFilter,
              } as AgGridDefaultColumnHeaderGroupProps,
            }
          }),
        }
      })

    const editProps =
      onUpdate && rowDimensionId !== null
        ? {
            onCellValueChanged: (
              params: CellValueChangedEvent<MetricGridRow>,
              metadata?: BaseGridOnCellValueChangedMetadata,
            ) => {
              if (params.source === CUSTOM_CELL_VALUE_CHANGED_EVENT_SOURCE) {
                return
              }
              return onUpdate(
                extractChangeFromEvent(
                  [rowDimensionId],
                  timeDimension,
                  params,
                  metadata,
                  filterOnUpdate,
                  false,
                ),
              )
            },
          }
        : undefined

    return {
      customToolPanels: [
        columnsToolPanelDefinition,
        listFilterToolPanelDefinition,
        ...(listEditorApi ? [listSettingsToolPanelDefinition] : []),
      ],
      editProps: {
        ...editProps,
        rowIdField: '_rowId',
      },
      agColumnDefs: [...valueColumns],
      fallbackGroupValueFormatter: (value: unknown): Maybe<string> =>
        allDimensionValuesLabels[value as string] || null,
    }
  }, [
    context,
    columnIds,
    onUpdate,
    rowDimensionId,
    listEditorApi,
    readonly,
    versionEditable,
    dimensions,
    versionEntities.entities,
    versionId,
    timeDimension,
    columnsDataIgnoredMemo,
    containTimeSeriesData,
    isLiveActuals,
    source,
    enableColumnColors,
    handleUpdateColumnColors,
    columnColors,
    allDimensionValuesLabels,
    dimensionPermissions,
  ])

  const handleDuplicate = useCallback(() => {
    onDuplicate?.(selectedRows)
  }, [selectedRows, onDuplicate])

  const handleDelete = useCallback(() => {
    onDelete?.(selectedRows)
  }, [selectedRows, onDelete])

  const rootDataAttributes: Record<string, string> = useMemo(
    () => ({
      ...(readonly ? { 'data-readonly': '' } : {}),
    }),
    [readonly],
  )

  const handleProcessDataFromClipboard = useProcessClipboardPaste()

  const handlePasteClipboard = useCallback(
    (params: ProcessDataFromClipboardParams<MetricGridRow>) =>
      handleProcessDataFromClipboard(
        params,
        typeof listFormula === 'string' ? 'calculated-list' : 'list',
      ),
    [listFormula, handleProcessDataFromClipboard],
  )

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) => {
      const sheetName = restProps.versionLabel

      return getContextMenuItemsByMaxRows(
        params,
        maxRowsToExportExcel,
        (value: unknown): Maybe<string> =>
          allDimensionValuesLabels[value as string] || null,
        title + (source === 'calculated' ? '.List (calculated)' : '.List'),
        sheetName,
      )
    },
    [restProps, source, title, allDimensionValuesLabels],
  )

  const handleLockedCellClick = useHandleLockedCellClick(
    source,
    versionUserLock,
  )

  const eventHandlers = useMemo<BaseGridEventHandlers<MetricGridRow>>(
    () => ({
      onFirstDataRendered: () => {
        setGridHaveRenderedData(true)
      },
      onRowSelected: (event) => {
        setSelectedRows(event.api.getSelectedRows())
      },
      onRangeSelectionChanged: (e) => {
        handleSelectedCellsForAggregation<MetricGridRow>(
          e,
          extractSelectedCellFromEvent,
        )
      },
      onModelUpdated: (e) => {
        handleSelectedCellsForAggregation<MetricGridRow>(
          e,
          extractSelectedCellFromEvent,
        )
      },
      onCellClicked: (e) => {
        handleLockedCellClick(e)
      },
    }),
    [handleLockedCellClick],
  )

  const handleReset = useCallback(() => {
    onReset?.()
  }, [onReset])

  const filterContext = useFilterContext()

  const themeWrapperClassNames = useMemo<Record<string, boolean>>(
    () =>
      ({
        'at-least-one-column-hidden': atListOneColumnHidden,
        'api-filter-is-applied': filterContext.filterIsApplied,
      } as Record<string, boolean>),
    [atListOneColumnHidden, filterContext.filterIsApplied],
  )
  const baseTimeDimension = useBaseTimeDimensionCurrentValue()
  const showPeriodSelector =
    !!baseTimeDimension && !widgetContext?.boardInDesignMode

  const titleText = useMemo(() => titleFormatter(title) || 'List', [title])

  const selectedCellAgGridContext = useSelectedCellAggregationAgGridContext()

  const editButton = useMemo(() => {
    if (
      !listEditorApi ||
      (!listEditorApi.active && !listEditorApi.allowedToEnterEditMode)
    ) {
      return null
    }

    if (versionUserLock.editIsBlocked && versionUserLock.lockedBy !== 'me') {
      return (
        <Tooltip title={versionIsLockedMessage(versionUserLock)} arrow>
          <span>
            <Button variant="outlined" color="black" disabled={true}>
              Edit
            </Button>
          </span>
        </Tooltip>
      )
    }

    if (listEditorApi?.active) {
      return (
        <Tooltip title={'You already edit list'}>
          <span>
            <Button variant="outlined" color="black" disabled>
              Edit
            </Button>
          </span>
        </Tooltip>
      )
    }

    const disabled = isError || isLoading || listEditorApi?.active

    const text = listEditorApi?.editList.running ? 'Loading...' : 'Edit'

    return (
      <Button
        variant="outlined"
        color="black"
        onClick={() => listEditorApi.editList.run(listId)}
        disabled={disabled}
      >
        {text}
      </Button>
    )
  }, [isError, isLoading, listEditorApi, listId, versionUserLock])

  const {
    addLines,
    isThisListAddLineDrawerActive,
    importRowsButton: _importRowsButton,
    importRowsModal,
  } = useListAddLineIntegration(
    {
      versionId,
      listId,
    },
    {
      periodSelection,
      columnColors,
      selectedRows,
      listMetadata,
    },
  )

  const addNewLineButton = useMemo(() => {
    if (!permissions.allowAll && !permissions.canAddRows) {
      return null
    }

    if (versionUserLock.editIsBlocked) {
      return (
        <Tooltip
          title={versionIsLockedMessage(versionUserLock, 'Adding new rows')}
          arrow
        >
          <span>
            <AddNewLineButton disabled />
          </span>
        </Tooltip>
      )
    }

    return (
      <AddNewLineButton
        onAddNewRows={addLines}
        active={isThisListAddLineDrawerActive}
        title={
          isThisListAddLineDrawerActive
            ? 'Add lines directly from the open drawer at the bottom of this screen'
            : undefined
        }
        disabled={
          isLoading ||
          isError ||
          readonly ||
          isThisListAddLineDrawerActive ||
          widgetContext?.boardInDesignMode ||
          selectedRows.length > 0
        }
      />
    )
  }, [
    permissions.canAddRows,
    permissions.allowAll,
    versionUserLock,
    addLines,
    isThisListAddLineDrawerActive,
    isLoading,
    isError,
    readonly,
    widgetContext?.boardInDesignMode,
    selectedRows.length,
  ])

  const importRowsButton =
    !permissions.allowAll || versionUserLock.editIsBlocked
      ? null
      : _importRowsButton

  const dublicateLineButton = useMemo(() => {
    if (!onDuplicate) {
      return null
    }

    if (!permissions.allowAll && !permissions.canDuplicateRows) {
      return null
    }

    if (versionUserLock.editIsBlocked) {
      return (
        <Tooltip
          title={versionIsLockedMessage(versionUserLock, 'Duplicating rows')}
          arrow
        >
          <span>
            <DuplicateRowsButton onClick={handleDuplicate} disabled />
          </span>
        </Tooltip>
      )
    }

    return (
      <DuplicateRowsButton
        onClick={handleDuplicate}
        disabled={
          isLoading ||
          isError ||
          readonly ||
          widgetContext?.boardInDesignMode ||
          selectedRows.length === 0
        }
      />
    )
  }, [
    handleDuplicate,
    isError,
    isLoading,
    onDuplicate,
    readonly,
    permissions.canDuplicateRows,
    permissions.allowAll,
    selectedRows.length,
    versionUserLock,
    widgetContext?.boardInDesignMode,
  ])

  const deleteLineButton = useMemo(() => {
    if (!onDelete) {
      return null
    }

    if (!permissions.allowAll && !permissions.canDeleteRows) {
      return null
    }

    if (versionUserLock.editIsBlocked) {
      return (
        <Tooltip
          title={versionIsLockedMessage(versionUserLock, 'Deleting rows')}
          arrow
        >
          <span>
            <DeleteRowsButton onClick={handleDelete} disabled />
          </span>
        </Tooltip>
      )
    }
    return (
      <DeleteRowsButton
        onClick={handleDelete}
        disabled={
          isLoading ||
          isError ||
          readonly ||
          widgetContext?.boardInDesignMode ||
          selectedRows.length === 0
        }
      />
    )
  }, [
    handleDelete,
    isError,
    isLoading,
    onDelete,
    readonly,
    permissions.canDeleteRows,
    permissions.allowAll,
    selectedRows.length,
    versionUserLock,
    widgetContext?.boardInDesignMode,
  ])

  const historyAllowed = useIsHistoryAllowed()

  const isMaybeRecoverableError =
    isError &&
    (error instanceof ListDoesNotExistError ||
      error instanceof UnsupportedGranularityAggregationError)

  return (
    <FormulasTabContextProvider value={formulasTabsState}>
      <GridColumnColorsCssWrapper columnColors={columnColors} periods={periods}>
        <BaseGrid<MetricGridRow>
          {...gridProps}
          errorContent={
            errorUiMapping ? <ErrorAlert {...errorUiMapping} /> : undefined
          }
          getContextMenuItems={getContextMenuItems}
          rootDataAttributes={rootDataAttributes}
          context={context}
          agContext={selectedCellAgGridContext}
          allowGrouping={true}
          suppressMovableColumns={true}
          setGridSizeCallback={setGridSizeCallback}
          enableTitleDateAutoFormatting={false}
          onCollapseStateChanged={widgetContext?.toggleCollapsedVert}
          eventHandlers={eventHandlers}
          collapsedFromOutside={!!widgetContext?.isCollapsedVert}
          apiRef={gridApi}
          gridRef={gridRef}
          onGridRefChanged={handleGridRefChanged}
          processDataFromClipboard={handlePasteClipboard}
          enableColumnsSelectionSidebar={false}
          themeWrapperClassNames={themeWrapperClassNames}
          themeWrapperSx={themeWrapperSx}
          components={cellEditors}
          checkboxSelection
          suppressFiltersToolPanel
          suppressLoadingOverlay
          noRowsOverlayComponent={NoResultsOverlay}
          noRowsOverlayComponentParams={
            { listOriginallyEmpty } as NoResultsOverlayProps
          }
          forceUniqueRowIds={true}
          applyCustomColorForEditableCells={true}
          topbar={
            <>
              <TopBar
                title={titleText}
                titleIcon={
                  <Tooltip title="List">
                    <ListIcon />
                  </Tooltip>
                }
                leftContent={
                  <>
                    {widgetContext?.draggable && <PanelDragHandle />}
                    {widgetContext?.collapsable && (
                      <CollapseExpandButton disabled={isLoading} />
                    )}
                    {addNewLineButton}
                    {dublicateLineButton}
                    {deleteLineButton}
                    {importRowsButton}
                    {typeof listFormula === 'string' && (
                      <FormulaButton
                        onClick={
                          formulaIsOpen && formulasTab === 'list'
                            ? closeFormula
                            : openListFormula
                        }
                        disabled={isError || isLoading}
                        isActive={formulaIsOpen && formulasTab === 'list'}
                        icon={isLiveActuals ? 'ax' : 'fx'}
                      />
                    )}
                    {!isVersionPage && !widgetContext?.boardInDesignMode && (
                      <ModelExplorerVersionSelector
                        entityId={listId}
                        entityTitle={titleText}
                      />
                    )}
                  </>
                }
                rightContent={
                  <>
                    <FintasticThemeProvider applyLegacyTheme={false}>
                      {editButton}
                    </FintasticThemeProvider>
                    {showPeriodSelector && <Box>{periodSelectorComponent}</Box>}
                    {enableLocalVersionSelector === true &&
                      'localVersion' in restProps && (
                        <SingleVersionSelector
                          onChange={restProps.onChangeLocalVersion}
                          value={restProps.localVersion}
                          options={restProps.localVersionOptions}
                          overridesGlobal={
                            restProps.localVersionOverridesGlobal
                          }
                          disabled={
                            isLoading ||
                            widgetContext?.boardInDesignMode ||
                            (isError && !isMaybeRecoverableError)
                          }
                        />
                      )}
                    <ToggleGroupingButton disabled={isLoading || isError} />
                    {false && (
                      <ResetGridButton
                        onClick={handleReset}
                        disabled={isLoading || isError}
                      />
                    )}

                    {historyAllowed &&
                      isInputList &&
                      !widgetContext?.boardInDesignMode && (
                        <EntityLevelHistoryButton
                          entityId={listId}
                          versionId={versionId}
                          disabled={isLoading || isError}
                        />
                      )}
                  </>
                }
              />
              <FormulaCollapse isOpen={formulaIsOpen}>
                <FormulaEditorRouter
                  tab={formulasTab}
                  column={formulasColumn}
                  onRequestClose={closeFormula}
                  versionId={versionId}
                  versionEditable={versionEditable}
                  listFormula={listFormula}
                  columns={columns}
                  listConnectedTable={listConnectedTable}
                  formulaEditorTitle={
                    isLiveActuals ? 'Actuals Formula' : undefined
                  }
                  controlledMode={isVersionPage}
                  readonly={isVersionPage}
                  clientOnlyMapping={clientOnlyMapping || null}
                  controlledFormulaErrorForListItself={null}
                  controlledFormulaErrorForColumns={null}
                />
              </FormulaCollapse>
            </>
          }
          bottomBar={
            <PanelBottomBar>
              <SelectedCellAggregation />
            </PanelBottomBar>
          }
        />
        {importRowsModal}
      </GridColumnColorsCssWrapper>
    </FormulasTabContextProvider>
  )
}

export const ListGrid = forwardRef<ListGridApi, ListGridProps>(
  ListGridComponent,
)

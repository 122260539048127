import { useDirectNavigationExecutor } from '@fintastic/web/feature/direct-navigation'
import { useMemo } from 'react'

type Depedencies = {
  isLoading: boolean
}

export const useListConnectorDirectNavigationExecutor = (
  params: {
    versionId: string
    listId: string
  },
  dependencies: Depedencies,
) => {
  useDirectNavigationExecutor<Depedencies>(
    useMemo(
      () => ({
        subtaskId: 'listConnector/waitForMetadataToBeLoaded',
        shouldStartExecution: ({ task }) =>
          (task.params.type === 'listColumnCell' ||
            task.params.type === 'listRows') &&
          params.versionId === task.params.coordinates.versionId &&
          task.params.coordinates.listId === params.listId,
        dependencies,
        executor: function* ({ taskParams, checkDependency }) {
          if (
            taskParams.type !== 'listColumnCell' &&
            taskParams.type !== 'listRows'
          ) {
            throw new Error('unsupported target type')
          }

          yield* checkDependency((deps) => !deps.isLoading, {
            retries: 30,
            retryInterval: 1000,
            debugLabel: 'isLoading',
          })
        },
      }),
      [dependencies, params.listId, params.versionId],
    ),
  )
}

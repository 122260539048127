import React from 'react'
import { AlertTitle, Alert, alertClasses } from '@mui/material'

export type DefinedByFormulaAlertProps = {
  title: React.ReactNode
  description: React.ReactNode
}

export const ReadonlyAlert: React.FC<DefinedByFormulaAlertProps> = ({
  title,
  description,
}) => (
  <Alert
    severity="info"
    icon={false}
    sx={{
      padding: '12px 12px',
      whiteSpace: 'initial',
      [`& .${alertClasses.message}`]: {
        padding: 0,
      },
    }}
  >
    <AlertTitle
      sx={{
        lineHeight: 1.3,
        marginBottom: '8px',
      }}
    >
      {title}
    </AlertTitle>
    {description}
  </Alert>
)

import { QueryClient } from 'react-query'
import { reportQueryKeyBase } from '@fintastic/web/util/generic-report'
import { inActiveTab } from '@fintastic/shared/util/web-api'

export const invalidateReportsCache = (
  queryClient: QueryClient,
  versionIds: string[],
) => {
  inActiveTab(() => {
    Promise.all(
      versionIds.map((versionId) =>
        queryClient.invalidateQueries({
          queryKey: reportQueryKeyBase(versionId),
        }),
      ),
    )
  })
}

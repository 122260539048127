import type { ICellRendererParams } from 'ag-grid-community'
import { VersionTable, VersionTableReport } from '../types'
import { Tag, Tags } from '@fintastic/shared/ui/components'
import { Box, ClickAwayListener, Skeleton } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isArray, sortBy } from 'lodash'
import { useLoadVersionEntities } from '@fintastic/web/data-access/versions'
import { DimensionCompactIcon } from '@fintastic/shared/ui/icons'
import { cleanupDimensionName } from '@fintastic/web/util/metrics-and-lists'

export const DimensionsCellRenderer = (
  params: ICellRendererParams<Exclude<VersionTable, VersionTableReport>> & {
    versionId: string
    isLiveActuals: boolean
  },
) => {
  const entitiesQuery = useLoadVersionEntities(params.versionId)

  const [focused, setFocused] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)

  const prevRowHeight = useRef(40)
  useEffect(() => {
    const wrapperHeight = wrapperRef.current?.clientHeight || 40
    let nextRowHeight = focused ? wrapperHeight : 40

    if (nextRowHeight < 40) {
      nextRowHeight = 40
    }

    if (prevRowHeight.current === nextRowHeight) {
      return
    }

    params.node.setRowHeight(nextRowHeight)
    params.api.onRowHeightChanged()
    prevRowHeight.current = nextRowHeight
  }, [focused, params.api, params.node])

  const localDimensionIds = useMemo<string[]>(
    () => (isArray(params.value) ? params.value : []),
    [params.value],
  )

  const dimensionsWithLabels = useMemo<Tag[]>(
    () =>
      sortBy(
        localDimensionIds.map<Tag>((id) => ({
          id: id,
          title:
            cleanupDimensionName(
              entitiesQuery.data?.dimensions.find((d) => d.id === id)?.label ??
                '',
            ) || id,
        })),
        (d) => d.title?.toLocaleLowerCase() ?? d.id,
      ),
    [entitiesQuery.data?.dimensions, localDimensionIds],
  )

  if (entitiesQuery.isLoading) {
    if (localDimensionIds.length) {
      return (
        <Box py="6px" display="flex" gap={1}>
          {localDimensionIds.map((id, index) => (
            <Skeleton key={id} width={index % 2 === 0 ? 60 : 80} />
          ))}
        </Box>
      )
    }

    return null
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setFocused(false)
      }}
    >
      <Box
        py="6px"
        onClick={() => setFocused(true)}
        ref={wrapperRef}
        position="relative"
      >
        <Tags
          tags={dimensionsWithLabels}
          singleline={!focused}
          limit={!focused}
          icon={
            <DimensionCompactIcon fontSize="small" style={{ color: 'black' }} />
          }
        />
      </Box>
    </ClickAwayListener>
  )
}

import React from 'react'
import {
  StyledCalcTypeIcon,
  StyledType,
  StyledTypeLeft,
  StyledTypeRight,
  StyledTypeText,
  StyledDataType,
} from './Type.styled'
import { Typography } from '@mui/material'
import { FunctionAXIcon, FunctionFXIcon } from '@fintastic/shared/ui/icons'
import {
  MetricConfigurableDataValueType,
  mapDataTypeToItsLabel,
} from '@fintastic/web/util/metrics-and-lists'

export const Type: React.FC<{
  calculated: boolean
  liveActuals: boolean
  dataType: MetricConfigurableDataValueType
}> = ({ calculated, liveActuals, dataType }) => (
  <StyledType>
    <StyledTypeLeft>
      <StyledTypeText>
        <Typography variant="body1">Metric</Typography>
      </StyledTypeText>
      {calculated && (
        <StyledCalcTypeIcon>
          {liveActuals ? <FunctionAXIcon /> : <FunctionFXIcon />}
        </StyledCalcTypeIcon>
      )}
    </StyledTypeLeft>
    <StyledTypeRight>
      <StyledDataType>
        <Typography variant="body2" color="text.secondary">
          {mapDataTypeToItsLabel(dataType)}
        </Typography>
      </StyledDataType>
    </StyledTypeRight>
  </StyledType>
)

import {
  createFieldKey,
  destructureField,
  idLooksLikeColumn,
  isDataTypeConversionNecessary,
  ListWithoutData,
  MetricWithoutData,
} from '@fintastic/web/util/metrics-and-lists'
import { BaseGridRow } from '@fintastic/shared/ui/grid-framework'
import { Maybe } from '@fintastic/shared/util/types'
import {
  idLooksLikeDimensionValue,
  TimeDimensionValueId,
} from '@fintastic/web/util/dimensions'
import { titleFormatter } from '@fintastic/shared/util/formatters'

type ColumnKeyMapper = (key: string) => Maybe<string>

export const convertRows = ({
  sourceList,
  sourceRows,
  targetList,
  versionId,
}: {
  versionId: string
  sourceList: ListWithoutData
  targetList: ListWithoutData
  sourceRows: BaseGridRow[]
}): Maybe<BaseGridRow[]> => {
  const mapper = makeColumnKeysMapper({
    versionId,
    sourceList,
    targetList,
  })

  if (!mapper) {
    return null
  }

  const convertedRows: BaseGridRow[] = []
  for (let i = 0; i < sourceRows.length; i++) {
    const convertedRow = mapRow(sourceRows[i], mapper)
    if (convertedRow) {
      convertedRows.push(convertedRow)
    }
  }

  return convertedRows.length === 0 ? null : convertedRows
}

const makeColumnKeysMapper = ({
  versionId,
  sourceList,
  targetList,
}: {
  versionId: string
  sourceList: ListWithoutData
  targetList: ListWithoutData
}): Maybe<ColumnKeyMapper> => {
  type SourceColumnId = string
  type TargetColumnId = string
  type ColumnFormattedLabel = string

  const targetColumns: Record<
    ColumnFormattedLabel,
    MetricWithoutData | undefined
  > = Object.fromEntries(
    targetList.metrics.map((column) => [titleFormatter(column.label), column]),
  )

  const columnsMap: Record<SourceColumnId, TargetColumnId | undefined> = {}
  for (let i = 0; i < sourceList.metrics.length; i++) {
    const sourceColumn = sourceList.metrics[i]
    const targetColumn = targetColumns[titleFormatter(sourceColumn.label)]
    if (!targetColumn) {
      continue
    }
    if (!areColumnsCompatible(sourceColumn, targetColumn)) {
      continue
    }
    columnsMap[sourceColumn.id] = targetColumn.id
  }

  return (columnKey) => {
    const parsedColumnKey = parseColumnKey(columnKey, versionId)
    if (parsedColumnKey === null) {
      return null
    }
    // we do not support time columns for now
    if (parsedColumnKey.timeDimensionValueId !== null) {
      return null
    }
    const mappedColumnId = columnsMap[parsedColumnKey.metricId]
    if (!mappedColumnId) {
      return null
    }

    return createFieldKey(versionId, mappedColumnId)
  }
}

const mapRow = (
  row: BaseGridRow,
  mapColumnKey: ColumnKeyMapper,
): Maybe<BaseGridRow> => {
  const keys = Object.keys(row)
  const mappedColumns: Array<[key: string, value: BaseGridRow[string]]> = []

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const mappedKey = mapColumnKey(key)
    if (mappedKey === null) {
      continue
    }
    mappedColumns.push([mappedKey, row[key]])
  }

  return mappedColumns.length === 0 ? null : Object.fromEntries(mappedColumns)
}

const parseColumnKey = (
  key: string,
  versionId: string,
): Maybe<{
  metricId: string
  timeDimensionValueId: Maybe<TimeDimensionValueId>
}> => {
  try {
    const destructuredField = destructureField(key)
    if (destructuredField.length < 2 || destructuredField.length > 3) {
      return null
    }
    const [varsedVersionId, metricId, timeDimensionValueId] = destructuredField
    if (varsedVersionId !== versionId) {
      return null
    }
    if (!idLooksLikeColumn(metricId)) {
      return null
    }
    if (
      timeDimensionValueId !== undefined &&
      !idLooksLikeDimensionValue(timeDimensionValueId)
    ) {
      return null
    }
    return {
      metricId,
      timeDimensionValueId: timeDimensionValueId || null,
    }
  } catch (e) {
    return null
  }
}

const areColumnsCompatible = (
  sourceColumn: MetricWithoutData,
  targetColumn: MetricWithoutData,
): boolean => {
  // we do not support time columns yet
  if (
    sourceColumn.metadata.base_time_dimension_id ||
    targetColumn.metadata.base_time_dimension_id
  ) {
    return false
  }

  if (sourceColumn.source === 'calculated' || targetColumn.source !== 'input') {
    return false
  }

  const sourceDataType = sourceColumn.metadata.value.type
  const targetDataType = targetColumn.metadata.value.type

  if (sourceDataType === targetDataType) {
    if (sourceDataType === 'dimension') {
      return (
        sourceColumn.metadata.value.dimension_id ===
        targetColumn.metadata.value.dimension_id
      )
    }
    return true
  }

  if (isDataTypeConversionNecessary(sourceDataType, targetDataType)) {
    return false
  }

  return true
}

import { DimensionId, TimeDimensionId } from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'
import { isDataTypeAggregatable } from './data-types/data-type-groups'
import { MetricDataValueType } from '../types'

export const canMetricBeAppliedAsWeightsFor = (
  weighting: {
    id: string
    dimensionsIds: DimensionId[]
    timeDimension: Maybe<TimeDimensionId>
    dataType: MetricDataValueType
  },
  weighted: {
    id: string
    dimensionsIds: DimensionId[]
    timeDimension: Maybe<TimeDimensionId>
  },
): boolean => {
  // cannot use itself
  if (weighting.id === weighted.id) {
    return false
  }

  // data type of weighting metric should be "summable"
  if (!isDataTypeAggregatable(weighting.dataType)) {
    return false
  }

  // Time dimension has to be aligned if the weighting metric has one
  // we assume here that time dimension is a base one
  if (
    weighting.timeDimension !== null &&
    weighting.timeDimension !== weighted.timeDimension
  ) {
    return false
  }

  // We allow weighting metric to have less dimensions than weighted metric but not the other way around.
  // Weighted metric has to include all dimensions that exist in its weighting metric (but can have additional ones).
  const weightedMetricDims = new Set(weighted.dimensionsIds)
  for (let i = 0; i < weighting.dimensionsIds.length; i += 1) {
    if (!weightedMetricDims.has(weighting.dimensionsIds[i])) {
      return false
    }
  }

  return true
}

import { useQuery } from 'react-query'
import { getMetrics } from '../api/metrics-api'
import { metricsAndListsCacheKeys } from '../cache'
import { Maybe } from '@fintastic/shared/util/types'
import { isNullish } from '@fintastic/shared/util/functional-programming'

export function useLoadListOfMetrics(
  versionId: Maybe<string>,
  showHidden = false,
) {
  return useQuery(
    metricsAndListsCacheKeys.metrics(versionId, showHidden),
    async () => {
      if (versionId === null) {
        throw new Error('versionId is not provided')
      }
      const response = await getMetrics(versionId, showHidden)
      return response.data.result
    },
    {
      enabled: !isNullish(versionId),
    },
  )
}

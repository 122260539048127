import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ExternalFilter } from '@fintastic/shared/util/ag-grid'
import { useSyncDeeplinkValue } from '@fintastic/web/util/deeplink'
import {
  GenericReportRow,
  GenericReportTreeRow,
  GenericReportTreeRowCellValue,
} from '@fintastic/web/util/generic-report'
import { AgGridReact } from 'ag-grid-react'

export type BlanksExternalFilterApi = ReturnType<typeof useBlanksExternalFilter>

export function useBlanksExternalFilter(
  gridApiRef: React.RefObject<AgGridReact<GenericReportTreeRowCellValue>>,
  isRowBlank: (data: GenericReportTreeRow) => boolean,
  context?: {
    widgetId?: string
  },
) {
  const [showBlanks, setShowBlanks] = useStateWithOptionalDeeplink(
    context?.widgetId,
  )
  const showBlanksRef = useRef(showBlanks)
  showBlanksRef.current = showBlanks

  const toggleBlanks = useCallback(
    (show: boolean) => {
      setShowBlanks(show)
    },
    [setShowBlanks],
  )

  useEffect(() => {
    gridApiRef.current?.api?.onFilterChanged()
  }, [gridApiRef, showBlanks])

  const externalFilter = useMemo<ExternalFilter<GenericReportRow>>(
    () => ({
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: (node) => {
        if (showBlanksRef.current) {
          return true
        }
        return node.data
          ? !isRowBlank(node.data as GenericReportTreeRow)
          : false
      },
    }),
    [isRowBlank],
  )

  return useMemo(
    () =>
      ({
        externalFilter,
        blanksVisible: showBlanks,
        toggleBlanks,
      } as const),
    [externalFilter, showBlanks, toggleBlanks],
  )
}

const useStateWithOptionalDeeplink = (widgetId?: string) => {
  // If widget id is provided
  const [deeplinkState, setDeeplinkState] = useSyncDeeplinkValue<boolean>({
    key: `w${widgetId || ''}_show-blanks`,
    defaultValue: false,
  })

  // If widget id is NOT provided
  const [localState, setLocalState] = useState(() => {
    const shouldShowBlanks = sessionStorage.getItem('_blanks_once_after_edit')
    return !!shouldShowBlanks
  })

  useLayoutEffect(() => {
    // it happens after useState, but just to make sure -
    setTimeout(() => {
      sessionStorage.removeItem('_blanks_once_after_edit')
    }, 1000)
  }, [])

  const handleShowBlanksChanged = useCallback(
    (showBlanks: boolean) => {
      if (widgetId) {
        setDeeplinkState(showBlanks)
      } else {
        setLocalState(showBlanks)
      }
    },
    [setDeeplinkState, widgetId],
  )

  const showBlanks = widgetId ? deeplinkState : localState

  return [showBlanks, handleShowBlanksChanged] as const
}

import React from 'react'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export const LinearProgressWithValueLabel: React.FC<
  LinearProgressProps & { value: number }
> = (props) => (
  <Box
    sx={{ display: 'flex', alignItems: 'center' }}
    data-testid="linear-progress"
  >
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress variant="determinate" {...props} />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >{`${Math.round(props.value)}%`}</Typography>
    </Box>
  </Box>
)

import {
  Filter,
  FilterByBoolean,
  FilterByDate,
  FilterByDimension,
  FilterByNumber,
  FilterByString,
} from '@fintastic/web/util/filters'
import { useMemo } from 'react'
import {
  MetricV2CacheListColumnParams,
  useV2ListColumnsQuery,
} from '@fintastic/web/data-access/metrics-and-lists'
import { Dictionary, compact, keyBy, sortBy } from 'lodash'
import type { DimensionWithoutLabel, Maybe } from '@fintastic/shared/util/types'
import { useLoadVersionEntities } from '@fintastic/web/data-access/versions'
import {
  MetricDataValueType,
  Metric,
} from '@fintastic/web/util/metrics-and-lists'
import { usePeriodSelectorContext } from '@fintastic/web/util/period-selector'

export const useListFilters = ({
  listId,
  versionId,
  visibleColumnIds,
}: UseListFilterParams): Filter[] => {
  const periodSelection = usePeriodSelectorContext()

  const mappedColumnIds = useMemo<MetricV2CacheListColumnParams[]>(
    () =>
      visibleColumnIds.map<MetricV2CacheListColumnParams>((metricId) => ({
        listId,
        versionId,
        metricId,
        // No need for real data, just metadata
        pagination: {
          offset: 0,
          limit: 0,
        },
        period: periodSelection,
      })),
    [listId, periodSelection, versionId, visibleColumnIds],
  )

  const listColumnsQuery = useV2ListColumnsQuery(mappedColumnIds, true)
  const versionEntities = useLoadVersionEntities(versionId)

  return useMemo<Filter[]>(() => {
    const readyData = compact(listColumnsQuery.data)
    if (!readyData?.length || !versionEntities.data) {
      return []
    }

    const extractDimensionFilter = (
      metric: Metric,
      hashedDimensions: Dictionary<DimensionWithoutLabel>,
    ): PartialFilter<FilterByDimension> => {
      if (
        metric.metadata.value.type !== 'dimension' ||
        !metric.metadata.value.dimension_id
      ) {
        return null
      }

      const entityDimension = versionEntities.data.dimensions.find(
        (d) => d.id === metric.metadata.value.dimension_id,
      )

      if (entityDimension?.type !== 'Category') {
        return null
      }

      // Find dimension in the metric with data and map it to options
      const options = sortBy(
        (
          hashedDimensions[metric.metadata.value.dimension_id]?.viewable ?? []
        ).map((value) => ({
          value,
          label: entityDimension.values[value],
        })),
        'label',
      )

      if (!options.length) {
        return null
      }

      return {
        id: metric.id,
        type: 'dimension',
        label: metric.label,
        options,
      }
    }

    const extractNumericFilter = (
      metric: Metric,
    ): PartialFilter<FilterByNumber> => {
      if (!numericDataTypes.includes(metric.metadata.value.type)) {
        return null
      }

      return {
        id: metric.id,
        label: metric.label,
        type: 'numeric',
        displayConfig: metric.metadata.display_config,
      }
    }

    const extractStringFilter = (
      metric: Metric,
    ): PartialFilter<FilterByString> => {
      if (metric.metadata.value.type !== 'string') {
        return null
      }

      return {
        id: metric.id,
        label: metric.label,
        type: 'string',
      }
    }

    const extractDateFilter = (metric: Metric): PartialFilter<FilterByDate> => {
      if (metric.metadata.value.type !== 'datetime') {
        return null
      }

      return {
        id: metric.id,
        label: metric.label,
        type: 'date',
      }
    }

    const extractBooleanFilter = (
      metric: Metric,
    ): PartialFilter<FilterByBoolean> => {
      if (metric.metadata.value.type !== 'boolean') {
        return null
      }

      return {
        id: metric.id,
        label: metric.label,
        type: 'boolean',
      }
    }

    return compact(
      readyData.map<Maybe<Filter>>((metric) => {
        if (!visibleColumnIds.includes(metric.id)) {
          return null
        }

        const hashedDimensions = keyBy(metric?.metadata.dimensions ?? [], 'id')

        const filter =
          extractNumericFilter(metric) ||
          extractStringFilter(metric) ||
          extractDateFilter(metric) ||
          extractBooleanFilter(metric) ||
          extractDimensionFilter(metric, hashedDimensions)

        if (!filter) {
          return null
        }

        const time_dimension_values = Object.keys(
          hashedDimensions[metric.metadata.time_dimension_id ?? '']?.values ??
            {},
        )

        return {
          ...filter,
          dataType: metric.metadata.value.type,
          time_dimension_values,
          time_dimension_id: metric.metadata.time_dimension_id ?? null,
        } as Filter
      }),
    )
  }, [listColumnsQuery.data, versionEntities.data, visibleColumnIds])
}

export type UseListFilterParams = {
  listId: string
  versionId: string
  visibleColumnIds: string[]
}

const numericDataTypes: MetricDataValueType[] = [
  'percentage',
  'currency',
  'integer',
  'number',
  'percentage_integer',
]

type PartialFilter<T extends Filter> = Maybe<
  Omit<T, 'dataType' | 'time_dimension_id' | 'time_dimension_values'>
>

import React from 'react'
import { Maybe } from '@fintastic/shared/util/types'
import { MultiversionChartData } from '../../../../../types'
import { ErrorAlert } from '@fintastic/shared/ui/components'
import { StyledChartError } from './MetricChartPanels.styled'
import {
  doNotSupportRequestedGranularityMessage,
  usePeriodSelectorResolution,
} from '@fintastic/web/util/period-selector'
import {
  CalendarIcon,
  EmptyFolderErrorIcon,
  PermissionsErrorIcon,
  WarningIcon,
} from '@fintastic/shared/ui/icons'

type MetricChartPanelsErrorProps = {
  chartData: Maybe<MultiversionChartData>
}

export const MetricChartPanelsError: React.FC<MetricChartPanelsErrorProps> = ({
  chartData,
}) => {
  const periodResolution = usePeriodSelectorResolution()

  if (chartData?.dataMostSevereError === 'TIME_DIM_ABSENT') {
    return (
      <StyledChartError>
        <ErrorAlert
          title={
            'Some versions have time dimension while other do not have it in the metric'
          }
          message={'Try selecting another version(s)'}
          severity={'info'}
          icon={<EmptyFolderErrorIcon />}
        />
      </StyledChartError>
    )
  }

  if (chartData?.dataMostSevereError === 'INVALID_RESOLUTION') {
    const title = doNotSupportRequestedGranularityMessage(
      'metric_chart',
      periodResolution,
    )
    return (
      <StyledChartError>
        <ErrorAlert
          title={title}
          message={'Try selecting another time granularity'}
          severity={'info'}
          icon={<CalendarIcon />}
        />
      </StyledChartError>
    )
  }

  if (
    chartData?.dataMostSevereError === 'METRIC_NOT_FOUND' ||
    chartData?.dataMostSevereError === 'VERSION_NOT_FOUND'
  ) {
    return (
      <StyledChartError>
        <ErrorAlert
          title={`Chart doesn't exist in the selected version${
            (chartData?.versionsRequested || 0) > 1 ? 's' : ''
          }`}
          message={'Try selecting another version'}
          severity={'info'}
          icon={<EmptyFolderErrorIcon />}
        />
      </StyledChartError>
    )
  }

  if (
    chartData?.dataMostSevereError === 'METRIC_FORBIDDEN' ||
    chartData?.dataMostSevereError === 'VERSION_FORBIDDEN'
  ) {
    return (
      <StyledChartError>
        <ErrorAlert
          title={'You need permissions to view this metric'}
          message={'Please try again or reach out the administrator.'}
          severity={'info'}
          icon={<PermissionsErrorIcon />}
        />
      </StyledChartError>
    )
  }

  if (
    chartData?.data &&
    chartData.data.length === 0 &&
    !chartData.allVersionsFailed
  ) {
    return (
      <StyledChartError>
        <ErrorAlert
          title={'Nothing to show'}
          message={'Please try again or reach out the administrator.'}
          severity={'info'}
          icon={<EmptyFolderErrorIcon />}
        />
      </StyledChartError>
    )
  }

  // generic (unknown) error
  return (
    <StyledChartError>
      <ErrorAlert
        title={'Something went wrong'}
        message={'Please try again or reach out the administrator.'}
        severity={'warning'}
        icon={<WarningIcon />}
      />
    </StyledChartError>
  )
}

import React from 'react'
import { createRoot } from 'react-dom/client'
import { AppContainer } from './AppContainer'
import reportWebVitals from './reportWebVitals'
import { init } from './init'

init().then(() => {
  const root = createRoot(document.getElementById('root')!)
  root.render(
    <React.StrictMode>
      <AppContainer />
    </React.StrictMode>,
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log)

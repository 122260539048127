import React, { useEffect, useRef } from 'react'
import { StyledInputContainer, StyledRoot } from './SearchInput.styled'
import { InputAdornment, TextField } from '@mui/material'
import { SearchIcon } from '@fintastic/shared/ui/icons'

export type SearchInputProps = {
  value: string
  onChange: (v: string) => void
  autoFocus?: boolean
}

export const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  autoFocus = true,
}: SearchInputProps) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.focus()
      }
    }, 200)
  }, [])

  return (
    <StyledRoot>
      <StyledInputContainer data-testid={'search-input-container'}>
        <TextField
          value={value}
          onChange={(e) => onChange(e.target.value)}
          fullWidth={true}
          variant="outlined"
          size="small"
          autoFocus={autoFocus}
          data-testid={'search-input-field-container'}
          InputProps={{
            autoComplete: 'off',
            placeholder: 'Search',
            inputRef: ref,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </StyledInputContainer>
    </StyledRoot>
  )
}

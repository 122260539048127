import { styled } from '@mui/material'

export const StyledScrollContainer = styled('div')`
  overflow-x: hidden;
  overflow-y: auto;

  border-top: 1px solid ${({ theme }) => theme.palette.divider};

  width: 100%;
`

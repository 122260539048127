import { AddLinesStateData } from '@fintastic/web/feature/list-add-lines'
import { useCallback } from 'react'
import { saveNewRows } from '../api'
import { ColumnsIdStructure } from '../columns'
import { AxiosError } from 'axios'
import { NonRecoverableSaveError, RecoverableSaveError } from '../consts'
import { convertStateToServerFormat } from './data-store-utils'
import { useDataUpdateOperationGlobalStateContext } from '@fintastic/web/data-access/metric-data-editing'

export const useSaveNewRows = (versionId: string, listId: string) => {
  const { startOperation } = useDataUpdateOperationGlobalStateContext()

  const handleSave = useCallback(
    async (data: AddLinesStateData, structure: ColumnsIdStructure) => {
      const dataToSend = convertStateToServerFormat(structure, data.values)
      if (!dataToSend) {
        return false
      }

      try {
        const {
          data: { stream_event_id },
        } = await saveNewRows(versionId, listId, dataToSend)
        startOperation({
          type: 'lists/addLine',
          versionId,
          streamEventId: stream_event_id,
          params: {
            listId,
          },
        })
      } catch (err) {
        console.error(err)

        if ((err as AxiosError).isAxiosError) {
          if ((err as AxiosError).response?.status === 404) {
            throw new RecoverableSaveError(
              'List not found (it is possible that changes to your permissions prevent you from accessing it, or it is temporarily locked)',
            )
          }

          if ((err as AxiosError).response?.status === 403) {
            throw new RecoverableSaveError(
              'You selected dimension value(s) that have been deleted',
            )
          }

          if ((err as AxiosError).response?.status === 422) {
            throw new NonRecoverableSaveError(
              'Changes to your permissions have prevented you from saving the lines',
            )
          }

          if ((err as AxiosError).response?.status || 0 >= 500) {
            throw new NonRecoverableSaveError(
              'Something went wrong: Internal server error',
            )
          }
        }

        throw new NonRecoverableSaveError('Something went wrong: Unknown error')
      }
      return true
    },
    [listId, startOperation, versionId],
  )

  return { saveNewRows: handleSave }
}

import React, { useEffect, useMemo } from 'react'
import { ListGridConnector } from '../list-grid'
import {
  SetGridSizeCallback,
  TopBar,
} from '@fintastic/shared/ui/grid-framework'
import { ListGridConnectorSettingsPanelProp } from '../list-grid/types'
import {
  ColumnColorsGridProps,
  ListIcon,
} from '@fintastic/web/feature/metrics-and-lists'
import { useVersionsApi } from './features/versions-api/useVersionsApi'
import { useListsApi } from './features/lists-api/useListsApi'
import { ListWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { Panel } from '@fintastic/shared/ui/panel-framework'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { Tooltip } from '@mui/material'
import {
  useVersionEntitiesContextValue,
  VersionEntitiesContext,
} from '@fintastic/web/data-access/versions'
import { useVersionsSelectorProps } from './features/versions-selector/useVersionsSelectorProps'
import { PaginatedListConnector } from '../paginated-list-grid'
import { useLocalColumnsVisibility } from './features/columns-visibility'
import { ColumnsPanelContext } from '../../features/columns-visibility'
import { ListFilterContextProvider } from '@fintastic/web/feature/filters'
import { useNoRowsWithPermittedDims } from './features/no-rows-with-permitted-dims'
import { useListConnectorDirectNavigationExecutor } from './features/direct-navigation'

export type ListConnectorProps = {
  listId: string
  selectableVersions: string[]
  title?: string
  setGridSizeCallback?: SetGridSizeCallback
  enableLocalVersionSelector?: boolean
  settingsPanel?: ListGridConnectorSettingsPanelProp // @todo remove
  isVersionPage?: boolean
  isCreationDummy?: boolean // @todo remove
  periodSelectorComponent: React.ReactNode
  requestEntityDeletion?: (entityId: string) => void
} & Partial<ColumnColorsGridProps>

const PAGINATED_LIST_SIZE_THRESHOLD = 1_000_000

export const ListConnector: React.FC<ListConnectorProps> = (props) => {
  const {
    selectableVersions,
    listId,
    isCreationDummy,
    enableLocalVersionSelector,
    requestEntityDeletion,
  } = props

  const versionsApi = useVersionsApi(selectableVersions)
  const { versionId } = versionsApi

  const listsApi = useListsApi({
    versionId: versionId || '',
    allVersions: selectableVersions,
    listId,
    isClientOnlyList: !!isCreationDummy,
  })
  const { list } = listsApi
  const { columnsPanelContextValue, visibleColumnIds } =
    useLocalColumnsVisibility(versionId || '', listId, list)

  const noRowsWithPermittedDimsApi = useNoRowsWithPermittedDims({
    listId,
    versionId,
    visibleColumnIds,
  })

  const listSize = useMemo(
    () => (list ? new ListWrapper(list).size(visibleColumnIds) : null),
    [list, visibleColumnIds],
  )

  const isPaginatedList =
    listSize === null
      ? false
      : listSize >= PAGINATED_LIST_SIZE_THRESHOLD &&
        (list?.source === 'raw' || list?.source === 'calculated')

  const entitiesContextValue = useVersionEntitiesContextValue(
    useMemo(() => (versionId ? [versionId] : []), [versionId]),
  )

  const versionSelectorProps = useVersionsSelectorProps(
    Boolean(enableLocalVersionSelector),
    selectableVersions,
    versionsApi,
    listsApi,
  )

  useEffect(() => {
    if (
      !versionsApi.versionId ||
      !listsApi.versionsWhereListDoesNotLoad.includes(versionsApi.versionId)
    ) {
      return
    }

    versionsApi.switchToVersion(
      selectableVersions.find(
        (versionId) =>
          !listsApi.versionsWhereListDoesNotLoad.includes(versionId),
      ) || selectableVersions[0],
    )
  }, [listsApi.versionsWhereListDoesNotLoad, selectableVersions, versionsApi])

  const isLoading =
    versionsApi.isLoading ||
    listsApi.isLoading ||
    entitiesContextValue.isLoading ||
    noRowsWithPermittedDimsApi.loading

  useListConnectorDirectNavigationExecutor(
    useMemo(
      () => ({
        versionId,
        listId,
      }),
      [versionId, listId],
    ),
    useMemo(
      () => ({
        isLoading,
      }),
      [isLoading],
    ),
  )

  if (isLoading) {
    return (
      <Panel
        isLoading={true}
        fullHeight={true}
        topbar={
          <TopBar
            title={titleFormatter(props.title)}
            titleIcon={
              <Tooltip title="List">
                <ListIcon />
              </Tooltip>
            }
          />
        }
      >
        <div />
      </Panel>
    )
  }

  const paginatedList = (
    <PaginatedListConnector
      title={props.title || ''}
      listId={props.listId}
      listsApi={listsApi}
      versionsApi={versionsApi}
      periodSelectorComponent={props.periodSelectorComponent}
      versionSelectorProps={versionSelectorProps}
      showSettingsPanel={!!props.isVersionPage}
      visibleColumnIds={visibleColumnIds}
      isVersionPage={!!props.isVersionPage}
      columnColors={props.columnColors}
      enableColumnColors={props.enableColumnColors}
      handleUpdateColumnColors={props.handleUpdateColumnColors}
      requestEntityDeletion={requestEntityDeletion}
      setGridSizeCallback={props.setGridSizeCallback}
      noRowsForPermittedDims={noRowsWithPermittedDimsApi.matches}
    />
  )

  const normalList = (
    <ListGridConnector
      {...props}
      listsApi={listsApi}
      versionsApi={versionsApi}
      versionSelectorProps={versionSelectorProps}
      visibleColumnIds={visibleColumnIds}
      requestEntityDeletion={requestEntityDeletion}
      noRowsForPermittedDims={noRowsWithPermittedDimsApi.matches}
    />
  )

  const content = isPaginatedList ? paginatedList : normalList

  return (
    <VersionEntitiesContext.Provider value={entitiesContextValue}>
      <ColumnsPanelContext.Provider value={columnsPanelContextValue}>
        <ListFilterContextProvider
          listId={props.listId}
          versionId={versionId}
          visibleColumnIds={visibleColumnIds}
        >
          {content}
        </ListFilterContextProvider>
      </ColumnsPanelContext.Provider>
    </VersionEntitiesContext.Provider>
  )
}

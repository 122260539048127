import React, { useMemo } from 'react'
import { AggregationSettingsProps as SharedProps } from '../types'
import { RollUpFunction } from '@fintastic/shared/util/types'
import {
  StyledList,
  StyledRoot,
  StyledTitle,
} from './AggregationSettings.styled'
import { useFlagsContext } from '../flags-context'
import { SelectableMenuItem } from '@fintastic/shared/ui/components'
import { WeightedAverageMenuItem } from './WeightedAverageMenuItem'
import { resolveRollUpName } from '../../../../../../utils/resolveRollUpName'
import { CompactMetric } from '@fintastic/web/util/metrics-and-lists'

export type AggregationSettingsProps = SharedProps & {
  title: string
  disabledFunctions?: RollUpFunction[]
  metricAllowedForWeights?: (metric: CompactMetric) => boolean
}

export const AggregationSettings: React.FC<AggregationSettingsProps> = ({
  rollUpFunction,
  disabledFunctions,
  title,
  weightsMetricId,
  onChange,
  metricAllowedForWeights,
}) => {
  const { readonly } = useFlagsContext()

  const menuItems = useMemo(() => {
    const allMenuItems: Array<{
      rollUpFunction: RollUpFunction
      node: React.ReactNode
    }> = [
      {
        rollUpFunction: 'sum',
        node: (
          <SelectableMenuItem
            label={resolveRollUpName('sum')}
            key="sum"
            onClick={() => onChange({ rollUpFunction: 'sum' })}
            disabled={readonly}
            selected={rollUpFunction === 'sum'}
          />
        ),
      },
      {
        rollUpFunction: 'mean',
        node: (
          <SelectableMenuItem
            label={resolveRollUpName('mean')}
            key="mean"
            onClick={() => onChange({ rollUpFunction: 'mean' })}
            disabled={readonly}
            selected={rollUpFunction === 'mean'}
          />
        ),
      },
      {
        rollUpFunction: 'first',
        node: (
          <SelectableMenuItem
            label={resolveRollUpName('first')}
            key="first"
            onClick={() => onChange({ rollUpFunction: 'first' })}
            disabled={readonly}
            selected={rollUpFunction === 'first'}
          />
        ),
      },
      {
        rollUpFunction: 'last',
        node: (
          <SelectableMenuItem
            label={resolveRollUpName('last')}
            key="last"
            onClick={() => onChange({ rollUpFunction: 'last' })}
            disabled={readonly}
            selected={rollUpFunction === 'last'}
          />
        ),
      },
      {
        rollUpFunction: 'weighted_avg',
        node: (
          <WeightedAverageMenuItem
            key="weighted_avg"
            selected={rollUpFunction === 'weighted_avg'}
            onChange={onChange}
            currentMetricId={weightsMetricId}
            metricAllowedForWeights={metricAllowedForWeights}
          />
        ),
      },
      {
        rollUpFunction: 'noop',
        node: (
          <SelectableMenuItem
            label={resolveRollUpName('noop')}
            key="noop"
            onClick={() => onChange({ rollUpFunction: 'noop' })}
            disabled={readonly}
            selected={rollUpFunction === 'noop'}
          />
        ),
      },
    ]

    return allMenuItems
      .filter(
        (item) =>
          !(disabledFunctions || []).includes(item.rollUpFunction) &&
          (readonly ? item.rollUpFunction === rollUpFunction : true),
      )
      .map((item) => item.node)
  }, [
    disabledFunctions,
    metricAllowedForWeights,
    onChange,
    readonly,
    rollUpFunction,
    weightsMetricId,
  ])

  return (
    <StyledRoot>
      <StyledTitle variant="overline" color="text.secondary">
        {title}
      </StyledTitle>
      <StyledList>{menuItems}</StyledList>
    </StyledRoot>
  )
}

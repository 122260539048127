import { axios } from '@fintastic/web/data-access/service-axios'
import { HistoryLogEntry } from '@fintastic/web/util/history'
import { HistoryLogFilters, HistoryLogPagination } from './types'

export type GetHistoryLogEntriesParams = {
  filter: HistoryLogFilters
  pagination: HistoryLogPagination
  desc: boolean
}

export const getHistoryLogEntries = (params: GetHistoryLogEntriesParams) =>
  axios.post<{
    total: number
    result: Omit<HistoryLogEntry, '_type'>[]
  }>('/audit/log/records-snapshot/', params, {
    transformRequest: [
      (data: GetHistoryLogEntriesParams) => {
        const desc = data.desc
        const pagination = data.pagination
        const filter: HistoryLogFilters = {}

        // backend does not process correctly empty arrays, that's why
        // filter object must be constructed from an empty object
        if (data.filter.entity_type && data.filter.entity_type.length > 0) {
          filter.entity_type = data.filter.entity_type
        }

        if (data.filter.entity_id && data.filter.entity_id.length > 0) {
          filter.entity_id = data.filter.entity_id
        }

        if (
          data.filter.parent_entity_id &&
          data.filter.parent_entity_id.length > 0
        ) {
          filter.parent_entity_id = data.filter.parent_entity_id
        }

        if (
          data.filter.parent_entity_type &&
          data.filter.parent_entity_type.length > 0
        ) {
          filter.parent_entity_type = data.filter.parent_entity_type
        }

        if (data.filter.max_date) {
          filter.max_date = data.filter.max_date
        }

        if (data.filter.min_date) {
          filter.min_date = data.filter.min_date
        }

        if (data.filter.action) {
          filter.action = data.filter.action
        }

        return {
          desc,
          pagination,
          filter,
        } as GetHistoryLogEntriesParams
      },
      ...(axios.defaults.transformRequest
        ? axios.defaults.transformRequest instanceof Array
          ? axios.defaults.transformRequest
          : [axios.defaults.transformRequest]
        : []),
    ],
  })

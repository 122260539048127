import { Maybe } from '@fintastic/shared/util/types'
import wcmatch from 'wildcard-match'

export type TenantConfig = {
  auth0ClientId: string
  auth0OrgId?: string
  tenantLogo: string | null
  overridingApiUrl?: string
}

type TenantHost = string | '*'
type TenantOverrideTenantKey = string

export type TenantConfigsSet =
  | TenantConfigsSetLegacyVersion
  | TenantConfigsSetVersion2
type TenantConfigsSetLegacyVersion = Record<TenantHost, TenantConfig>
type TenantConfigsSetVersion2 = {
  version: 2
  byHost?: Record<TenantHost, TenantConfig>
  byTenantOverride?: Record<TenantOverrideTenantKey, TenantConfig>
}

export const resolveTenantConfig = (
  tenantConfigs: TenantConfigsSet,
  tenantOverride: Maybe<TenantOverrideTenantKey>,
): Maybe<TenantConfig> => {
  if (tenantOverride) {
    const config = resolveTenantConfigByTenantOverride(
      tenantConfigs,
      tenantOverride,
    )
    if (config) {
      return config
    }
  }

  return resolveTenantConfigByHost(
    tenantConfigs,
    window?.location.hostname || '',
  )
}

const resolveTenantConfigByHost = (
  configs: TenantConfigsSet,
  host: string,
): Maybe<TenantConfig> => {
  const byHost = (configs.version === 2 ? configs.byHost : configs) as Record<
    TenantHost,
    TenantConfig
  >
  return (
    Object.entries(byHost).find(([hostKey]) => wcmatch(hostKey)(host))?.[1] ||
    null
  )
}

const resolveTenantConfigByTenantOverride = (
  _configs: TenantConfigsSet,
  tenantOverride: TenantOverrideTenantKey,
): Maybe<TenantConfig> => {
  if (_configs.version !== 2) {
    return null
  }
  const configs = _configs as TenantConfigsSetVersion2
  return configs.byTenantOverride?.[tenantOverride] || null
}

import type { GenericReportDetails } from '@fintastic/web/util/generic-report'
import { endpoints } from './endpoints'
import { axios } from '@fintastic/web/data-access/service-axios'
import {
  isPeriodBasedSelection,
  isRangeBasedSelection,
  PeriodSelection,
} from '@fintastic/web/util/period-selector'
import { NullableFields } from '@fintastic/shared/util/types'
import Qs from 'qs'

const mapPeriodSelectionToParams = (
  periodSelection: PeriodSelection | NullableFields<PeriodSelection>,
) => {
  if (!periodSelection) {
    return {}
  }

  if (isRangeBasedSelection(periodSelection)) {
    return {
      from_dim_val: periodSelection.range[0],
      to_dim_val: periodSelection.range[1],
      time_dim_id: periodSelection.dimensionId,
    }
  }

  if (isPeriodBasedSelection(periodSelection)) {
    return {
      periods: periodSelection.periods,
      time_dim_id: periodSelection.dimensionId,
    }
  }

  return {}
}

export function getReportDetailsAPI(
  versionId: string,
  category: string,
  dimensionFilters: DimensionFiltersAPIPayload,
  periodSelection: Omit<PeriodSelection, 'aggregationDimensionId'>,
) {
  return axios.post<GenericReportDetails>(
    endpoints.details(versionId, category),
    dimensionFilters,
    {
      params: {
        ...mapPeriodSelectionToParams({
          ...periodSelection,
          aggregationDimensionId: null,
        } as NullableFields<PeriodSelection>),
      },
      paramsSerializer: (p) => Qs.stringify(p, { arrayFormat: 'repeat' }),
    },
  )
}

export type DimensionFiltersAPIPayload = {
  metric_filters: Record<string, string[]>
  time_filter: {
    dim_name: string
    dim_value_label: string
  }
}

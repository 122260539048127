import { Maybe } from '@fintastic/shared/util/types'
import { TaskState, UpdateTaskFunction } from '../task-state'
import { InactivityTimeoutError } from './errors'
import { useCallback, useEffect, useRef } from 'react'

type Milliseconds = number

const INACTIVITY_SUBTASK_ID = 'inactivityTimeoutCheck'

export const useInactivityTimoutExecutor = <TParams = void>(
  taskState: Maybe<TaskState<TParams, InactivityTimeoutError>>,
  update: UpdateTaskFunction<InactivityTimeoutError>,
  options?: {
    timout?: Milliseconds
  },
) => {
  const taskStateRef = useRef(taskState)
  taskStateRef.current = taskState
  const updateRef = useRef(update)
  updateRef.current = update
  const optionsRef = useRef(options)
  optionsRef.current = options

  const timoutIdRef = useRef<Maybe<number>>(null)

  const inactivityCallback = useCallback(() => {
    if (taskStateRef.current === null) {
      return
    }

    if (
      taskStateRef.current.status === 'failed' ||
      taskStateRef.current.status === 'completed'
    ) {
      return
    }

    updateRef.current({
      id: INACTIVITY_SUBTASK_ID,
      status: 'failed',
      error: new InactivityTimeoutError(),
    })
  }, [])

  const clearInactivityCheck = useCallback(() => {
    if (timoutIdRef.current) {
      clearTimeout(timoutIdRef.current)
    }
  }, [])

  const scheduleInactivityCheck = useCallback(() => {
    clearInactivityCheck()
    timoutIdRef.current = setTimeout(
      inactivityCallback,
      optionsRef.current?.timout || 5000,
    ) as unknown as number
  }, [inactivityCallback, clearInactivityCheck])

  useEffect(() => clearInactivityCheck, [clearInactivityCheck])

  useEffect(() => {
    clearInactivityCheck()

    if (
      taskState === null ||
      taskState.status === 'failed' ||
      taskState.status === 'completed'
    ) {
      return
    }

    scheduleInactivityCheck()
  }, [clearInactivityCheck, scheduleInactivityCheck, taskState])
}

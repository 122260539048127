import { Task, TaskStatus } from './types'

export const deriveTaskStatus = (task: Task<any, any>): TaskStatus => {
  if (task.subtasks.length === 0) {
    return 'pending'
  }

  if (task.subtasks.some((s) => s.status === 'failed')) {
    return 'failed'
  }

  if (
    task.subtasks.every((s) => s.status === 'completed') &&
    task.subtasks.some((s) => s.status === 'completed' && s.final)
  ) {
    return 'completed'
  }

  return 'running'
}

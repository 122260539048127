import React, { CSSProperties } from 'react'
import { SmartSelectOption } from '../types'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

export type OptionsListItemProps<T> = {
  option: SmartSelectOption<T>
  selected: boolean
  disabled: boolean
  onClick: () => void
  style: CSSProperties
}

export const OptionsListItem = <T,>({
  option,
  disabled,
  onClick,
  selected,
  style,
}: OptionsListItemProps<T>) => (
  <ListItem disablePadding={true} sx={style} data-testid={'options-list-item'}>
    <ListItemButton
      disabled={disabled}
      role={undefined}
      onClick={onClick}
      dense
      sx={{
        height: '100%',
      }}
      selected={selected}
      data-testid={'options-list-item-button'}
    >
      {option.icon && (
        <ListItemIcon
          sx={{
            fontSize: '18px',
            minWidth: '18px',
            mr: 1,
          }}
        >
          {option.icon}
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{
          variant: 'body1',
          sx: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        primary={option.label}
      />
    </ListItemButton>
  </ListItem>
)

import { Checkbox, FormControl, Tooltip } from '@mui/material'
import { memo, MemoExoticComponent } from 'react'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import {
  StyledDiffContainer,
  StyledDiffItemRoot,
  StyledFormLabel,
  StyledMultipartText,
  StyledRecord,
  StyledSectionRoot,
} from './DiffPairsContainer.styled'
import { DiffModeSelector } from './DiffModeSelector'
import {
  defaultDiffMode,
  DiffMode,
  isDiffPairEqualsByVersions,
} from '@fintastic/web/util/versions'

export type DiffPairsContainerProps = {
  diffPairs: [string, string, DiffMode][]
  selectedDiffs: [string, string, DiffMode][]
  getDiffPairLabels: (diffIds: [string, string]) => [string, string]
  handleDiffPairToggle: (column: [string, string, DiffMode]) => void
  handleDiffPairMode: (column: [string, string, DiffMode]) => void
}

export const DiffPairsContainer: MemoExoticComponent<
  (props: DiffPairsContainerProps) => JSX.Element
> = memo(
  ({
    diffPairs,
    selectedDiffs,
    getDiffPairLabels,
    handleDiffPairToggle,
    handleDiffPairMode,
  }: DiffPairsContainerProps) => (
    <StyledSectionRoot>
      <FormControl>
        <FintasticThemeProvider applyLegacyTheme={false}>
          <StyledFormLabel
            variant="overline"
            data-testid="versions-and-diff-select-compare-label"
          >
            Compare
          </StyledFormLabel>
        </FintasticThemeProvider>

        <StyledDiffContainer>
          {diffPairs.map((pair) => {
            const checkedPair = selectedDiffs.find((currentColumn) =>
              isDiffPairEqualsByVersions(currentColumn, pair),
            )

            const labels = getDiffPairLabels([pair[0], pair[1]])
            const labelComponent =
              labels.length === 2 ? (
                <Tooltip
                  title={
                    <span>
                      {labels[0]} <br />
                      vs. <br /> {labels[1]}
                    </span>
                  }
                  enterDelay={700}
                  enterNextDelay={500}
                  leaveDelay={0}
                >
                  <StyledMultipartText data-testid="versions-and-diff-select-compare-text">
                    <span
                      data-testid="versions-and-diff-select-first-version"
                      data-test-version={pair[0]}
                    >
                      {labels[0]}
                    </span>
                    <b>vs.</b>
                    <span
                      data-testid="versions-and-diff-select-second-version"
                      data-test-version={pair[1]}
                    >
                      {labels[1]}
                    </span>
                  </StyledMultipartText>
                </Tooltip>
              ) : null

            return (
              <StyledDiffItemRoot
                key={pair.join('_')}
                data-testid="versions-and-diff-select-compare-row"
              >
                <StyledRecord
                  control={
                    <Checkbox
                      disabled={!checkedPair && selectedDiffs.length > 2}
                      checked={!!checkedPair}
                      onChange={() => handleDiffPairToggle(pair)}
                      size="medium"
                      disableRipple={true}
                      color="primary"
                      data-testid="versions-and-diff-select-compare-checkbox-container"
                    />
                  }
                  disableTypography
                  label={labelComponent}
                />
                {checkedPair && (
                  <DiffModeSelector
                    mode={checkedPair[2] || defaultDiffMode}
                    setMode={(mode) =>
                      handleDiffPairMode([checkedPair[0], checkedPair[1], mode])
                    }
                  />
                )}
              </StyledDiffItemRoot>
            )
          })}
        </StyledDiffContainer>
      </FormControl>
    </StyledSectionRoot>
  ),
)

export default DiffPairsContainer

import React, { useMemo } from 'react'
import {
  StyledColumns,
  StyledColumnsTitle,
  StyledColumnsList,
  StyledColumnsListItem,
  StyledColumnsListItemIcon,
  StyledColumnsListItemTextContainer,
  StyledColumnsListItemDimensionChip,
  StyledColumnsListItemColumnChip,
  StyledColumnsListItemLabel,
  StyledColumnsListItemDataType,
} from './Columns.styled'
import { Typography, Tooltip } from '@mui/material'
import { DimensionId, VersionDimension } from '@fintastic/web/util/dimensions'
import {
  DimensionIcon,
  FunctionAXIcon,
  FunctionFXIcon,
  InputColumnIconIcon,
} from '@fintastic/shared/ui/icons'
import { ListColumnWrapper } from '@fintastic/web/data-access/metrics-and-lists'
import { Maybe } from '@fintastic/shared/util/types'
import {
  ListWithoutData,
  mapDataTypeToItsLabel,
  MetricConfigurableDataValueType,
} from '@fintastic/web/util/metrics-and-lists'
import { titleFormatter } from '@fintastic/shared/util/formatters'
import { keyBy } from 'lodash'

export const Columns: React.FC<{
  title: string
  columns: ListWithoutData['metrics']
  allVersionDimensions: VersionDimension[]
  beforeColumnsSlot?: React.ReactNode
  liveActuals: boolean
}> = ({
  title,
  columns: _columns,
  beforeColumnsSlot,
  allVersionDimensions,
  liveActuals,
}) => {
  const dimensionsMap = useMemo<Record<DimensionId, VersionDimension>>(
    () => keyBy(allVersionDimensions, 'id'),
    [allVersionDimensions],
  )

  const columns = useMemo(
    () => _columns.map((c) => new ListColumnWrapper(c)),
    [_columns],
  )

  return (
    <StyledColumns>
      <StyledColumnsTitle>
        <Typography variant="overline">{title}</Typography>
      </StyledColumnsTitle>
      {beforeColumnsSlot}
      <StyledColumnsList>
        {columns.map((column) => {
          if (column.type() === 'dimension') {
            const Icon = iconsMap.dimension
            const dimension =
              dimensionsMap[column.valuesDimensionId() as string]
            const dimensionLabel =
              titleFormatter(dimension?.label) || column.valuesDimensionId()
            let overridingColumnName: Maybe<string> = titleFormatter(
              column.label(),
            )
            if (overridingColumnName === dimensionLabel) {
              overridingColumnName = null
            }

            return (
              <StyledColumnsListItem key={column.id()}>
                <StyledColumnsListItemIcon>
                  <Icon />
                </StyledColumnsListItemIcon>

                <StyledColumnsListItemTextContainer>
                  <Tooltip
                    title={dimensionLabel}
                    placement="top"
                    arrow
                    disableInteractive
                  >
                    <StyledColumnsListItemDimensionChip variant="body2">
                      {dimensionLabel}
                    </StyledColumnsListItemDimensionChip>
                  </Tooltip>
                  {overridingColumnName && (
                    <Tooltip
                      title={overridingColumnName}
                      placement="top"
                      arrow
                      disableInteractive
                    >
                      <StyledColumnsListItemLabel variant="body1">
                        {overridingColumnName}
                      </StyledColumnsListItemLabel>
                    </Tooltip>
                  )}
                </StyledColumnsListItemTextContainer>
              </StyledColumnsListItem>
            )
          }

          let Icon =
            column.type() === 'calculated'
              ? iconsMap.calculated
              : iconsMap.input
          if (column.type() === 'calculated' && liveActuals) {
            Icon = iconsMap.calculatedLiveActuals
          }

          let timeDimensionLabel: Maybe<string> = null
          if (column.hasTimeDimension()) {
            timeDimensionLabel =
              titleFormatter(
                dimensionsMap[column.baseTimeDimension() || '']?.label,
              ) || null
          }
          const columnLabel = titleFormatter(column.label())
          const dataType = `${
            timeDimensionLabel ? `(${timeDimensionLabel}), ` : ''
          }${mapDataTypeToItsLabel(
            column.dataType() as MetricConfigurableDataValueType,
          )}`

          return (
            <StyledColumnsListItem key={column.id()}>
              <StyledColumnsListItemIcon>
                <Icon />
              </StyledColumnsListItemIcon>

              <StyledColumnsListItemTextContainer>
                <Tooltip
                  title={columnLabel}
                  placement="top"
                  arrow
                  disableInteractive
                >
                  <StyledColumnsListItemColumnChip variant="body2">
                    {columnLabel}
                  </StyledColumnsListItemColumnChip>
                </Tooltip>
                <Tooltip
                  title={dataType}
                  placement="top"
                  arrow
                  disableInteractive
                >
                  <StyledColumnsListItemDataType
                    variant="body2"
                    color="text.secondary"
                  >
                    {dataType}
                  </StyledColumnsListItemDataType>
                </Tooltip>
              </StyledColumnsListItemTextContainer>
            </StyledColumnsListItem>
          )
        })}
      </StyledColumnsList>
    </StyledColumns>
  )
}

const iconsMap = {
  input: InputColumnIconIcon,
  calculated: FunctionFXIcon,
  calculatedLiveActuals: FunctionAXIcon,
  dimension: DimensionIcon,
}

import React from 'react'
import {
  StyledDimensions,
  StyledDimensionsTitle,
  StyledDimensionsList,
  StyledDimensionsListItem,
  StyledDimensionsListItemIcon,
  StyledDimensionsListItemName,
  StyledDimensionChip,
} from './Dimensions.styled'
import { Typography, Tooltip } from '@mui/material'
import { DimensionIcon } from '@fintastic/shared/ui/icons'

export const Dimensions: React.FC<{
  label: string
  dimensions: Array<{ label: string; id: string }>
}> = ({ label, dimensions }) => (
  <StyledDimensions>
    <StyledDimensionsTitle>
      <Typography variant="overline">{label}</Typography>
    </StyledDimensionsTitle>
    <StyledDimensionsList>
      {dimensions.map((dimension) => (
        <StyledDimensionsListItem key={dimension.id}>
          <StyledDimensionsListItemIcon>
            <DimensionIcon />
          </StyledDimensionsListItemIcon>
          <StyledDimensionsListItemName>
            <Tooltip
              title={dimension.label}
              placement="top"
              arrow
              disableInteractive
            >
              <StyledDimensionChip variant="body2">
                {dimension.label}
              </StyledDimensionChip>
            </Tooltip>
          </StyledDimensionsListItemName>
        </StyledDimensionsListItem>
      ))}
    </StyledDimensionsList>
  </StyledDimensions>
)

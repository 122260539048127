import { MetricConfigurableDataValueType } from '@fintastic/web/util/metrics-and-lists'

const dataTypeLabels: Record<
  MetricConfigurableDataValueType | 'dimension',
  string
> = {
  number: 'Number',
  integer: 'Integer',
  percentage: 'Percent Number',
  percentage_integer: 'Percent Integer',
  currency: 'Currency',
  boolean: 'Boolean',
  datetime: 'Date',
  string: 'Text',
  dimension: 'Dimension',
}

export const mapDataTypeToItsLabel = (
  dataType: MetricConfigurableDataValueType | 'dimension',
): string => dataTypeLabels[dataType]

import { CellRendererSelectorFunc } from 'ag-grid-community'
import {
  MaybeHaveCommentCalculatedRowCellRenderer,
  MaybeHaveCommentCellRenderer,
  MaybeHaveCommentFooterRowCellRenderer,
} from './maybe-have-comment'
import { GenericReportTreeRow } from '@fintastic/web/util/generic-report'

export const cellRendererSelector: CellRendererSelectorFunc<
  GenericReportTreeRow
> = (params) => {
  if (params.data?.calculatedRow) {
    return {
      component: MaybeHaveCommentCalculatedRowCellRenderer,
    }
  }

  if (params.node.footer && !params.pinned) {
    return {
      component: MaybeHaveCommentFooterRowCellRenderer,
    }
  }

  if (params.valueFormatted) {
    return {
      component: MaybeHaveCommentCellRenderer,
    }
  }

  return undefined
}

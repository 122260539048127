import {
  ChartSettings,
  getChartWidgetSettingsOrDefaults,
  MetricChartDimensions,
  updateChartSettings,
} from '@fintastic/web/feature/charts'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ChartSettingsEditContextValue } from '../contexts'
import { isEqual } from 'lodash'
import { Maybe } from '@fintastic/shared/util/types'

export type useEditChartSettingParams = {
  chartSettings: ChartSettings
  referenceSettings?: ChartSettings
  handleUpdateChartWidgetSettings?: (
    chartSettings: Maybe<Partial<ChartSettings>>,
  ) => void
  immediatelyApply?: boolean
  extraPayload?: Record<string, unknown>
  usedDimensions?: MetricChartDimensions
  hasDifferentDimensions?: boolean
}

export const useEditChartSettings = ({
  chartSettings,
  referenceSettings,
  handleUpdateChartWidgetSettings,
  immediatelyApply = false,
  extraPayload,
  usedDimensions,
  hasDifferentDimensions,
}: useEditChartSettingParams): ChartSettingsEditContextValue => {
  const initialStateWithDefaults = useMemo<ChartSettings>(
    () => getChartWidgetSettingsOrDefaults(chartSettings),
    [chartSettings],
  )

  const referenceStateWithDefaults = useMemo<Maybe<ChartSettings>>(
    () =>
      referenceSettings
        ? getChartWidgetSettingsOrDefaults(referenceSettings)
        : null,
    [referenceSettings],
  )

  const [localSettings, setLocalSettings] = useState<ChartSettings>(
    initialStateWithDefaults,
  )

  useEffect(() => {
    if (hasDifferentDimensions) {
      setLocalSettings(() => ({
        ...initialStateWithDefaults,
        dimensions: usedDimensions,
      }))
    } else {
      setLocalSettings(() => initialStateWithDefaults)
    }
  }, [hasDifferentDimensions, initialStateWithDefaults, usedDimensions])

  const widgetSettingsChanged = useMemo<boolean>(
    () => !isEqual(localSettings, initialStateWithDefaults),
    [localSettings, initialStateWithDefaults],
  )

  const handleApply = useCallback(() => {
    handleUpdateChartWidgetSettings?.(localSettings)
  }, [handleUpdateChartWidgetSettings, localSettings])

  const handleCancel = useCallback(() => {
    // back to initial settings
    setLocalSettings(initialStateWithDefaults)
  }, [initialStateWithDefaults])

  const handleReset = useCallback(() => {
    // back to pre-designed settings
    if (!referenceStateWithDefaults) {
      console.error('No reference settings provided')
      return
    }
    setLocalSettings(referenceStateWithDefaults)
    handleUpdateChartWidgetSettings?.(null)
  }, [handleUpdateChartWidgetSettings, referenceStateWithDefaults])

  const handleSettingsChange = useCallback(
    (partialSettings: Partial<ChartSettings>) => {
      setLocalSettings((oldSettings: ChartSettings) =>
        updateChartSettings(oldSettings, partialSettings),
      )
      if (immediatelyApply && handleUpdateChartWidgetSettings) {
        handleUpdateChartWidgetSettings(partialSettings)
      }
    },
    [handleUpdateChartWidgetSettings, immediatelyApply],
  )

  const initialSettingsChanged = useMemo(
    () => !isEqual(initialStateWithDefaults, localSettings),
    [initialStateWithDefaults, localSettings],
  )

  const referenceSettingsChanged = useMemo(
    () =>
      referenceStateWithDefaults
        ? !isEqual(referenceStateWithDefaults, initialStateWithDefaults)
        : false,
    [referenceStateWithDefaults, initialStateWithDefaults],
  )

  return useMemo<ChartSettingsEditContextValue>(
    () => ({
      handleCancel,
      handleApply,
      handleReset,
      initialSettingsChanged,
      referenceSettingsChanged,
      widgetSettingsChanged,
      localSettings,
      handleSettingsChange,
      extraPayload,
      usedDimensions,
      hasDifferentDimensions,
    }),
    [
      handleCancel,
      handleApply,
      handleReset,
      initialSettingsChanged,
      referenceSettingsChanged,
      widgetSettingsChanged,
      localSettings,
      handleSettingsChange,
      extraPayload,
      usedDimensions,
      hasDifferentDimensions,
    ],
  )
}

import React, { useCallback } from 'react'
import {
  BooleanFilterInput,
  BooleanFilterInputProps,
} from '@fintastic/shared/ui/filters'
import {
  FilterByBoolean,
  FilterListAPIOperatorBooleanUiValue,
  filterUtils,
  FilterValue,
  useFilterContext,
} from '@fintastic/web/util/filters'
import type { Maybe } from '@fintastic/shared/util/types'

export const BooleanFilterGroup: React.FC<{
  filter: FilterByBoolean
  timeDimensionValueId: Maybe<string>
  disableClear?: boolean
}> = ({ filter, timeDimensionValueId, disableClear }) => {
  const filterContext = useFilterContext()

  const handleValueChanged = useCallback<BooleanFilterInputProps['onChange']>(
    (nextValue) => {
      filterContext.setFilterValue(filter.id, timeDimensionValueId, nextValue)
    },
    [filter.id, filterContext, timeDimensionValueId],
  )

  return (
    <BooleanFilterInput
      onChange={handleValueChanged}
      disableClear={disableClear}
      filter={filter}
      filterValue={
        filterUtils.getValueFromTheModel<
          FilterValue<FilterListAPIOperatorBooleanUiValue['value']>
        >(filterContext.model, filter.id, timeDimensionValueId) ?? {}
      }
    />
  )
}

BooleanFilterGroup.displayName = 'BooleanFilterGroup'

import React, {
  ForwardedRef,
  forwardRef,
  PropsWithoutRef,
  RefAttributes,
} from 'react'
import { SmartSelectOption } from '../../types'
import {
  StyledFieldset,
  StyledHighlightedText,
  StyledIcon,
  StyledOptionText,
  StyledPlaceholder,
  StyledRoot,
  StyledText,
  StyledTextMain,
} from './SmartSelectInputButton.styled'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { FormControl, InputLabel, SxProps, Theme } from '@mui/material'

export type SmartSelectInputButtonProps<T> = {
  placeholder?: React.ReactNode
  values: SmartSelectOption<T>[]
  onClick?: () => void
  isOpened?: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
  label?: string
}

const _SmartSelectInputButton = <T,>(
  {
    placeholder,
    values,
    onClick,
    isOpened,
    disabled,
    sx,
    label,
  }: SmartSelectInputButtonProps<T>,
  ref: ForwardedRef<HTMLButtonElement>,
) => (
  <FormControl fullWidth={true} data-testid={'smart-select-form'}>
    {label && (
      <InputLabel
        shrink={true}
        focused={!!isOpened}
        data-testid={'smart-select-label'}
      >
        {label}
      </InputLabel>
    )}
    <StyledRoot
      ref={ref}
      onClick={onClick}
      isOpened={!!isOpened}
      disabled={!!disabled}
      sx={sx}
      data-testid={'smart-select-root'}
    >
      <StyledText data-testid={'smart-select-placeholder'}>
        {values.length === 0 ? (
          <StyledPlaceholder variant="body2">{placeholder}</StyledPlaceholder>
        ) : (
          <StyledOptionText variant="body2">
            <StyledTextMain>{values?.[0]?.label}</StyledTextMain>
            {values.length > 1 && (
              <StyledHighlightedText>
                &nbsp;{`(+${values.length - 1})`}
              </StyledHighlightedText>
            )}
          </StyledOptionText>
        )}
      </StyledText>
      <StyledIcon>
        <ArrowDropDownIcon />
      </StyledIcon>
      <StyledFieldset
        aria-hidden="true"
        isOpened={!!isOpened}
        hasLabel={!!label}
      >
        {label && (
          <legend data-testid={'smart-select-legend'}>
            <span>{label}</span>
          </legend>
        )}
      </StyledFieldset>
    </StyledRoot>
  </FormControl>
)

export const SmartSelectInputButton = forwardRef(_SmartSelectInputButton) as <
  T,
>(
  props: PropsWithoutRef<SmartSelectInputButtonProps<T>> &
    RefAttributes<HTMLButtonElement>,
) => ReturnType<typeof _SmartSelectInputButton>

import { styled, Dialog, DialogProps } from '@mui/material'

export const StyledModalDialogRoot = styled(
  (props: DialogProps & { width?: number | string; noContent?: boolean }) => {
    const { width, ...dialogProps } = props
    return <Dialog {...dialogProps} />
  },
  {
    shouldForwardProp: (propName) =>
      propName !== 'width' && propName !== 'noContent',
  },
)(({ theme, width, noContent }) => ({
  '.MuiDialog-paper': {
    width,
    maxWidth: width ? '100%' : undefined,
    paddingBottom: noContent ? '24px' : undefined,
  },
}))

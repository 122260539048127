import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TokenWithIndex } from '../tokens/types'
import { Popper, Paper, ClickAwayListener } from '@mui/material'
import {
  ParsedListId,
  ParsedMetricId,
} from '@fintastic/web/util/metrics-and-lists'
import { ListDetails } from './ListDetails'
import { MetricDetails } from './MetricDetails'
import { Maybe } from '@fintastic/shared/util/types'
import { Instance, Options } from '@popperjs/core/lib/types'

export const TokenDetails: React.FC<{
  versionId: string
  token: TokenWithIndex
  onMouseOver: (token: TokenWithIndex) => void
  onMouseLeave: () => void
  onRequestClose: () => void
  getTokensNodes: () => HTMLElement[]
}> = ({
  token,
  onMouseOver,
  onMouseLeave,
  versionId,
  getTokensNodes,
  onRequestClose,
}) => {
  const popperRef = useRef<Maybe<Instance>>(null)
  const [tokenNode, setTokenNode] = useState<Maybe<HTMLElement>>(null)

  useEffect(() => {
    const allTokens = getTokensNodes()
    if (allTokens.length < token.index - 1) {
      return
    }
    setTokenNode(allTokens[token.index])
  }, [token, getTokensNodes])

  const updatePosition = useCallback(() => {
    popperRef.current?.update()
  }, [])

  const handleMouseOver = useCallback(() => {
    onMouseOver(token)
  }, [onMouseOver, token])

  const details = useMemo(() => {
    if (token.type === 'list' || token.type === 'calculatedList') {
      const listId = new ParsedListId(token.text).toString()
      return (
        <ListDetails
          listId={listId}
          versionId={versionId}
          onLoadingStateChanged={updatePosition}
        />
      )
    }

    if (token.type === 'metric' || token.type === 'calculatedMetric') {
      const metricId = new ParsedMetricId(token.text).toString()
      return (
        <MetricDetails
          metricId={metricId}
          versionId={versionId}
          onLoadingStateChanged={updatePosition}
        />
      )
    }

    return null
  }, [token.text, token.type, updatePosition, versionId])

  return (
    <Popper
      popperRef={popperRef}
      open={!!tokenNode}
      anchorEl={tokenNode}
      placement="top-start"
      sx={{
        zIndex: (theme) => theme.zIndex.modal,
      }}
      modifiers={popperModifiers}
    >
      <ClickAwayListener onClickAway={onRequestClose}>
        <Paper onMouseOver={handleMouseOver} onMouseLeave={onMouseLeave}>
          {details}
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
}

const popperModifiers: Options['modifiers'] = [
  {
    name: 'offset',
    options: {
      offset: [0, 8],
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      mainAxis: true,
      altAxis: true,
      altBoundary: true,
      tether: true,
      rootBoundary: 'document',
      padding: 8,
      boundary: document.body,
    },
  },
]

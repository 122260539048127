import React, { useCallback, useMemo } from 'react'
import { VersionTimeDimension } from '@fintastic/web/util/dimensions'
import { Maybe } from '@fintastic/shared/util/types'
import { Dimension } from '@fintastic/shared/util/types'
import { TimeDimensionSelectorInput } from '../../../../shared/TimeDimensionSelectorInput'
import { useActionsContext } from '../../../connection/actions/actions-context'
import { useDataContext } from '../../../connection/data/data-context'

export const TimeDimensionSelector: React.FC<{
  enabled: boolean
}> = ({ enabled }) => {
  const { metric, dimensionsList } = useDataContext()
  const { readonly, actions } = useActionsContext()

  const handleChangeTimeDimension = useCallback(
    (newTimeDimension: Maybe<Dimension>) => {
      actions.changeTimeDimension(newTimeDimension)
    },
    [actions],
  )

  const timeDimensions = useMemo<VersionTimeDimension[]>(
    () => dimensionsList?.filter((dim) => dim.type === 'Time') ?? [],
    [dimensionsList],
  )

  if (!metric) {
    return null
  }

  return (
    <TimeDimensionSelectorInput
      metricDimensions={metric.data().dimensions()}
      timeDimensions={timeDimensions}
      onChangeTimeDimension={handleChangeTimeDimension}
      readonly={readonly || !enabled}
    />
  )
}

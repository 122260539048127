import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'
import { useEffect } from 'react'

export const useHistoryAutoClose = (versionId: string, listId: string) => {
  const historyApi = useHistoryLogGlobalApi()

  useEffect(() => {
    if (
      historyApi?.state?.level === 'entity' &&
      historyApi.state.entityId === listId &&
      versionId !== historyApi.state.versionId
    ) {
      historyApi.close()
    }
  }, [historyApi, versionId])
}

import { useMemo } from 'react'
import { isTotalPeriodName } from '../../utils/is-total-period-name'
import { useIsFeatureEnabled } from '@fintastic/web/feature/config'
import { Period } from '@fintastic/web/util/period-selector'

export type UsePeriodOverPeriodAvailableParams = {
  totalsColumnEnabled: boolean
  allPeriods: Period[]
  reportsOrVersionsIds: string[]
}

export const usePeriodOverPeriodAvailable = ({
  totalsColumnEnabled,
  allPeriods,
  reportsOrVersionsIds,
}: UsePeriodOverPeriodAvailableParams) => {
  const multiplePeriodOverPeriodDiffEnabled = useIsFeatureEnabled(
    'multiple_period_over_period_diff',
  )

  const totalColumnsLength = useMemo(
    () => allPeriods.filter(isTotalPeriodName).length,
    [allPeriods],
  )

  return useMemo<boolean>(() => {
    if (reportsOrVersionsIds.length > 1) {
      return false
    }

    if (!multiplePeriodOverPeriodDiffEnabled) {
      if (totalsColumnEnabled) {
        return allPeriods.length === totalColumnsLength + 2
      }

      return allPeriods.length === 2
    }

    if (totalsColumnEnabled) {
      return allPeriods.length > totalColumnsLength + 1
    }

    return allPeriods.length > 1
  }, [
    multiplePeriodOverPeriodDiffEnabled,
    reportsOrVersionsIds.length,
    totalsColumnEnabled,
    allPeriods.length,
    totalColumnsLength,
  ])
}

import { CalendarDatePickerConfig, PeriodSelection } from '../types'
import { convertRangeSelectionToPeriods } from './convert-to-periods'
import { convertPeriodSelectionToRange } from './convert-to-range'
import { isRangeBasedSelection } from './selection-guards'

export const reverseRangeAndPeriodsSelection = (
  periodSelection: PeriodSelection,
  datePickerConfig: CalendarDatePickerConfig,
): PeriodSelection => {
  if (isRangeBasedSelection(periodSelection)) {
    const periods = convertRangeSelectionToPeriods(
      periodSelection,
      datePickerConfig,
    )

    return {
      aggregationDimensionId: periodSelection.aggregationDimensionId,
      dimensionId: periodSelection.dimensionId,
      periods,
    }
  }

  const range = convertPeriodSelectionToRange(periodSelection, datePickerConfig)

  return {
    aggregationDimensionId: periodSelection.aggregationDimensionId,
    dimensionId: periodSelection.dimensionId,
    range,
  }
}

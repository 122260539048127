import {
  getMaxVisibleGroupLevel,
  getMeaningfulColumns,
  getVisibleRows,
  sanitizeName,
} from './utils'
import {
  extendHeadersWithGroupHeaders,
  fillDatasetWithHeaders,
  getColumnsHeaderRows,
  HeaderCell,
} from './header-utils'
import { getGroupHeaderRow } from './group-utils'
import { CellData, getDataValues } from './data-values'
import { prepareTempXLSArray } from './prepare-excel'
import { prepareTempCSVArray } from './prepare-csv'
import { getColumnsWidth } from './excel-utils'
import { DataExportParams } from '../types'
import { getComments } from './grid-comments'
import { maxColumnsInXLS, maxRowsInXLS } from '../consts'

export const exportGrid = async ({
  exportName,
  sheetName,
  api,
  columnApi,
  context,
  fallbackGroupValueFormatter,
  sortedGroupNames,
  format = 'xls',
  mode = 'current',
  threads,
  labels,
  monthOverMonthEnabled,
}: DataExportParams) => {
  const meaningfulColumns = getMeaningfulColumns(columnApi)
  const rowsToDisplay = getVisibleRows(api, mode)
  const isCurrentMode = mode === 'current' || mode === 'current-with-comments'

  // For 'current' mode - maximum expanded level
  const maxGroupLevel = isCurrentMode
    ? getMaxVisibleGroupLevel(rowsToDisplay)
    : 255

  // comments
  const withComments =
    mode === 'current-with-comments' || mode === 'flat-with-comments'

  const comments = withComments
    ? getComments({
        context,
        rowsToDisplay,
        columns: meaningfulColumns,
        monthOverMonthEnabled: monthOverMonthEnabled || false,
        threads,
        labels,
      })
    : null

  // Cells data
  const rawDataSet = getDataValues({
    api,
    columnApi,
    context,
    fallbackGroupValueFormatter,
    columns: meaningfulColumns,
    rowsToDisplay,
    mode,
    comments,
  })

  // Column names (-=excluding=- grouping column)
  // may be multiline
  const baseHeaders = getColumnsHeaderRows(meaningfulColumns)

  // Current (sorted) group names, mostly for reports that pass that data outside
  // because they are trees and have external drag-n-drop group names sorting.
  // Always single line - yet
  const groupHeaders = getGroupHeaderRow({
    api: api,
    columnApi: columnApi,
    sortedGroupNames: sortedGroupNames,
    maxGroupLevel,
  })

  // Prepare data table with header row(s)
  // First, make table of header row(s)
  const dataTable: Array<HeaderCell[] | CellData[]> =
    extendHeadersWithGroupHeaders({
      columnHeaders: baseHeaders.data,
      groupColumnIdx: baseHeaders.groupColumnIdx,
      headerStackDepth: baseHeaders.headerStackDepth,
      groupHeaders,
    })

  // Fill the data table with data + extend group column with pre-calculated header columns
  // e.g., [group column] 1 2 3 -> [Product] [Sub product] [Region] [group column] 1 2 3
  // raw dataset could be
  //  - mapped to headers 1:1
  //  - mapped to sparse header (with groups)
  // means that raw dataset row always <= header row
  if (baseHeaders.groupColumnIdx === -1) {
    // 1:1, just add all the data rows as is
    dataTable.push(...rawDataSet)
  } else {
    // each row must be extended with group headers at left
    fillDatasetWithHeaders({
      rawDataSet,
      dataTable,
      baseHeaders,
      groupHeaders,
      mode,
    })
  }

  // Prepare data to export (make XLS or CSV)
  const tempData =
    !format || format === 'xls'
      ? prepareTempXLSArray(dataTable)
      : prepareTempCSVArray(dataTable)

  if (!tempData || tempData.length === 0) {
    throw new Error('Oops, something went wrong: empty export table')
  }

  if (
    format === 'xls' &&
    (tempData.length >= maxRowsInXLS || tempData[0].length >= maxColumnsInXLS)
  ) {
    throw new Error(
      'The size of the grid you are trying to download exceeds the limitation, please download as CSV',
    )
  }

  const xlsSheetName = sanitizeName(
    sheetName || exportName || 'Export',
  ).substring(0, 31) // max length ot sheet name

  const XLSX = await import('xlsx-js-style')

  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.aoa_to_sheet(tempData)

  XLSX.utils.book_append_sheet(workbook, worksheet, xlsSheetName)

  const filename = sanitizeName(exportName || 'Export')

  if (format === 'csv') {
    XLSX.writeFile(workbook, filename + '.csv', {
      bookType: 'csv',
    })
    return
  }
  worksheet['!cols'] = getColumnsWidth(dataTable)
  XLSX.writeFile(workbook, filename + '.xlsx', { compression: true })
}

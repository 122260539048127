import React, { useCallback, useMemo } from 'react'
import {
  Topbar,
  TopbarSettingsItem,
  useLayoutStateIsDiscussionsOpened,
} from '@fintastic/shared/ui/app-layout-framework'
import {
  useBoardParamsLocalStorageSyncEffect,
  useBoardsParamsDiffs,
  useBoardsParamsVersions,
} from '../hooks/boards-params/hooks'
import { CommentButton } from '@fintastic/shared/ui/components'
import {
  PlanningVersionsAndDiffsSelect,
  VersionCalculationStatus,
} from '@fintastic/web/feature/versions'
import { useLoadVersionsList } from '@fintastic/web/data-access/versions'
import { isVersionArchived } from '@fintastic/web/util/versions'

export type BoardsTopbarProps = {
  allowDiffsSelection?: boolean
  defaultVersions: string[]
  periodSelectionComponent: React.ReactNode
  isDesignMode?: boolean
  noComments?: boolean
}

export const BoardsTopbar: React.FC<BoardsTopbarProps> = ({
  allowDiffsSelection = true,
  defaultVersions,
  periodSelectionComponent,
  isDesignMode = false,
  noComments = false,
}) => {
  const { versions, setVersions } = useBoardsParamsVersions(defaultVersions)
  const { diffs, setDiffs } = useBoardsParamsDiffs(defaultVersions)

  const { data: versionsList } = useLoadVersionsList({
    withLiveActuals: true,
    showArchived: false,
  })
  const versionsListWithoutArchived = useMemo(
    () => (versionsList || []).filter((v) => !isVersionArchived(v)),
    [versionsList],
  )

  const [isCommentPanelOpen, setCommentPanelOpen] =
    useLayoutStateIsDiscussionsOpened()
  const handleCommentsButtonClick = useCallback(() => {
    setCommentPanelOpen(!isCommentPanelOpen)
  }, [isCommentPanelOpen, setCommentPanelOpen])

  useBoardParamsLocalStorageSyncEffect(defaultVersions)

  const settingsItems: TopbarSettingsItem[] = useMemo(
    () => [
      {
        content: (
          <PlanningVersionsAndDiffsSelect
            versions={versions}
            diffs={diffs}
            onChangeVersions={setVersions}
            onChangeDiffs={setDiffs}
            versionsData={versionsListWithoutArchived}
            showApplyButton={true}
            enableDiffsSelection={allowDiffsSelection}
          />
        ),
        sx: {
          marginRight: 'auto',
          marginLeft: 0,
          width: '100%',
        },
      },
      {
        content: periodSelectionComponent,
      },
      {
        content: noComments ? null : (
          <CommentButton
            sx={{
              backgroundColor: isCommentPanelOpen
                ? 'rgba(38, 54, 70, 0.08)'
                : '#fff',
            }}
            onClick={handleCommentsButtonClick}
          />
        ),
      },
      {
        content: <VersionCalculationStatus versionIds={versions} />,
        mostRight: true,
      },
    ],
    [
      versions,
      diffs,
      setVersions,
      setDiffs,
      versionsListWithoutArchived,
      allowDiffsSelection,
      periodSelectionComponent,
      noComments,
      isCommentPanelOpen,
      handleCommentsButtonClick,
    ],
  )

  return <Topbar settingsItems={settingsItems} disabled={isDesignMode} />
}

import {
  ChartSettings,
  MetricChartDimensions,
} from '@fintastic/web/feature/charts'
import React, { useMemo, useRef } from 'react'
import {
  ChartSettingsContext,
  ChartSettingsContextValue,
  ChartSettingsEditContext,
  ChartSettingsNavigationContext,
} from './contexts'
import { MemoryRouter, useLocation } from '@fintastic/shared/util/memory-router'
import { FintasticThemeProvider } from '@fintastic/shared/ui/mui-theme'
import { useEditChartSettings } from './hooks'
import { chartSettingsSectionRoutes } from './routes/routes'
import { Maybe } from '@fintastic/shared/util/types'

export type ChartSettingsContextWrapperProps = {
  widgetId: string
  loading?: boolean
  metricId: string
  versions: string[]
  chartSettings: ChartSettings // always from board/widget
  referenceSettings?: ChartSettings // may be from design mode
  usedDimensions?: MetricChartDimensions
  hasDifferentDimensions?: boolean
  handleUpdateChartWidgetSettings?: (
    chartSettings: Maybe<ChartSettings>,
  ) => void
}

export const ChartSettingsContextWrapper: React.FC<
  ChartSettingsContextWrapperProps
> = ({
  widgetId,
  metricId,
  versions,
  chartSettings,
  referenceSettings,
  handleUpdateChartWidgetSettings,
  usedDimensions,
  hasDifferentDimensions,
  loading,
}) => {
  const initialSettingsContextValue = useMemo<ChartSettingsContextValue>(
    () => ({
      widgetId,
      metricId,
      versions,
      initialChartSettings: chartSettings,
      referenceSettings,
      handleUpdateChartWidgetSettings,
      loading,
    }),
    [
      widgetId,
      metricId,
      versions,
      chartSettings,
      referenceSettings,
      handleUpdateChartWidgetSettings,
      loading,
    ],
  )
  const defaultInitialLocation = useRef(['/initial']) // ! must be stable singleton

  const location = useLocation(defaultInitialLocation.current)

  const settingsEditContextValue = useEditChartSettings({
    chartSettings,
    referenceSettings,
    handleUpdateChartWidgetSettings,
    usedDimensions,
    hasDifferentDimensions,
  })

  return (
    <FintasticThemeProvider applyLegacyTheme={false}>
      <ChartSettingsContext.Provider value={initialSettingsContextValue}>
        <ChartSettingsNavigationContext.Provider value={location}>
          <ChartSettingsEditContext.Provider value={settingsEditContextValue}>
            <MemoryRouter
              routes={chartSettingsSectionRoutes}
              location={location}
            />
          </ChartSettingsEditContext.Provider>
        </ChartSettingsNavigationContext.Provider>
      </ChartSettingsContext.Provider>
    </FintasticThemeProvider>
  )
}

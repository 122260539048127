import { styled, Typography } from '@mui/material'

export const StyledColumns = styled('div')`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
`

export const StyledColumnsTitle = styled('div')`
  padding-top: 2px;

  color: #6e665e;
`

export const StyledColumnsList = styled('div')`
  padding-top: 4px;
`

export const StyledColumnsListItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 8px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const StyledColumnsListItemIcon = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  svg {
    width: 24px;
    height: auto;
  }
`

export const StyledColumnsListItemTextContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: calc(100% - 32px);
`

export const StyledColumnsListItemDimensionChip = styled(Typography)`
  overflow: hidden;

  display: inline-block;
  padding: 4px 8px;

  text-overflow: ellipsis;
  line-height: 16px;
  white-space: nowrap;

  color: #7042c2;
  background: #faf7f5;
  border-radius: 4px;
`

export const StyledColumnsListItemColumnChip = styled(Typography)`
  overflow: hidden;

  display: inline-block;
  padding: 4px 8px;
  margin-right: auto;

  text-overflow: ellipsis;
  line-height: 16px;
  white-space: nowrap;

  color: #99491f;
  background: #faf7f5;
  border-radius: 4px;
`

export const StyledColumnsListItemLabel = styled(Typography)`
  overflow: hidden;

  margin-left: 8px;

  white-space: nowrap;
  text-overflow: ellipsis;

  min-width: 30%;
`

export const StyledColumnsListItemDataType = styled(Typography)`
  overflow: hidden;

  margin-left: 8px;
  margin-top: 6px;

  min-width: 30%;

  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
`

import { Operation, spawn, each, on, sleep, action } from 'effection'
import {
  isNullish,
  Nullable,
} from '@fintastic/shared/util/functional-programming'
import { DependenciesChangedEventTarget } from './event-targets'

type PositiveInteger = number
type Milliseconds = PositiveInteger

export type GetDependencyOptions = {
  retries?: PositiveInteger
  retryInterval?: Milliseconds
  debugLabel?: string
  debugDetails?: any
}

export type CheckDependencyOptions = {
  retries?: PositiveInteger
  retryInterval?: Milliseconds
  debugLabel?: string
  debugDetails?: any
}

export const makeDepencenciesOperations = <TDependencies>(params: {
  getDependencies: () => TDependencies
  dependenciesChangedChecker: DependenciesChangedEventTarget
}) => {
  const { getDependencies, dependenciesChangedChecker } = params

  function getDependency<TResult>(
    selector: (deps: TDependencies) => Nullable<TResult>,
    _options?: GetDependencyOptions,
  ): Operation<TResult> {
    const options = {
      ..._options,
      retries: _options === undefined ? 0 : _options.retries,
      retryInterval: _options === undefined ? 1000 : _options.retryInterval,
    } as GetDependencyOptions &
      Required<Pick<GetDependencyOptions, 'retries' | 'retryInterval'>>

    return action<TResult>(function* (resolve) {
      const firstTryResult = selector(getDependencies())
      if (!isNullish(firstTryResult)) {
        resolve(firstTryResult)
        return
      }

      if (options.retries === 0) {
        throw new DependencyIsNotDefinedError(
          options.debugLabel,
          options.debugDetails,
        )
      }

      const retriesCount = {
        count: 0,
      }
      yield* spawn(function* () {
        while (true) {
          if (retriesCount.count >= options.retries) {
            throw new DependencyIsNotDefinedError(
              options.debugLabel,
              options.debugDetails,
            )
          }
          yield* sleep(options.retryInterval)
          const result = selector(getDependencies())
          retriesCount.count += 1
          if (!isNullish(result)) {
            resolve(result)
          }
        }
      })

      yield* spawn(function* () {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const _ of yield* each(
          on(
            dependenciesChangedChecker,
            DependenciesChangedEventTarget.DEPENDENCIES_CHANGED_EVENT_NAME,
          ),
        )) {
          const result = selector(getDependencies())
          if (!isNullish(result)) {
            resolve(result)
          }
          yield* each.next()
        }
      })
    })
  }

  function checkDependency(
    predicate: (deps: TDependencies) => boolean,
    _options?: CheckDependencyOptions,
  ): Operation<void> {
    const options = {
      ..._options,
      retries: _options === undefined ? 0 : _options.retries,
      retryInterval: _options === undefined ? 1000 : _options.retryInterval,
    } as GetDependencyOptions &
      Required<Pick<CheckDependencyOptions, 'retries' | 'retryInterval'>>

    return action(function* (resolve) {
      const firstTryResult = predicate(getDependencies())
      if (firstTryResult) {
        resolve()
        return
      }

      if (options.retries === 0) {
        throw new DependencyCheckFailedError(
          options.debugLabel,
          options.debugDetails,
        )
      }

      const retriesCount = {
        count: 0,
      }
      yield* spawn(function* () {
        while (true) {
          if (retriesCount.count >= options.retries) {
            throw new DependencyCheckFailedError(
              options.debugLabel,
              options.debugDetails,
            )
          }
          yield* sleep(options.retryInterval)
          const result = predicate(getDependencies())
          retriesCount.count += 1
          if (result) {
            resolve()
          }
        }
      })

      yield* spawn(function* () {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const _ of yield* each(
          on(
            dependenciesChangedChecker,
            DependenciesChangedEventTarget.DEPENDENCIES_CHANGED_EVENT_NAME,
          ),
        )) {
          const result = predicate(getDependencies())
          if (result) {
            resolve()
          }
          yield* each.next()
        }
      })
    })
  }

  return { getDependency, checkDependency }
}

export class DependencyIsNotDefinedError extends Error {
  constructor(message?: string, public details?: unknown) {
    super(message)
    this.name = 'DependencyIsNotDefinedError'
    Object.setPrototypeOf(this, DependencyIsNotDefinedError.prototype)
  }
}

export class DependencyCheckFailedError extends Error {
  constructor(message?: string, public details?: unknown) {
    super(message)
    this.name = 'DependencyCheckFailedError'
    Object.setPrototypeOf(this, DependencyCheckFailedError.prototype)
  }
}

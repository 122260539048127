import { InactivityTimeoutError } from '@fintastic/shared/util/distributed-mono-task'
import { WithUserFriendlyErrorText } from '@fintastic/shared/util/errors'

export type DirectNavigationError =
  | InactivityTimeoutError
  | DirectNavigationSubtaskRuntimeError

export interface DirectNavigationErrorWithMeta
  extends WithUserFriendlyErrorText {
  getSeverity(): 'error' | 'warning'
}

export class DirectNavigationSubtaskRuntimeError
  extends Error
  implements DirectNavigationErrorWithMeta
{
  constructor(public cause: Error | DirectNavigationErrorWithMeta) {
    super()
    this.name = 'DirectNavigationSubtaskRuntimeError'
    Object.setPrototypeOf(this, DirectNavigationSubtaskRuntimeError.prototype)
  }

  getUiMessage(): string {
    if ('getUiMessage' in this.cause) {
      return this.cause.getUiMessage()
    }
    return 'Cannot navigate to the target. Something went wrong.'
  }

  getSeverity() {
    if ('getSeverity' in this.cause) {
      return this.cause.getSeverity()
    }
    return 'error'
  }
}

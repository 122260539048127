import {
  ChartColumnData,
  ChartDisplaySettings,
} from '@fintastic/web/feature/charts'
import { DataRow, HeaderRow } from './types'
import { ChartDefaultStyle } from './chart-default-style'
import { EChartsOption } from 'echarts'
import { createChartValueFormatter } from './chart-value-formatters'
import { Maybe } from '@fintastic/shared/util/types'
import { MetricMetadata } from '@fintastic/web/util/metrics-and-lists'
import { processLegendSettings } from './process-chart-legend'
import { processGridLinesSettings } from './process-chart-grid-lines'
import { processXAxis } from './process-xaxis-grid'
import { processYAxis } from './process-yaxis-grid'
import { processZoomPan } from './process-zoom-pan'

export const extractChartData = (data?: ChartColumnData) => {
  if (!data || data.length <= 1) {
    return {
      headers: [],
      meaningRows: [],
    }
  }

  const headers = (data[0] as HeaderRow).toSpliced(0, 1)
  const meaningRows = (data as DataRow[]).toSpliced(0, 1)

  return { headers, meaningRows }
}

export const getMinFromArray = (arr?: Array<number>): number =>
  (arr || []).reduce(
    (prev, cur) => (typeof cur === 'number' ? Math.min(cur, prev) : prev),
    +Infinity,
  )

export const getMaxFromArray = (arr?: Array<number>): number =>
  (arr || []).reduce(
    (prev, cur) => (typeof cur === 'number' ? Math.max(cur, prev) : prev),
    -Infinity,
  )

export type PrepareOptionsParams = {
  headers: string[]
  series: EChartsOption['series']
  metadata?: Maybe<MetricMetadata>
  displaySettings?: ChartDisplaySettings
  min?: number
  max?: number
  showZoomPan?: boolean
}

export const prepareOptions = ({
  headers,
  series,
  metadata,
  displaySettings,
  min,
  max,
  showZoomPan = false,
}: PrepareOptionsParams) => {
  const valueFormatter = createChartValueFormatter(metadata, displaySettings)

  const axisXLabel = (ChartDefaultStyle.xAxis as any).axisLabel || {}
  const axisYLabel = (ChartDefaultStyle.yAxis as any).axisLabel || {}

  const seriesWithTooltip: EChartsOption['series'] = Array.isArray(series || [])
    ? ((series || []) as Array<any>).map((s: any) => ({
        ...s,
        tooltip: { valueFormatter },
      }))
    : series

  const result = {
    ...ChartDefaultStyle,
    xAxis: {
      ...ChartDefaultStyle.xAxis,
      data: headers,
      axisLabel: {
        ...axisXLabel,
      },
    },
    yAxis: {
      ...ChartDefaultStyle.yAxis,
      type: 'value',
      axisLabel: {
        ...axisYLabel,
        formatter: valueFormatter,
      },
    },
    series: seriesWithTooltip,
  } as EChartsOption

  result.grid = {
    ...result.grid,
    // https://echarts.apache.org/en/option.html#grid.top
    right: 40,
    left: 20,
    top: 20,
    bottom: 20,
  }

  if (displaySettings) {
    // modify result settings according to display settings
    processLegendSettings(result, displaySettings, series, showZoomPan)
    processXAxis(result, displaySettings, headers)

    const maxYLabelLengthByMin =
      typeof min === 'number' ? valueFormatter(min).length : 0

    const maxYLabelLengthByMax =
      typeof max === 'number' ? valueFormatter(max).length : 0

    processYAxis(result, Math.max(maxYLabelLengthByMin, maxYLabelLengthByMax))

    processGridLinesSettings(result, displaySettings)
  }

  // result.toolbox = {
  //   showTitle: false,
  //   feature: {
  //     dataZoom: {
  //       //yAxisIndex: 'none',
  //       show: false,
  //     },
  //     saveAsImage: {
  //       show: true,
  //     },
  //   },
  // }

  if (showZoomPan) {
    processZoomPan(result, displaySettings)
  }

  return result
}

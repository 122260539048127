import React from 'react'
import { ViewLayout } from '@fintastic/shared/ui/view-layout'
import { useColumnByUrlParam } from './hooks/useColumnByUrlParam'
import { ColumnAggregationFunctionSettings } from '../settings/column-aggregation-function/ColumnAggregationFunctionSettings'
import { useActionsContext } from '../connection/actions/actions-context'

export const ColumnAggregationFunction: React.FC = () => {
  const column = useColumnByUrlParam()
  const { actions, readonly } = useActionsContext()

  return (
    <ViewLayout title={'Aggregation Function'}>
      {column && (
        <ColumnAggregationFunctionSettings
          column={column}
          onChangeCategoryAggregation={actions.changeColumnCategoryAggregation}
          onChangeTimeAggregation={actions.changeColumnTimeAggregation}
          readonly={!!readonly}
        />
      )}
    </ViewLayout>
  )
}

import { QueryClient } from 'react-query'
import { isListV2QueryKey } from './utils/query-keys'
import { inActiveTab } from '@fintastic/shared/util/web-api'

const invalidateAll = (queryClient: QueryClient) => {
  inActiveTab(() => {
    queryClient.invalidateQueries({
      predicate: (query) => isListV2QueryKey(query.queryKey),
    })
  })
}

const invalidateVersion = (queryClient: QueryClient, versionId: string) => {
  inActiveTab(() => {
    queryClient.invalidateQueries({
      predicate: (query) => isListV2QueryKey(query.queryKey, versionId),
    })
  })
}

const invalidateList = (
  queryClient: QueryClient,
  versionId: string,
  listId: string,
) => {
  inActiveTab(() => {
    queryClient.invalidateQueries({
      predicate: (query) => isListV2QueryKey(query.queryKey, versionId, listId),
    })
  })
}

const invalidateListColumn = (
  queryClient: QueryClient,
  versionId: string,
  listId: string,
  metricId: string,
) => {
  inActiveTab(() => {
    queryClient.invalidateQueries({
      predicate: (query) =>
        isListV2QueryKey(query.queryKey, versionId, listId, metricId),
    })
  })
}

const refetchListColumn = (
  queryClient: QueryClient,
  versionId: string,
  listId: string,
  metricId: string,
) => {
  inActiveTab(() => {
    queryClient.refetchQueries({
      predicate: (query) =>
        isListV2QueryKey(query.queryKey, versionId, listId, metricId),
    })
  })
}

export const invalidateV2MetricCache = {
  invalidateAll,
  invalidateVersion,
  invalidateList,
  invalidateListColumn,
  refetchListColumn,
}

import { useHistoryLogGlobalApi } from '@fintastic/web/data-access/history'
import { useEffect } from 'react'

export const useHistoryAutoClose = (
  versionIds: string[],
  isEditMode: boolean,
) => {
  const historyApi = useHistoryLogGlobalApi()

  useEffect(() => {
    if (
      isEditMode ||
      (historyApi?.state?.level === 'entity' &&
        !versionIds.includes(historyApi.state.versionId))
    ) {
      historyApi?.close()
    }
  }, [historyApi, versionIds, isEditMode])
}

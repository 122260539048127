import { fintasticTheme } from '../../../themes/fintastic.theme'
import { oceanTheme } from '../../../themes/ocean.theme'
import { springTheme } from '../../../themes/spring.theme'
import { vintageTheme } from '../../../themes/vintage.theme'
import { defaultChartTextStyle } from './chart-default-style'
import { ChartDisplaySettings } from '@fintastic/web/feature/charts'

export const processZoomPan = (
  option: any,
  settings?: ChartDisplaySettings,
) => {
  if (!option || !option.grid) {
    return
  }
  let currentFillColor = '#8e97a9'
  let currentBorder = '#838a9a'
  let currentHandleColor = '#afb9d0'
  const defaultTransparency = '20'
  const borderTransparency = '50'

  switch (settings?.theme) {
    case 'fintastic':
      currentFillColor = fintasticTheme.color[0] // #RRGGBBAA
      currentBorder = fintasticTheme.color[0]
      currentHandleColor = fintasticTheme.color[0]
      break
    case 'ocean':
      currentFillColor = oceanTheme.color[0]
      currentBorder = oceanTheme.color[0]
      currentHandleColor = oceanTheme.color[1]
      break
    case 'spring':
      currentFillColor = springTheme.color[0]
      currentBorder = springTheme.color[0]
      currentHandleColor = springTheme.color[1]
      break
    case 'vintage':
      currentFillColor = vintageTheme.color[0]
      currentBorder = vintageTheme.color[2]
      currentHandleColor = vintageTheme.color[3]
      break
  }
  option.dataZoom = [
    {
      type: 'slider',
      xAxisIndex: 0,
      start: 0,
      end: 20,
      // showDataShadow: false,
      textStyle: { ...defaultChartTextStyle },
      fillerColor: currentFillColor + defaultTransparency,
      borderColor: currentBorder + borderTransparency,
      dataBackground: {
        areaStyle: {
          color: currentFillColor + '80',
        },
        lineStyle: {
          color: 'transparent',
        },
      },
      selectedDataBackground: {
        areaStyle: {
          color: currentFillColor,
        },
        lineStyle: {
          color: currentBorder,
        },
      },
      handleStyle: {
        borderColor: currentBorder,
      },
      moveHandleStyle: {
        borderColor: currentBorder,
        color: currentHandleColor + 'E0',
        opacity: 1,
        // shadowColor: 'rgba(0, 0, 0, 0.2)',
        // shadowBlur: 1,
      },
    },
  ]

  option.grid.bottom = (option.grid.bottom as number) + 45
  if (settings?.legendPosition !== 'right') {
    option.grid.right = (option.grid.right as number) + 40
  }
  if (
    settings?.legendPosition !== 'left' &&
    (option.grid.left as number) < 80
  ) {
    option.grid.left = (option.grid.left as number) + 20 // usually at left we already have some extra space for labels
  }
}

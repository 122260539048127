import { useCallback, useMemo } from 'react'
import { Board, BoardFolder } from '../types'
import { useBoardUpdateMutation } from './useBoardUpdateMutation'
import { Maybe } from '@fintastic/shared/util/types'
import { queryClient } from '@fintastic/web/data-access/service-query-client'
import { boardsFoldersListQueryKeyGetter } from './useBoardsFoldersListQuery'

export function useBoardMoveToFolder() {
  const updateBoardMutation = useBoardUpdateMutation()

  const callback = useCallback(
    (boardId: Board['id'], folderId: Maybe<BoardFolder['id']>) =>
      updateBoardMutation.mutate(
        {
          boardId,
          folder_id: folderId,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries(boardsFoldersListQueryKeyGetter())
          },
        },
      ),
    [updateBoardMutation],
  )

  return useMemo(
    () => ({
      mutationState: updateBoardMutation,
      moveBoardToFolder: callback,
    }),
    [callback, updateBoardMutation],
  )
}

import { styled } from '@mui/material'

export const StyledType = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;
  margin-top: 4px;
`

export const StyledTypeLeft = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const StyledTypeRight = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  text-align: right;
`

export const StyledTypeText = styled('div')`
  margin-right: 6px;
`

export const StyledCalcTypeIcon = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: auto;
  }
`

export const StyledDataType = styled('div')``

export function injectSyntaxScript(apiUrl: string) {
  const src = `${apiUrl}/planning/static/fintastic-syntax.iife.js?t=${new Date().getTime()}`

  return new Promise((res, rej) => {
    try {
      const script = document.createElement('script')
      script.setAttribute('src', src)
      document.head.appendChild(script)
      script.onload = res
      script.onerror = rej
    } catch (e) {
      rej(e)
    }
  })
}

import { Loader } from './Loader'
import { Header } from './Header'
import { Type } from './Type'
import { Columns } from './Columns'
import { ScrollContainer } from './ScrollContainer'
import { Error } from './Error'

export const ListMetadataView = {
  Loader,
  Header,
  ScrollContainer,
  Type,
  Columns,
  Error,
}

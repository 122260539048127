import {
  mapDataTypeToItsLabel,
  MetricConfigurableDataValueType,
} from '@fintastic/web/util/metrics-and-lists'

export type DataTypeOption = {
  label: string
  value: MetricConfigurableDataValueType
}

export const dataTypeOptions: Array<DataTypeOption> = [
  {
    label: mapDataTypeToItsLabel('number'),
    value: 'number',
  },
  {
    label: mapDataTypeToItsLabel('integer'),
    value: 'integer',
  },
  {
    label: mapDataTypeToItsLabel('percentage'),
    value: 'percentage',
  },
  {
    label: mapDataTypeToItsLabel('percentage_integer'),
    value: 'percentage_integer',
  },
  {
    label: mapDataTypeToItsLabel('currency'),
    value: 'currency',
  },
  {
    label: mapDataTypeToItsLabel('boolean'),
    value: 'boolean',
  },
  {
    label: mapDataTypeToItsLabel('datetime'),
    value: 'datetime',
  },
  {
    label: mapDataTypeToItsLabel('string'),
    value: 'string',
  },
]

import { metricsAndListsCacheKeys } from '../cache'
import { PeriodSelection } from '@fintastic/web/util/period-selector'
import { makeLoadListQueryFn } from './useLoadListInDifferentVersions'
import { useQuery } from 'react-query'

export const useLoadListWithoutData = (
  versionId: string,
  listId: string,
  periodSelection?: PeriodSelection,
  enabled = true,
) =>
  useQuery({
    queryKey: metricsAndListsCacheKeys.listWithoutData(
      versionId,
      listId,
      periodSelection,
    ),
    queryFn: makeLoadListQueryFn(versionId, listId, periodSelection),
    enabled,
    retry: false,
  })

import React, { JSX, useCallback, useEffect, useMemo, useRef } from 'react'
import {
  AgGridThemeFintasticWrapper,
  getFintasticAgIconsRecord,
} from '@fintastic/shared/ui/ag-grid-theme-fintastic'
import {
  useColumnsPanelContext,
  columnsToolPanelDefinition,
} from '../../features/columns-visibility'
import clsx from 'clsx'
import { AgGridReact } from 'ag-grid-react'
import { SideBarDef } from 'ag-grid-community/dist/lib/entities/sideBar'
import {
  Metric,
  MetricOrListSource,
  extractSelectedCellFromEvent,
} from '@fintastic/web/util/metrics-and-lists'
import { useColDefs, useRowData } from '../../ag-grid'
import {
  RangeDimensionId,
  TimeDimensionValueId,
  WrappedDimension,
} from '@fintastic/web/util/dimensions'
import {
  addButtonsIdentifiers,
  agGridComponentsMap,
  useCopyFormattedValue,
} from '@fintastic/shared/ui/ag-grid'
import { listSettingsToolPanelDefinition } from '@fintastic/web/feature/metrics-and-lists'
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events'
import { ColDef, GridApi } from 'ag-grid-community'
import { Maybe } from '@fintastic/shared/util/types'
import { SetGridSizeCallback } from '@fintastic/shared/ui/grid-framework'
import {
  handleSelectedCellsForAggregation,
  HandleSelectedCellsForAggregationEvent,
  useSelectedCellAggregationAgGridContext,
} from '@fintastic/web/feature/selected-cell-aggregation'
import { MetricGridRow } from '@fintastic/web/util/metrics-and-lists'
import { useHandleLockedCellClick } from '../../features/data-editing/useHandleLockedCellClick'
import { VersionUserLockParsed } from '@fintastic/web/util/versions'
import {
  FloatingPanelHasFilter,
  FloatingPanelTrigger,
  listFilterToolPanelDefinition,
} from '@fintastic/web/feature/filters'
import {
  NoResultsOverlay,
  NoResultsOverlayProps,
} from '../../features/no-results-overlay'
import { useFilterContext } from '@fintastic/web/util/filters'

const icons = getFintasticAgIconsRecord()

export type PaginatedListGridProps = {
  versionId: string
  columns: Metric[]
  versionDimensions: WrappedDimension[]
  rowDimension: RangeDimensionId
  isLiveActuals: boolean
  listSource: Maybe<MetricOrListSource>
  versionUserLock: VersionUserLockParsed
  showSettingsPanel: boolean
  setGridSizeCallback?: SetGridSizeCallback
  renderColumnColorSelector: Maybe<
    (metricId: string, period: Maybe<TimeDimensionValueId>) => JSX.Element
  >
  listOriginallyEmpty: boolean
}

export const PaginatedListGrid: React.FC<PaginatedListGridProps> = ({
  versionId,
  columns,
  versionDimensions,
  rowDimension,
  isLiveActuals,
  listSource,
  showSettingsPanel,
  versionUserLock,
  renderColumnColorSelector,
  setGridSizeCallback,
  listOriginallyEmpty,
}) => {
  const containerRef = useRef<Maybe<HTMLDivElement>>(null)
  const gridApiRef = useRef<Maybe<GridApi>>(null)

  const columnsVisibility = useColumnsPanelContext()
  const atListOneColumnHidden = columnsVisibility.hiddenColumns.length > 0
  const filterContext = useFilterContext()

  const colDefs = useColDefs(
    versionId,
    columns,
    useMemo(
      () => ({
        isLiveActuals,
        isCalculatedList: listSource === 'calculated',
      }),
      [isLiveActuals, listSource],
    ),
    useMemo(
      () => ({
        versionDimensions,
      }),
      [versionDimensions],
    ),
    useMemo(
      () => ({
        column: (colDef: ColDef, context: { columnId: string }) => ({
          ...colDef,
          headerComponentParams: {
            ...colDef.headerComponentParams,
            contentBeforeMenu: renderColumnColorSelector
              ? renderColumnColorSelector(context.columnId, null)
              : null,
            ContentAfterMenuComponent: FloatingPanelTrigger,
            ContentAfterTextComponent: FloatingPanelHasFilter,
          },
        }),
        periodSubColumn: (
          colDef: ColDef,
          context: { columnId: string; period: TimeDimensionValueId },
        ) => ({
          ...colDef,
          headerComponentParams: {
            ...colDef.headerComponentParams,
            contentBeforeMenu: renderColumnColorSelector
              ? renderColumnColorSelector(context.columnId, context.period)
              : null,
            ContentAfterMenuComponent: FloatingPanelTrigger,
            ContentAfterTextComponent: FloatingPanelHasFilter,
          },
        }),
      }),
      [renderColumnColorSelector],
    ),
  )
  const rowData = useRowData(
    columns,
    useMemo(
      () => ({
        versionId,
        rowDimension,
      }),
      [rowDimension, versionId],
    ),
  )

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        columnsToolPanelDefinition,
        listFilterToolPanelDefinition,
        ...(showSettingsPanel ? [listSettingsToolPanelDefinition] : []),
      ],
    }),
    [showSettingsPanel],
  )

  const dispatchGridSizeCallback = useCallback(() => {
    if (!gridApiRef.current || !setGridSizeCallback) {
      return
    }
    const themeSizes = gridApiRef.current.getSizesForCurrentTheme()
    setGridSizeCallback({
      rows: rowData.length,
      rowHeight: themeSizes.rowHeight,
      headerHeight: themeSizes.headerHeight || 0,
    })
  }, [rowData.length, setGridSizeCallback])

  useEffect(() => {
    dispatchGridSizeCallback()
  }, [dispatchGridSizeCallback])

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      if (containerRef.current) {
        addButtonsIdentifiers(containerRef.current)
      }
      gridApiRef.current = event.api
      dispatchGridSizeCallback()
    },
    [dispatchGridSizeCallback],
  )

  const handleOnFirstDataRendered = useCallback(() => {
    if (containerRef.current) {
      addButtonsIdentifiers(containerRef.current)
    }
  }, [])

  const agContext = useSelectedCellAggregationAgGridContext()

  const handleRangeSelectionChanged = useCallback(
    (e: HandleSelectedCellsForAggregationEvent<MetricGridRow>) => {
      handleSelectedCellsForAggregation<MetricGridRow>(
        e,
        extractSelectedCellFromEvent,
      )
    },
    [],
  )

  const handleLockedCellClick = useHandleLockedCellClick(
    listSource,
    versionUserLock,
  )
  const handleCopyFormattedValue = useCopyFormattedValue()

  return (
    <AgGridThemeFintasticWrapper
      ref={containerRef}
      sx={{
        'flex': 1,
        'width': '100%',
        'height': '100%',
        '&.at-least-one-column-hidden': {
          '[data-icon="custom-columns"]': {
            background: 'var(--fintastic-applied-sidebar-button-bg)',
          },
        },
        '&.api-filter-is-applied': {
          '[data-icon="native-filter"]': {
            background: 'var(--fintastic-applied-sidebar-button-bg)',
          },
        },
      }}
      className={clsx({
        'at-least-one-column-hidden': atListOneColumnHidden,
        'api-filter-is-applied': filterContext.filterIsApplied,
      })}
      themeOptions={{
        inscribedShape: true,
      }}
    >
      {({ defaultGridProps }) => (
        <AgGridReact<MetricGridRow>
          {...defaultGridProps}
          sideBar={sideBar}
          icons={icons}
          rowData={rowData}
          columnDefs={colDefs}
          components={agGridComponentsMap}
          onGridReady={onGridReady}
          suppressExcelExport={true}
          suppressCsvExport={true}
          context={agContext}
          processCellForClipboard={handleCopyFormattedValue}
          onRangeSelectionChanged={handleRangeSelectionChanged}
          onModelUpdated={handleRangeSelectionChanged}
          onFirstDataRendered={handleOnFirstDataRendered}
          onCellClicked={handleLockedCellClick}
          noRowsOverlayComponent={NoResultsOverlay}
          noRowsOverlayComponentParams={
            { listOriginallyEmpty } as NoResultsOverlayProps
          }
        />
      )}
    </AgGridThemeFintasticWrapper>
  )
}

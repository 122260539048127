/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react'

export const usePrevValues = <TValue>(
  value: TValue,
  historyLength = 1,
): (TValue | undefined)[] => {
  const historyRef = useRef<TValue[]>([])

  if (!Object.is(historyRef.current[0], value)) {
    historyRef.current = [value, ...historyRef.current].slice(
      0,
      historyLength + 1,
    )
  }

  return historyRef.current.slice(1)
}
